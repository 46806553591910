import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// ----------------------------------------------------------------------
const ButtonStyle = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  background: '#FFFFFF',
  color: '#3C60B5',
  borderRadius: '5px',
  '&:hover': { color: '#FFFFFF' }
}));
export default function DoneMeasureButton({ ...props }) {
  const theme = useTheme();
  return (
    <ButtonStyle
      sx={{}}
      variant="outlined"
      aria-label="select merge strategy"
      aria-haspopup="menu"
      onClick={() => console.log()}
      {...props}
    >
      Terminer
    </ButtonStyle>
  );
}
