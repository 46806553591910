import { alpha } from '@mui/material/styles';

// ----------------------------------------------------------------------

function createGradient(color1, color2) {
  return `linear-gradient(to bottom, ${color1}, ${color2})`;
}

// SETUP COLORS
const PRIMARY = {
  lighter: '#b9d9fc', //'#e7e381', //'#DCFDE9',
  light: '#52A2F9', //'#d2b239', // '#96F6CD',
  main: '#668AF5', //'#b6963a', // '#4FE3C1',
  dark: '#3379C5', //'#6a5732', //'#27A39E',
  darker: '#2C4DB0' //'#5d4525' //'#0F5E6C',
};
const SECONDARY = {
  lighter: '#FDE1DC',
  light: '#F69699',
  main: '#E34F71',
  dark: '#A3275B',
  darker: '#6C0F48'
};
const INFO = {
  lighter: '#D3EAFF',
  light: '#7AB7FF',
  main: '#2377FF',
  dark: '#1144B7',
  darker: '#06207A'
};
const SUCCESS = {
  lighter: '#DAFDD4',
  light: '#7EF483',
  main: '#2BDB54',
  dark: '#7EF483',
  darker: '#086944'
};
const WARNING = {
  lighter: '#FFF6CF',
  light: '#FFDC6F',
  main: '#FFB70F',
  dark: '#B77707',
  darker: '#7A4702'
};
const ERROR = {
  lighter: '#FFE2DB',
  light: '#FF9695',
  main: '#FF4F6F',
  dark: '#B7275D',
  darker: '#7A0F4B'
};

const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24',
  900_80: alpha('#161C24', 0.8),
  900_90: alpha('#161C24', 0.9),
  500_8: alpha('#919EAB', 0.08),
  500_12: alpha('#919EAB', 0.12),
  500_16: alpha('#919EAB', 0.16),
  500_24: alpha('#919EAB', 0.24),
  500_32: alpha('#919EAB', 0.32),
  500_48: alpha('#919EAB', 0.48),
  500_56: alpha('#919EAB', 0.56),
  500_80: alpha('#919EAB', 0.8)
};

const GRADIENTS = {
  primary: createGradient(PRIMARY.light, PRIMARY.main),
  info: createGradient(INFO.light, INFO.main),
  success: createGradient(SUCCESS.light, SUCCESS.main),
  warning: createGradient(WARNING.light, WARNING.main),
  error: createGradient(ERROR.light, ERROR.main)
};
const CHART_COLORS = {
  violet: ['#826AF9', '#9E86FF', '#D0AEFF', '#F7D2FF'],
  blue: ['#2D99FF', '#83CFFF', '#A5F3FF', '#CCFAFF'],
  green: ['#2CD9C5', '#60F1C8', '#A4F7CC', '#C0F2DC'],
  yellow: ['#FFE700', '#FFEF5A', '#FFF7AE', '#FFF3D6'],
  red: ['#FF6C40', '#FF8F6D', '#FFBD98', '#FFF2D4']
};
const COMMON = {
  common: { black: '#000', white: '#fff' },
  primary: { ...PRIMARY, contrastText: '#ffff' },
  secondary: { ...SECONDARY, contrastText: '#fff' },
  info: { ...INFO, contrastText: '#fff' },
  success: { ...SUCCESS, contrastText: GREY[800] },
  warning: { ...WARNING, contrastText: GREY[800] },
  error: { ...ERROR, contrastText: '#fff' },
  grey: GREY,
  gradients: GRADIENTS,
  chart: CHART_COLORS,
  divider: GREY[500_24],
  action: {
    hover: GREY[500_8],
    selected: GREY[500_16],
    disabled: GREY[500_80],
    disabledBackground: GREY[500_24],
    focus: GREY[500_24],
    hoverOpacity: 0.08,
    disabledOpacity: 0.48
  }
};

const palette = {
  // LIGHT MODE
  light: {
    ...COMMON,
    mode: 'light',
    text: {
      primary: GREY[800],
      secondary: GREY[600],
      disabled: GREY[500]
    },
    background: {
      paper: '#fff',
      default: '#fff',
      neutral: GREY[200]
    },
    action: {
      active: GREY[600],
      ...COMMON.action
    }
  },

  // DARK MODE
  dark: {
    ...COMMON,
    mode: 'dark',
    text: {
      primary: '#fff',
      secondary: GREY[500],
      disabled: GREY[600]
    },
    background: {
      paper: GREY[800],
      default: GREY[900],
      neutral: GREY[500_16]
    },
    action: {
      active: GREY[500],
      ...COMMON.action
    }
  }
};

export default palette;
