import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 0, 0)
  // padding: theme.spacing(1, 1, 1, 0)
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '90px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

export default function SpecificationListFilter({
  optionsSpecTypes,
  handleChangeFilters,
  type,
  ...props
}) {
  const [currentZIndex, setcurrentZIndex] = useState(-1);
  const handleChange = (event) => {
    handleChangeFilters({ type: event.target.value });
  };
  const handeFocus = () => {
    if (currentZIndex > -1) return;
    setcurrentZIndex(2);
  };
  const handleFocusOut = () => {
    setcurrentZIndex(-1);
  };
  return (
    <RootStyle>
      <FormControl variant="standard">
        <Select
          fullWidth
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={type}
          onChange={handleChange}
          input={<BootstrapInput onFocus={handeFocus} onfocusout={handleFocusOut} />}
          menuContainerStyle={{ zIndex: 999 }}
          MenuProps={{
            style: { zIndex: currentZIndex }
          }}
        >
          {optionsSpecTypes.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.id}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </RootStyle>
  );
}
