import React from 'react';
import { Navigate ,useLocation} from 'react-router-dom';
import useAuth from '../hooks/useAuth';
 import { PATH_PAGE } from '../config/paths';
// ----------------------------------------------------------------------
export default function RequireAuth({ children }) {
  const { isAuthenticated } = useAuth();
  const location = useLocation();
  return isAuthenticated === true ? (
    children
  ) : (
    <Navigate to={PATH_PAGE.auth.resetPassword} replace state={{ path: location.pathname }} />
  );
}
