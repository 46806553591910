import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, NavLink as RouterLink, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP, BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import { NAV_URL_COM } from 'config/paths';
// ----------------------------------------------------------------------
export default function TypoForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();
  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            <SelectTypo building={editProject.building} />
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, [editProject]);
}
const ContentBoxMargin = 63;
const RootStyle = styled(Box)(({ image, theme }) => {
  const img = image ? image : `${process.env.PUBLIC_URL}/static/bg/bg_typology.svg`;
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflowX: 'hidden',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover'
  };
});
const RightSideStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '366px',
  height: `100vh`,
  background: ' #F9F9F9',
  boxShadow: `0px 8px 25px rgba(0, 0, 0, 0.15)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingRight: '16px',
  paddingLeft: '16px',
  paddingBottom: theme.spacing(2)
}));
const TitleBoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '166px',
  background: `linear-gradient(68deg, #85A9E6 0.04%, #B0D9E2 99.19%, rgba(255, 255, 255, 0) 101.68%)`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 0, 0)
}));
const ContentBoxStyle = styled(Box)(({ theme }) => ({
  overflow: 'auto',
  width: '100%',
  height: '100%',
  background: `linear-gradient(360deg, #FFFFFF -2.96%, rgba(255, 255, 255, 0) 136.82%)`,
  backdropFilter: `blur(25px)`,
  borderRadius: '20px',
  marginTop: `${ContentBoxMargin}px`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: theme.spacing(3, 0, 0, 0)
}));
const ContentTypoStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(0, 0, 0, 0),
  padding: theme.spacing(0, 2, 0, 2),
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const TypoImgStyle = styled(Box)(({ image, theme }) => {
  const img =
    image && typeof image === 'string'
      ? image
      : `${process.env.PUBLIC_URL}/static/bg/bg_school.svg`;
  return {
    width: '320px',
    height: '180px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img})`,
    backgroundSize: 'contain'
  };
});
const SurfaceBtnStyle = styled(Box)(({ selected, theme }) => ({
  width: '85px',
  height: '31px',
  background: selected ? '#93D5FE' : '#E1E4E6',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));
const GlazingBtnStyle = styled(Box)(({ width, height, selected, theme }) => ({
  width: width ? width : '85px',
  height: height ? height : '85px',
  background: selected ? '#93D5FE' : '#E1E4E6',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: theme.spacing(0, 0, 2, 0)
}));

const FloorsBtnStyle = styled(Button)(({ colored, theme }) => ({
  width: '55px',
  height: '55px',
  background: colored === 1 ? '#93D5FE' : '#FFFFFF',
  color: colored === 1 ? '#FFFFFF' : '#93D5FE',
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px'
}));

const LabelStyle = styled(Typography)(({ selected, theme }) => ({
  textAlign: 'left',
  color: '#121E6E',
  width: '100%',
  margin: theme.spacing(1, 0, 1, 0)
}));

const SelectTypo = ({ building, ...props }) => {
  const navigate = useNavigate();
  const { editProject, onUpdateEditProject } = useClientEditProject();
  const theme = useTheme();
  const [surfaceSelected, setSurfaceSelected] = useState(null);
  const [glazingType, setGlazingType] = useState(building ? building.glazingType : null);
  const [floorsNb, setFloorsNb] = useState(0);
  const [ignoredFloorsNb, setIgnoredFloorsNb] = useState(false);
  const [roofType, setRoofType] = useState(null);
  useEffect(() => {
    if (building) {
      setSurfaceSelected(building.surfaceSelected);
      setGlazingType(building.glazingType);
      setFloorsNb(building.floorsNb ? building.floorsNb : 0);
      setIgnoredFloorsNb(building.ignoredFloorsNb);
      setRoofType(building.roofType);
    }
  }, [building]);
  const validateTypo = () => {
    const newProject = { ...editProject };
    newProject.building = {
      ...building,
      surfaceSelected,
      glazingType,
      floorsNb,
      ignoredFloorsNb,
      roofType
    };
    onUpdateEditProject(newProject);
    navigate(NAV_URL_COM.scope);

    //  onNextConfig({ surfaceSelected, glazingType, floorsNb, ignoredFloorsNb, roofType })
  };
  return (
    <RootStyle image={building.type.background}>
      <RightSideStyle>
        <TitleBoxStyle>
          {/* <Typography variant="h4" sx={{ color: '#FFFFFF' }}>
            Typologie
          </Typography> */}
        </TitleBoxStyle>
        <ContentBoxStyle>
          <ContentTypoStyle>
            <h3 style={{ color: '#FFFFFF' }}>{building.type.label}</h3>
            <TypoImgStyle image={building.type.photo} />
            <SurfaceSelect select={surfaceSelected} onSelect={(s) => setSurfaceSelected(s)} />
            <GlazingSelect select={glazingType} onSelect={(g) => setGlazingType(g)} />
            <FloorsSelect
              value={floorsNb}
              handleClick={(v) => setFloorsNb((prev) => prev + v)}
              onIgnore={(isIgnored) => {
                setIgnoredFloorsNb(isIgnored);
              }}
            />
            <RoofSelect select={roofType} onSelect={(r) => setRoofType(r)} />
          </ContentTypoStyle>
        </ContentBoxStyle>
        <GradientBtn
          size="large"
          color="info"
          variant="contained"
          onClick={validateTypo}
          sx={{ margin: theme.spacing(3, 0, 0, 0), width: '251px' }}
        >
          Suivant
        </GradientBtn>
      </RightSideStyle>
    </RootStyle>
  );
};
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const SurfaceSelect = ({ select, onSelect, ...props }) => {
  const { surfaces } = useSelector((state) => state.referential);
  const [selectValue, setselectValue] = useState('');
  useEffect(() => {
    if (!select) return;
    setselectValue(select.code);
  }, [select]);
  const handleChange = (event) => {
    const opt = surfaces.find((it) => String(it.code) === String(event.target.value));
    onSelect(opt);
  };
  return useMemo(() => {
    if (!surfaces) return <></>;
    return (
      <>
        <LabelStyle variant="body1">Superficie du bâtiment</LabelStyle>
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ width: '100%' }}>
          <Select
            fullWidth
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={selectValue}
            onChange={handleChange}
            input={<BootstrapInput />}
          >
            {surfaces.map((opt, i) => {
              return (
                <MenuItem key={i} value={opt.code}>
                  {opt.label}
                </MenuItem>
              );
            })}
          </Select>
          {/*  {surfaces.map((opt) => {
            return (
              <SurfaceBtnStyle
                key={Math.random()}
                selected={select ? opt.code === select.code : false}
                onClick={() => onSelect(opt)}
              >
                <Typography variant="body2">{opt.label}</Typography>
              </SurfaceBtnStyle>
            );
          })} */}
        </Box>
      </>
    );
  }, [surfaces, selectValue]);
};

const GlazingSelect = ({ select, onSelect, ...props }) => {
  const { glazing_types } = useSelector((state) => state.referential);
  return useMemo(() => {
    if (!glazing_types) return <></>;
    return (
      <>
        <LabelStyle variant="body1">Type de vitrage</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {glazing_types.map((opt) => {
            return (
              <Box key={Math.random()} display="flex" flexDirection="column" alignItems="center">
                <GlazingBtnStyle
                  selected={select ? opt.code === select.code : false}
                  onClick={() => onSelect(opt)}
                >
                  {opt.lines && Number(opt.lines) > 0 && (
                    <>
                      {[...Array(opt.lines)].map((line, i) => {
                        return (
                          <Box
                            key={i}
                            sx={{
                              background: '#FFFFFF',
                              width: '8px',
                              height: '58px',
                              marginLeft: i > 0 ? '7px' : 0
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                </GlazingBtnStyle>
                <Typography variant="subtitle1">{opt.label}</Typography>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }, [glazing_types, select]);
};
const MAX_NBFLOORS = 10;
const FloorsSelect = ({ value, handleClick, onIgnore, ...props }) => {
  const theme = useTheme();
  const { floors } = useSelector((state) => state.referential);
  return useMemo(() => {
    if (!floors) return <></>;
    return (
      <>
        <LabelStyle variant="body1">Nombre d'étages</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          sx={{ width: '100%', margin: theme.spacing(0, 0, 2, 0) }}
        >
          <FloorsBtnStyle
            aria-label="Moins"
            size="large"
            onClick={() => {
              if (value >= 1) {
                handleClick(-1);
              }
            }}
            colored={value > 0 ? 1 : 0}
          >
            <RemoveIcon />
          </FloorsBtnStyle>
          <Typography
            sx={{
              fontFamily: 'Proxima Nova',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '36px',
              lineHeight: '44px',

              textAlign: 'center',
              letterSpacing: '0.1em',
              textTransform: 'uppercase',

              color: '#000000',
              margin: theme.spacing(0, 2, 0, 2)
            }}
          >
            {value}
          </Typography>
          <FloorsBtnStyle
            aria-label="Plus"
            size="large"
            onClick={() => {
              if (value < MAX_NBFLOORS) {
                handleClick(1);
              }
            }}
            colored={value < MAX_NBFLOORS ? 1 : 0}
          >
            <AddIcon />
          </FloorsBtnStyle>
        </Box>
        <Box width="100%">
          <FormControlLabel
            onChange={(e) => {
              onIgnore(e.target.checked);
            }}
            control={<Checkbox />}
            label={<Typography sx={{ fontFamily: 'ProximaMedium' }}>Je ne sais pas</Typography>}
            sx={{ alignSeft: 'left' }}
          />
        </Box>
      </>
    );
  }, [floors, value]);
};

const RoofSelect = ({ select, onSelect, ...props }) => {
  const { roof_types } = useSelector((state) => state.referential);
  const getWidthImg = () => {
    try {
      const nbRoof = roof_types.length;
      const res = Number(300 / nbRoof - 5).toFixed(0);
      return res > 50 ? res : 50;
    } catch (error) {
      return 'auto';
    }
  };
  const getTypo = (width) => {
    try {
      if (width >= 85) {
        return 'subtitle1';
      }
      if (width < 85 && width >= 60) {
        return 'subtitle2';
      }
      return 'caption';
    } catch (error) {
      return 'caption';
    }
  };
  return useMemo(() => {
    if (!roof_types) return <></>;
    return (
      <>
        <LabelStyle variant="body1">Type de toit</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: '100%',
            overflowX: 'auto',
            overflowY: 'hidden',
            minHeight: '120px'
          }}
        >
          {roof_types.map((opt) => {
            return (
              <Box
                key={Math.random()}
                display="flex"
                flexDirection="column"
                alignItems="center"
                sx={{ mx: 1 }}
              >
                <GlazingBtnStyle
                  selected={select ? opt.code === select.code : false}
                  onClick={() => onSelect(opt)}
                  width={`${getWidthImg()}px`}
                  height={`${getWidthImg()}px`}
                >
                  {opt.code && opt.code > 1 && (
                    <img src={`/static/bg/bg_roof_${opt.code}.svg`} alt={opt.label} />
                  )}
                </GlazingBtnStyle>
                <Typography variant={getTypo(getWidthImg())} sx={{ textTransform: 'uppercase' }}>
                  {opt.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }, [roof_types, select]);
};
