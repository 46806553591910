import React, { useContext, useCallback, useState } from 'react';
import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Stack from '@mui/material/Stack';
import { useTheme, styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import { useDispatch, useSelector } from 'react-redux';
import GradientBtn from 'components/custom/GradientBtn';
import FullScalableVideo from 'components/player/FullScalableVideo';
import { EditProjectContext } from 'provider/EditProjectProvider';
import Drawer from '@mui/material/Drawer';
import { TransitionGroup } from 'react-transition-group';
import BackIcon from 'components/custom/icons/BackIcon';
import DrawerSpecDetail from './DrawerSpecDetail';
// ----------------------------------------------------------------------
const BuildingTypesStackStyle = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center'
}));
const BackButtonStyle = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '30px',
  left: '30px',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const DrawerStyle = styled(Box)(({ theme }) => ({
  background: 'transparent!important',
  flexDirection: 'row!important',
  boxShadow: 'none!important'
}));

export default function FlowSelectBuilding({ flow, ...props }) {
  const { onChangeFlow } = useContext(EditProjectContext);
  const theme = useTheme();
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayDrawerSpec, setDisplayDrawerSpec] = useState(null);
  const [displaySpecs, setDisplaySpecs] = useState({ open: false, buildingTypeSelected: null });
  const [url, setUrl] = useState('https://abbsmartsocieties.com/r/vd%201440/Land_Main.mp4');
  const { buildingTypes, specifications } = useSelector((state) => state.referential);
  const viewSpecsByBuildingType = (bType) => {
    setUrl('https://abbsmartsocieties.com/r/vd%201280/5.mp4');
    setDisplaySpecs({ open: true, buildingTypeSelected: bType });
  };
  const returnToSelectBuilding = ({ flow, ...props }) => {
    setUrl('https://abbsmartsocieties.com/r/vd%201440/Land_Main.mp4');
    setDisplaySpecs({ open: false, buildingTypeSelected: null });
  };
  const viewSpecsDetail = (s) => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
    setDisplayDrawerSpec(s);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDisplayDrawerSpec(null);
  };
  /*  */
  const onChangeSpec = useCallback(
    (pos) => {
      if (!specifications || !displayDrawerSpec) return;
      const currentIdx = specifications.findIndex((it) => {
        return displayDrawerSpec.id === it.id;
      });
      if (currentIdx < 0) return;
      if (currentIdx === specifications.length - 1 && pos > 0) {
        setDisplayDrawerSpec(specifications[0]);
      } else if (currentIdx === 0 && pos < 0) {
        setDisplayDrawerSpec(specifications[specifications.length - 1]);
      } else {
        setDisplayDrawerSpec(specifications[currentIdx + pos]);
      }
    },
    [displayDrawerSpec]
  );
  const onSelectSpec = useCallback(() => {
    onChangeFlow(flow, displaySpecs.buildingTypeSelected);
  }, [displayDrawerSpec]);
  return (
    <>
      <FullScalableVideo
        url={url}
        preview="https://abbsmartsocieties.com/r/screenshots_/Land_Main_.png"
      />
      {displaySpecs.open ? (
        <>
          <BuildingTypesStackStyle>
            {specifications && (
              <Box display="flex" flexDirection="column">
                {specifications.map((it, i) => {
                  return (
                    <Button
                      key={Math.random()}
                      variant="contained"
                      color="warning"
                      onClick={() => {
                        viewSpecsDetail(it);
                      }}
                      sx={{ margin: i > 0 ? theme.spacing(2, 0, 0, 0) : 0 }}
                    >
                      {it.label}
                    </Button>
                  );
                })}
              </Box>
            )}
          </BuildingTypesStackStyle>
          <BackButtonStyle onClick={returnToSelectBuilding} size="small">
            <BackIcon />
          </BackButtonStyle>
        </>
      ) : (
        <BuildingTypesStackStyle>
          {buildingTypes && (
            <TransitionGroup>
              {buildingTypes.map((it, i) => {
                return (
                  <Collapse
                    key={Math.random()}
                    sx={{ margin: i > 0 ? theme.spacing(2, 0, 0, 0) : 0 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => {
                        viewSpecsByBuildingType(it);
                      }}
                    >
                      {it.label}
                    </Button>
                    {/*  <GradientBtn size="small" color="info" variant="contained">
                    {it.label}
                  </GradientBtn> */}
                  </Collapse>
                );
              })}
            </TransitionGroup>
          )}
        </BuildingTypesStackStyle>
      )}
      <Drawer
        anchor="right"
        open={openDrawer}
        onClose={onCloseDrawer}
        ModalProps={{
          keepMounted: true
        }}
        PaperProps={{ component: DrawerStyle }}
        hideBackdrop
      >
        <DrawerSpecDetail
          spec={displayDrawerSpec}
          onCloseDrawer={onCloseDrawer}
          onChangeSpec={onChangeSpec}
          onSelectSpec={onSelectSpec}
        />
      </Drawer>
    </>
  );
}
