import React from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import SaveIcon from '@mui/icons-material/Save';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useTechEditProject from 'hooks/useTechEditProject';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'theme/@material-extend/Buttons';
import closeFill from '@iconify-icons/eva/close-fill';
// ----------------------------------------------------------------------
export default function AddNoteToOrder({ project, ...props }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const [value, setValue] = React.useState('');
  const { problematics, saveOrder, initOrderProject } = useTechEditProject();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handeSave = async () => {
    try {
      if (!project) return;
      console.log(value);
      const newPrj = { ...project };
      newPrj.order = {
        status: 'draft',
        note: value,
        description: 'Merci de nous tenir informés des délais de livraison des produits suivants'
      };
      await saveOrder(newPrj);
      enqueueSnackbar('Sauvegarde réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <Button
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          fontFamily: 'Proxima',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '19px',
          color: '#3C60B5',
          textTransform: 'none'
        }}
      >
        <img
          src="/static/icons/ic_add_circle_blue.svg"
          alt="Nouveau commentaire"
          style={{ margin: theme.spacing(0, 2, 0, 0) }}
        />
        Ajouter un commentaire
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Box
          component="form"
          sx={{
            '& .MuiTextField-root': { m: 1, width: '25ch' },
            padding: theme.spacing(3)
          }}
          noValidate
          autoComplete="off"
        >
          <TextField
            id="outlined-multiline-static"
            label="Commentaires"
            multiline
            rows={4}
            defaultValue=""
            onChange={handleChange}
          />
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <IconButton color="primary" onClick={handeSave}>
              <SaveIcon />
            </IconButton>
            <IconButton onClick={handleClose}>
              <HighlightOffIcon />
            </IconButton>
          </Box>
        </Box>
      </Popover>
    </div>
  );
}
