import React, { useState, useEffect, useContext } from 'react';
import { useLocation, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { ProjectContext } from 'provider/ProjectProvider';
import ProjectList from 'components/project/ProjectList';
import ProjectListFilter from 'components/project/ProjectListFilter';
import IconButton from '@mui/material/IconButton';
import { styled, useTheme } from '@mui/material/styles';
import GradientBtn from 'components/custom/GradientBtn';
import AddIcon from '@mui/icons-material/Add';
import { PATH_CLIENT, PATH_TECH } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';
import Fade from '@mui/material/Fade';
import useAuth from 'hooks/useAuth';
import { CLIENT_PROFILE, TECH_PROFILE, isClientProfile, isTechProfile } from 'config/appConfig';
// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  padding: theme.spacing(0, 0, 5, 0),
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end'
}));
const ProductLisStyle = styled(Box)(({ theme }) => ({
  height: '100%',
  //backgroundColor: theme.palette.grey[0],
  display: 'flex',
  // boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.1)',
  borderRadius: '20px'
  // margin: theme.spacing(1, 0, 0, 0)
  //padding: theme.spacing(12, 6, 12, 6),
}));
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const ColorButton = styled(IconButton)(({ theme }) => {
  return {
    //border: '1px solid #FFFFFF',
    padding: '11px',
    background: `linear-gradient(93.46deg, #668AF5 37.45%, #52A2F9 93.7%)`,
    boxShadow: '0px 8px 25px rgba(99, 142, 244, 0.46)',
    width: '60px',
    minHeight: '60px'
  };
});
export default function ProjectPage({ ...props }) {
  const theme = useTheme();
  const { projects, handleChangeFilters, filters, optionsState } = useContext(ProjectContext);
  const { sceneLoaded, scene, handleUpdScene } = useProjectUnity();
  const [showPage, setShowPage] = useState(false);
  const { user } = useAuth();
  const location = useLocation();

  useEffect(() => {
    handleUpdScene({
      name: 'Mascotte',
      updTime: new Date().getTime()
    });
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 2000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const displayAddBtn = (byProfile) => {
    try {
      if (!user) return false;
      if (!location || !location.pathname) return false;
      const path = location.pathname;

      const pathArr = path.split('/');
      if (pathArr[1].includes(byProfile) && isClientProfile(user)) {
        return true;
      }
      if (pathArr[1].includes(byProfile) && isTechProfile(user)) {
        return true;
      }

      return false;
    } catch (error) {
      return false;
    }
  };
  return (
    <>
      {!showPage && (
        <BoxProgrestStyle>
          <CircularProgress size={60} sx={{ color: '#668AF5' }} />
        </BoxProgrestStyle>
      )}
      <Fade in={showPage}>
        <RootStyle>
          {displayAddBtn(CLIENT_PROFILE) && (
            <Box display="flex" justifyContent="center" alignItems="center">
              <ColorButton
                component={RouterLink}
                to={PATH_CLIENT.project}
                sx={{ mx: theme.spacing(3) }}
              >
                <img src="/static/icons/ic_shotcut.svg" alt="" />
              </ColorButton>
              <GradientBtn
                size="lg"
                color="info"
                variant="contained"
                component={RouterLink}
                to={PATH_CLIENT.addPrj}
              >
                <img
                  src="/static/icons/ic_add_circle.svg"
                  alt="Nouveau projet"
                  style={{ margin: theme.spacing(0, 2, 0, 0) }}
                />
                {/* <AddIcon sx={{ mr: 2 }} />*/}
                Nouveau projet
              </GradientBtn>
            </Box>
          )}
          {displayAddBtn(TECH_PROFILE) && (
            <GradientBtn
              size="lg"
              color="info"
              variant="contained"
              component={RouterLink}
              to={PATH_TECH.project}
            >
              <img
                src="/static/icons/ic_add_circle.svg"
                alt="Nouveau projet"
                style={{ margin: theme.spacing(0, 2, 0, 0) }}
              />
              {/* <AddIcon sx={{ mr: 2 }} />*/}
              Nouveau projet
            </GradientBtn>
          )}
          <ProjectListFilter
            handleChangeFilters={handleChangeFilters}
            optionsState={optionsState}
            filters={filters}
          />
          {projects && projects.length > 0 ? <ProjectList items={projects} /> : <></>}
        </RootStyle>
      </Fade>
    </>
  );
}
