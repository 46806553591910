function path(root, sublink) {
  return `${root}${sublink}`;
}
const ROOTS = {
  auth: '/auth',
  app: '/app',
  client: '/client',
  tech: '/tech',
  com: '/com',
  superadmin: '/superadmin'
};
export const PATH_PAGE = {
  auth: {
    root: ROOTS.auth,
    login: path(ROOTS.auth, '/login'),
    resetPassword: path(ROOTS.auth, '/reset-password')
  }
};
export const PATH_APP = {
  root: ROOTS.app,
  dashboard: path(ROOTS.app, '/dashboard'),
  waiting: path(ROOTS.app, '/waiting')
};
export const PATH_COM = {
  root: ROOTS.com,
  projects: path(ROOTS.com, '/projects'),
  addPrj: path(ROOTS.com, '/projects/add'),
  botDialog: 'botdialog',
  building: 'building',
  specifications: 'specifications',
  typo: 'typology',
  scope: 'scope',
  custom: 'custom',
  contact: 'contact'
};

export const PATH_CLIENT = {
  root: ROOTS.client,
  projects: path(ROOTS.client, '/projects'),
  project: path(ROOTS.client, '/project'),
  addPrj: path(ROOTS.client, '/projects/add'),
  botDialog: 'botdialog',
  building: 'building',
  specifications: 'specifications',
  typo: 'typology',
  scope: 'scope',
  custom: 'custom',
  contact: 'contact',
  projectCreated: 'projectCreated'
};
export const PATH_TECH = {
  root: ROOTS.tech,
  projects: path(ROOTS.tech, '/projects'),
  project: path(ROOTS.tech, '/project'),
  editProject: path(ROOTS.tech, '/editProject'),
  summary: 'summary',
  contact: 'contact',
  measure: 'measure',
  measureBuilding: 'building',
  measureGlazing: 'glazing',
  order: 'order',
  billing: 'billing',
  estimate: 'estimate',
  technote: 'technote',
  invoice: 'invoice'
};
export const PATH_SUPERADMIN = {
  root: ROOTS.superadmin,
  home: path(ROOTS.client, '/home')
};
export const NAV_URL_COM = {
  projects: path(ROOTS.client, '/projects'),
  botDialog: `${PATH_CLIENT.addPrj}`,
  building: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.building}`,
  specifications: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.specifications}`,
  typo: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.typo}`,
  scope: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.scope}`,
  custom: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.custom}`,
  contact: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.contact}`,
  projectCreated: `${PATH_CLIENT.addPrj}/${PATH_CLIENT.projectCreated}`
};
