import React, { useState, useEffect } from 'react';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Popover from '@mui/material/Popover';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioBtnStyled from 'components/custom/RadioBtnStyled';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import {
  SCOPE_INOUT,
  SCOPE_FLOORS,
  SCOPE_ROOMS,
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';
import useTechEditProject from 'hooks/useTechEditProject';
import UploadSingleFile from 'components/file/UploadSingleFile';
import useMediaQuery from '@mui/material/useMediaQuery';
import GlazingTabs from './GlazingTabs';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ width, theme }) => ({
  width: width ? width : '728px',
  height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  overflowY: 'auto',
  padding: theme.spacing(0, 4, 3, 4)
}));
export default function CreateGlazingForm({
  onCancelCreateForm,
  project,
  problematic,
  glazing,
  ...props
}) {
  const matchesMinWithToDisplay = useMediaQuery('(max-width:1350px)');
  const navigate = useNavigate();
  const { initProjectById, updateGlazing } = useTechEditProject();
  const [editingGlazing, seteditingGlazing] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [editGlazingTabs, setEditGlazingTabs] = useState(null);
  const [errorOnValid, seterrorOnValid] = useState(false);
  const [modeTabs, setmodeTabs] = useState(false);
  useEffect(() => {
    const roomsBuilding = project && project.buildings ? project.buildings[0].rooms : [];
    if (glazing && roomsBuilding) {
      const newGlazing = { ...glazing };
      newGlazing.name = glazing ? glazing.label : '';
      const refFloor = SCOPE_FLOORS.find((it) => it.label === glazing.floors);
      const refRoom = roomsBuilding.find((it) => it.label === glazing.room);
      newGlazing.floors = { ...refFloor };
      newGlazing.installation = { code: glazing.installation };
      newGlazing.room = { ...refRoom };
      seteditingGlazing(newGlazing);
    }
  }, [project]);

  const onCancelTabs = () => {
    setEditGlazingTabs(null);
    setmodeTabs(false);
  };

  const onComplete = async (values) => {
    try {
      if (!editGlazingTabs) return;
      const buildingProject = project.buildings[0];
      if (!buildingProject) return;
      const curPbIndx = project.problematics.findIndex((it) => it.code === problematic.code);
      if (curPbIndx > -1) {
        const glazingList = [];
        const objGlazing = { ...editGlazingTabs };
      
        if (editGlazingTabs.photo) {
          const result_base64 = await new Promise((resolve) => {
            const fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(editGlazingTabs.photo);
          });
          objGlazing.base64 = result_base64;
        }
        delete objGlazing.photo;
        const newGlazing = { ...objGlazing, ...values };
        const newEditGlazing = {
          label: newGlazing.name,
          quantity: newGlazing.quantity,
          description: newGlazing.description,
          base64: newGlazing.base64,
          height: newGlazing.height,
          width: newGlazing.width,
          problematic: {
            code: problematic.code,
            label: problematic.label
          },
          cutting_height: newGlazing.cutting_height,
          cutting_width: newGlazing.cutting_width,
          installation: {
            ...newGlazing.installation
          },
          floors: { ...newGlazing.floors },

          rooms: { ...newGlazing.room },
          product: {
            ...newGlazing.product
          },
          glazing_format: { ...newGlazing.glazing_format },
          removable_frame: newGlazing.removable_frame,
          frame_type: {
            ...newGlazing.frame_type
          },
          glazing_type: {
            ...newGlazing.glazing_type
          },
          teinted_glazing: newGlazing.teinted_glazing,
          joint_type: {
            ...newGlazing.joint_type
          },
          joint_status: {
            ...newGlazing.joint_status
          },
          shutter_type: {
            ...newGlazing.shutter_type
          },
          wind_exposure: {
            ...newGlazing.wind_exposure
          },
          sun_exposure: {
            ...newGlazing.sun_exposure
          },
          shaded_area: {
            ...newGlazing.shaded_area
          },
          advertising: newGlazing.advertising
        };
        if (glazing) {
          newEditGlazing.code = glazing.code;
        }
        console.log(newEditGlazing);
        glazingList.push(newEditGlazing);

        //buildingProject.glazings = [...glazingList];

        const newPrj = {
          project: {
            id: project.code,
            buildings: [{ ...buildingProject, glazings: [...glazingList] }]
          }
        };
        const modeGl = newEditGlazing.code ? 'edit' : 'add';
        await updateGlazing(newPrj, modeGl);
        await initProjectById(project.code);
        setEditGlazingTabs({ ...values });
        seterrorOnValid(false);
        enqueueSnackbar('Les informations de la vitre sont enregistrées avec succès.', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        const urlToRedirect = `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.measure}/${PATH_TECH.measureGlazing}`;
        onCancelCreateForm();
        navigate(urlToRedirect, { replace: true });
      }
    } catch (error) {
      seterrorOnValid(true);
      console.error(error);
      let msg = '';
      if (error && error.response && error.response.data) {
        msg = error.response.data.message;
      } else {
        msg = 'Une erreur est survenue';
      }
      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const onValidForm = (values) => {
    seteditingGlazing({ ...values });
    const newEditTab = { ...editGlazingTabs, ...values };
    setEditGlazingTabs({ ...newEditTab });
    setmodeTabs(true);
    seterrorOnValid(false);
  };
  const onBackToForm = () => {
    setmodeTabs(false);
  };
  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
        <MBuildingStyle width={matchesMinWithToDisplay ? '530px' : null}>
          {modeTabs ? (
            <GlazingTabs
              editGlazingTabs={editGlazingTabs}
              onCancelTabs={onCancelTabs}
              onComplete={onComplete}
              errorOnValid={errorOnValid}
              onBackToForm={onBackToForm}
            />
          ) : (
            <FormEdit
              glazing={editingGlazing}
              problematic={problematic}
              onValidForm={onValidForm}
              onCancelCreateForm={onCancelCreateForm}
              project={project}
              oriPhoto={glazing && glazing.photo ? glazing.photo : null}
            />
          )}
        </MBuildingStyle>
      </Box>
    </>
  );
}

const FormEdit = ({
  project,
  glazing,
  problematic,
  onValidForm,
  onCancelCreateForm,
  oriPhoto,
  ...props
}) => {
  const theme = useTheme();

  useEffect(() => {
    if (!project) return;
    if (!problematic) return;
    if (values.product) return;
    try {
      const problematicProject = project.problematics.find((it) => it.code === problematic.code);
      if (!problematicProject) return;
      const firstProductCode = problematicProject.product[0].code;
      setFieldValue('product', firstProductCode);
    } catch (error) {
      console.error(error);
    }
  }, [project]);

  const GlazingSchema = Yup.object().shape({
    name: Yup.string()
      .required('Veuillez renseigner le nom de la vitre')
      .max(30, 'Maximum 10 caracters'),
    quantity: Yup.string()
      .required('Veuillez renseigner la quantité de la vitre')
      .max(4, 'Maximum 4 caracters'),
    product: Yup.string().required(`Veuillez sélectionner un produit`)
  });

  const roomsBuilding = project && project.buildings ? project.buildings[0].rooms : [];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: glazing && glazing.name ? glazing.name : '',
      quantity: glazing && glazing.quantity ? glazing.quantity : 1,
      product: glazing && glazing.product ? glazing.product.code : '',
      floors: glazing && glazing.floors ? glazing.floors.code : '',
      installation: glazing && glazing.installation ? glazing.installation.code : '',
      room: glazing && glazing.room ? glazing.room.code : '',
      type: glazing && glazing.glazing_type ? glazing.glazing_type.code : '',
      description: glazing && glazing.description ? glazing.description : '',
      photo: ''
    },

    validationSchema: GlazingSchema,
    onSubmit: async (values) => {
      try {
        const productFounded = problematic.category[0].products.find(
          (it) => String(it.code) === String(values.product)
        );
        const installation = SCOPE_INOUT.find((it) => it.code === values.installation);
        const product = productFounded
          ? { ...productFounded }
          : { code: '1', reference: 'RFSZFCS', label: 'XHE 70 SS ER HPR' };
        const floorsFounded = SCOPE_FLOORS.find((it) => it.code === values.floors);
        const roomFounded = roomsBuilding.find((it) => it.code === values.room);

        onValidForm({
          ...glazing,
          ...values,
          installation,
          floors: { ...floorsFounded },
          room: { ...roomFounded },
          product,
          photo: values.photo
        });
      } catch (error) {
        console.error(error);
      }
    }
  });
  const {
    errors,
    touched,
    values,
    setFieldValue,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps
  } = formik;
  return (
    <>
      <Box
        display="flex"
        justifyContent="fex-start"
        alignItems="center"
        sx={{ mt: theme.spacing(4) }}
      >
        <IconButton size="large" onClick={onCancelCreateForm}>
          <ArrowBackIcon />
        </IconButton>
        <Typography variant="h3" sx={{ textTransform: 'uppercase', ml: theme.spacing(4) }}>
          {glazing ? 'Modification' : 'Nouvelle vitre'}
        </Typography>
      </Box>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{
            margin: theme.spacing(3, 0, 3, 0),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="text"
              label="Nom de la vitre"
              {...getFieldProps('name')}
              error={Boolean(touched.name && errors.name)}
              helperText={touched.name && errors.name}
            />
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="number"
              label="Quantité"
              {...getFieldProps('quantity')}
              error={Boolean(touched.quantity && errors.quantity)}
              helperText={touched.quantity && errors.quantity}
            />
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="text"
              multiline
              label="Commentaire"
              {...getFieldProps('description')}
              error={Boolean(touched.description && errors.description)}
              helperText={touched.description && errors.description}
            />
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Installation"
              value={values.installation}
              onChange={handleChange('installation')}
              {...getFieldProps('installation')}
            >
              {SCOPE_INOUT.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Etage"
              value={values.floors}
              onChange={handleChange('floors')}
              {...getFieldProps('floors')}
            >
              {SCOPE_FLOORS.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TextField
                sx={{ flexGrow: 1, input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                select
                label="Pièces"
                value={values.room}
                onChange={handleChange('room')}
                {...getFieldProps('room')}
              >
                {roomsBuilding.map((opt, i) => {
                  return (
                    <MenuItem key={Math.random()} value={opt.code}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </TextField>{' '}
              <CreateRoom project={project} />
            </Box>

            {problematic.category[0].products && (
              <TextField
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                select
                label="Sélectionner un produit"
                value={values.product}
                onChange={handleChange('product')}
                {...getFieldProps('product')}
              >
                {problematic.category[0].products.map((p) => {
                  return (
                    <MenuItem key={Math.random()} value={p.code}>
                      {p.label}
                    </MenuItem>
                  );
                })}
              </TextField>
            )}
          </Stack>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
            justifyContent="center"
            sx={{ margin: theme.spacing(1.5, 0, 0, 0) }}
          >
            {oriPhoto && (
              <Box
                component="img"
                alt=""
                src={oriPhoto}
                sx={{
                  borderRadius: 1,
                  objectFit: 'cover',
                  width: '150px',
                  height: 'auto'
                }}
              />
            )}
            <UploadSingleFile
              value={values.photo}
              onChange={(val) => setFieldValue('photo', val)}
              caption="(Les formats *.jpeg and *.png sont acceptés)"
              error={Boolean(touched.photo && errors.photo)}
              sx={{ maxHeight: '150px' }}
            />
          </Stack>
          <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(4, 0, 2, 0) }}
            >
              Suivant
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};

const CreateRoom = ({ project, ...props }) => {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [roomLabel, setroomLabel] = useState('');

  const { createRoom } = useTechEditProject();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleCreateRoom = async () => {
    if (roomLabel.length < 1) return;
    try {
      await createRoom(project, roomLabel);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <MenuItem
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          margin: theme.spacing(0, 0, 0, 2),
          background: `linear-gradient(91.63deg, #678BF6 18.03%, #54A0F9 79.34%)`,
          borderRadius: '5px',
          color: '#FFFFFF',
          fontFamily: 'Proxima',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '29px'
        }}
      >
        Créer une nouvelle pièce
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <Card>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexDirection="column"
            sx={{
              padding: '10px',
              width: '400px',
              height: '200px',
              '& .MuiTextField-root': { width: '100%' }
            }}
            component="form"
            noValidate
            autoComplete="off"
          >
            <TextField
              type="text"
              sx={{ width: '100%', margin: theme.spacing(0, 0, 2, 0) }}
              required
              label="Nom de la pièce"
              value={roomLabel}
              onChange={(e) => setroomLabel(e.target.value)}
            />
            <GradientBtn size="large" color="info" onClick={handleCreateRoom}>
              Créer
            </GradientBtn>
          </Box>
        </Card>
      </Popover>
    </>
  );
};
/*  PaperProps={{
          sx: {
            background: 'transparent',
            boxShadow: 'none'
          }
        }} */
