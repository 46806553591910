import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTheme, styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';

import EstimateInfos from './bill/EstimateInfos';

import TechEditOrderBillTable from './bill/TechEditOrderBillTable';

import EstimatePayment from './bill/EstimatePayment';
import EstimateDiscount from './bill/EstimateDiscount';
import EstimateDesc from './bill/EstimateDesc';
import PaymentCondition from './bill/PaymentCondition';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ theme }) => ({
  width: '80%',
  minHeight: `110vh`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px'
}));
const BgbBillStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100vh',
  backgroundPosition: 'top',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bill.svg)`,
  backgroundSize: '101% auto',
  borderRadius: '5px'
}));
export default function TechEditBillingEstimate({ ...props }) {
  const { project } = useOutletContext();
  const { problematics } = useTechEditProject();
  const theme = useTheme();
  return useMemo(() => {
    if (!project) return <></>;
    if (!problematics) return <></>;
    let avProducts = [];
    project.problematics.forEach((p) => {
      const pFounded = problematics.find((it) => String(it.code) === String(p.code));
      if (pFounded) {
        avProducts = [...avProducts, ...pFounded.category[0].products];
      }
    });
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
        <MBuildingStyle>
          <BgbBillStyle />
          <Box sx={{ padding: theme.spacing(6, 4, 6, 4) }}>
            <EstimateDesc project={project} />
            <EstimateInfos project={project} />
            <TechEditOrderBillTable avProducts={avProducts} />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ margin: theme.spacing(3, 0, 3, 0) }}
            >
              <EstimateDiscount project={project} />
            </Box>
            <EstimatePayment />
            <PaymentCondition />
          </Box>
        </MBuildingStyle>
      </Box>
    );
  }, [project, problematics]);
}
