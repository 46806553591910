import { createSlice } from '@reduxjs/toolkit';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: true,
  project: null,
  orders: null,
  orderBill: null
};

const techEditProjectSlice = createSlice({
  name: 'techEditProject',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    }, // INITIALISE
    initTechProject(state, action) {
      state.isLoading = false;
      state.project = action.payload;
      state.orders = null;
    },
    initOrders(state, action) {
      state.orders = action.payload;
    },
    initOrderBill(state, action) {
      state.orderBill = action.payload;
    },
    clearTechProject(state, action) {
      state.isLoading = false;
      state.project = null;
      state.orders = null;
      state.orderBill = null;
    }
  }
});
export const { initOrders, initOrderBill, startLoading, initTechProject, clearTechProject } =
  techEditProjectSlice.actions;
const techEditProjectReducer = techEditProjectSlice.reducer;

export default techEditProjectReducer;
