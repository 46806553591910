import React, { useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import { fShortenNumber } from 'utils/formatNumber';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
// ----------------------------------------------------------------------

const RightSideStyle = styled(Box)(({ right, width, theme }) => ({
  position: 'absolute',
  top: `${APP_BAR_DESKTOP + 18 + MARGE_BAR_DESKTOP}px`,
  right: right ? right : '76px',
  width: width ? width : '300px',
  height: `calc(100vh - ${
    APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP * 2
  }px)`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(0, 4, 0, 4)
}));
const BoxItemConfigtStyle = styled(Box)(({ theme }) => ({
  '& .title': {
    fontStyle: 'normal',
    fontWeight: '600',
    fontSize: '16px',
    lineHeight: '13px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',
    color: '#95A2AD',
    marginBottom: '15px'
  },
  '& .percent': {
    fontStyle: 'normal',
    fontWeight: '200',
    fontSize: '28px',
    lineHeight: '58px',
    textAlign: 'left',
    textTransform: 'uppercase',
    color: '#95A2AD'
  },
  '& .percent-caption': {
    textAlign: 'center',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '25px',
    lineHeight: '16px',
    color: '#95A2AD',
    opacity: 0.6
  },
  '& .divider': {
    width: '100%',
    height: '0px',
    background: '#95A2AD',
    border: '0.5px solid #95A2AD',
    marginTop: '18px'
  }
}));
export default function TechEditMeasureRight({ ...props }) {
  const { project } = useOutletContext();
  const theme = useTheme();
  const matchesMinWithToDisplay = useMediaQuery('(min-width:1100px)');
  const matchesMinWithToResize = useMediaQuery('(max-width:1270px)');
  return useMemo(() => {
    if (!project) return;
    const building = project.buildings[0];
    try {
      return (
        <>
          {matchesMinWithToDisplay ? (
            <RightSideStyle
              right={matchesMinWithToResize ? '10px' : null}
              width={matchesMinWithToResize ? '250px' : null}
            >
              <Content building={building} />
            </RightSideStyle>
          ) : (
            <></>
          )}
        </>
      );
    } catch (error) {
      return <></>;
    }
  }, [project, matchesMinWithToDisplay, matchesMinWithToResize]);
}
const Content = ({ building, ...props }) => {
  const theme = useTheme();
  const {
    exterior_perimeter,
    exterior_surface,
    interior_perimeter,
    interior_surface,
    nb_jours,
    nb_poseurs
  } = building;
  return (
    <>
      <BoxItemConfigtStyle sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
        <Box className="title">Surface vitrée ext</Box>
        <Box className="percent-caption">{fShortenNumber(exterior_surface)} m²</Box>
        <Box className="divider" />
      </BoxItemConfigtStyle>
      <BoxItemConfigtStyle sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
        <Box className="title">Surface vitrée int</Box>
        <Box className="percent-caption">{fShortenNumber(interior_surface)} m²</Box>
        <Box className="divider" />
      </BoxItemConfigtStyle>
      <BoxItemConfigtStyle sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
        <Box className="title">Sellement total</Box>
        <Box className="percent-caption">m</Box>
        <Box className="divider" />
      </BoxItemConfigtStyle>
      <BoxItemConfigtStyle sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
        <Box className="title">Nombre de poseurs</Box>
        <Box className="percent-caption">{nb_poseurs} </Box>
        <Box className="divider" />
      </BoxItemConfigtStyle>
      <BoxItemConfigtStyle sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
        <Box className="title">Nombre de jours</Box>
        <Box className="percent-caption">{nb_jours} jours</Box>
        <Box className="divider" />
      </BoxItemConfigtStyle>
    </>
  );
};
