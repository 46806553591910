import React, { useEffect, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useTechEditProject from 'hooks/useTechEditProject';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'theme/@material-extend/Buttons';
import closeFill from '@iconify-icons/eva/close-fill';
import DoneIcon from '@mui/icons-material/Done';
import InputBase from '@mui/material/InputBase';

// ----------------------------------------------------------------------
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const optionsUnit = [
  { code: 'percent', label: '%' },
  { code: 'euro', label: '€' }
];
export default function EstimateDiscount({ project, ...props }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const [value, setValue] = React.useState('');
  const [remiseUnit, setRemiseUnit] = useState('percent');
  const { orderBill, saveOrderBill } = useTechEditProject();
  const handleChange = (event) => {
    setValue(event.target.value);
  };
  useEffect(() => {
    if (!orderBill) return;
    if (orderBill && orderBill.discount_unity) setRemiseUnit(orderBill.discount_unity);
  }, [orderBill]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayAddDiscount = useMemo(() => {
    try {
      if (!orderBill) return false;
      if (Number(orderBill.discount_total) === 0) {
        return true;
      }
      return false;
    } catch (error) {
      return false;
    }
  }, [orderBill]);
  const handleRemoveDiscount = async () => {
    try {
      if (!project) return;
      if (!orderBill) return;
      const newOrd = {
        quote: {
          code: orderBill.code,
          discount: {
            value: 0,
            unity: 'percent'
          }
        }
      };
      await saveOrderBill(newOrd);
      enqueueSnackbar('Suprression réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const handeSave = async () => {
    try {
      if (!project) return;
      if (!orderBill) return;

      const newOrd = {
        quote: {
          code: orderBill.code,
          discount: {
            value,
            unity: remiseUnit
          }
        }
      };
      await saveOrderBill(newOrd);
      enqueueSnackbar('Sauvegarde réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <>
      {displayAddDiscount ? (
        <div>
          <Button
            aria-describedby={id}
            onClick={handleClick}
            sx={{
              fontFamily: 'Proxima',
              fontStyle: 'normal',
              fontWeight: 400,
              fontSize: '16px',
              lineHeight: '19px',
              color: '#3C60B5',
              textTransform: 'none'
            }}
          >
            <img
              src="/static/icons/ic_add_circle_blue.svg"
              alt="Nouveau commentaire"
              style={{ margin: theme.spacing(0, 2, 0, 0) }}
            />
            Ajouter une remise
          </Button>
          <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left'
            }}
          >
            <Box
              component="form"
              sx={{
                '& .MuiTextField-root': { m: 1, width: '25ch' },
                padding: theme.spacing(3)
              }}
              noValidate
              autoComplete="off"
              display="flex"
              alignItems="center"
            >
              <TextField
                id="outlined-multiline-static"
                label="Montant de la remise"
                type="number"
                onChange={handleChange}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
              />

              <FormControl variant="standard" sx={{ margin: theme.spacing(0, 0, 0, 1) }}>
                <Select
                  fullWidth
                  value={remiseUnit}
                  onChange={(event) => setRemiseUnit(event.target.value)}
                  input={<BootstrapInput />}
                >
                  {optionsUnit.map((opt) => {
                    return (
                      <MenuItem key={Math.random()} value={opt.code}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ mx: theme.spacing(2) }}
              >
                <IconButton color="primary" onClick={handeSave}>
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </Box>
          </Popover>
        </div>
      ) : (
        <Button
          onClick={handleRemoveDiscount}
          sx={{
            fontFamily: 'Proxima',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#3C60B5',
            textTransform: 'none'
          }}
        >
          <img
            src="/static/icons/ic_remove.svg"
            alt=" Supprimer la remise"
            style={{ margin: theme.spacing(0, 2, 0, 0) }}
          />
          Supprimer la remise
        </Button>
      )}
    </>
  );
}
