import React, { useEffect, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useTechEditProject from 'hooks/useTechEditProject';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'theme/@material-extend/Buttons';
import closeFill from '@iconify-icons/eva/close-fill';
import DoneIcon from '@mui/icons-material/Done';
import InputBase from '@mui/material/InputBase';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import axios from 'axios';
import { throttle } from 'lodash';
import { API_URL, SCOPE_INOUT, SCOPE_FLOORS } from 'config/appConfig';

// ----------------------------------------------------------------------
function sleep(delay = 0) {
  return new Promise((resolve) => {
    setTimeout(resolve, delay);
  });
}
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

export default function ContactAutoComplet({ project, label, onSelect, fixCompany, ...props }) {
  const theme = useTheme();
  const [openAc, setOpenAc] = React.useState(false);
  const [options, setOptions] = React.useState([]);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [value, setValue] = React.useState(options[0]);
  const [inputValue, setInputValue] = React.useState('');
  const { orderBill } = useTechEditProject();

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handeSave = () => {
    try {
      onSelect(value);
      handleClose();
    } catch (error) {
      console.error(error);
    }
  };

  React.useEffect(() => {
    if (!openAc) {
      setOptions([]);
    }
  }, [openAc]);

  const fetch = React.useMemo(
    () =>
      throttle(async (request) => {
        try {
          let arr = [];
          const res = await axios.post(`${API_URL.searchCompanyContact}`, {
            search: request?.input
          });

          if (res && res.data) {
            res.data.data.companies.forEach((it) => {
              it.contacts.forEach((c) => {
                arr.push({ ...c, company: { code: it.code, name: it.name, email: it.email } });
              });
            });
          }
          if (fixCompany) {
            arr = arr.filter((it) => String(it.company.code) === String(fixCompany));
          }
          setOptions(arr);
          return arr;
        } catch (error) {
          console.error(error);
          return [];
        }
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (inputValue === '') {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results) => {
      if (active) {
        let newOptions = [];
        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
      }
    });
    return () => {
      active = false;
    };
  }, [value, inputValue, fetch]);

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <div>
        <IconButton aria-describedby={id} onClick={handleClick} color="primary">
          <PersonSearchIcon />
        </IconButton>

        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left'
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '450px', height: '70px' },
              padding: theme.spacing(3)
            }}
            noValidate
            autoComplete="off"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Autocomplete
              id="asynchronous-demo"
              value={value}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
              inputValue={inputValue}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              open={openAc}
              onOpen={() => {
                setOpenAc(true);
              }}
              onClose={() => {
                setOpenAc(false);
              }}
              groupBy={(option) => option.company.name}
              autoComplete
              includeInputInList
              filterSelectedOptions
              isOptionEqualToValue={(option, value) => option.firstname === value.firstname}
              getOptionLabel={(option) =>
                `${option.firstname} ${option.lastname} ${option.poste ? ' - ' + option.poste : ''}`
              }
              getOptionSelected={(optionA, optionB) => optionA.firstname === optionB.firstname}
              filterOptions={(x) => x}
              options={options}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={label}
                  value
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: <>{params.InputProps.endAdornment}</>
                  }}
                />
              )}
            />
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ width: '100%', mx: theme.spacing(2) }}
            >
              <IconButton color="primary" onClick={handeSave}>
                <DoneIcon />
              </IconButton>
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Box>
        </Popover>
      </div>
    </>
  );
}
