// scroll bar
import 'simplebar/src/simplebar.css';

import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
//handler error on app
import { ErrorBoundary } from 'react-error-boundary';
//
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import ErrorAppFallback from './components/errors/ErrorAppFallback';
// ----------------------------------------------------------------------
ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
      <ErrorBoundary
        FallbackComponent={ErrorAppFallback}
        onReset={() => {
          // reset the state of your app so the error doesn't happen again
        }}
        onError={(error, errorInfo) => console.error({ error, errorInfo })}
      >
        <App />
      </ErrorBoundary>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root')
);

// If you want to enable client cache, register instead.
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
