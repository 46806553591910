import React, { useMemo, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTheme, styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import { MARGE_BAR_DESKTOP, TOP_BAR_TECHPROJECT_DESKTOP, APP_BAR_DESKTOP } from 'config/appConfig';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ theme }) => ({
  width: '80%',
  minHeight: `99vh`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  padding: theme.spacing(6, 4, 0, 4),
  position: 'relative'
}));
const MBuildingFillImgStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  opacity: 0.05,
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/logoFonce.svg)`,
  backgroundSize: '30%'
}));
const LabelStyle = styled(Box)(({ theme }) => ({
  width: '350px',
  height: '50px',
  padding: theme.spacing(3, 2, 3, 2),
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center'
}));
const ValuelStyle = styled(Box)(({ theme }) => ({
  width: '350px',
  height: '50px',
  padding: theme.spacing(3, 2, 3, 2),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center'
}));
const TitleBoxStyle = styled(Box)(({ height, theme }) => ({
  width: '100%',
  height: height ? height : '50px',
  margin: theme.spacing(2, 0, 2, 0),
  padding: theme.spacing(3, 2, 3, 2),
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  '& .title': {}
}));
export default function TechEditBillingTechnote({ ...props }) {
  const { project } = useOutletContext();
  const theme = useTheme();
  const getBoolString = (val) => {
    try {
      return String(val) === '1' ? 'Oui' : 'Non';
    } catch (error) {
      return '';
    }
  };
  return useMemo(() => {
    if (!project) return <></>;
    const building = project.buildings[0];
    if (!building) return <></>;
    return (
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
        <MBuildingStyle>
          <MBuildingFillImgStyle />
          <img src={`${process.env.PUBLIC_URL}/static/bg/bg_logo.svg`} alt="JPS-France" />
          <>
            <TitleBoxStyle height="auto">
              <Typography variant="h5">
                {project.name} {project.description ? ' - ' + project.description : ''}
              </Typography>
            </TitleBoxStyle>
            {building && (
              <>
                <TitleBoxStyle>
                  <Typography variant="h5">Informations du building</Typography>
                </TitleBoxStyle>
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <Box display="flex">
                    <LabelStyle>Hauteur du bâtiment</LabelStyle>
                    <ValuelStyle>{building.height} m </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Année de construction</LabelStyle>
                    <ValuelStyle>{building.year} </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Type de toiture</LabelStyle>
                    <ValuelStyle>{building.roof_type.label} </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Accès bâtiment</LabelStyle>
                    <ValuelStyle>{building.access ? building.access.label : ''} </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Occupation des locaux</LabelStyle>
                    <ValuelStyle>
                      {building.occupation ? building.occupation.label : ''}{' '}
                    </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Bâtiment soumis aux normes PPMS</LabelStyle>
                    <ValuelStyle>{getBoolString(building.ppms)} </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Besoin d'un élévateur/nacelle/autre</LabelStyle>
                    <ValuelStyle>{getBoolString(building.elevator)} </ValuelStyle>
                  </Box>
                  <Box display="flex">
                    <LabelStyle>Location de l'élévateur à ajouter</LabelStyle>
                    <ValuelStyle>{getBoolString(building.elevator_free)} </ValuelStyle>
                  </Box>
                </Box>
                {building.glazings && (
                  <>
                    <TitleBoxStyle>
                      <Typography variant="h5">Informations de vitrage</Typography>
                    </TitleBoxStyle>
                    <TableGlazingsInfos rows={building.glazings} />
                  </>
                )}
              </>
            )}{' '}
          </>
        </MBuildingStyle>
      </Box>
    );
  }, [project]);
}
const TableGlazingsInfos = ({ rows, ...props }) => {
  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Id</TableCell>
            <TableCell align="left">Etage</TableCell>
            <TableCell align="left">Vitre</TableCell>
            <TableCell align="left">Produit</TableCell>
            <TableCell align="left">Hauteur / Largeur (cm)</TableCell>
            <TableCell align="left">Installation</TableCell>
            <TableCell align="left">Pièce</TableCell>
            <TableCell align="left">Commentaire</TableCell>
            <TableCell align="left">Photo</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.name} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
              <TableCell component="th" scope="row">
                {row.code}
              </TableCell>
              <TableCell align="left">{row.floors}</TableCell>
              <TableCell align="left">{row.label}</TableCell>
              {row.product ? (
                <TableCell align="left">
                  {row.product.reference} <br />
                  <Typography variant="caption">réf: {row.product.label}</Typography>
                </TableCell>
              ) : (
                <TableCell align="left">produit indisponible</TableCell>
              )}
              <TableCell align="left">
                {row.height} / {row.width}
              </TableCell>
              <TableCell align="left">{row.installation}</TableCell>
              <TableCell align="left">{row.room}</TableCell>
              <TableCell align="left">{row.description}</TableCell>
              <TableCell align="left">
                {row.photo && (
                  <Box
                    component="img"
                    alt=""
                    src={row.photo}
                    sx={{
                      borderRadius: 1,
                      objectFit: 'cover',
                      width: '150px',
                      height: 'auto'
                    }}
                  />
                )}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
