import React, { useState, useEffect } from 'react';

import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import useTechEditProject from 'hooks/useTechEditProject';

// ----------------------------------------------------------------------
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '100px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    //border: '1px solid #95A2AD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
export default function SelectCellProd({ orderBill, item, avProducts, ...props }) {
  const { saveOrderBill } = useTechEditProject();
  const [displaySelect, setdisplaySelect] = useState(null);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleChange = async (e) => {
    try {
      const prod = e.target.value;
      const productFounded = avProducts.find((it) => String(it.code) === String(prod));
      if (!productFounded) return;
      const newOrd = {
        quote: {
          code: orderBill.code,
          items: [
            {
              code: item.ref,

              product: {
                code: productFounded.code,
                label: productFounded.label
              }
            }
          ]
        }
      };
      await saveOrderBill(newOrd);

      enqueueSnackbar('Le produit est enregistrée avec succès.', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error && error.response && error.response.data) {
        msg = error.response.data.message;
      } else {
        msg = 'Une erreur est survenue';
      }
      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  useEffect(() => {
    if (!item) return;
    if (!avProducts) return;
    try {
      const idx = avProducts.findIndex((it) => String(it.code) === String(item.product.code));
      if (idx > -1) {
        setdisplaySelect(true);
      }
    } catch (error) {
      console.error();
    }
  }, []);

  return (
    <>
      {displaySelect ? (
        <FormControl variant="standard">
          <Select
            sx={{ minWidth: '200px' }}
            fullWidth
            labelId="demo-customized-select-label"
            id="demo-customized-select"
            value={item.product.code}
            onChange={handleChange}
            input={<BootstrapInput />}
            IconComponent={() => <img src="/static/icons/ic_select.svg" alt="Produit" />}
          >
            {avProducts.map((opt) => {
              return (
                <MenuItem key={Math.random()} value={opt.code}>
                  {opt.label}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      ) : (
        <>{item.ref}</>
      )}
    </>
  );
}
