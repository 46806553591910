import React, { useContext } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
const BoxIconStyle = styled(Box)(({ theme }) => ({
  width: '35px',
  height: '31px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function SendIcon({ sx, ...props }) {
  return (
    <BoxIconStyle sx={{ ...sx }}>
      <svg
        width="35"
        height="31"
        viewBox="0 0 35 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M11.6462 30.2893C11.6462 30.499 11.7627 30.6913 11.9479 30.7893C12.1329 30.8872 12.3579 30.8747 12.5309 30.7562L16.4261 28.0965L11.6463 25.8184V30.2893H11.6462Z"
          fill="#3C60B5"
        />
        <path
          d="M34.8181 0.29283C34.7111 0.194194 34.573 0.142578 34.4334 0.142578C34.3493 0.142578 34.2654 0.161245 34.186 0.200275L0.558884 16.6953C0.215179 16.8642 -0.00231473 17.2152 1.8589e-05 17.5991C0.00235191 17.9826 0.223522 18.3312 0.569702 18.4963L9.43221 22.7192L27.1527 8.15435L11.628 23.7654L22.6685 29.026C22.8041 29.0909 22.9522 29.1233 23.0987 29.1233C23.2237 29.1233 23.3475 29.1005 23.4654 29.0542C23.7225 28.9525 23.9269 28.7489 24.0289 28.4916L34.9599 0.916038C35.0464 0.699039 34.99 0.45093 34.8181 0.29283Z"
          fill="#3C60B5"
        />
      </svg>
    </BoxIconStyle>
  );
}
