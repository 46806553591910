import { Link as RouterLink } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Box, Container, Typography } from '@mui/material';
import { capitalize } from '@mui/material/utils';
// layouts
import AuthLayout from '../layouts/AuthLayout';
// components
import Page from '../components/Page';
import { MHidden } from '../theme/@material-extend';
import { LoginForm } from '../components/authentication/login';
import AuthSocial from '../components/authentication/AuthSocial';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('md')]: {
    display: 'flex'
  }
}));

const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_login.png)`,
  backgroundSize: 'auto 100%'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.grey[0],
  width: '500px',
  height: '650px',
  display: 'flex',
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.1)',
  borderRadius: '20px',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(12, 6, 12, 6),
  zIndex: 1,
  position: 'relative'
}));

// ----------------------------------------------------------------------

export default function Login() {
  return (
    <RootStyle title="Login | JPS France">
      <SectionStyle />
      <Container
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100vh'
        }}
      >
        <ContentStyle>
          <Stack sx={{ mb: 5 }}>
            <Typography
              variant="h3"
              gutterBottom
              sx={{ color: 'black', textTransform: 'uppercase', fontFamily: 'ProximaBold' }}
            >
              Identifiez-vous
            </Typography>
            <Typography sx={{ color: '#112847', fontFamily: 'ProximaLight' }} variant="body1">
              Bienvenue sur l’interface de décision de JPS France ! Connectez-vous.
            </Typography>
          </Stack>
          <LoginForm />
          {process.env.REACT_APP_MY_NODE_ENV === 'staging' && (
            <Box
              sx={{ width: '100%', position: 'absolute', bottom: 10, left: 0 }}
              display="flex"
              justifyContent="center"
              alignItems="center"
            >
              <Typography sx={{ color: '#112847', fontFamily: 'ProximaLight' }} variant="caption">
                environnement pre-prod
              </Typography>
            </Box>
          )}
        </ContentStyle>
      </Container>
    </RootStyle>
  );
}
