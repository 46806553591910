import React from 'react';
import ReactPlayer from 'react-player';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------
const PlayerStyle = styled(ReactPlayer)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  minWidth: '100%',
  minHeight: '100%',
  overflow: 'hidden',
  '& video': {
    minWidth: '100%',
    minHeight: '100%',
    width: 'auto!important',
    height: 'auto!important',
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%,-50%)'
  }
}));
export default function FullScalableVideo({
  url,
  preview,
  isLooped,
  handleEndedVideo,
  handleBufferEnd,
  ...props
}) {
  return (
    <>
      {isLooped ? (
        <PlayerStyle
          url={url}
          //  light={preview}
          playing
          autoPlay
          muted
          loop
        />
      ) : (
        <PlayerStyle
          url={url}
          playing
          autoPlay
          muted
          onEnded={handleEndedVideo}
          onBufferEnd={handleBufferEnd}
        />
      )}
    </>
  );
}
