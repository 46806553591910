import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { API_URL, SCOPE_INOUT, SCOPE_FLOORS } from 'config/appConfig';
import {
  initOrders,
  initOrderBill,
  startLoading,
  clearTechProject,
  initTechProject
} from 'redux/slices/techEditProjectSlice';
import { getAllProjects } from 'redux/slices/referentialSlice';

export default function useTechEditProject() {
  const dispatch = useDispatch();
  const { isLoading, project, orders, orderBill } = useSelector((state) => state.techEditProject);
  const {
    payment_conditions,
    payment_methods,
    problematics,
    roof_types,
    building_occupation,
    building_access,
    building_types,
    surfaces,
    glazing_types,
    floors,
    joint_types,
    joint_status,
    frame_type,
    shutter_type,
    wind_exposure,
    sun_exposure,
    shaded_area,
    avProjects,
    glazing_format,
    refInitialized
  } = useSelector((state) => state.referential);
  return {
    payment_conditions,
    payment_methods,
    avProjects,
    glazing_format,
    isLoading,
    problematics,
    roof_types,
    building_occupation,
    building_access,
    building_types,
    surfaces,
    glazing_types,
    floors,
    joint_types,
    joint_status,
    frame_type,
    shutter_type,
    wind_exposure,
    sun_exposure,
    shaded_area,
    project,
    orders,
    orderBill,
    initProjectById: async (idProject) => {
      try {
        if (!idProject) return;
        dispatch(startLoading());
        const response = await axios.get(`${API_URL.project}/${idProject}`);
        if (response && response.data) {
          if (response.data.status !== 'Error') {
            dispatch(initTechProject(response.data.data.project));
          }
        } else {
          dispatch(clearTechProject());
        }
      } catch (error) {
        console.error(error);
        dispatch(clearTechProject());
        throw error;
      }
    },
    deleteProject: async (idProject) => {
      try {
        if (!idProject) return;
        dispatch(startLoading());
        const response = await axios.get(`${API_URL.project}/${idProject}/delete`);
        if (response && response.data) {
          dispatch(getAllProjects());
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    createRoom: async (prj, room) => {
      try {
        if (!room || !prj) return;
        const buildingPrj = prj.buildings[0];
        const prjCreated = {
          project: {
            id: prj.code,
            building: {
              id: buildingPrj.code,
              room: {
                name: room
              }
            }
          }
        };
        await axios.post(`${API_URL.roomCreate}`, prjCreated);
        const response = await axios.get(`${API_URL.project}/${prj.code}`);
        if (response && response.data) {
          if (response.data.status !== 'Error') {
            dispatch(initTechProject(response.data.data.project));
          }
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    updateBuilding: async (newProj) => {
      try {
        if (!newProj) return;
        dispatch(startLoading());
        await axios.post(`${API_URL.buildingUpdate}`, newProj);
      } catch (error) {
        console.error(error);
        dispatch(clearTechProject());
        throw error;
      }
    },
    updateGlazing: async (newProj, mode) => {
      try {
        if (!newProj) return;
        const url =
          mode && mode === 'edit' ? `${API_URL.glazingUpdate}` : `${API_URL.glazingCreate}`;
        const res = await axios.post(url, newProj);
        if (res && res.data) {
          return res.data.data;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    removeGlazing: async (newPrj) => {
      try {
        if (!newPrj) return;
        dispatch(startLoading());
        console.log('remove', newPrj);
        await axios.post(`${API_URL.glazingRemove}`, newPrj);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    clearProject: () => {
      dispatch(clearTechProject());
    },
    initOrderBillProject: async (p) => {
      try {
        if (!project) return;
        let { quote_id } = project;
        const prjOrder = {
          project: {
            id: project.code,
            building: {
              id: project.buildings[0].code
            },
            order: {
              status: 'submited',
              description:
                'Merci de nous tenir informés des délais de livraison des produits suivants'
            }
          }
        };
        const res = await axios.post(`${API_URL.glazingOrder}`, prjOrder);

        if (!project.quote_id) {
          if (res && res.data) {
            const response = await axios.get(`${API_URL.project}/${project.code}`);
            if (response && response.data) {
              if (response.data.status !== 'Error') {
                dispatch(initTechProject(response.data.data.project));
                quote_id = response.data.data.project.quote_id;
              }
            }
          }
        }
        if (!quote_id) return;
        const resOrd = await axios.get(`${API_URL.orderBill}/${quote_id}`);

        if (resOrd && resOrd.data) {
          /* try {
            if (project.buildings[0].glazings.length !== resOrd.data.data.quote.items.length) {
              await axios.post(`${API_URL.glazingOrder}`, prjOrder);
              resOrd = await axios.get(`${API_URL.orderBill}/${quote_id}`);
            }
          } catch (error) {
            console.error(error);
          } */
          dispatch(
            initOrderBill({
              ...resOrd.data.data.quote,
              invoice: { ...resOrd.data.data.invoice }
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(initOrderBill(null));
        throw error;
      }
    },
    initOrderProject: (p) => {
      try {
        if (!project) return;
        if (!p) p = { ...project };

        const building = project.buildings[0];
        if (!building) return;
        const { glazings } = building;
        if (!glazings || glazings.length === 0) return;
        const orderProject = glazings.map((e) => {
          const {
            quantity,
            floors,
            code,
            room,
            cutting_height,
            cutting_width,
            height,
            width,
            label,
            product,
            surface,
            sun_exposure,
            teinted_glazing
          } = e;
          return {
            floors,
            code,
            room,
            ref: product.label,
            refCode: product.code,
            designation: label,
            measure: `${height}*${width}`,
            quantity: quantity ? quantity : 1,
            surface,
            exposition: sun_exposure.label,
            tinted: Boolean(teinted_glazing) === true ? 'Oui' : 'Non',
            dimension: `${cutting_height}*${cutting_width}`
          };
        });

        dispatch(initOrders([...orderProject]));
        return orderProject;
      } catch (error) {
        console.error(error);
      }
    },
    udateGlazingOrder: async (glazingOrder) => {
      try {
        if (!project) return;
        if (!glazingOrder) return;
        console.log(glazingOrder);
        const building = project.buildings[0];
        if (!building) return;
        const glazing = building.glazings.find(
          (it) => String(it.code) === String(glazingOrder.code)
        );
        if (!glazing) return;
        const floorsFounded = SCOPE_FLOORS.find(
          (it) => String(it.label) === String(glazing.floors)
        );
        const roomFounded = building.rooms.find((it) => String(it.label) === String(glazing.room));
        const installationFounded = SCOPE_INOUT.find(
          (it) => String(it.code) === String(glazing.installation)
        );
        const glazingCreate = {
          ...glazing,
          quantity: glazingOrder.quantity,
          floors: floorsFounded
            ? { ...floorsFounded }
            : {
                code: '0',
                label: 'RDC'
              },
          installation: { ...installationFounded },
          rooms: { ...roomFounded },
          product: { code: glazingOrder.product.code, label: glazingOrder.product.label }
        };
        delete glazingCreate.room;
        const newPrj = {
          project: {
            id: project.code,
            buildings: [
              {
                code: building.code,
                glazings: [{ ...glazingCreate }]
              }
            ]
          }
        };
        await axios.post(`${API_URL.glazingUpdate}`, newPrj);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    saveTechProject: async (projectToCreate) => {
      try {
        console.log(projectToCreate);
        const res = await axios.post(`${API_URL.projectCreate}`, projectToCreate);
        if (res && res.data) {
          dispatch(getAllProjects());
        } else {
          throw new Error('Création de projet est échouée');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    addProblematics: async (prj) => {
      try {
        if (!prj) return;
        const response = await axios.post(`${API_URL.addProblematics}`, prj);
        if (response && response.data) {
          if (response.data.status !== 'Error') {
            dispatch(initTechProject(response.data.data.project));
          }
        } else {
          throw new Error('Ajout de problématiques a été échouée.');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    saveOrder: async (prj) => {
      try {
        if (!prj) return;
        const prjOrder = {
          project: {
            id: prj.code,
            building: {
              id: prj.buildings[0].code
            },
            order: {
              ...prj.order
            }
          }
        };
        const res = await axios.post(`${API_URL.glazingOrder}`, prjOrder);
        if (res && res.data) {
          const { order } = res.data.data;
          const urlToPdf = order.pdf;
          if (urlToPdf) {
            const arr = urlToPdf.split('/');
            const pdfCode = arr[arr.length - 1];
            const urlPdf = `${API_URL.glazingOrder}/pdf/${pdfCode}`;
            downloadOrderData(urlPdf, pdfCode, 'order');
          }
        } else {
          throw new Error('Création de projet est échouée');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    saveBill: (bill) => {
      try {
        if (!bill) return;
        if (!bill.pdf) return;
        console.log(bill);
        downloadOrderData(bill.pdf, bill.reference, 'quote');
      } catch (error) {
        console.error(error);
      }
    },
    saveTechNote: (bill) => {
      try {
        if (!bill) return;
        if (!bill.note_pdf) return;
        console.log('saveTechNote', bill);
        downloadOrderData(bill.note_pdf, bill.reference, 'note');
      } catch (error) {
        console.error(error);
      }
    },
    saveInvoice: (bill) => {
      try {
        if (!bill) return;
        if (!bill.pdf) return;
        console.log('saveInvoice', bill);
        const urlPdf = bill.invoice.pdf;
        downloadOrderData(urlPdf, bill.invoice.reference, 'invoice');
      } catch (error) {
        console.error(error);
      }
    },
    saveOrderBill: async (ord) => {
      try {
        if (!ord) return;
        const res = await axios.post(`${API_URL.orderBillUpdate}`, ord);
        if (res && res.data) {
          dispatch(
            initOrderBill({
              ...res.data.data.quote,
              invoice: { ...res.data.data.invoice }
            })
          );
        } else {
          throw new Error('Création de remise est échouée');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };
}
const downloadOrderData = async (pdfUrl, pdfCode, type) => {
  try {
    const response = await axios.get(pdfUrl);
    console.log(response);
    const blob = new Blob([response.data], { type: 'application/pdf' });
    const linkSource = `data:application/pdf;base64,${response.data.data[type]}`;
    const link = document.createElement('a');
    link.href = linkSource;
    link.download = `${type}_${pdfCode}.pdf`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } catch (error) {
    console.error(error);
  }
};
/* updateTechProject: async (newProj) => {
      try {
        if (!newProj || !newProj.id) return;
        dispatch(startLoading());
        await axios.put(`${API_URL.projects}/${newProj.id}`, newProj);
        const response = await axios.get(`${API_URL.project}/${newProj.id}`);
        if (response.data) {
          if (response.data.status !== 'Error') {
            dispatch(initTechProject(response.data));
          }
        } else {
          dispatch(clearTechProject());
        }
      } catch (error) {
        console.error(error);
        dispatch(clearTechProject());
        throw error;
      }
    }, */
