import React from 'react';
import { Link } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
import { PATH_TECH, PATH_CLIENT } from 'config/paths';
// components
import Page from 'components/Page';
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',
  height: `calc(100vh - ${APP_BAR_MOBILE * 2.8}px)`,
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP * 2.2}px)`
  },
  zIndex: 1
}));
const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_cloud.svg)`,
  backgroundSize: 'auto 100%'
}));
const CenterSectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  margin: 0,
  padding: theme.spacing(0, 3, 0, 3)
}));
const ContentStyle = styled('div')(({ theme }) => ({
  width: '80%',
  zIndex: 1,
  display: 'flex',
  justifyContent: 'space-around',
  padding: theme.spacing(8, 0, 0, 0)
}));

const BoxImgRightStyle = styled(Box)(({ theme }) => ({
  width: '200px',
  background: `linear-gradient(180deg, #89DDF1 0%, #65B0E9 100%);`
}));
const MENU = [
  {
    label: 'Interface commerciale',
    description: 'Créer le projet avec les modèles 3D',
    url: PATH_CLIENT.projects
  },
  {
    label: 'Interface technicienne',
    description: 'Créer et configurer les données techniques du projet',
    url: PATH_TECH.root
  }
];
export default function SuperAdminHome({ ...props }) {
  const theme = useTheme();
  return (
    <RootStyle title="Interface de Superadmin | JPS France">
      <SectionStyle />
      <CenterSectionStyle>
        <ContentStyle>
          {MENU.map((it, i) => {
            return (
              <Card
                key={i}
                sx={{
                  display: 'flex',
                  height: '150px',
                  width: '500px'
                }}
              >
                <Box
                  sx={{
                    position: 'relative',
                    padding: theme.spacing(3, 0, 3, 0),
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%'
                  }}
                >
                  <CardContent
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%'
                    }}
                  >
                    <Link to={it.url} style={{ textDecoration: 'none' }}>
                      <Typography component="div" variant="h4">
                        {it.label}
                      </Typography>
                      <Typography
                        variant="overline"
                        color="text.secondary"
                        component="span"
                        style={{ textTransform: 'none' }}
                      >
                        {it.description}
                      </Typography>
                    </Link>
                  </CardContent>
                </Box>
                <BoxImgRightStyle />
              </Card>
            );
          })}
        </ContentStyle>
      </CenterSectionStyle>
    </RootStyle>
  );
}
