import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------

export default function EstimateDesc({ project, ...props }) {
  const theme = useTheme();

  return (
    <Box
      sx={{ minHeight: '80vh', px: theme.spacing(8) }}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexDirection="column"
    >
      <Typography variant="h3" sx={{ my: theme.spacing(4) }}>
        Problématiques du projet
      </Typography>
      {project.problematics.map((it, i) => {
        return (
          <Box key={i} sx={{ my: theme.spacing(4) }}>
            <Typography variant="h5">{it.label} </Typography>
            <Typography variant="p">{it.text1} </Typography>
          </Box>
        );
      })}
    </Box>
  );
}
