import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  startLoading,
  setSceneLoaded,
  setProgression,
  setScene,
  resetUnity,
  setSendingConfig,
  setSceneVisibility,
  setBgStyle,
  setEnableZindex
} from 'redux/slices/projectUnitySlice';

function useProjectUnity() {
  const dispatch = useDispatch();
  const {
    sceneInitialized,
    bgStyle,
    display,
    scene,
    sendingConfig,
    isLoading,
    sceneLoaded,
    progression,
    enableZindex
  } = useSelector((state) => state.projectUnity);
  return {
    isLoading,
    progression,
    sceneLoaded,
    scene,
    sendingConfig,
    display,
    bgStyle,
    enableZindex,
    sceneInitialized,
    handleUpdScene: (value) => {
      dispatch(setScene({ scene: value }));
    },
    handleUpdProgression: (value) => {
      dispatch(setProgression({ progression: value }));
    },
    handeSceneLoaded: () => {
      try {
        dispatch(setSceneLoaded());
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    handleSendingConfig: (value) => {
      try {
        dispatch(setSendingConfig({ sendingConfig: value }));
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    setBgColor: (value) => {
      dispatch(setBgStyle({ bgStyle: { ...value } }));
    },
    setSceneVisibility: (value) => {
      dispatch(setSceneVisibility({ visibility: value }));
    },
    setEnableZindex: (value) => {
      dispatch(setEnableZindex({ enableZindex: value }));
    },
    resetUnity: () => {
      try {
        dispatch(resetUnity());
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };
}
export default useProjectUnity;
