import React, { useCallback, useState, useEffect } from 'react';
import axios from 'axios';
import { API_URL } from 'config/appConfig';
import { useDispatch, useSelector } from 'react-redux';
import useClientEditProject from 'hooks/useClientEditProject';
import { getAllProjects, getReferential } from 'redux/slices/referentialSlice';

// ----------------------------------------------------------------------

export const ProjectContext = React.createContext();
const ProjectProvider = ({ user, ...props }) => {
  const dispatch = useDispatch();
  const [initalProjects, setInitalProjects] = useState([]);
  const [projects, setProjects] = useState([]);
  const { avProjects, refInitialized } = useSelector((state) => state.referential);
  const [optionsState, setOptionsState] = useState([
    {
      id: 100,
      label: 'Tous'
    },
    {
      id: 'ordered',
      label: 'Validé'
    },
    {
      id: 'draft',
      label: 'Étude'
    },
    {
      id: 'installed',
      label: 'Installé'
    }
  ]);
  const [filters, setFilters] = useState({ state: 100, keyword: '', timeUpd: null });
  const [loadingPrj, setLoadingProject] = useState(false);
  useEffect(() => {
    if (!refInitialized) {
      dispatch(getReferential());
      dispatch(getAllProjects());
    }
  }, []);
  useEffect(() => {
    if (!avProjects) return;
    setProjects([...avProjects]);
    setInitalProjects([...avProjects]);
  }, [avProjects]);
  /* when filters is changed */
  useEffect(() => {
    if (!filters) return;
    if (Object.keys(filters).length === 0) return;
    if (!filters.timeUpd) return;
    const kw = filters.keyword;
    const newProjects = initalProjects.filter((p) => {
      try {
        const isMatchState = filters.state === 100 ? true : p.status.code === filters.state;
        const isMatchKeyword =
          kw.length > 2 ? p.name.toUpperCase().includes(kw.toUpperCase()) : true;
        return isMatchState && isMatchKeyword;
      } catch (error) {
        return true;
      }
    });
    setProjects(newProjects);
  }, [filters]);

  const handleChangeFilters = useCallback(
    (values) => {
      const newFilter = { ...filters, ...values, timeUpd: new Date().getTime() };
      setFilters(newFilter);
    },
    [filters]
  );
  return (
    <ProjectContext.Provider
      value={{ handleChangeFilters, optionsState, filters, loadingPrj, projects }}
    >
      {props.children}
    </ProjectContext.Provider>
  );
};
export default ProjectProvider;
