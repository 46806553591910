import React, { useState, useCallback, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioBtnStyled from 'components/custom/RadioBtnStyled';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';

import { PATH_TECH } from 'config/paths';
import {
  SCOPE_FLOORS,
  SCOPE_INOUT,
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import GlazingList from './glazing/GlazingList';
import CreateGlazingForm from './glazing/CreateGlazingForm';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ width, mode, theme }) => ({
  width: width ? width : '728px',
  height: `calc(100vh - ${
    APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP + 20
  }px)`,
  background: mode && mode === 'add' ? '#F9F9F9' : 'transprent',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(0, 4, 0, 4)
}));

export default function TechEditMeasureGlazing({ ...props }) {
  const matchesMinWithToDisplay = useMediaQuery('(max-width:1350px)');
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [mode, setMode] = useState(null);
  const { project } = useOutletContext();
  const { problematics, initProjectById, updateGlazing } = useTechEditProject();
  const onCreate = (problematic) => {
    if (!problematics) return;
    const p = problematics.find((it) => String(it.code) === String(problematic.code));
    setMode({ code: 'add', value: p });
  };

  const onCancelCreateForm = () => {
    setMode(null);
  };
  const handleEditGlazing = (prob, item) => {
    try {
      if (prob) {
        if (!problematics) return;
        const p = problematics.find((it) => String(it.code) === String(prob.code));
        setMode({ code: 'edit', value: p, glazing: item });
      } else {
        const probProject = project.problematics[0];
        const p = problematics.find((it) => String(it.code) === String(probProject.code));
        console.log(p, probProject);
        setMode({ code: 'edit', value: p, glazing: item });
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handleDupliGlazing = async (problematic, item) => {
    try {
      if (project && problematic && item) {
        try {
          const buildingProject = project.buildings[0];
          if (!buildingProject) return;
          const roomsBuilding = buildingProject.rooms;
          let roomFounded = roomsBuilding.find((it) => it.label === item.room);
          if (!roomFounded) {
            roomFounded = { ...roomsBuilding[0] };
          }
          let floorsFounded = SCOPE_FLOORS.find((it) => it.label === item.floors);
          if (!floorsFounded) {
            floorsFounded = { code: 0, label: 'RDC' };
          }
          const curPbIndx = project.problematics.findIndex((it) => it.code === problematic.code);
          if (curPbIndx > -1) {
            const glazingList = [];
            const newGlazing = { ...item };
            const newEditGlazing = {
              label: newGlazing.label,
              description: newGlazing.description,
              quantity: newGlazing.quantity,
              height: newGlazing.height,
              width: newGlazing.width,
              photo: newGlazing.photo,
              problematic: {
                code: problematic.code,
                label: problematic.label
              },
              cutting_height: newGlazing.cutting_height,
              cutting_width: newGlazing.cutting_width,
              installation: {
                ...SCOPE_INOUT.find((it) => it.code === newGlazing.installation)
              },
              floors: floorsFounded,
              rooms: { ...roomFounded },
              product: {
                ...newGlazing.product
              },
              glazing_format: { ...newGlazing.glazing_format },
              removable_frame: newGlazing.removable_frame,
              frame_type: {
                ...newGlazing.frame_type
              },
              glazing_type: {
                ...newGlazing.glazing_type
              },
              teinted_glazing: newGlazing.teinted_glazing,
              joint_type: {
                ...newGlazing.joint_type
              },
              joint_status: {
                ...newGlazing.joint_status
              },
              shutter_type: {
                ...newGlazing.shutter_type
              },
              wind_exposure: {
                ...newGlazing.wind_exposure
              },
              sun_exposure: {
                ...newGlazing.sun_exposure
              },
              shaded_area: {
                ...newGlazing.shaded_area
              },
              advertising: newGlazing.advertising
            };

            delete newEditGlazing.code;
            glazingList.push(newEditGlazing);
            const newPrj = {
              project: {
                id: project.code,
                buildings: [{ code: buildingProject.code, glazings: [...glazingList] }]
              }
            };
            const glazingDuplicated = await updateGlazing(newPrj, 'add');
            console.log(glazingDuplicated);
            await initProjectById(project.code);
            enqueueSnackbar('Les informations de la vitre sont enregistrées avec succès.', {
              anchorOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
            /* 
             const p = problematics.find((it) => String(it.code) === String(prob.code));
        setMode({ code: 'edit', value: p, glazing: item });
             */
          }
        } catch (error) {
          console.error(error);
          let msg = '';
          if (error && error.response && error.response.data) {
            msg = error.response.data.message;
          } else {
            msg = 'Une erreur est survenue';
          }
          enqueueSnackbar(msg, {
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left'
            },
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return useMemo(() => {
    if (!project) return <></>;
    if (!problematics) return <></>;
    return (
      <>
        <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
          <MBuildingStyle mode={mode} width={matchesMinWithToDisplay ? '530px' : null}>
            {mode && mode.value ? (
              <>
                {mode.code === 'add' && (
                  <CreateGlazingForm
                    onCancelCreateForm={onCancelCreateForm}
                    project={project}
                    problematic={mode.value}
                  />
                )}
                {mode.code === 'edit' && (
                  <CreateGlazingForm
                    onCancelCreateForm={onCancelCreateForm}
                    project={project}
                    problematic={mode.value}
                    glazing={mode.glazing}
                  />
                )}
              </>
            ) : (
              <GlazingList
                onCreate={onCreate}
                project={project}
                handleEditGlazing={handleEditGlazing}
                handleDupliGlazing={handleDupliGlazing}
              />
            )}
          </MBuildingStyle>
        </Box>
      </>
    );
  }, [mode, project, problematics, matchesMinWithToDisplay]);
}
