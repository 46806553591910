import React, { useMemo } from 'react';
import { useOutletContext, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import useMediaQuery from '@mui/material/useMediaQuery';
import { PATH_TECH } from 'config/paths';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import TopNavbar from 'layouts/vertical/TopNavbar';
import TechTopBar from './TechTopBar';

const SummaryStyle = styled(Box)(({ width, theme }) => {
  return {
    width: width ? width : '728px',
    height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
    background: '#F9F9F9',
    boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    overflowY: 'auto',
    padding: theme.spacing(0, 4, 3, 4),
    '& .title': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '44px',
      textTransform: 'uppercase',
      color: '#121E6E',
      textAlign: 'center',
      margin: theme.spacing(2, 0, 4, 0)
    },
    '& .pro-title': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
      color: '#000000'
    },
    '& .sub-title': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '40px',
      textTransform: 'uppercase',
      color: '#121E6E'
    },
    '& .paragraph': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '40px',
      color: '#000000',
      margin: theme.spacing(1, 0, 2, 0)
    },
    '& .label': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '22px',
      color: '#121E6E'
    }
  };
});
export default function TechEditSummary({ ...props }) {
  const theme = useTheme();
  const { project } = useOutletContext();
  const matchesMinWithToDisplay = useMediaQuery('(max-width:850px)');
  return useMemo(() => {
    if (!project) return <></>;
    const building = project.buildings[0];
    if (!building) return <></>;
    if (!building.type) return <></>;
    return (
      <>
        <TopNavbar>
          <TechTopBar>
            <Button
              variant="outlined"
              sx={{ mx: theme.spacing(3), color: '#FFFFFF' }}
              component={RouterLink}
              to={PATH_TECH.root}
            >
              <ClearOutlinedIcon sx={{ mr: theme.spacing(1) }} /> Fermer
            </Button>
          </TechTopBar>
        </TopNavbar>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ height: 'auto', px: matchesMinWithToDisplay ? '90px' : '' }}
        >
          <SummaryStyle width={matchesMinWithToDisplay ? '530px' : null}>
            <Box className="title">Récapitulatif</Box>
            <Box className="pro-title" sx={{ margin: theme.spacing(2, 0, 4, 0) }}>
              {project.name}
            </Box>
            {project.description && (
              <Box className="paragraph" sx={{ margin: theme.spacing(0, 0, 4, 0) }}>
                {project.description}
              </Box>
            )}

            <Grid container spacing={2}>
              <Grid item md={6}>
                <Box className="sub-title">Typologie</Box>
                <Box className="paragraph">{building.type.label}</Box>
                <Box className="label">Superficie du bâtiment</Box>
                <Box className="paragraph">{building.surface.label}</Box>
                <Box className="label">Type de vitrage</Box>
                <Box className="paragraph">{building.glazing_type.label}</Box>
                <Box className="label">Nombre d'étage</Box>
                <Box className="paragraph">{building.floors}</Box>
                <Box className="label">Type de toit</Box>
                <Box className="paragraph">{building.roof_type.label}</Box>
              </Grid>
              <Grid item md={6}>
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                >
                  <Box className="sub-title">Les films adhésifs</Box>
                  {project.problematics.map((p) => {
                    return (
                      <React.Fragment key={Math.random()}>
                        {p.product && (
                          <>
                            {p.product.map((prod) => {
                              return (
                                <Box
                                  sx={{ margin: theme.spacing(2, 0, 0, 0), textAlign: 'left' }}
                                  key={Math.random()}
                                >
                                  <Box className="sub-title">{p.label}</Box>
                                  <Box className="paragraph" sx={{ margin: '0!important' }}>
                                    {prod.reference}
                                  </Box>
                                </Box>
                              );
                            })}
                          </>
                        )}
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Grid>
            </Grid>
          </SummaryStyle>
        </Box>
      </>
    );
  }, [project, matchesMinWithToDisplay]);
}
