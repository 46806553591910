import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { useSnackbar } from 'notistack';
import closeFill from '@iconify-icons/eva/close-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import useTechEditProject from 'hooks/useTechEditProject';
// ----------------------------------------------------------------------
const CellBoxStyle = styled(Box)(({ theme }) => ({
  minWidth: '80px',
  height: '25px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export default function EditQtyCell({
  project,
  item,
  fiedToEdit,
  fieldLabel,
  handeSave,
  avProducts,
  ...props
}) {
  const { udateGlazingOrder, initProjectById } = useTechEditProject();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const [value, setvalue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!item) return;
    setvalue(item[fiedToEdit]);
  }, [item]);
  const CellNumberSchema = Yup.object().shape({
    quantity: Yup.string()
      //.matches(/^[-+]?[0-9]+\.[0-9]+$/, 'Veuillez renseigner un nombre')
      .max(10, 'Maximum 10 digits')
      .required(`Quantité est obligatoire.`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      quantity: item.quantity
    },
    validationSchema: CellNumberSchema,
    onSubmit: async (values) => {
      try {
        const { quantity } = values;
        const prod = item.refCode;
        const productFounded = avProducts.find((it) => String(it.code) === String(prod));
        if (!productFounded) {
          throw new Error(`Le produit n'est pas disponible`);
        }
        await udateGlazingOrder({ ...item, product: productFounded, quantity });
        await initProjectById(project.code);
        enqueueSnackbar('Les informations de la vitre sont enregistrées avec succès.', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        handleClose();
      } catch (error) {
        console.error(error);
        let msg = '';
        if (error && error.response && error.response.data) {
          msg = error.response.data.message;
        } else {
          msg = 'Une erreur est survenue';
        }
        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        handleClose();
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      {value}
      <IconButton aria-describedby={id} color="primary" onClick={handleClick}>
        <EditIcon sx={{ fontSize: '16px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ p: theme.spacing(3) }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  fullWidth
                  type="number"
                  label="Quantité"
                  {...getFieldProps('quantity')}
                  error={Boolean(touched.quantity && errors.quantity)}
                  helperText={touched.quantity && errors.quantity}
                />
              </Stack>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: theme.spacing(2) }}
              >
                <IconButton color="primary" type="submit">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </Form>
          </FormikProvider>
        </Box>
      </Popover>
    </>
  );
}
