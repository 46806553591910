import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import DoneIcon from '@mui/icons-material/Done';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PATH_TECH } from 'config/paths';
import useTechEditProject from 'hooks/useTechEditProject';
// ----------------------------------------------------------------------

export default function ProjectMenuAction({ item, ...props }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenuAction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        sx={{ p: '10px' }}
        aria-label="Menu"
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenuAction}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem
          onClick={handleClose}
          component={RouterLink}
          to={`${PATH_TECH.editProject}/${item.code}`}
        >
          <EditIcon fontSize="small" sx={{ mr: theme.spacing(1) }} /> Editer
        </MenuItem>
        <DupliProject project={item} handleClose={handleClose} />
        <DeleteProject project={item} handleClose={handleClose} />
      </Menu>
    </>
  );
}
const DupliProject = ({ handleClose, project, ...props }) => {
  const theme = useTheme();
  const { saveTechProject } = useTechEditProject();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [processing, setprocessing] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handeDupli = async () => {
    try {
      if (!project) return;
      if (!project.code) return;
      setprocessing(true);
      const building = project.buildings[0];
      const pbs = project.problematics.map((it) => {
        console.log(it);
        const newItem = { ...it };
        delete newItem.floors;
        const obj = {
          ...newItem,
          products: [...it.product],
          scope: {
            installation: { code: 'ext', label: 'Extérieur' },
            floors: [
              { code: '2', label: '2ème étage' },
              { code: 3, label: '3ème étage' }
            ],
            rooms: { code: 1, label: '1 pièce' }
          }
        };
        return obj;
      });
      const projectToDupi = {
        project: {
          company: {
            name: 'Soc',
            type: 'societe',
            address1: '101 rue jean',
            phone: '123',
            email: 'thy@gmail.com',
            url: ''
          },
          contacts: [...project.contacts],
          name: project.name,
          city: 'Lyon',
          description: 'Description',
          problematics: [...pbs],
          building: {
            ...building
          }
        }
      };
      await saveTechProject(projectToDupi);
      setprocessing(false);
    } catch (error) {
      setprocessing(false);
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <MenuItem onClick={handleClick}>
        <ContentCopyIcon fontSize="small" sx={{ mr: theme.spacing(1) }} /> Dupliquer
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            background: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <BoxDialog bg="#FFFFFF" textColor="#000000">
          <Box display="flex" justifyContent="center" alignContent="center" flexDirection="column">
            <span className="text">Votre projet sera dupliqué. Confirmez-vous ?</span>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ m: theme.spacing(2) }}
            >
              <IconButton 
                 
                variant="contained"
                color="primary"
                onClick={handeDupli}
                disabled={processing}
              >
                <DoneIcon />
              </IconButton>
              <IconButton sx={{ color: '#000000' }} variant="contained" onClick={handleClosePop}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Box>
        </BoxDialog>
      </Popover>
    </>
  );
};
const BoxDialog = styled(Box)(({ bg, textColor, theme }) => ({
  width: '395px',
  height: '160px',
  background: bg,
  borderRadius: '30px 30px 30px 30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '100px',
  padding: theme.spacing(4, 4, 4, 4),
  '& .text': {
    fontFamily: 'Proxima',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: textColor
  }
}));
const DeleteProject = ({ handleClose, project, ...props }) => {
  const theme = useTheme();
  const { deleteProject } = useTechEditProject();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [deleting, setdeleting] = useState(false);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClosePop = () => {
    setAnchorEl(null);
  };
  const handeDelete = async () => {
    try {
      if (!project) return;
      if (!project.code) return;
      setdeleting(true);
      await deleteProject(project.code);
    } catch (error) {
      setdeleting(false);
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <MenuItem onClick={handleClick}>
        <DeleteForeverIcon color="error" fontSize="small" sx={{ mr: theme.spacing(1) }} />
        Supprimer
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            background: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <BoxDialog bg="#668AF5" textColor="#FFFFFF">
          <Box display="flex" justifyContent="center" alignContent="center" flexDirection="column">
            <span className="text">Votre projet sera supprimé. Confirmez-vous ?</span>

            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              sx={{ m: theme.spacing(2) }}
            >
              <IconButton
                sx={{ color: '#FFFFFF' }}
                variant="contained"
                color="primary"
                onClick={handeDelete}
                disabled={deleting}
              >
                <DoneIcon />
              </IconButton>
              <IconButton sx={{ color: '#FFFFFF' }} variant="contained" onClick={handleClosePop}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
          </Box>
        </BoxDialog>
      </Popover>
    </>
  );
};
