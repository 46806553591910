import React from 'react';
import { NavLink as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
// ----------------------------------------------------------------------
const TopBarStyle = styled(Box)(({ theme }) => ({
  zIndex: 1200,
  width: '100%',
  margin: theme.spacing(1, 3, 0, 3),
  marginBottom: `${MARGE_BAR_DESKTOP}px`,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: 'transparent',
  position: 'relative',
  minHeight: `${APP_BAR_MOBILE}px`,
  [theme.breakpoints.up('lg')]: {},
  '& .center-items-box': {
    width: '100%',
    height: `${TOP_BAR_TECHPROJECT_DESKTOP}px`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute'
  }
}));
export default function TechTopBar({ style, menuCenter, ...props }) {
  const theme = useTheme();
  return (
    <>
      <TopBarStyle style={{ ...style }}>
        {menuCenter && (
          <>
            <Box className="center-items-box">
              {menuCenter.map((it, i) => {
                return (
                  <Button
                    style={({ isActive }) => ({
                      opacity: it.defActiv || isActive ? 1 : 0.3
                    })}
                    sx={{ mx: theme.spacing(1.5), color: '#FFFFFF' }}
                    variant="outlined"
                    key={Math.random()}
                    component={RouterLink}
                    to={it.path}
                  >
                    {it.label}
                  </Button>
                );
              })}
            </Box>
          </>
        )}
        {props.children}
      </TopBarStyle>
    </>
  );
}
