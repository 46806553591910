import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import { alpha, styled } from '@mui/material/styles';
import { debounce } from 'lodash';

// ----------------------------------------------------------------------
const SearchInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    borderRadius: 4,
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'background-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main
    }
  }
}));
const regex = new RegExp('^[a-zA-Z0-9.,/ $@()]+$');
export default function ProjectListSearch({ handleChangeFilters, ...props }) {
  const [keyword, setKeyword] = useState('');
  const [textEntered, setTextEntered] = useState([]);

  const handleClear = () => {
    setKeyword('');
    handleChangeFilters({ keyword: '' });
  };

  useEffect(() => {
    if (!keyword) return;
    debounce((keyword) => {
      handleChangeFilters({ keyword });
    }, 350);
  }, [keyword]);

  useEffect(() => {
    if (!keyword) return;
    handleChangeFilters({ keyword });
  }, [keyword]);

  return (
    <Paper
      component="form"
      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', backgroundColor: 'transparent' }}
    >
      <Box sx={{ borderRadius: '4px', border: '1px solid #95A2AD' }}>
        <SearchInput
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
          sx={{ ml: 1, flex: 1 }}
          placeholder="Rechercher"
          inputProps={{ 'aria-label': 'Rechercher' }}
        />
        <IconButton sx={{ p: '10px' }} aria-label="menu">
          {/* <SearchIcon /> */}
          <img src="/static/icons/ic_search.svg" alt="Recherche" />
        </IconButton>
      </Box>
      {/*  <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" /> */}
      <IconButton sx={{ p: '10px' }} aria-label="Effacer" onClick={handleClear}>
        <ClearIcon />
      </IconButton>
    </Paper>
  );
}
