import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { keyframes } from '@mui/system';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import ClickAwayListener from '@mui/material/ClickAwayListener';

// ----------------------------------------------------------------------
const MAX_FADE_TIME = 5;
const MIN_FADE_TIME = 1.5;
const fade = keyframes`
  from { opacity: 0.3; } 
  20% { opacity: 0.4; } 
  50% { opacity: 0.5; } 
  70% { opacity: 0.7; } 
  90% { opacity: 0.9; } 
`;
const colorFixed = '#668AF5';
const ColorButton = styled(IconButton)(({ activ, theme }) => {
  const rdTimeout = Math.random() * (MAX_FADE_TIME - MIN_FADE_TIME) + MIN_FADE_TIME;
  return {
    color: theme.palette.getContrastText(colorFixed),
    //border: '1px solid #FFFFFF',
    padding: '11px',
    backgroundColor: activ ? '#668AF5' : 'none',
    '&:hover': {
      backgroundColor: '#668AF5'
    },
    width: 'auto',
    zIndex: 20,
    animation: `${fade} ${rdTimeout}s ease-out infinite alternate`
  };
});
const colorFixedInner = '#FFFFFF';
const InnerColorButton = styled(Box)(({ bg, borderColor, pd, theme }) => {
  const rdTimeout = Math.random() * (MAX_FADE_TIME - MIN_FADE_TIME) + MIN_FADE_TIME;
  return {
    border: `1px solid ${borderColor}`,
    padding: `${pd}px`,
    borderRadius: '50%',
    backgroundColor: bg,
    width: 'auto',
    zIndex: 20,
    animation: `${fade} ${rdTimeout}s ease-out infinite alternate`
  };
});
export default function SpecButton({ activ, item, handleOnClickSpec, icon, style, ...props }) {
  return (
    <ColorButton activ={activ} style={{ ...style }} onClick={handleOnClickSpec}>
      <img src="/static/bg/bg_multi_circle.svg" alt="" />
    </ColorButton>
  );
}
/* <ColorButton style={{ ...style }} onClick={handleOnClickSpec}>
      <InnerColorButton bg="#FFFFFF" borderColor="#668AF5" pd="9">
        <InnerColorButton bg="#668AF5" borderColor="#FFFFFF" pd="8">
          <InnerColorButton bg="#FFFFFF" borderColor="#668AF5" pd="5" />
        </InnerColorButton>
      </InnerColorButton>
    </ColorButton> */
