import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setInitialize, setUser, logout } from 'redux/slices/authSlice';
import axios from 'axios';
import { API_URL, CLIENT_PROFILE, TECH_PROFILE, SUPERADMIN_PROFILE } from 'config/appConfig';
import { resetUnity } from 'redux/slices/projectUnitySlice';
import { resetProject } from 'redux/slices/clientEditProjectSlice';
import { getAllProjects, getReferential } from 'redux/slices/referentialSlice';

export default function useAuth() {
  const dispatch = useDispatch();
  const { token, remember, user, isLoading, isAuthenticated } = useSelector((state) => state.auth);
  return {
    remember,
    isAuthenticated,
    isLoading,
    user,
    initAuth: async () => {
      try {
        const accessToken = window.localStorage.getItem('accessToken');
        if (accessToken) {
          const response = await axios.get(API_URL.authenticateByToken, {
            headers: {
              Authorization: `Bearer ${accessToken}`
            }
          });
          const { user } = response.data.data;
          const profile = getRole(user);
          const newU = {
            ...user,
            profile
          };
          dispatch(
            setInitialize({
              isAuthenticated: true,
              user: newU,
              accessToken
            })
          );
        } else {
          dispatch(
            setInitialize({
              isAuthenticated: false,
              user: null,
              accessToken: null
            })
          );
        }
      } catch (error) {
        console.error(error);
        dispatch(
          setInitialize({
            isAuthenticated: false,
            user: null,
            accessToken: null
          })
        );
      }
    },
    login: async (email, password, remember) => {
      try {
        const response = await axios.post(API_URL.login, {
          email,
          password
        });
        const { token, user } = response.data.data;
        const profile = getRole(user);
        const newU = {
          ...user,
          profile
        };
        console.log('useAuth', newU);
        dispatch(resetProject());
        dispatch(setUser({ user: newU, accessToken: token, remember }));
        dispatch(getReferential());
        dispatch(getAllProjects());
      } catch (error) {
        console.error();
        throw error;
      }
    },
    logout() {
      dispatch(resetUnity());
      dispatch(resetProject());
      return new Promise((res) => {
        dispatch(logout());
        res();
      });
    }
  };
}
function getRole(u) {
  try {
    if (
      u.profile.role === 'superadmin' ||
      u.profile.role === 'admin' ||
      u.profile.role === 'manager'
    ) {
      return SUPERADMIN_PROFILE;
    }
    if (u.profile.role === 'technician') {
      return TECH_PROFILE;
    }
    return CLIENT_PROFILE;
  } catch (error) {
    console.error(error);
    return CLIENT_PROFILE;
  }
}
