import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, NavLink as RouterLink } from 'react-router-dom';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';

import { styled, useTheme } from '@mui/material/styles';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';
import {
  SCOPE_FLOORS_COUNT,
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import ProjectProvider from 'provider/ProjectProvider';
import { PATH_TECH } from 'config/paths';
import TopNavbar from 'layouts/vertical/TopNavbar';
import TechTopBar from 'pages/tech/edit/TechTopBar';
import TechContactProject from './TechContactProject';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ theme }) => ({
  width: '828px',
  height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(0, 4, 0, 4),
  overflow: 'auto'
}));
export default function TechProject({ ...props }) {
  const navigate = useNavigate();
  const { id } = useParams();
  const [editingProject, seteditingProject] = useState(null);
  const [displayContact, setdisplayContact] = useState(false);
  const {
    clearProject,
    project,
    initProjectById,
    building_types,
    surfaces,
    glazing_types,
    roof_types,
    floors,
    problematics,
    saveTechProject
  } = useTechEditProject();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  useEffect(() => {
    if (!id) return;
    initProjectById(id);
  }, [id]);
  useEffect(() => {
    //seteditingProject(project);
    clearProject();
  }, []);

  const ProjectSchema = Yup.object().shape({
    name: Yup.string().required('Veuillez renseigner le nom du projet'),
    city: Yup.string().required('Veuillez renseigner la ville où se trouve le projet'),
    description: Yup.string().required('Veuillez renseigner le nom du projet'),
    building_type: Yup.string().required('Veuillez renseigner le type du bâtiment'),
    building_surface: Yup.string().required(`Veuillez renseigner la surface de l'immeuble'`),
    building_glazing_type: Yup.string().required('Veuillez renseigner le type de vitrage'),
    building_roof_type: Yup.string().required('Veuillez renseigner le type de toiture'),
    building_floors: Yup.string().required(`Veuillez renseigner le nombre d'étage`),

    problematics: Yup.array().min(1, 'Veuillez sélectionner au moins une problématique')
  });
  const building = project && project.buildings ? project.buildings[0] : null;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editingProject ? editingProject.name : '',
      description: editingProject ? editingProject.name : '',
      building_type: building && building.roof_type ? building.roof_type.code : '',
      building_surface: building && building.access ? building.access.code : '',
      building_glazing_type: building && building.glazing_type ? building.glazing_type.code : '',
      building_roof_type: building && building.roof_type ? building.roof_type.code : false,
      building_floors: building ? building.floors : '',
      problematics: editingProject && editingProject.problematics ? editingProject.problematics : []
    },
    validationSchema: ProjectSchema,
    onSubmit: async (values) => {
      try {
        const buildingProj = building_types.find(
          (it) => String(it.code) === String(values.building_type)
        );
        const problematicsBuilding = [...values.problematics];
        const problematicsPrj = problematics.filter((it) => {
          const idx = problematicsBuilding.findIndex((p) => String(p) === String(it.code));
          return idx > -1;
        });

        const floorsPrj = SCOPE_FLOORS_COUNT.find(
          (it) => String(it.label) === String(values.building_floors)
        );
        const obj = {};
        obj.name = values.name;
        obj.city = values.city;
        obj.description = values.description;
        obj.problematics = problematicsPrj.map((it) => {
          const { code, label, category } = it;
          return {
            code,
            label,
            products: [
              {
                code: category[0].products[0].code,
                label: category[0].products[0].label
              }
            ],
            scope: {
              installation: {
                code: 'ext',
                label: 'Extérieur'
              },
              floors: [
                {
                  code: '2',
                  label: '2ème étage'
                },
                {
                  code: 3,
                  label: '3ème étage'
                }
              ],
              rooms: {
                code: 1,
                label: '1 pièce'
              }
            }
          };
        });
        obj.building = {
          type: {
            code: buildingProj.code,
            label: buildingProj.label
          },
          surface: {
            code: values.building_surface
          },
          glazing_type: {
            code: values.building_glazing_type
          },
          roof_type: {
            code: values.building_roof_type
          },

          floors: values.building_floors,
          ignoredFloorsNb: false
        };
        seteditingProject({ ...obj });
        setdisplayContact(true);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const {
    errors,
    touched,
    setFieldValue,
    values,
    isSubmitting,
    handleChange,
    handleSubmit,
    getFieldProps
  } = formik;

  const handleChangeProblematic = (e) => {
    const { checked, name } = e.target;

    if (checked) {
      const pb = values.problematics.filter((v) => v !== 'on');
      setFieldValue('problematics', [...pb, name]);
    } else {
      setFieldValue(
        'problematics',
        values.problematics.filter((v) => v !== name && v !== 'on')
      );
    }
  };
  const onComplete = async (valuesContact) => {
    try {
      const { client, techResp } = valuesContact;
      const company = {
        name: client.nameCompany,
        type: 'societe',
        address1: client.addrQuote,
        phone: client.phoneNumber,
        email: client.email,
        url: ''
      };
      const contacts = [
        {
          lastname: client.clientLastName,
          firstname: client.clientName,
          poste: client.clientRank,
          role: 'administrator',
          email: client.email,
          phone: client.phoneNumber
        },
        {
          lastname: techResp.clientLastName,
          firstname: techResp.clientName,
          poste: techResp.clientRank,
          role: 'technician',
          email: techResp.email,
          phone: client.phoneNumber
        }
      ];
      const objToCreate = {
        project: {
          company,
          contacts,
          ...editingProject
        }
      };
      await saveTechProject(objToCreate);

      enqueueSnackbar('Les informations techniques sont enregistrées avec succès.', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      navigate(PATH_TECH.root);
    } catch (error) {
      const { message } = error.response.data;
      enqueueSnackbar(message, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  return (
    <ProjectProvider>
      <TopNavbar>
        <TechTopBar>
          <Button
            variant="outlined"
            sx={{ mx: theme.spacing(3), color: '#FFFFFF' }}
            component={RouterLink}
            to={PATH_TECH.root}
          >
            <ClearOutlinedIcon sx={{ mr: theme.spacing(1) }} /> Annuler
          </Button>
        </TechTopBar>
      </TopNavbar>
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        sx={{ height: 'auto', padding: theme.spacing(3, 0, 0, 0) }}
      >
        {displayContact ? (
          <TechContactProject onComplete={onComplete} />
        ) : (
          <MBuildingStyle>
            <Typography variant="h3" sx={{ textTransform: 'uppercase', mt: theme.spacing(4) }}>
              Informations sur le projet
            </Typography>
            <FormikProvider value={formik}>
              <Form
                autoComplete="off"
                noValidate
                onSubmit={handleSubmit}
                style={{
                  margin: theme.spacing(3, 0, 3, 0),
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'column'
                }}
              >
                <Stack spacing={2} sx={{ width: '100%' }}>
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    fullWidth
                    type="text"
                    label="Nom du projet"
                    {...getFieldProps('name')}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    fullWidth
                    type="text"
                    label="La ville où se trouve le projet"
                    {...getFieldProps('city')}
                    error={Boolean(touched.city && errors.city)}
                    helperText={touched.city && errors.city}
                  />
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    fullWidth
                    type="text"
                    label="Description"
                    multiline
                    {...getFieldProps('description')}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                  />
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    select
                    label="Type de bâtiment"
                    value={values.building_type}
                    onChange={handleChange('building_type')}
                    {...getFieldProps('building_type')}
                    error={Boolean(touched.building_type && errors.building_type)}
                    helperText={touched.building_type && errors.building_type}
                  >
                    {building_types.map((option, i) => (
                      <MenuItem key={i} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    select
                    label="Surface du bâtiment"
                    value={values.building_surface}
                    onChange={handleChange('building_surface')}
                    {...getFieldProps('building_surface')}
                    error={Boolean(touched.building_surface && errors.building_surface)}
                    helperText={touched.building_surface && errors.building_surface}
                  >
                    {surfaces.map((option, i) => (
                      <MenuItem key={i} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    select
                    label="Type de vitrage"
                    value={values.building_glazing_type}
                    onChange={handleChange('building_glazing_type')}
                    {...getFieldProps('building_glazing_type')}
                    error={Boolean(touched.building_glazing_type && errors.building_glazing_type)}
                    helperText={touched.building_glazing_type && errors.building_glazing_type}
                  >
                    {glazing_types.map((option, i) => (
                      <MenuItem key={i} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    select
                    label="Type de toiture"
                    value={values.building_roof_type}
                    onChange={handleChange('building_roof_type')}
                    {...getFieldProps('building_roof_type')}
                    error={Boolean(touched.building_roof_type && errors.building_roof_type)}
                    helperText={touched.building_roof_type && errors.building_roof_type}
                  >
                    {roof_types.map((option, i) => (
                      <MenuItem key={i} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  <TextField
                    sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                    select
                    label="Nombre d'étage"
                    value={values.building_floors}
                    onChange={handleChange('building_floors')}
                    {...getFieldProps('building_floors')}
                    error={Boolean(touched.building_floors && errors.building_floors)}
                    helperText={touched.building_floors && errors.building_floors}
                  >
                    {SCOPE_FLOORS_COUNT.map((option, i) => (
                      <MenuItem key={i} value={option.code}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                  {problematics && (
                    <>
                      <Box display="flex" justifyContent="space-between" alignItems="center">
                        <FormLabel component="legend" sx={{ margin: theme.spacing(0, 2, 0, 0) }}>
                          Problématiques
                        </FormLabel>
                        <FormGroup aria-label="Problématique" row>
                          {problematics.map((it, i) => {
                            return (
                              <FormControlLabel
                                key={i}
                                control={
                                  <Checkbox
                                    name={it.code}
                                    checked={values.problematics.includes(String(it.code))}
                                    onChange={handleChangeProblematic}
                                  />
                                }
                                label={it.label}
                              />
                            );
                          })}
                        </FormGroup>
                      </Box>
                      {errors && errors.problematics && (
                        <Alert severity="error">{errors.problematics} </Alert>
                      )}
                    </>
                  )}
                </Stack>

                <Stack direction="row" alignItems="center" justifyContent="center">
                  <GradientBtn
                    type="submit"
                    size="large"
                    color="info"
                    variant="contained"
                    sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
                  >
                    Valider
                  </GradientBtn>
                </Stack>
              </Form>
            </FormikProvider>
          </MBuildingStyle>
        )}
      </Box>
    </ProjectProvider>
  );
}
