import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

import { useTheme, styled } from '@mui/material/styles';
import * as Yup from 'yup';
import { EditProjectContext } from 'provider/EditProjectProvider';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';
// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const CenterBoxStyle = styled(Box)(({ theme }) => ({
  width: '551px',
  height: '100%',
  background: `linear-gradient(360deg, #FFFFFF -2.96%, rgba(255, 255, 255, 0) 136.82%)`,
  backdropFilter: 'blur(25px)',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& .title': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '44px',
    textTransform: 'uppercase',
    color: '#121E6E',
    textAlign: 'center',
    margin: theme.spacing(2, 0, 0, 0)
  },
  '& .inner-box': {
    margin: theme.spacing(1.5, 0, 1.5, 0),
    padding: theme.spacing(2, 3, 2, 3),
    width: '475px',
    background: '#FFFFFF',
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '10px'
  }
}));

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#121E6E'
  }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'uppercase',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '48px',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  marginRight: theme.spacing(1),

  '&:hover': {
    color: '#3C60B5',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#3C60B5',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));
export default function FlowContact({ flow, ...props }) {
  const theme = useTheme();
  const { onChangeFlow, validateProject } = useContext(EditProjectContext);
  const [contact, setContact] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [disableTechResp, setDisableTechResp] = useState(true);

  useEffect(() => {
    if (!contact || !contact.client) setDisableTechResp(true);
  }, [contact]);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onSubmitContact = useCallback(
    (values) => {
      setCurrentTab(1);
      setContact({ client: { ...values } });
    },
    [currentTab, contact]
  );
  const onSubmitTechResp = useCallback(
    (values) => {
      setCurrentTab(1);
      setContact({ client: { ...values } });
    },
    [currentTab, contact]
  );
  const onChangeTechResp = useCallback(
    (val) => {
      const newContact = { ...contact };
      newContact.isTechResp = val;
      if (val) {
        const { clientName, clientRank, email, phoneNumber } = contact.client;
        newContact.techResp = {
          clientLastName: clientName,
          clientName,
          clientRank,
          email,
          phoneNumber
        };
      } else {
        delete newContact.techResp;
      }
      setContact(newContact);
    },
    [currentTab, contact]
  );
  const onComplete = useCallback(
    async (values) => {
      const newContact = { ...contact };
      newContact.techResp = { ...values };
      setContact(newContact);
      await onChangeFlow(flow, newContact);
    },
    [currentTab, contact]
  );
  return (
    <RootStyle>
      <CenterBoxStyle>
        <Typography className="title">Fiche Contact</Typography>
        <Box className="inner-box">
          <AntTabs value={currentTab} onChange={handleChange} aria-label="Contact" centered>
            <AntTab label="Contact client" />
            <AntTab label="Responsable technique" disabled={disableTechResp} />
          </AntTabs>
          {currentTab === 0 && (
            <FormContact
              client={contact ? contact.client : null}
              onSubmitContact={onSubmitContact}
            />
          )}
          {currentTab === 1 && (
            <FormTechResp
              techResp={contact ? contact.techResp : null}
              isTechResp={contact ? contact.isTechResp : false}
              onSubmitTechResp={onSubmitTechResp}
              onChangeTechResp={onChangeTechResp}
              onComplete={onComplete}
            />
          )}
        </Box>
      </CenterBoxStyle>
    </RootStyle>
  );
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FormContact = ({ onSubmitContact, client, ...props }) => {
  const theme = useTheme();
  const ContactSchema = Yup.object().shape({
    nameCompany: Yup.string().required('Veuillez renseigner le nom de la société'),
    addrQuote: Yup.string().required(`Veuillez renseigner l'adresse du devis`),
    addrProject: Yup.string(),
    clientName: Yup.string().required(`Veuillez renseigner le nom de la personne à contacter`),
    clientRank: Yup.string().required(`Veuillez renseigner le poste de la personne à contacter`),
    email: Yup.string()
      .email(`L'adresse mail n'est pas valide`)
      .required(`Veuillez renseigner un email`),
    phoneNumber: Yup.string().matches(phoneRegExp, `Numéro de téléphone n'est pas valide`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameCompany: client ? client.nameCompany : '',
      addrQuote: client ? client.addrQuote : '',
      addrProject: client ? client.addrProject : '',
      clientName: client ? client.clientName : '',
      clientRank: client ? client.clientRank : '',
      email: client ? client.email : '',
      phoneNumber: client ? client.phoneNumber : ''
    },
    validationSchema: ContactSchema,
    onSubmit: async (values) => {
      try {
        console.log('submit');
        onSubmitContact(values);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{
          margin: theme.spacing(3, 0, 3, 0),
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Nom de la société"
            {...getFieldProps('nameCompany')}
            error={Boolean(touched.nameCompany && errors.nameCompany)}
            helperText={touched.nameCompany && errors.nameCompany}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Adresse du devis"
            {...getFieldProps('addrQuote')}
            error={Boolean(touched.addrQuote && errors.addrQuote)}
            helperText={touched.addrQuote && errors.addrQuote}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Adresse du projet si différente"
            {...getFieldProps('addrProject')}
            error={Boolean(touched.addrProject && errors.addrProject)}
            helperText={touched.addrProject && errors.addrProject}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Nom"
            {...getFieldProps('clientName')}
            error={Boolean(touched.clientName && errors.clientName)}
            helperText={touched.clientName && errors.clientName}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Poste"
            {...getFieldProps('clientRank')}
            error={Boolean(touched.clientRank && errors.clientRank)}
            helperText={touched.clientRank && errors.clientRank}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="email"
            label="Adresse email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
            fullWidth
            type="text"
            label="Numéro de téléphone"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          <GradientBtn
            type="submit"
            size="large"
            color="info"
            variant="contained"
            sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
          >
            Valider
          </GradientBtn>
        </Stack>
      </Form>
    </FormikProvider>
  );
};

const FormTechResp = ({
  onSubmitTechResp,
  techResp,
  isTechResp,
  onChangeTechResp,
  onComplete,
  ...props
}) => {
  const theme = useTheme();
  const TechRespSchema = Yup.object().shape({
    clientLastName: Yup.string().required('Veuillez renseigner le prénom'),
    clientName: Yup.string().required(`Veuillez renseigner le nom famille`),
    clientRank: Yup.string().required(`Veuillez renseigner le poste de la personne à contacter`),
    email: Yup.string()
      .email(`L'adresse mail n'est pas valide`)
      .required(`Veuillez renseigner un email`),
    phoneNumber: Yup.string().matches(phoneRegExp, `Numéro de téléphone n'est pas valide`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientLastName: techResp ? techResp.clientLastName : '',
      clientName: techResp ? techResp.clientName : '',
      clientRank: techResp ? techResp.clientRank : '',
      email: techResp ? techResp.email : '',
      phoneNumber: techResp ? techResp.phoneNumber : ''
    },
    validationSchema: TechRespSchema,
    onSubmit: async (values) => {
      try {
        onComplete(values);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const handleCheck = (event) => {
    onChangeTechResp(event.target.checked);
  };
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
        <FormControlLabel
          control={<Checkbox checked={isTechResp} onChange={handleCheck} />}
          label="Je suis responsable technique"
        />
      </Stack>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            margin: theme.spacing(3, 0, 3, 0),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Stack spacing={2}>
            <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
              fullWidth
              type="text"
              label="Prénom"
              {...getFieldProps('clientLastName')}
              error={Boolean(touched.clientLastName && errors.clientLastName)}
              helperText={touched.clientLastName && errors.clientLastName}
            />
            <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
              fullWidth
              type="text"
              label="Nom"
              {...getFieldProps('clientName')}
              error={Boolean(touched.clientName && errors.clientName)}
              helperText={touched.clientName && errors.clientName}
            />
            <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
              fullWidth
              type="text"
              label="Poste"
              {...getFieldProps('clientRank')}
              error={Boolean(touched.clientRank && errors.clientRank)}
              helperText={touched.clientRank && errors.clientRank}
            />
            <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
              fullWidth
              type="email"
              label="Adresse email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }} 
              fullWidth
              type="text"
              label="Numéro de téléphone"
              {...getFieldProps('phoneNumber')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
            >
              Finaliser
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};
