import { img64_black_and_white, img64_dore } from './img64';

export const themeMode = 'light';
export const themeDirection = 'ltr';
export const TOKEN_SECRET_KEY = 'PARIS';
export const APP_BAR_MOBILE = 64;
export const APP_BAR_DESKTOP = 92;
export const MARGE_BAR_DESKTOP = 8;
export const TOP_BAR_TECHPROJECT_DESKTOP = 70;
export const MENU_CLIP_WIDTH = 90;
export const MENU_CLIP_HEIGHT = 800;
export const MENU_CLIP_SHOT_WIDTH = 83;
export const MENU_CLIP_SHOT_HEIGHT = 650;
export const LOCAL_PROJECT = 'LOCAL_PROJECT';
export const TECH_PROFILE = 'tech';
export const CLIENT_PROFILE = 'client';
export const ADMIN_PROFILE = 'admin';
export const SUPERADMIN_PROFILE = 'superadmin';
export const MANAGER_PROFILE = 'manager';
export function isComProfile(user) {
  if (!user) return false;
  if (!user.profile) return false;
  const p = user.profile;
  if (p && [ADMIN_PROFILE, SUPERADMIN_PROFILE, 'com'].includes(p)) {
    return true;
  }
  return false;
}
export function isClientProfile(user) {
  if (!user) return false;
  if (!user.profile) return false;
  const p = user.profile;
  if (p && [ADMIN_PROFILE, SUPERADMIN_PROFILE, CLIENT_PROFILE].includes(p)) {
    return true;
  }
  return false;
}
export function isTechProfile(user) {
  if (!user) return false;
  if (!user.profile) return false;
  const p = user.profile;
  if (p && [ADMIN_PROFILE, SUPERADMIN_PROFILE, 'tech', TECH_PROFILE].includes(p)) {
    return true;
  }
  return false;
}
export function isSuperadminProfile(user) {
  if (!user) return false;
  if (!user.profile) return false;
  const p = user.profile;
  if (p && [SUPERADMIN_PROFILE, MANAGER_PROFILE].includes(p)) {
    return true;
  }
  return false;
}
/* const HOST = 'http://localhost:8000'; https://back.jpsmetaview.com/api/v1 https://jpsmetaview.com/*/
const HOST = 'https://api-jps.creativitywebm.com';
const HOST_PHP = process.env.REACT_APP_API_ENDPOINT; // 'https://back.jpsmetaview.com/api/v1';
/*   login: `${HOST_PHP}/login`,
  authenticateByToken: `${HOST_PHP}/me`, */
export const API_URL = {
  login: `${HOST_PHP}/login`,
  authenticateByToken: `${HOST_PHP}/me`,
  projects: `${HOST_PHP}/projects`,
  project: `${HOST_PHP}/project`,
  projectCreate: `${HOST_PHP}/project/create`,
  buildingTypes: `${HOST_PHP}/buildingTypes`,
  specifications: `${HOST_PHP}/specifications`,
  products: `${HOST_PHP}/products`,
  orders: `${HOST_PHP}/orders`,
  referentiel: `${HOST_PHP}/referentiel`,
  buildingUpdate: `${HOST_PHP}/building/update`,
  roomCreate: `${HOST_PHP}/room/create`,
  glazingCreate: `${HOST_PHP}/glazing/create`,
  glazingUpdate: `${HOST_PHP}/glazings/update`,
  glazingRemove: `${HOST_PHP}/glazings/delete`,
  glazingOrder: `${HOST_PHP}/glazings/order`,
  glazingsCalculate: `${HOST_PHP}/glazings/calculate`,
  orderBill: `${HOST_PHP}/quote`,
  orderBillUpdate: `${HOST_PHP}/quote/update`,
  addProblematics: `${HOST_PHP}/problematic/add`,
  searchCompanyContact: `${HOST_PHP}/search`
};
export const API_URL_DEV = {
  login: `${HOST}/auth/login`,
  authenticateByToken: `${HOST}/auth/authenticateByToken`,
  projects: `${HOST}/projects`,
  buildingTypes: `${HOST}/buildingTypes`,
  specifications: `${HOST}/specifications`,
  products: `${HOST}/products`,
  orders: `${HOST}/orders`
};
export const BOT_WIDTH = 600;
export const BOT_HEIGHT = 600;

export const ROOFTYPES = [
  { code: 1, label: 'Plat', img: `${process.env.PUBLIC_URL}/static/bg/bg_roof_plat.svg` },
  { code: 2, label: 'Verrière', img: `${process.env.PUBLIC_URL}/static/bg/bg_roof_verriere.svg` },
  { code: 3, label: 'Aucun' }
];
export const GlAZINGTYPE = [
  { code: 1, label: 'Simple', lines: 1 },
  { code: 2, label: 'Double', lines: 2 },
  { code: 3, label: 'TBD' }
];
export const SURFACES = [
  { code: 50, label: '50M²' },
  { code: 10, label: '100M²' },
  { code: 1000, label: '1000M²' }
];
export const ACCESS_BUILDING = [
  { code: 1, label: `Accueil disponible` },
  { code: 2, label: 'Badge' },
  { code: 3, label: 'Libre' }
];
export const OCCUPY_BUILDING = [
  { code: 1, label: `Propriétaire occuppant` },
  { code: 2, label: 'Locataire' },
  { code: 3, label: 'Association' }
];

export const SCOPE_INOUT = [
  {
    code: 'ext',
    label: 'Extérieur'
  },
  {
    code: 'int',
    label: 'Intérieur'
  }
];
export const SCOPE_FLOORS_COUNT = [...Array(10)].map((it, i) => {
  return {
    code: i,
    label: i
  };
});
export const SCOPE_FLOORS = [...Array(10)].map((it, i) => {
  return {
    code: i,
    label: i === 0 ? 'RDC' : i === 1 ? 'Premier étage' : `${i}nd étage${i === 1 ? '' : 's'}`
  };
});
//SCOPE_FLOORS.push({ code: 'all', label: 'Tous les étages' });
export const SCOPE_ROOMS = [...Array(10)].map((it, i) => {
  return {
    code: i + 1,
    label: `${i + 1} pièce${i + 1 === 1 ? '' : 's'}`
  };
});
SCOPE_ROOMS.push({ code: 'all', label: 'Toutes les pièces' });
export const GLAZING_FORMAT = [
  {
    code: 1,
    label: 'Vitre rectangle'
  },
  {
    code: 2,
    label: 'Vitre carré'
  },
  {
    code: 3,
    label: 'Vitre ovale'
  }
];
export const GLAZING_HEIGHT = [
  {
    code: 200,
    label: '200'
  },
  {
    code: 215,
    label: '215'
  }
];
export const GLAZING_WIDTH = [
  {
    code: 90,
    label: '90'
  },
  {
    code: 120,
    label: '120'
  },
  {
    code: 180,
    label: '180'
  },
  {
    code: 210,
    label: '210'
  },
  {
    code: 240,
    label: '240'
  }
];
export const GLAZING_FRAME_TYPE = [
  {
    code: 1,
    label: 'Bois'
  },
  {
    code: 2,
    label: 'PVC'
  },
  {
    code: 3,
    label: 'Aluminium'
  }
];
export const GLAZING_JOINT_TYPE = [
  {
    code: 1,
    label: 'Mousse'
  },
  {
    code: 2,
    label: 'Plastique'
  },
  {
    code: 3,
    label: 'Métalique'
  }
];
export const GLAZING_WINDOWBIND_TYPE = [
  {
    code: 1,
    label: 'Vénitien'
  },
  {
    code: 2,
    label: 'Enrouleur'
  },
  {
    code: 3,
    label: 'Bâteau'
  },
  {
    code: 4,
    label: 'Japonais'
  }
];
export const GLAZING_JOINT_STATE = [
  {
    code: 1,
    label: 'Mauvais'
  },
  {
    code: 2,
    label: 'Bon état'
  },
  {
    code: 3,
    label: 'Neuf'
  }
];
export const GLAZING_WIND_EXPO = [
  {
    code: 1,
    label: 'Faible'
  },
  {
    code: 2,
    label: 'Moyenne'
  },
  {
    code: 3,
    label: 'Forte'
  }
];
export const GLAZING_SUN_EXPO = [
  {
    code: 1,
    label: 'Sud'
  },
  {
    code: 2,
    label: 'Nord'
  },
  {
    code: 3,
    label: 'Est'
  },
  {
    code: 3,
    label: 'Ouest'
  }
];
export const GLAZING_SHADED_ZONE = [
  {
    code: 1,
    label: 'Nulle'
  },
  {
    code: 2,
    label: 'Partielle'
  },
  {
    code: 3,
    label: 'Totale'
  }
];
export const DECO_SCENE_TEXTURES = [
  {
    code: 0,
    label: 'Aucune',
    color: '#FFFFFF',
    img64: ''
  }
];
export const DECO_SCENE_MOTIF = [
  {
    code: 1,
    label: 'Kids',
    color: '#FFFFFF',
    motif: 1
  },
  {
    code: 2,
    label: 'Doré',
    color: '#FFFFFF',
    motif: 3
  },
  {
    code: 10,
    label: 'Texture doré',
    color: '#FFFFFF',
    img64: img64_dore
  },
  {
    code: 22,
    label: 'Black & White',
    color: '#FFFFFF',
    img64: img64_black_and_white
  },
  {
    code: 3,
    label: 'Noir',
    color: '#000000',
    motif: 0
  },
  {
    code: 4,
    label: 'Bleu',
    color: '#233884',
    motif: 0
  },
  {
    code: 5,
    label: 'Emeraude',
    color: '#36ACB4',
    motif: 0
  },
  {
    code: 6,
    label: 'Or',
    color: '#D4B237',
    motif: 0
  },
  {
    code: 7,
    label: 'Vert',
    color: '#B1C78D',
    motif: 0
  }
];

export const PRIVACY_SCENE_MOTIF = [
  {
    code: 1,
    label: 'Dépoli blanc',
    motif: 1,
    color: '#FFFFFF'
  },
  { code: 2, label: 'Miroir', motif: 0, color: '#FFFFFF00', metalness: 1 },
  {
    code: 3,
    label: 'Bandes',
    motif: 2,
    color: '#FFFFFFFF'
  },
  {
    code: 4,
    label: 'Occultant',
    color: '#FFFFFFFF'
  },
  {
    code: 5,
    label: 'Carrés',
    motif: 3,
    color: '#FFFFFFFF'
  }
];
export const PRIVACY_SCENE_COVERING = [
  {
    code: 1,
    label: 'Partiel',
    value: 0.5,
    verticality: 0
  },
  {
    code: 2,
    label: 'Haut',
    value: 0.3,
    verticality: 1
  },
  {
    code: 3,
    label: 'Bas',
    value: 0.7,
    verticality: 0
  },
  {
    code: 4,
    label: 'Complet',
    value: 1,
    verticality: 0
  }
];

export const SECURITY_SCENE_RESISANCE = {
  thin: {
    code: 1,
    label: 'Faible',
    opacity: 20,
    color: '#FFFFFF'
  },
  medium: {
    code: 2,
    label: 'Moyen',
    opacity: 40,
    color: '#FFFFFF'
  },
  strong: {
    code: 3,
    label: 'Fort',
    opacity: 60,
    color: '#FFFFFF'
  }
};
export const VEHICULE_SCENE_SCOPE = [
  {
    code: 1,
    label: 'Décoration de la carrosserie'
  },
  {
    code: 2,
    label: 'Vitre'
  }
];
export const VEHICULE_VITRE_TEINTE = [
  {
    code: 1,
    label: 'Teinte 1',
    color: '#00000030'
  },
  {
    code: 2,
    label: 'Teinte 2',
    color: '#00000050'
  },
  {
    code: 3,
    label: 'Teinte 3',
    color: '#00000070'
  },
  {
    code: 4,
    label: 'Teinte 4',
    color: '#00000090'
  }
];
export const VEHICULE_SCENE_COLOR = [
  {
    code: 1,
    label: 'Noir brillant',
    color: '#000000'
  },
  {
    code: 2,
    label: 'Noir mat',
    color: '#000000',
    mat: true
  },
  {
    code: 3,
    label: 'Bleu brillant',
    color: '#2349AC90'
  },
  {
    code: 4,
    label: 'Bleu mat',
    color: '#2349AC90',
    mat: true
  },
  {
    code: 5,
    label: 'Rouge brillant',
    color: '#C2474790'
  },
  {
    code: 6,
    label: 'Rouge mat',
    color: '#C2474790',
    mat: true
  },
  {
    code: 9,
    label: 'Blanc brillant',
    color: '#FFFFFF90'
  },
  {
    code: 10,
    label: 'Blanc mat',
    color: '#FFFFFF90',
    mat: true
  },
  {
    code: 11,
    label: 'Jaune brillant',
    color: '#FFE60090'
  },
  {
    code: 12,
    label: 'Jaune mat',
    color: '#FFE60090',
    mat: true
  }
];
export const DEPOSIT_OPTIONS = [
  {
    code: 1,
    label: 'A la signature'
  },
  {
    code: 2,
    label: 'Paiement partiel'
  }
];
