import React, { useContext } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
const BoxIconStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function PrevIcon({ sx, ...props }) {
  return (
    <svg
      width="100"
      height="84"
      viewBox="0 0 100 84"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_0_1)">
        <circle
          cx="50"
          cy="46"
          r="24.5"
          stroke="url(#paint0_linear_0_1)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <circle cx="50" cy="46" r="20" fill="white" />
        <circle cx="50" cy="46" r="19.75" stroke="url(#paint1_linear_0_1)" strokeWidth="0.5" />
      </g>
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M46.4137 46.2744L51.039 50.8869C51.1905 51.038 51.4359 51.0377 51.5872 50.8861C51.7383 50.7346 51.7379 50.4891 51.5864 50.338L47.2364 46L51.5865 41.662C51.7381 41.5109 51.7385 41.2655 51.5873 41.114C51.5115 41.038 51.4121 41 51.3128 41C51.2137 41 51.1147 41.0377 51.039 41.1132L46.4137 45.7256C46.3407 45.7982 46.2997 45.897 46.2997 46C46.2997 46.103 46.3408 46.2017 46.4137 46.2744Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="100"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <filter
          id="filter1_d_0_1"
          x="5"
          y="5"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="92.5"
          y1="59"
          x2="25"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAD3E3" />
          <stop offset="1" stopColor="#92B7E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="84"
          y1="56.4"
          x2="30"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAD3E3" />
          <stop offset="1" stopColor="#92B7E5" />
        </linearGradient>
        <clipPath id="clip0_0_1">
          <rect width="10" height="10" fill="white" transform="translate(54 51) rotate(-180)" />
        </clipPath>
      </defs>
    </svg>
  );
}
