import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useSound from 'use-sound';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import { BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import useClientEditProject from 'hooks/useClientEditProject';
import { NAV_URL_COM } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';
import CellPhoneSound from 'assets/sound/sound_cell_phone.mp3';
import StepsForm from './StepsForm';
import StepEntryForm from './StepEntryForm';
import ProjectFlowForm from './flow/ProjectFlowForm';
import ProjectValidated from './flow/ProjectValidated';
// ----------------------------------------------------------------------
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot.svg)`,
  backgroundSize: 'contain'
}));
const FormBoxStyle = styled(Box)(({ theme }) => ({
  width: '650px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 250}px`,
  right: `${BOT_WIDTH + 50}px`
}));
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function BotDialog({ ...props }) {
  const { editProject, steps } = useClientEditProject();

  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <DialogValidated steps={editProject.steps} />
        ) : (
          <BotForm initialSteps={steps} />
        )}
      </>
    );
  }, [editProject]);
}
const BotForm = ({ initialSteps, ...props }) => {
  const [play] = useSound(CellPhoneSound);
  const { sceneLoaded, scene, handleUpdScene } = useProjectUnity();
  const navigate = useNavigate();
  const { onValidatedStep } = useClientEditProject();
  const [steps, setSteps] = useState(initialSteps);
  const [showBotForm, setShowBotForm] = useState(false);
  useEffect(() => {
    handleUpdScene({
      name: 'Mascotte',
      display: 'visible',
      style: { visibility: 'visible', zIndex: 20 },
      updTime: new Date().getTime()
    });
    if (!sceneLoaded) return;
    const timeout = setTimeout(() => {
      setShowBotForm(true);
    }, 200);
    return () => {
      clearTimeout(timeout);
      handleUpdScene({
        ...scene,
        display: 'hidden',
        style: { visibility: 'hidden' },
        updTime: new Date().getTime()
      });
    };
  }, [sceneLoaded]);

  const handleSubmitStep = useCallback(
    (step, values) => {
      const newSteps = [...steps];
      const idx = newSteps.findIndex((s, i) => {
        return s.id === step.id;
      });
      const myStep = {
        ...step,
        user: { ...step.user, text: values.entryText },
        isValidate: true,
        isCurrent: false
      };

      newSteps[idx] = myStep;
      const isLastStep = idx === newSteps.length - 1;
      if (isLastStep) {
        newSteps.push({
          id: 'last',
          text: `Parfait ! Maintenant que l’on se connait un peu mieux, créons ensemble votre projet depuis notre JPS MetaView.`
        });
      } else {
        let nextStep = newSteps[idx + 1];
        if (nextStep.id === 'name') {
          const botText = nextStep.bot.text.replace('@name', values.entryText);
          nextStep = { ...nextStep, bot: { ...nextStep.bot, text: botText } };
        }
        nextStep = { ...nextStep, isCurrent: true };
        newSteps[idx + 1] = nextStep;
      }

      setSteps(newSteps);
    },
    [steps]
  );
  const handleValidSteps = useCallback(() => {
    const stepsPrj = [...steps];
    const lastIdx = steps.length - 1;
    const lastStep = steps[lastIdx];
    stepsPrj[lastIdx] = {
      ...lastStep,
      textValidBtn: `Votre projet a été initié, veuillez sélectionner le type d'immeuble`
    };
    onValidatedStep(stepsPrj);
    play();
    navigate(NAV_URL_COM.building);
  }, [steps]);

  const getCurrentStep = () => {
    if (!steps) return;
    return steps.find((s) => {
      return s.isCurrent === true;
    });
  };
  const currentStep = getCurrentStep();

  return (
    <>
      {!showBotForm && (
        <BoxProgrestStyle>
          <CircularProgress size={60} sx={{ color: '#668AF5' }} />
        </BoxProgrestStyle>
      )}
      <Fade in={showBotForm}>
        <div>
          {/* <BgBotStyle /> */}
          <FormBoxStyle>
            <StepsForm steps={steps} handleValidSteps={handleValidSteps} />
            {currentStep && (
              <StepEntryForm step={currentStep} handleSubmitStep={handleSubmitStep} />
            )}
          </FormBoxStyle>
        </div>
      </Fade>
    </>
  );
};
const DialogValidated = ({ steps, ...props }) => {
  const { sceneLoaded, scene, handleSendingConfig, handleUpdScene } = useProjectUnity();
  useEffect(() => {
    if (!steps) return;
    if (!sceneLoaded) return;
    handleUpdScene({
      name: 'Mascotte',
      display: 'visible',
      style: { visibility: 'visible', zIndex: 20 },
      updTime: new Date().getTime()
    });
    handleSendingConfig({
      objectName: 'Game Controller',
      methodName: 'SetAnim',
      parameters: 'Felicite'
    });
    return () => {
      handleUpdScene({
        ...scene,
        display: 'hidden',
        style: { visibility: 'hidden' },
        updTime: new Date().getTime()
      });
    };
  }, [steps]);
  return (
    <>
      <FormBoxStyle>
        <StepsForm steps={steps} urlToBuilding={NAV_URL_COM.building} />
      </FormBoxStyle>
    </>
  );
};
