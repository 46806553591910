import React from 'react';
// material
import { alpha, styled } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Typography, Popover, Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
// components
import Logo from 'components/Logo';
import { APP_BAR_DESKTOP, APP_BAR_MOBILE } from 'config/appConfig';
//
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import { NAV_URL_COM } from 'config/paths';
import TopAccountPopover from './TopAccountPopover';
// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ bg, theme }) => {
  return {
    /* border: '1px solid red', */
    boxShadow: 'none',
    height: '120px',
    /* backdropFilter: 'blur(6px)', */
    /*   WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
    backgroundColor: 'transparent' //alpha(theme.palette.background.default, 0.72) */
    background: bg && bg !== 'transparent' ? bg : 'none'
  };
});

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APP_BAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APP_BAR_DESKTOP,
    padding: theme.spacing(0, 5)
  }
}));
/* const TopGradientStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${APP_BAR_DESKTOP}px`,
  left: 0,
  width: '100%',
  height: '274px',
  background: `linear-gradient(180deg, #FFFFFF 18.84%, rgba(255, 255, 255, 0) 77.75%)`,
  zIndex: 1
})); */
// ----------------------------------------------------------------------

export default function TopNavbar({ ...props }) {
  const location = useLocation();
  const getBgColorToolbar = () => {
    try {
      const pathName = location.pathname.split('/');
      const lastIdx = pathName.length - 1;
      if (['client', 'projects'].includes(pathName[lastIdx])) {
        return 'transparent';
      }

      return `linear-gradient(180deg, #FFFFFF 18.84%, rgba(255, 255, 255, 0) 77.75%)`;
    } catch (error) {
      return 'transparent';
    }
  };
  const getLogo = () => {
    try {
      const pathName = location.pathname.split('/');
      const lastIdx = pathName.length - 1;
      if (['client', 'projects'].includes(pathName[lastIdx])) {
        return '/static/logo.png';
      }
      return '/static/logoFonce.svg';
    } catch (error) {
      return <></>;
    }
  };
  const colorTb = getBgColorToolbar();
  return (
    <RootStyle bg={colorTb}>
      <ToolbarStyle>
        <Logo src={getLogo()} />
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          {colorTb !== 'transparent' && <ResetProject />}
          <TopAccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
const BoxDialog = styled(Box)(({ theme }) => ({
  width: '395px',
  height: '160px',
  background: '#668AF5',
  borderRadius: '30px 30px 30px 30px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',
  marginBottom: '100px',
  padding: theme.spacing(4, 4, 4, 4),
  '& .text': {
    fontFamily: 'Proxima',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: '18px',
    lineHeight: '22px',
    color: '#FFFFFF'
  }
}));

const IconButtonStyled = styled(IconButton)(({ theme }) => ({
  color: '#668AF5'
}));
const ResetProject = () => {
  const navigate = useNavigate();
  const { resetEditProject } = useClientEditProject();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleReset = () => {
    try {
      resetEditProject();
      navigate(NAV_URL_COM.projects);
    } catch (error) {
      console.error(error);
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      <IconButtonStyled
        size="large"
        color="error"
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
      >
        <RestartAltIcon />
      </IconButtonStyled>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            background: 'transparent',
            boxShadow: 'none'
          }
        }}
      >
        <BoxDialog>
          <Box display="flex" justifyContent="center" alignContent="center">
            <span className="text">Votre projet sera réinitialisé. Confirmez-vous ?</span>
            <IconButton sx={{ color: '#FFFFFF' }} variant="contained" onClick={handleReset}>
              <CheckIcon />
            </IconButton>
          </Box>
        </BoxDialog>
      </Popover>
    </>
  );
};
