import React, { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';
import useAuth from 'hooks/useAuth';
import {
  PATH_PAGE,
  PATH_APP,
  PATH_COM,
  PATH_CLIENT,
  PATH_TECH,
  PATH_SUPERADMIN
} from 'config/paths';
import RequireAuth from 'auth/RequireAuth';
import RequireClient from 'auth/RequireClient';
import RequireTech from 'auth/RequireTech';
import RequireSuperadmin from 'auth/RequireSuperadmin';
import RequireCom from 'auth/RequireCom';
import RequireGuest from 'auth/RequireGuest';
import ClientLayout from 'layouts/project/ClientLayout';
import VerticalLayout from 'layouts/vertical';
import BlankLayout from 'layouts/BlankLayout';
import LogoOnlyLayout from 'layouts/LogoOnlyLayout';
import DashboardLayout from 'layouts/dashboard';
import DashboardApp from 'pages/DashboardApp';
import User from 'pages/User';
import Products from 'pages/Products';
import Blog from 'pages/Blog';
import Login from 'pages/Login';
import Register from 'pages/Register';
import NotFound from 'pages/Page404';
import NotAuthorize from 'pages/Page401';
import AuthWaitingToRedirect from 'pages/AuthWaitingToRedirect';

/* Client */
import ClientProjects from 'pages/client/ClientProjects';
import ClientAddProjectShot from 'pages/client/ClientAddProjectShot';
import AddProject from 'pages/client/AddProject';
import BotDialog from 'pages/client/BotDialog';
import BuildingForm from 'pages/client/BuildingForm';
import SpecForm from 'pages/client/SpecForm';
import TypoForm from 'pages/client/TypoForm';
import ScopeForm from 'pages/client/ScopeForm';
import CustomForm from 'pages/client/CustomForm';
import ContactForm from 'pages/client/ContactForm';
import ProjectCreated from 'pages/client/ProjectCreated';
/* tech */
import TechProjects from 'pages/tech/TechProjects';
import TechProject from 'pages/tech/project/TechProject';
import TechEdit from 'pages/tech/edit/TechEdit';
import TechEditSummary from 'pages/tech/edit/TechEditSummary';
import TechEditContact from 'pages/tech/edit/TechEditContact';
import TechEditMeasure from 'pages/tech/edit/TechEditMeasure';
import TechEditMeasureBuilding from 'pages/tech/edit/TechEditMeasureBuilding';
import TechEditMeasureGlazing from 'pages/tech/edit/TechEditMeasureGlazing';
import TechEditOrder from 'pages/tech/edit/TechEditOrder';
import TechEditBilling from 'pages/tech/edit/TechEditBilling';
import TechEditBillingEstimate from 'pages/tech/edit/TechEditBillingEstimate';
import TechEditBillingTechnote from 'pages/tech/edit/TechEditBillingTechnote';
import TechEditBillingInvoice from 'pages/tech/edit/TechEditBillingInvoice';
/* com */
import ComProjects from 'pages/com/ComProjects';
/* superadmin */
import SuperAdminHome from 'pages/superadmin/SuperAdminHome';
// ----------------------------------------------------------------------
function childPath(root, p) {
  return p.replace(root, '').substring(1, p.length - 1);
}
export default function Router() {
  const { isAuthenticated, user } = useAuth();

  const appRoutes = useRoutes([
    /* client */
    {
      path: PATH_CLIENT.root,
      element: (
        <RequireClient>
          <ClientLayout />
        </RequireClient>
      ),
      children: [
        { path: '', element: <ClientProjects /> },
        { path: childPath(PATH_CLIENT.root, PATH_CLIENT.projects), element: <ClientProjects /> },
        {
          path: childPath(PATH_CLIENT.root, PATH_CLIENT.project),
          element: <ClientAddProjectShot />
        },
        {
          path: childPath(PATH_CLIENT.root, PATH_CLIENT.addPrj),
          element: <AddProject />,
          children: [
            { path: '', element: <BotDialog /> },
            { path: PATH_CLIENT.botDialog, element: <BotDialog /> },
            { path: PATH_CLIENT.building, element: <BuildingForm /> },
            { path: PATH_CLIENT.specifications, element: <SpecForm /> },
            { path: PATH_CLIENT.typo, element: <TypoForm /> },
            { path: PATH_CLIENT.scope, element: <ScopeForm /> },
            { path: PATH_CLIENT.custom, element: <CustomForm /> },
            { path: PATH_CLIENT.contact, element: <ContactForm /> },
            { path: PATH_CLIENT.projectCreated, element: <ProjectCreated /> }
          ]
        }
      ]
    },
    /* commercial */
    {
      path: PATH_COM.root,
      element: (
        <RequireCom>
          <BlankLayout />
        </RequireCom>
      ),
      children: [
        { path: '', element: <ComProjects /> },
        { path: childPath(PATH_COM.root, PATH_COM.projects), element: <ComProjects /> }
      ]
    },
    /* Technicien */
    {
      path: PATH_TECH.root,
      element: (
        <RequireTech>
          <VerticalLayout bg="#3C60B5" />
        </RequireTech>
      ),
      children: [
        { path: '', element: <TechProjects /> },
        { path: childPath(PATH_TECH.root, PATH_TECH.project), element: <TechProject /> },
        { path: `${childPath(PATH_TECH.root, PATH_TECH.project)}/:id`, element: <TechProject /> },
        { path: childPath(PATH_TECH.root, PATH_CLIENT.projects), element: <TechProjects /> },
        {
          path: `${childPath(PATH_TECH.root, PATH_TECH.editProject)}/:id`,
          element: <TechEdit />,
          children: [
            { path: '', element: <TechEditSummary /> },
            { path: PATH_TECH.summary, element: <TechEditSummary /> },
            { path: PATH_TECH.contact, element: <TechEditContact /> },
            {
              path: PATH_TECH.measure,
              element: <TechEditMeasure />,
              children: [
                { path: '', element: <TechEditMeasureBuilding /> },
                { path: PATH_TECH.measureBuilding, element: <TechEditMeasureBuilding /> },
                { path: PATH_TECH.measureGlazing, element: <TechEditMeasureGlazing /> }
              ]
            },
            { path: PATH_TECH.order, element: <TechEditOrder /> },
            {
              path: PATH_TECH.billing,
              element: <TechEditBilling />,
              children: [
                { path: '', element: <TechEditBillingEstimate /> },
                { path: PATH_TECH.estimate, element: <TechEditBillingEstimate /> },
                { path: PATH_TECH.technote, element: <TechEditBillingTechnote /> },
                { path: PATH_TECH.invoice, element: <TechEditBillingInvoice /> }
              ]
            }
          ]
        }
      ]
    },
    /* superadmin */
    {
      path: PATH_SUPERADMIN.root,
      element: (
        <RequireSuperadmin>
          <VerticalLayout />
        </RequireSuperadmin>
      ),
      children: [
        { path: '', element: <SuperAdminHome /> },
        { path: childPath(PATH_SUPERADMIN.root, PATH_SUPERADMIN.home), element: <SuperAdminHome /> }
      ]
    },
    /** waiting after authenticate */
    {
      path: PATH_APP.waiting,
      element: (
        <RequireAuth>
          <AuthWaitingToRedirect />
        </RequireAuth>
      )
    },
    /* general */
    {
      path: '/',
      element: (
        <RequireGuest>
          <LogoOnlyLayout />
        </RequireGuest>
      ),
      children: [
        { path: '', element: <Navigate to={PATH_PAGE.auth.login} /> },
        { path: PATH_PAGE.auth.login, element: <Login /> }
      ]
    },
    { path: '401', element: <NotAuthorize /> },
    { path: '404', element: <NotFound /> },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
  return <>{appRoutes}</>;
}
