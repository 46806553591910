import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useAuth from 'hooks/useAuth';

export const AuthContext = React.createContext();
const AuthProvider = ({ ...props }) => {
  const dispatch = useDispatch();
  const auth = useAuth();
  useEffect(() => {
    auth.initAuth();
  }, []);

  return <AuthContext.Provider value={{ auth }}>{props.children}</AuthContext.Provider>;
};
export default AuthProvider;
