import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import { NAV_URL_COM } from 'config/paths';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import SpecificationListFilter from './flow/SpecificationListFilter';
import ConfirmSpecsSelected from './flow/ConfirmSpecsSelected';
// ----------------------------------------------------------------------
export default function SpecForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();
  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            {editProject.problematics ? (
              <ValidatedSpecs specs={editProject.problematics} />
            ) : (
              <SelectSpecs />
            )}
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, [editProject]);
}
const ValidatedSpecs = ({ specs, ...props }) => {
  if (!specs) return <></>;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: '50px' }}
    >
      <Box sx={{ mb: '50px' }}>
        <h3>Vous avez choisi ces problématiques:</h3>
        <List>
          {specs.map((item) => {
            return (
              <ListItem key={Math.random()} disablePadding>
                <ListItemButton>
                  <ListItemText primary={item.label} />
                </ListItemButton>
              </ListItem>
            );
          })}
        </List>
      </Box>

      <GradientBtn
        size="large"
        color="info"
        variant="contained"
        component={RouterLink}
        to={NAV_URL_COM.typo}
        style={{ textTransform: 'normal' }}
      >
        Veuillez sélectionner la typologie du projet
      </GradientBtn>
    </Box>
  );
};

const PageStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  height: `calc(100vh - ${
    APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP + 20
  }px)`,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  padding: theme.spacing(0, 3, 0, 0)
}));
const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  overflow: 'hidden',
  paddingBottom: '50px'
}));

const LeftSideStyle = styled(Box)(({ theme }) => ({
  width: '626px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const RightSideStyle = styled(Box)(({ theme }) => ({
  width: '626px',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  margin: theme.spacing(0, 3, 0, 3),
  zIndex: 1
}));
const DragListStyle = styled(Box)(({ theme }) => ({
  width: '626px',
  height: '100%',
  border: '2px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(4, 0, 0, 0),
  padding: theme.spacing(3, 0, 3, 0),
  overflow: 'auto'
}));
const DropListStyle = styled(Box)(({ theme }) => ({
  width: '626px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  margin: theme.spacing(4, 0, 0, 0),
  padding: theme.spacing(3, 0, 3, 0),
  overflow: 'auto',
  border: '2px solid #FFFFFF',
  boxSizing: 'border-box',
  borderRadius: '20px'
}));

/* Style for Dnd */
const getListStyle = (isDraggingOver) => ({
  //padding: grid
  //width: '99%'
});
const getItemStyle = (isDragging, draggableStyle, theme) => {
  return {
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    borderRadius: '16px',
    // change background colour if dragging
    background: isDragging ? theme.palette.primary.main : 'transparent',

    // styles we need to apply on draggables
    ...draggableStyle
  };
};
const grid = 1;
/*  */
const optionsSpecTypes = [
  {
    id: 'tous',
    label: 'Tous'
  },
  {
    id: 'exterieur',
    label: 'Extérieur'
  },
  {
    id: 'interieur',
    label: 'Intérieur'
  }
];

function SelectSpecs({ ...props }) {
  const navigate = useNavigate();
  const { editProject, onUpdateEditProject } = useClientEditProject();
  const theme = useTheme();
  const { specifications } = useSelector((state) => state.referential);
  const [items, setitems] = useState([]);
  const [selected, setselected] = useState([]);
  const [filters, setFilters] = useState({ type: '' });
  const [displayConfirm, setDisplayConfirm] = useState({ open: false, spec: null });

  const id2List = {
    droppable: items,
    droppable2: selected
  };
  useEffect(() => {
    if (!specifications) return;
    setitems([]);
    setselected(specifications);
  }, [specifications]);
  /* Drag and drop */
  const onDragEnd = (result) => {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    //drop in the same list, just reorder
    if (source.droppableId === destination.droppableId) {
      const items = reorder(id2List[source.droppableId], source.index, destination.index);
      if (source.droppableId === 'droppable2') {
        setselected(items);
      } else {
        setitems(items);
      }
    } else {
      const result = move(
        id2List[source.droppableId],
        id2List[destination.droppableId],
        source,
        destination
      );
      setitems(result.droppable);
      setselected(result.droppable2);
    }
  };
  /**reorder in the same list */
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };
  /**
   * Moves an item from one list to another list.
   */
  const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);
    destClone.splice(droppableDestination.index, 0, removed);
    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };
  const handleChange = (id, value, droppableId, typechange) => {
    const arr = id2List[droppableId];
    for (let i = 0; i < arr.length; i++) {
      const item = arr[i];
      if (item.id === id) {
        arr[i].content[typechange] = value;
        break;
      }
    }
    if (droppableId === 'droppable2') {
      setselected(arr);
    } else {
      setitems(arr);
    }
  };
  /* END ---------------------------------------------------------------------- */
  /* Listen filters for specifications */
  useEffect(() => {
    if (!filters || Object.keys(filters) === 0) return;
    /* do not use items all ready on droppable zone */
    let specsDroppable = [...specifications].filter((it) => {
      const existOnDropzone = items.findIndex((o) => {
        return o.id === it.id;
      });
      return existOnDropzone < 0;
    });
    let isChanged = false;
    Object.entries(filters).forEach(([k, v]) => {
      if (v && v.length > 0) {
        isChanged = true;
        specsDroppable = specsDroppable.filter((it) => {
          if (v === 'tous') return true;
          return it[k] === v;
        });
      }
    });
    if (isChanged) setselected(specsDroppable);
  }, [items, specifications, filters]);
  const handleChangeFilters = useCallback(
    (filtersSelected) => {
      try {
        if (!filtersSelected) return;
        const newFilters = { ...filters, ...filtersSelected };

        setFilters(newFilters);
      } catch (error) {
        console.error(error);
      }
    },
    [filters]
  );
  /*  */
  const onValidSpecs = () => {
    try {
      if (!items || items.length === 0) return;
      const firstSpec = items[0];
      setDisplayConfirm({ open: true, spec: firstSpec });
    } catch (error) {
      console.error(error);
    }
  };
  const onConfirmSpecs = useCallback(() => {
    if (!items || items.length === 0) return;
    const newProject = { ...editProject };
    newProject.problematics = [...items];
    onUpdateEditProject(newProject);
    navigate(NAV_URL_COM.typo);
  }, [items]);
  return (
    <PageStyle>
      {displayConfirm.open && (
        <ConfirmSpecsSelected spec={displayConfirm.spec} onConfirmSpecs={onConfirmSpecs} />
      )}
      <GradientBtn
        size="lg"
        color="info"
        variant="contained"
        sx={{ width: '211px' }}
        onClick={onValidSpecs}
      >
        Valider
      </GradientBtn>
      <RootStyle>
        {items && selected ? (
          <>
            <DragDropContext onDragEnd={onDragEnd}>
              <LeftSideStyle>
                <Box sx={{ height: '50px' }}>
                  <Typography variant="h3" sx={{ textTransform: 'uppercase' }}>
                    Vos problématiques
                  </Typography>
                </Box>

                <Droppable droppableId="droppable">
                  {(provided, snapshot) => (
                    <DragListStyle
                      ref={provided.innerRef}
                      style={{ ...getListStyle(snapshot.isDraggingOver) }}
                    >
                      {items.map((item, index) => (
                        <Draggable key={Math.random()} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                theme
                              )}
                            >
                              <SpecificationListItem
                                key={Math.random()}
                                item={item}
                                style={{ margin: index > 0 ? theme.spacing(3, 0, 0, 0) : 0 }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </DragListStyle>
                  )}
                </Droppable>
              </LeftSideStyle>
              <RightSideStyle>
                <Box sx={{ height: '50px' }}>
                  <SpecificationListFilter
                    optionsSpecTypes={optionsSpecTypes}
                    type={filters.type}
                    handleChangeFilters={handleChangeFilters}
                  />
                </Box>
                <Droppable droppableId="droppable2">
                  {(provided, snapshot) => (
                    <DropListStyle
                      ref={provided.innerRef}
                      style={getListStyle(snapshot.isDraggingOver)}
                    >
                      {selected.map((item, index) => (
                        <Draggable key={Math.random()} draggableId={item.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={getItemStyle(
                                snapshot.isDragging,
                                provided.draggableProps.style,
                                theme
                              )}
                              className="card border-success mb-3"
                            >
                              <SpecificationListItem
                                key={Math.random()}
                                item={item}
                                style={{ margin: index > 0 ? theme.spacing(3, 0, 0, 0) : 0 }}
                              />
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </DropListStyle>
                  )}
                </Droppable>
              </RightSideStyle>
            </DragDropContext>
          </>
        ) : (
          <></>
        )}
      </RootStyle>
    </PageStyle>
  );
}

const SpecificationListItem = ({ item, style, ...props }) => {
  const theme = useTheme();
  const getProductsNumber = () => {
    try {
      let nb = 0;
      item.category.forEach((it) => (nb += it.products.length));
      return nb;
    } catch (error) {
      return 0;
    }
  };
  const getThematique = () => {
    try {
      return item.category[0].label;
    } catch (error) {
      return '';
    }
  };
  return (
    <Card
      sx={{
        display: 'flex',
        height: '170px',
        width: '500px',
        ...style
      }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: theme.spacing(3, 0, 3, 0),
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Typography component="div" variant="h4">
            {item.label}
          </Typography>
          <Typography variant="overline" color="text.secondary" component="span">
            {item.category.label}
          </Typography>
          <Typography
            variant="caption"
            color="text.secondary"
            component="span"
            sx={{ marginTop: theme.spacing(2) }}
          >
            {/*  {getProductsNumber()} références */}
            {getThematique()}
          </Typography>
        </CardContent>
      </Box>
      <Box
        sx={{
          width: '200px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${item.photo})`,
          backgroundSize: 'cover'
        }}
      />
    </Card>
  );
};
