import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config/appConfig';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: true,
  avProjects: [],
  buildingTypes: [],
  specifications: [],
  roof_types: [],
  building_occupation: [],
  building_access: [],
  building_types: [],
  surfaces: [],
  glazing_types: [],
  floors: [],
  joint_types: [],
  joint_status: [],
  frame_type: [],
  shutter_type: [],
  wind_exposure: [],
  sun_exposure: [],
  shaded_area: [],
  orders: [],
  glazing_format: [],
  payment_methods: [],
  payment_conditions: [],
  refInitialized: false,
  isMediaCaching: false,
  projectChanged: null
};

const referentialSlice = createSlice({
  name: 'referential',
  initialState,
  reducers: {
    setRefs(state, action) {
      state.buildingTypes = action.payload.buildingTypes;
      state.specifications = action.payload.specifications;
      state.problematics = action.payload.problematics;
      state.roof_types = action.payload.roof_types;
      state.building_occupation = action.payload.building_occupation;
      state.building_access = action.payload.building_access;
      state.building_types = action.payload.building_types;
      state.surfaces = action.payload.surfaces;
      state.glazing_types = action.payload.glazing_types;
      state.floors = action.payload.floors;
      state.joint_types = action.payload.joint_types;
      state.joint_status = action.payload.joint_status;
      state.frame_type = action.payload.frame_type;
      state.shutter_type = action.payload.shutter_type;
      state.wind_exposure = action.payload.wind_exposure;
      state.sun_exposure = action.payload.sun_exposure;
      state.shaded_area = action.payload.shaded_area;
      state.orders = action.payload.orders;
      state.glazing_format = action.payload.glazing_format;
      state.payment_methods = action.payload.payment_methods;
      state.payment_conditions = action.payload.payment_conditions;
      state.refInitialized = true;
      state.isMediaCaching = true;
    },
    initCacheMedia(state, action) {
      state.buildingTypes = action.payload.buildingTypes;
      state.isMediaCaching = true;
    },
    setProjectChanged(state, action) {
      state.projectChanged = new Date().getTime();
    },
    setAvProjects(state, action) {
      state.avProjects = action.payload.avProjects;
    }
  }
});
export const { setAvProjects, setRefs, initCacheMedia, setProjectChanged } =
  referentialSlice.actions;
const referentialReducer = referentialSlice.reducer;

export default referentialReducer;

export function getAllProjects() {
  return async (dispatch) => {
    const res = await axios.get(API_URL.projects);
    if (res && res.data) {
      const arrPro = [...res.data.data.projects];
      dispatch(referentialSlice.actions.setAvProjects({ avProjects: [...arrPro] }));
    }
  };
}
export function getReferential() {
  return async (dispatch) => {
    axios.all([axios.get(API_URL.referentiel)]).then(
      axios.spread(async (resReferentiel) => {
        const {
          projects,
          problematics,
          roof_types,
          building_occupation,
          building_access,
          building_types,
          surfaces,
          glazing_types,
          floors,
          joint_types,
          joint_status,
          frame_type,
          shutter_type,
          wind_exposure,
          sun_exposure,
          shaded_area,
          glazing_format,
          payment_methods,
          payment_conditions
        } = resReferentiel.data.data;
        dispatch(
          referentialSlice.actions.setRefs({
            buildingTypes: mergeBuidingTypesWithVid(building_types),
            specifications: [...problematics].map((it) => {
              return { ...it, id: String(it.code) };
            }),
            problematics,
            roof_types,
            building_occupation,
            building_access,
            building_types,
            surfaces,
            glazing_types,
            floors,
            joint_types,
            joint_status,
            frame_type,
            shutter_type,
            wind_exposure,
            sun_exposure,
            glazing_format,
            payment_methods,
            payment_conditions,
            shaded_area
          })
        );
        /* const buildingsWithMedias = mergeBuidingTypesWithVid(building_types);
        const newArr = buildingsWithMedias.map(async (it) => {
          const vid = await loadAllVideoForItem(it);
          return { ...it, ...vid };
        });
        const resAll = await Promise.all(newArr);
        console.log('Media', resAll);
        dispatch(referentialSlice.actions.initCacheMedia({ buildingTypes: resAll })); */
      })
    );
  };
}
const loadAllVideoForItem = async (it) => {
  const video = await preloadVideo(it.video);
  const videoInvert = await preloadVideo(it.videoInvert);
  const videoZoom = await preloadVideo(it.videoZoom);
  const videoDezoom = await preloadVideo(it.videoDezoom);
  return { video, videoInvert, videoZoom, videoDezoom };
};
const mergeBuidingTypesWithVid = (arr) => {
  try {
    const newArr = arr.map((it) => {
      const obj = mappingVid[it.code];
      return { ...it, ...obj };
    });
    return newArr;
  } catch (error) {
    return arr;
  }
};
const mappingVid = {
  1: {
    video: 'https://jpsmetaview.com/static/building/videos/Office.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Office.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Office.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Office.mp4'
  },
  2: {
    video: 'https://jpsmetaview.com/static/building/videos/Shop.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Shop.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Shop.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Shop.mp4'
  },
  3: {
    video: 'https://jpsmetaview.com/static/building/videos/School.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/School.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/School.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/School.mp4'
  },
  4: {
    video: 'https://jpsmetaview.com/static/building/videos/Hopital.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Hopital.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Hopital.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Hopital.mp4'
  },
  5: {
    video: 'https://jpsmetaview.com/static/building/videos/Residence.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Residence.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Residence.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Residence.mp4'
  },
  6: {
    video: 'https://jpsmetaview.com/static/building/videos/Stade.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Stade.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Stade.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Stade.mp4'
  },
  7: {
    video: 'https://jpsmetaview.com/static/building/videos/Mairie.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Mairie.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Mairie.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Mairie.mp4'
  },
  8: {
    video: 'https://jpsmetaview.com/static/building/videos/Opera.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Opera.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Opera.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Opera.mp4'
  },
  9: {
    video: 'https://jpsmetaview.com/static/building/videos/Museum.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Museum.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/inverter/Museum.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Museum.mp4'
  },
  10: {
    video: 'https://jpsmetaview.com/static/building/videos/Parking.mp4',
    videoInvert: 'https://jpsmetaview.com/static/building/inverter/Parking.mp4',
    videoZoom: 'https://jpsmetaview.com/static/building/zooms/Parking.mp4',
    videoDezoom: 'https://jpsmetaview.com/static/building/dezooms/Parking.mp4'
  }
};
async function preloadVideo(src) {
  const res = await fetch(src);
  const blob = await res.blob();
  return URL.createObjectURL(blob);
}
