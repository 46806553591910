import React from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioBtnStyled from 'components/custom/RadioBtnStyled';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import {
  GlAZINGTYPE,
  SURFACES,
  ACCESS_BUILDING,
  OCCUPY_BUILDING,
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';
import useTechEditProject from 'hooks/useTechEditProject';
import useMediaQuery from '@mui/material/useMediaQuery';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ width, theme }) => ({
  width: width ? width : '728px',
  height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  overflowY: 'auto',
  padding: theme.spacing(0, 4, 3, 4)
}));
export default function TechEditMeasureBuilding({ ...props }) {
  const matchesMinWithToDisplay = useMediaQuery('(max-width:1350px)');
  const navigate = useNavigate();
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { project } = useOutletContext();
  const { roof_types, building_access, building_occupation, initProjectById, updateBuilding } =
    useTechEditProject();
  const BuildingSchema = Yup.object().shape({
    buildingHeight: Yup.string().max(10, 'Maximum 10 digits'),
    constructionYear: Yup.string().max(5, 'Maximum 5 digits'),
    buildingAccess: Yup.string().required(`Veuillez renseigner l'accès au bâtiment`),
    buildingOccupy: Yup.string().required(`Veuillez renseigner l'occupationdes locaux`)
  });
  const { buildings } = project;
  const building = buildings[0];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      buildingHeight: building && building.height ? building.height : '',
      constructionYear: building && building.year ? building.year : '',
      roofType: building && building.roof_type ? building.roof_type.code : '',
      buildingAccess: building && building.access ? building.access.code : '',
      buildingOccupy: building && building.occupation ? building.occupation.code : '',
      ppms: building && building.ppms ? Boolean(building.ppms) : false,
      elevator: building && building.elevator ? Boolean(building.elevator) : false,
      elevator_free: building && building.elevator_free ? Boolean(building.elevator_free) : false
    },
    validationSchema: BuildingSchema,
    onSubmit: async (values) => {
      try {
        const roofT = roof_types.find((it) => it.code === values.roofType);
        const buildingAccess = ACCESS_BUILDING.find((it) => it.code === values.buildingAccess);
        const buildingOccupy = OCCUPY_BUILDING.find((it) => it.code === values.buildingOccupy);
        const newProj = {
          project: {
            id: project.code,
            building: {
              id: building.code,
              roof_type: {
                ...roofT
              },
              occupation_type: {
                ...buildingOccupy
              },
              access: {
                ...buildingAccess
              },

              height: values.buildingHeight,
              year: values.constructionYear,
              ppms: values.ppms && String(values.ppms) === 'true' ? 1 : 0,
              elevator: values.elevator && String(values.elevator) === 'true' ? 1 : 0,
              elevator_free: values.elevator_free && String(values.elevator_free) === 'true' ? 1 : 0
            }
          }
        };
        await updateBuilding(newProj);
        await initProjectById(project.code);
        navigate(
          `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.measure}/${PATH_TECH.measureGlazing}`
        );
        enqueueSnackbar('Les informations techniques sont enregistrées avec succès', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (error) {
        console.error(error);
        const { message } = error.response.data;
        enqueueSnackbar(message, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } =
    formik;

  return (
    <>
      <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
        <MBuildingStyle width={matchesMinWithToDisplay ? '530px' : null}>
          <Typography
            variant="h3"
            sx={{ textTransform: 'uppercase', mt: theme.spacing(4), fontFamily: 'ProximaBold' }}
          >
            Informations sur le bâtiment
          </Typography>
          <FormikProvider value={formik}>
            <Form
              autoComplete="off"
              noValidate
              onSubmit={handleSubmit}
              style={{
                margin: theme.spacing(3, 0, 3, 0),
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column'
              }}
            >
              <Stack spacing={2} sx={{ width: '100%' }}>
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  fullWidth
                  type="number"
                  label="Hauteur du bâtiment"
                  {...getFieldProps('buildingHeight')}
                  error={Boolean(touched.buildingHeight && errors.buildingHeight)}
                  helperText={touched.buildingHeight && errors.buildingHeight}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">m</InputAdornment>
                  }}
                />
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  fullWidth
                  type="number"
                  label="Année de construction"
                  {...getFieldProps('constructionYear')}
                  error={Boolean(touched.constructionYear && errors.constructionYear)}
                  helperText={touched.constructionYear && errors.constructionYear}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">AAAA</InputAdornment>
                  }}
                />
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  select
                  label="Type de toiture"
                  value={values.roofType}
                  onChange={handleChange('roofType')}
                  {...getFieldProps('roofType')}
                  error={Boolean(touched.roofType && errors.roofType)}
                  helperText={touched.roofType && errors.roofType}
                >
                  {roof_types.map((option, i) => (
                    <MenuItem key={i} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  select
                  label="Accès bâtiment"
                  value={values.buildingAccess}
                  onChange={handleChange('buildingAccess')}
                  {...getFieldProps('buildingAccess')}
                  error={Boolean(touched.buildingAccess && errors.buildingAccess)}
                  helperText={touched.buildingAccess && errors.buildingAccess}
                >
                  {building_access.map((option, i) => (
                    <MenuItem key={i} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <TextField
                  sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                  select
                  label="Occupation des locaux"
                  value={values.buildingOccupy}
                  onChange={handleChange('buildingOccupy')}
                  {...getFieldProps('buildingOccupy')}
                  error={Boolean(touched.buildingOccupy && errors.buildingOccupy)}
                  helperText={touched.buildingOccupy && errors.buildingOccupy}
                >
                  {building_occupation.map((option, i) => (
                    <MenuItem key={i} value={option.code}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel component="legend">Bâtiment soumis aux normes PPMS</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.ppms}
                    onChange={handleChange('ppms')}
                  >
                    <FormControlLabel
                      value={Boolean(true)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Oui
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={Boolean(false)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Non
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel component="legend">Besoin d'un élévateur/nacelle/autre</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.elevator}
                    onChange={handleChange('elevator')}
                  >
                    <FormControlLabel
                      value={Boolean(true)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Oui
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={Boolean(false)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Non
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <FormLabel component="legend">Location de l'élévateur à ajouter</FormLabel>
                  <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={values.elevator_free}
                    onChange={handleChange('elevator_free')}
                  >
                    <FormControlLabel
                      value={Boolean(true)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Oui
                        </Typography>
                      }
                    />
                    <FormControlLabel
                      value={Boolean(false)}
                      control={<RadioBtnStyled />}
                      label={
                        <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                          Non
                        </Typography>
                      }
                    />
                  </RadioGroup>
                </Box>
              </Stack>

              <Stack direction="row" alignItems="center" justifyContent="center">
                <GradientBtn
                  type="submit"
                  size="large"
                  color="info"
                  variant="contained"
                  sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
                >
                  Valider
                </GradientBtn>
              </Stack>
            </Form>
          </FormikProvider>
        </MBuildingStyle>
      </Box>
    </>
  );
}
