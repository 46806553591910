import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
// ----------------------------------------------------------------------

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});
// ----------------------------------------------------------------------

export default function BlankLayout() {
  return (
    <RootStyle>
      <Outlet />
    </RootStyle>
  );
}
