import React, { useContext, useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import ArchitectureIcon from '@mui/icons-material/Architecture';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';
import HomeIcon from '@mui/icons-material/Home';

import { PATH_TECH } from 'config/paths';
import {
  MENU_CLIP_WIDTH,
  MENU_CLIP_HEIGHT,
  MENU_CLIP_SHOT_WIDTH,
  MENU_CLIP_SHOT_HEIGHT
} from 'config/appConfig';
// ----------------------------------------------------------------------

const WrapperMenuStyle = styled('div')(({ theme }) => ({
  width: `${MENU_CLIP_WIDTH}px`,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));
const BoxMenuStyle = styled(Box)(({ theme }) => ({
  position: 'relative',
  height: `${MENU_CLIP_HEIGHT}px`,
  backgroundColor: 'transparent',
  width: '100%',
  /*  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg)`, */
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));
const BgMenuStyle = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));
const VerticalMenuStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'transparent',
  width: '100%',
  /*  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg)`, */
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  // padding: theme.spacing(14, 0, 14, 0)
}));
const ContentMenuStyle = styled(Box)(({ theme }) => ({
  height: '600px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  zIndex: 1
}));
const MenuItemStyle = styled(IconButton)(({ theme }) => ({
  //height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export default function TechEditMenu({ item, ...props }) {
  const theme = useTheme();
  const MENU = [
    {
      label: 'Home',
      icon: <img src="/static/icons/ic_home.svg" alt="Accueil" />,
      path: `${PATH_TECH.editProject}/${item.code}`
    },
    {
      label: 'Contact',
      icon: <img src="/static/icons/ic_contact.svg" alt="Contact" />,
      path: `${PATH_TECH.editProject}/${item.code}/${PATH_TECH.contact}`
    },
    {
      label: 'Mesures',
      icon: <img src="/static/icons/ic_measure.svg" alt="Mesures" />,
      path: `${PATH_TECH.editProject}/${item.code}/${PATH_TECH.measure}`
    },
    {
      label: 'Commande',
      icon: <img src="/static/icons/ic_order.svg" alt="Commandes" />,
      path: `${PATH_TECH.editProject}/${item.code}/${PATH_TECH.order}`
    },
    {
      label: 'Facturation',
      icon: <img src="/static/icons/ic_bill.svg" alt="Factures" />,
      path: `${PATH_TECH.editProject}/${item.code}/${PATH_TECH.billing}`
    }
  ];
  if (!item || Object.keys(item).length === 0) return <></>;
  return (
    <WrapperMenuStyle>
      <BoxMenuStyle>
        <BgMenuStyle
          src={`${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg#svgView(preserveAspectRatio(none))`}
          width={MENU_CLIP_WIDTH}
          height={MENU_CLIP_HEIGHT}
        />
        <ContentMenuStyle>
          {MENU.map((it, i) => {
            return (
              <MenuItemStyle
                key={Math.random()}
                component={RouterLink}
                to={it.path}
                style={({ isActive }) => ({
                  color: isActive ? '#668AF5' : ''
                })}
              >
                {it.icon}
                <Typography
                  sx={{
                    textAlign: 'center',
                    fontSize: '9px',
                    margin: theme.spacing(1, 0, 0, 0)
                  }}
                >
                  {it.label}
                </Typography>
              </MenuItemStyle>
            );
          })}
        </ContentMenuStyle>
      </BoxMenuStyle>
    </WrapperMenuStyle>
  );
}
