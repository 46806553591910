import React, { Fragment, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Unity, { UnityContext } from 'react-unity-webgl';
import 'components/unity/smartcity.css';
import useProjectUnity from 'hooks/useProjectUnity';
import { BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  right: 0,
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  /*  borderRadius: '50%', */
  overflow: 'hidden',
  visibility: 'hidden',
  margin: theme.spacing(0, 2, 2, 0)
}));
const unityContext = new UnityContext({
  productName: 'JPS-Films',
  companyName: 'JPS France',
  loaderUrl: '/static/unity/product/public.loader.js',
  dataUrl: '/static/unity/product/public.data',
  frameworkUrl: '/static/unity/product/public.framework.js',
  codeUrl: '/static/unity/product/public.wasm',
  streamingAssetsUrl: '/static/unity/product/StreamingAssets',
  webglContextAttributes: {
    preserveDrawingBuffer: true
  }
});
export default function UnitySection({ ...props }) {
  const [currentScene, setCurrentScene] = useState(null);
  const {
    enableZindex,
    sendingConfig,
    scene,
    isLoading,
    sceneLoaded,
    progression,
    handeSceneLoaded,
    handleUpdProgression,
    display
  } = useProjectUnity();
  useEffect(() => {
    unityContext.on('canvas', handleOnUnityCanvas);
    unityContext.on('progress', handleUpdProgression);
    unityContext.on('loaded', handeSceneLoaded);
    return function () {
      unityContext.removeAllEventListeners();
    };
  }, []);

  function handleOnUnityCanvas(canvas) {
    canvas.setAttribute('role', 'unityCanvas');
    /*  canvas.setAttribute('tabindex', 1); */
  }

  useEffect(() => {
    if (!scene) return;
    if (!sceneLoaded) return;
    if (scene.name === currentScene) return;

    setCurrentScene(scene.name);
  }, [scene, sceneLoaded, currentScene]);

  useEffect(() => {
    if (!currentScene) return;
    if (!sceneLoaded) return;
    unityContext.send('Scene Manager', 'LoadScene', currentScene);
  }, [currentScene, sceneLoaded]);
  useEffect(() => {
    if (!sendingConfig) return;
    if (!unityContext) return;
    if (!sceneLoaded) return;
    if (!scene) return;
    console.log(sendingConfig);
    unityContext.send(sendingConfig.objectName, sendingConfig.methodName, sendingConfig.parameters);
  }, [sendingConfig]);
  const styleDiv = scene && scene.style ? { ...scene.style } : {};
  const styleZindex = enableZindex ? { zIndex: enableZindex } : {};
  return (
    <RootStyle style={{ ...styleDiv, ...styleZindex }}>
      <div className="wrapper">
        <>
          <div className="unity-container">
            {!sceneLoaded && (
              <div className="loading-overlay">
                <div className="progress-bar">
                  <div className="progress-bar-fill" style={{ width: `${progression * 100}%` }} />
                </div>
              </div>
            )}
            {display && <Unity className="unity-canvas" unityContext={unityContext} />}
          </div>
        </>
      </div>
    </RootStyle>
  );
}
