import React, { useEffect } from 'react';
import { Outlet, useOutletContext, useLocation } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import TopNavbar from 'layouts/vertical/TopNavbar';
import TechTopBar from './TechTopBar';
import SaveBillButton from './bill/SaveBillButton';
import SendBillButton from './bill/SendBillButton';

// ----------------------------------------------------------------------
export default function TechEditBilling({ ...props }) {
  const theme = useTheme();
  const location = useLocation();
  const { project } = useOutletContext();
  const isActive = (path) => {
    try {
      if (!location) return;
      const pathArr = location.pathname.split('/');
      const lastIdx = pathArr[pathArr.length - 1];
      return path === PATH_TECH.estimate && lastIdx === PATH_TECH.billing;
    } catch (error) {
      return false;
    }
  };
  const MENU = [
    {
      label: 'Devis',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.billing}/${PATH_TECH.estimate}`,
      defActiv: isActive(PATH_TECH.estimate)
    },
    {
      label: 'Note technique',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.billing}/${PATH_TECH.technote}`
    },
    {
      label: 'Facture',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.billing}/${PATH_TECH.invoice}`
    }
  ];
  const { orderBill, initOrderBillProject, saveBill, saveTechNote, saveInvoice } =
    useTechEditProject();
  useEffect(() => {
    initOrderBillProject(project);
  }, []);
  const handleSaveBill = async () => {
    try {
      if (!project) return;
      if (!orderBill) return;
      if (location.pathname.endsWith('billing')) {
        saveBill(orderBill);
      } else if (location.pathname.includes(PATH_TECH.estimate)) {
        saveBill(orderBill);
      } else if (location.pathname.includes(PATH_TECH.technote)) {
        saveTechNote(orderBill);
      } else if (location.pathname.includes(PATH_TECH.invoice)) {
        saveInvoice(orderBill);
      }
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      {project && (
        <>
          <TopNavbar>
            <TechTopBar menuCenter={MENU}>
              {/* <SaveBillButton handleClick={handleSaveBill} /> */}
              <SendBillButton handleClick={handleSaveBill} />
            </TechTopBar>
          </TopNavbar>

          <Outlet context={{ project }} />
        </>
      )}
    </>
  );
}
