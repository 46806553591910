import React, { useContext } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
const BoxIconStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function BackIcon({ sx, ...props }) {
  return (
    <BoxIconStyle sx={{ ...sx }}>
      <svg
        width="93"
        height="98"
        viewBox="0 0 93 98"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_0_1)">
          <circle cx="43" cy="46" r="25" fill="white" />
        </g>
        <path
          d="M51.166 37.8338C49.1411 35.8082 46.3962 34.6671 43.5321 34.6602C40.6679 34.6532 37.9176 35.781 35.8828 37.7967V35.3924H34.418V40.5194H39.5449V39.0545H36.6934C37.5686 38.1281 38.6237 37.3901 39.7941 36.8857C40.9645 36.3813 42.2255 36.121 43.5 36.1209C48.6714 36.1209 52.8789 40.3285 52.8789 45.4998C52.8789 50.6712 48.6714 54.8787 43.5 54.8787C38.3286 54.8787 34.1211 50.6712 34.1211 45.4998H32.6602C32.6597 47.6441 33.2952 49.7403 34.4863 51.5233C35.6774 53.3063 37.3705 54.696 39.3515 55.5166C41.3325 56.3372 43.5123 56.5519 45.6153 56.1335C47.7184 55.715 49.65 54.6823 51.166 53.1659C52.1729 52.1592 52.9716 50.9641 53.5166 49.6488C54.0615 48.3334 54.342 46.9236 54.342 45.4998C54.342 44.0761 54.0615 42.6663 53.5166 41.3509C52.9716 40.0355 52.1729 38.8404 51.166 37.8338Z"
          fill="black"
        />
        <defs>
          <filter
            id="filter0_d_0_1"
            x="-7"
            y="0"
            width="100"
            height="100"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
          </filter>
        </defs>
      </svg>
    </BoxIconStyle>
  );
}
