import PropTypes from 'prop-types';
// material
import { alpha, styled } from '@mui/material/styles';
import { useLocation } from 'react-router-dom';
import { Box, Stack, AppBar, Toolbar, IconButton } from '@mui/material';
// components
import Logo from 'components/Logo';
//
import { TECH_PROFILE, APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
import TopAccountPopover from './TopAccountPopover';

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar)(({ bg, theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: bg ? bg : 'transparent' //alpha(theme.palette.background.default, 0.72)
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: `${APP_BAR_MOBILE}px`,
  [theme.breakpoints.up('lg')]: {
    minHeight: `${APP_BAR_DESKTOP}px`,
    padding: theme.spacing(0, 5)
  }
}));

// ----------------------------------------------------------------------

export default function TopNavbar({ bg, ...props }) {
  const location = useLocation();
  const getBackgroundColor = () => {
    try {
      if (!location.pathname) return null;
      const path = location.pathname;
      const pathArr = path.split('/');
      if (pathArr[1].includes(TECH_PROFILE)) {
        return '#3C60B5';
      }
      return 'transparent';
    } catch (error) {
      return 'transparent';
    }
  };
  return (
    <RootStyle bg={getBackgroundColor()}>
      <ToolbarStyle>
        <Logo />

        {props.children}
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction="row" alignItems="center" spacing={{ xs: 0.5, sm: 1.5 }}>
          <TopAccountPopover />
        </Stack>
      </ToolbarStyle>
    </RootStyle>
  );
}
