import React, { useMemo } from 'react';
import { useOutletContext, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import { PATH_TECH } from 'config/paths';
import TopNavbar from 'layouts/vertical/TopNavbar';

import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useMediaQuery from '@mui/material/useMediaQuery';
import TechTopBar from './TechTopBar';
// ----------------------------------------------------------------------
const ContactStyle = styled(Box)(({ width, theme }) => {
  return {
    width: width ? width : '728px',
    height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
    background: '#F9F9F9',
    boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
    borderRadius: '5px',
    overflowY: 'auto',
    padding: theme.spacing(0, 4, 3, 4),
    '& .title': {
      fontStyle: 'normal',
      fontWeight: 'bold',
      fontSize: '36px',
      lineHeight: '44px',
      textTransform: 'uppercase',
      color: '#121E6E',
      textAlign: 'center',
      margin: theme.spacing(2, 0, 4, 0)
    },
    '& .pro-title': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '22px',
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
      color: '#000000'
    },
    '& .sub-title': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '40px',
      textTransform: 'uppercase',
      color: '#121E6E'
    },
    '& .paragraph': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '40px',
      color: '#000000'
    },
    '& .label': {
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '18px',
      lineHeight: '22px',
      color: '#121E6E'
    }
  };
});
const TopBarStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100px',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  background: 'transparent'
}));
export default function TechEditContact({ ...props }) {
  const theme = useTheme();
  const { project } = useOutletContext();
  const matchesMinWithToDisplay = useMediaQuery('(max-width:850px)');
  return useMemo(() => {
    if (!project) return <></>;
    if (!Array.isArray(project.contacts)) return <></>;
    try {
      const client = project.contacts.find(
        (it) => it.administrator === 1 && it.company_type === 'customer'
      );
      const techResp = project.contacts.find(
        (it) => it.technician === 1 && it.company_type === 'customer'
      );
      console.log(project);
      return (
        <>
          <TopNavbar>
            <TechTopBar>
              <Button
                variant="outlined"
                sx={{ mx: theme.spacing(3), color: '#FFFFFF' }}
                component={RouterLink}
                to={PATH_TECH.root}
              >
                <ClearOutlinedIcon sx={{ mr: theme.spacing(1) }} /> Fermer
              </Button>
            </TechTopBar>
          </TopNavbar>

          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ height: 'auto', px: matchesMinWithToDisplay ? '90px' : '' }}
          >
            <ContactStyle width={matchesMinWithToDisplay ? '530px' : null}>
              <Box className="title">Contact</Box>
              {client && (
                <>
                  {' '}
                  <Box className="sub-title">Coordonnées projet</Box>
                  <Box className="paragraph">{project.name}</Box>
                  <Box className="paragraph">{project.location}</Box>
                  <Box className="sub-title" sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
                    Coordonnées Client
                  </Box>
                  <Box className="paragraph">{client.firstname}</Box>
                  <Box className="paragraph">{client.lastname}</Box>
                  <Box className="paragraph">{client.poste}</Box>
                  <Box className="paragraph">{client.email}</Box>
                  <Box className="paragraph">{client.phone}</Box>
                </>
              )}
              {techResp && (
                <>
                  <Box className="sub-title" sx={{ margin: theme.spacing(4, 0, 0, 0) }}>
                    Coordonnées techniques
                  </Box>
                  <Box className="paragraph">{techResp.firstname}</Box>
                  <Box className="paragraph">{techResp.lastname}</Box>
                  <Box className="paragraph">{techResp.poste}</Box>
                  <Box className="paragraph">{techResp.email}</Box>
                  <Box className="paragraph">{techResp.phone}</Box>
                </>
              )}
            </ContactStyle>
          </Box>
        </>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  }, [project, matchesMinWithToDisplay]);
}
