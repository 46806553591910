import rtl from 'jss-rtl';
import { create } from 'jss';
import PropTypes from 'prop-types';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';
import { CacheProvider } from '@emotion/react';
import useSettings from 'hooks/useSettings';
import React, { useMemo, useEffect } from 'react';
import { ThemeProvider, createTheme, StyledEngineProvider } from '@mui/material/styles';
import { jssPreset, StylesProvider } from '@mui/styles';

import { CssBaseline } from '@mui/material';
import Proxima from 'assets/fonts/Proxima-Nova-Regular.woff2';
import ProximaBold from 'assets/fonts/Proxima-Nova-Bold.woff2';
import ProximaLight from 'assets/fonts/Proxima-Nova-Light.woff2';
import ProximaMedium from 'assets/fonts/Proxima-Nova-Medium.woff2';

import RalewayWoff2 from 'assets/fonts/raleway-v22-latin-regular.woff2';
import componentsOverride from './overrides';
import borderRadius from './borderRadius';
import GlobalStyles from './globalStyles';
import breakpoints from './breakpoints';
import typography from './typography';
import palette from './palette';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

RTLProvider.propTypes = {
  direction: PropTypes.oneOf(['ltr', 'rtl']),
  children: PropTypes.node
};

function RTLProvider({ direction, children }) {
  const isRTL = direction === 'rtl';
  const jss = create({
    plugins: [...jssPreset().plugins, rtl()]
  });

  const cache = createCache({
    key: isRTL ? 'rtl' : 'css',
    prepend: true,
    stylisPlugins: isRTL ? [rtlPlugin] : []
  });

  cache.compat = true;

  useEffect(() => {
    document.dir = direction;
  }, [direction]);

  return (
    <CacheProvider value={cache}>
      <StylesProvider jss={jss}>{children}</StylesProvider>
    </CacheProvider>
  );
}

ThemeConfig.propTypes = {
  children: PropTypes.node
};

function ThemeConfig({ children }) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: palette[isLight ? 'light' : 'dark'],
      shadows: shadows[isLight ? 'light' : 'dark'],
      customShadows: customShadows[isLight ? 'light' : 'dark'],
      typography,
      shape: borderRadius,
      breakpoints,
      direction: themeDirection,
      components: {
        MuiCssBaseline: {
          styleOverrides: `
        @font-face {
          font-family: 'Proxima';
          font-style: normal;
          font-display: swap;
          font-weight: 400;
          src: local('Proxima'), local('Proxima-Nova-Regular'), url(${Proxima}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
        }
        @font-face {
          font-family: 'ProximaBold';
           font-weight: bold;
          font-style: normal;
          src: local('Proxima'), local('Proxima-Nova-Bold'), url(${ProximaBold}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;

        }
        @font-face {
          font-family: 'ProximaLight';
           font-weight: bold;
          font-style: normal;
          src: local('Proxima'), local('Proxima-Nova-Light'), url(${ProximaLight}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
         }
         @font-face {
          font-family: 'ProximaMedium';
           font-weight: normal;
          font-style: normal;
          src: local('Proxima'), local('Proxima-Nova-Medium'), url(${ProximaMedium}) format('woff2');
          unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
         }
      `
        },
        ...componentsOverride({
          theme: {
            palette: palette[isLight ? 'light' : 'dark'],
            shadows: shadows[isLight ? 'light' : 'dark'],
            typography,
            shape: borderRadius,
            direction: themeDirection
          }
        })
      }
    }),

    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <GlobalStyles />
        <RTLProvider direction={themeDirection}>{children}</RTLProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default ThemeConfig;
