import React, { useEffect, useMemo } from 'react';
import { Outlet, useNavigate, useOutletContext, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import TopNavbar from 'layouts/vertical/TopNavbar';
import TechTopBar from './TechTopBar';
import TechEditOrderTable from './order/TechEditOrderTable';
import SaveOrderButton from './order/SaveOrderButton';
import DoneOrderButton from './order/DoneOrderButton';
import AddNoteToOrder from './order/AddNoteToOrder';
// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ theme }) => ({
  width: '80%',
  height: `calc(100vh - ${APP_BAR_DESKTOP + TOP_BAR_TECHPROJECT_DESKTOP + MARGE_BAR_DESKTOP}px)`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(6, 4, 0, 4)
}));

export default function TechEditOrder({ ...props }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const { project } = useOutletContext();
  const MENU = [
    {
      label: 'Commande',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.order}`,
      defActiv: true
    }
  ];
  const { problematics, saveOrder, initOrderProject } = useTechEditProject();
  useEffect(() => {
    initOrderProject();
  }, []);
  const handleSaveOrder = async () => {
    try {
      if (!project) return;
      const newPrj = { ...project };
      newPrj.order = {
        status: 'submited',
        description: 'Merci de nous tenir informés des délais de livraison des produits suivants'
      };
      await saveOrder(newPrj);
    } catch (error) {
      console.error(error);
    }
  };

  return useMemo(() => {
    if (!problematics) return <></>;
    if (!project) return <></>;
    try {
      let avProducts = [];
      project.problematics.forEach((p) => {
        const pFounded = problematics.find((it) => String(it.code) === String(p.code));
        if (pFounded) {
          avProducts = [...avProducts, ...pFounded.category[0].products];
        }
      });
      return (
        <>
          {project && (
            <>
              <TopNavbar>
                <TechTopBar menuCenter={MENU}>
                  {/* <SaveOrderButton handleClick={handleSaveOrder} /> */}
                  <DoneOrderButton
                    handleClick={handleSaveOrder}
                    /* component={RouterLink}
                    to={`${PATH_TECH.editProject}/${project.code}/${PATH_TECH.billing}/${PATH_TECH.estimate}`} */
                  />
                </TechTopBar>
              </TopNavbar>

              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                sx={{ height: 'auto' }}
              >
                <MBuildingStyle>
                  <TechEditOrderTable avProducts={avProducts} avProject={project} />
                  <Box
                    display="flex"
                    justifyContent="flex-end"
                    alignItems="center"
                    sx={{ height: 'auto' }}
                  >
                    <AddNoteToOrder project={project} />
                  </Box>
                </MBuildingStyle>
              </Box>
            </>
          )}
        </>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  }, [project, problematics]);
}
