import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTheme, styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import { EditProjectContext } from 'provider/EditProjectProvider';
import GradientBtn from 'components/custom/GradientBtn';
import { ROOFTYPES, GlAZINGTYPE, SURFACES } from 'config/appConfig';
// ----------------------------------------------------------------------
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '300px',
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    border: '1px solid #B0B6C2',

    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const RootStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  padding: theme.spacing(0, 2, 0, 2),
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const TypoImgStyle = styled(Box)(({ theme }) => ({
  width: '320px',
  height: '180px',
  backgroundPosition: 'center',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_school.svg)`,
  backgroundSize: 'contain'
}));
const SurfaceBtnStyle = styled(Box)(({ selected, theme }) => ({
  width: '85px',
  height: '31px',
  background: selected ? '#93D5FE' : '#E1E4E6',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));
const GlazingBtnStyle = styled(Box)(({ selected, theme }) => ({
  width: '85px',
  height: '85px',
  background: selected ? '#93D5FE' : '#E1E4E6',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
  margin: theme.spacing(0, 0, 2, 0)
}));

const FloorsBtnStyle = styled(Button)(({ colored, theme }) => ({
  width: '55px',
  height: '55px',
  background: colored ? '#93D5FE' : '#FFFFFF',
  boxShadow: '0px 4px 25px rgba(0, 0, 0, 0.08)',
  borderRadius: '10px'
}));

const LabelStyle = styled(Typography)(({ selected, theme }) => ({
  textAlign: 'left',
  color: '#121E6E',
  width: '100%',
  margin: theme.spacing(3, 0, 2, 0)
}));

export default function ConfigTypoAndScope({
  spec,
  selectSpecifications,
  onDisplayConfirm,
  flow,
  ...props
}) {
  const { onChaneFlowValue } = useContext(EditProjectContext);
  const [configSpec, setConfigSpec] = useState(null);
  const [tabActiv, setTabActiv] = useState('config');
  const [scopeTypo, setScopeTypo] = useState({});
  const onNextConfig = (c) => {
    setConfigSpec(c);
    onChaneFlowValue(flow, { configSpec: c });
    setTabActiv('scope');
  };
  const onBackConfig = (c) => {
    setTabActiv('config');
  };
  useEffect(() => {
    if (!flow.value) return;

    setConfigSpec(flow.value.configSpec);
    setScopeTypo(flow.value.scopeTypo);
  }, [flow]);
  useEffect(() => {
    if (!scopeTypo) return;

    if (selectSpecifications.value.length === Object.keys(scopeTypo).length) {
      onDisplayConfirm(configSpec, scopeTypo);
    }
  }, [scopeTypo]);
  const onValidScopeByTypo = (val) => {
    if (!selectSpecifications) return;
    let newScope = { ...scopeTypo };
    if (val.id === 'all') {
      newScope = {}; //reinitialize
      selectSpecifications.value.forEach((it) => {
        if (it.id !== 'all') {
          newScope[it.id] = {
            spec: it,
            values: { ...val.data }
          };
        }
      });
    } else {
      const specFound = selectSpecifications.value.find((it) => it.id === val.id);
      newScope[val.id] = {
        spec: specFound,
        values: { ...val.data }
      };
    }
    onChaneFlowValue(flow, { scopeTypo: newScope });
    setScopeTypo(newScope);
  };

  return (
    <>
      <RootStyle>
        <TypoImgStyle />
        {tabActiv === 'config' && (
          <ConfigTypo onNextConfig={onNextConfig} configSpec={configSpec} />
        )}
        {tabActiv === 'scope' && (
          <ScopeTypo
            scopeTypo={scopeTypo}
            selectSpecifications={selectSpecifications}
            onBackConfig={onBackConfig}
            onValidScopeByTypo={onValidScopeByTypo}
          >
            <>
              {scopeTypo && Object.keys(scopeTypo).length > 0 && (
                <>
                  <LabelStyle variant="body1">Problématique(s)</LabelStyle>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="flex-start"
                    sx={{ width: '100%' }}
                  >
                    {Object.entries(scopeTypo).map(([k, v]) => {
                      const { spec, values } = v;
                      return (
                        <React.Fragment key={Math.random()}>
                          <Typography variant="subtitle1">{spec.label}</Typography>
                          {Object.entries(values).map(([key, value]) => {
                            try {
                              const { label } = value;

                              return (
                                <React.Fragment key={Math.random()}>
                                  <Typography variant="caption">{label}</Typography>
                                </React.Fragment>
                              );
                            } catch (error) {
                              return <></>;
                            }
                          })}
                        </React.Fragment>
                      );
                    })}
                  </Box>
                </>
              )}
            </>
          </ScopeTypo>
        )}
      </RootStyle>
    </>
  );
}
const SCOPE_INOUT = [
  {
    code: 'ext',
    label: 'Extérieur'
  },
  {
    code: 'int',
    label: 'Intérieur'
  }
];
const SCOPE_FLOORS = [...Array(10)].map((it, i) => {
  return {
    code: i,
    label: i === 0 ? 'RDC' : `${i} étage${i === 1 ? '' : 's'}`
  };
});
SCOPE_FLOORS.push({ code: 'all', label: 'Tous les étages' });
const SCOPE_ROOMS = [...Array(10)].map((it, i) => {
  return {
    code: i + 1,
    label: `${i + 1} pièce${i + 1 === 1 ? '' : 's'}`
  };
});
SCOPE_ROOMS.push({ code: 'all', label: 'Toutes les pièces' });
const ScopeTypo = ({
  scopeTypo,
  selectSpecifications,
  onBackConfig,
  onValidScopeByTypo,
  ...props
}) => {
  const theme = useTheme();
  const [idSpecSelected, setIdSpecSelected] = useState({
    id: 'all',
    values: { inOut: 'ext', floors: 'all', rooms: 1 },
    data: {
      inOut: SCOPE_INOUT[0],
      floors: { code: 'all', label: 'Tous les étages' },
      rooms: SCOPE_ROOMS[0]
    }
  });
  /*  useEffect(() => {
    if (!scopeTypo) return;
    setIdSpecSelected(scopeTypo);
  }, [scopeTypo]); */
  const handleChangeOptSpec = (event) => {
    const newSpec = { ...idSpecSelected };
    newSpec.id = event.target.value;
    setIdSpecSelected(newSpec);
  };

  const updateScope = (key, value, opts) => {
    const newSpec = { ...idSpecSelected };
    newSpec.values[key] = value;
    newSpec.data[key] = opts.find((it) => {
      return it.code === value;
    });
    setIdSpecSelected(newSpec);
  };
  return useMemo(() => {
    console.log(scopeTypo);
    return (
      <Box display="flex" flexDirection="column" sx={{ height: '70%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <LabelStyle variant="body1">Périmètre d'intervention</LabelStyle>
          {/* specification */}
          <FormControl variant="standard">
            <Select
              fullWidth
              value={idSpecSelected.id}
              onChange={handleChangeOptSpec}
              input={<BootstrapInput />}
            >
              <MenuItem value="all">Tous</MenuItem>
              {selectSpecifications.value.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.id}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {idSpecSelected && <ScopeTypoBySpec spec={idSpecSelected} updateScope={updateScope} />}
          {props.children}
        </Box>

        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          sx={{ margin: theme.spacing(3, 0, 0, 0), width: '100%' }}
        >
          <Button size="large" color="warning" onClick={() => onBackConfig()}>
            <ArrowBackIcon />
          </Button>
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            sx={{ width: '161px' }}
            onClick={() => onValidScopeByTypo(idSpecSelected)}
          >
            Valider
          </GradientBtn>
        </Box>
      </Box>
    );
  }, [idSpecSelected, selectSpecifications, scopeTypo]);
};

const ScopeTypoBySpec = ({ spec, updateScope, ...props }) => {
  const theme = useTheme();

  return (
    <>
      {/* inout */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          fullWidth
          value={spec.values.inOut}
          onChange={(e) => updateScope('inOut', e.target.value, SCOPE_INOUT)}
          input={<BootstrapInput />}
        >
          {SCOPE_INOUT.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* floors */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          fullWidth
          value={spec.values.floors}
          onChange={(e) => updateScope('floors', e.target.value, SCOPE_FLOORS)}
          input={<BootstrapInput />}
        >
          <MenuItem value="tous">Tous les étages</MenuItem>
          {SCOPE_FLOORS.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* Rooms */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          fullWidth
          value={spec.values.rooms}
          onChange={(e) => updateScope('rooms', e.target.value, SCOPE_ROOMS)}
          input={<BootstrapInput />}
        >
          {SCOPE_ROOMS.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const ConfigTypo = ({ onNextConfig, configSpec, ...props }) => {
  const theme = useTheme();
  const [surfaceSelected, setSurfaceSelected] = useState(null);
  const [glazingType, setGlazingType] = useState(configSpec ? configSpec.glazingType : null);
  const [floorsNb, setFloorsNb] = useState(0);
  const [ignoredFloorsNb, setIgnoredFloorsNb] = useState(false);
  const [roofType, setRoofType] = useState(null);
  useEffect(() => {
    if (configSpec) {
      setSurfaceSelected(configSpec.surfaceSelected);
      setGlazingType(configSpec.glazingType);
      setFloorsNb(configSpec.floorsNb);
      setIgnoredFloorsNb(configSpec.ignoredFloorsNb);
      setRoofType(configSpec.roofType);
    }
  }, [configSpec]);

  return (
    <>
      <SurfaceSelect select={surfaceSelected} onSelect={(s) => setSurfaceSelected(s)} />
      <GlazingSelect select={glazingType} onSelect={(g) => setGlazingType(g)} />
      <FloorsSelect
        value={floorsNb}
        handleClick={(v) => setFloorsNb((prev) => prev + v)}
        onIgnore={(isIgnored) => {
          setIgnoredFloorsNb(isIgnored);
        }}
      />
      <RoofSelect select={roofType} onSelect={(r) => setRoofType(r)} />
      <GradientBtn
        size="large"
        color="info"
        variant="contained"
        onClick={() =>
          onNextConfig({ surfaceSelected, glazingType, floorsNb, ignoredFloorsNb, roofType })
        }
        sx={{ margin: theme.spacing(3, 0, 0, 0), width: '251px' }}
      >
        Suivant
      </GradientBtn>
    </>
  );
};

const SurfaceSelect = ({ select, onSelect, ...props }) => {
  return useMemo(() => {
    return (
      <>
        <LabelStyle variant="body1">Superficie du bâtiment</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {SURFACES.map((opt) => {
            return (
              <SurfaceBtnStyle
                key={Math.random()}
                selected={select ? opt.code === select.code : false}
                onClick={() => onSelect(opt)}
              >
                <Typography variant="body2">{opt.label}</Typography>
              </SurfaceBtnStyle>
            );
          })}
        </Box>
      </>
    );
  }, [select]);
};

const GlazingSelect = ({ select, onSelect, ...props }) => {
  return useMemo(() => {
    return (
      <>
        <LabelStyle variant="body1">Type de vitrage</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {GlAZINGTYPE.map((opt) => {
            return (
              <Box key={Math.random()} display="flex" flexDirection="column" alignItems="center">
                <GlazingBtnStyle
                  selected={select ? opt.code === select.code : false}
                  onClick={() => onSelect(opt)}
                >
                  {opt.lines && Number(opt.lines) > 0 && (
                    <>
                      {[...Array(opt.lines)].map((line, i) => {
                        return (
                          <Box
                            key={Math.random()}
                            sx={{
                              background: '#FFFFFF',
                              width: '8px',
                              height: '58px',
                              marginLeft: i > 0 ? '7px' : 0
                            }}
                          />
                        );
                      })}
                    </>
                  )}
                </GlazingBtnStyle>
                <Typography variant="subtitle1">{opt.label}</Typography>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }, [select]);
};
const MAX_NBFLOORS = 10;
const FloorsSelect = ({ value, handleClick, onIgnore, ...props }) => {
  const theme = useTheme();
  return useMemo(() => {
    return (
      <>
        <LabelStyle variant="body1">Type de vitrage</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="start"
          sx={{ width: '100%', margin: theme.spacing(0, 0, 2, 0) }}
        >
          <FloorsBtnStyle
            aria-label="Moins"
            size="large"
            onClick={() => {
              if (value >= 1) {
                handleClick(-1);
              }
            }}
            colored={Boolean(value > 0)}
          >
            <RemoveIcon />
          </FloorsBtnStyle>
          <Typography
            sx={{
              fontFamily: 'Proxima Nova',
              fontStyle: 'normal',
              fontWeight: 'normal',
              fontSize: '36px',
              lineHeight: '44px',

              textAlign: 'center',
              letterSpacing: '0.1em',
              textTransform: 'uppercase',

              color: '#000000',
              margin: theme.spacing(0, 2, 0, 2)
            }}
          >
            {value}
          </Typography>
          <FloorsBtnStyle
            aria-label="Plus"
            size="large"
            onClick={() => {
              if (value < MAX_NBFLOORS) {
                handleClick(1);
              }
            }}
            colored={Boolean(value < MAX_NBFLOORS)}
          >
            <AddIcon />
          </FloorsBtnStyle>
        </Box>
        <Box width="100%">
          <FormControlLabel
            onChange={(e) => {
              onIgnore(e.target.checked);
            }}
            control={<Checkbox />}
            label={
              <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                Je ne sais pas
              </Typography>
            }
            sx={{ alignSeft: 'left' }}
          />
        </Box>
      </>
    );
  }, [value]);
};

const RoofSelect = ({ select, onSelect, ...props }) => {
  return useMemo(() => {
    return (
      <>
        <LabelStyle variant="body1">Type de toit</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ width: '100%' }}
        >
          {ROOFTYPES.map((opt) => {
            return (
              <Box key={Math.random()} display="flex" flexDirection="column" alignItems="center">
                <GlazingBtnStyle
                  selected={select ? opt.code === select.code : false}
                  onClick={() => onSelect(opt)}
                >
                  {opt.img && <img src={opt.img} alt={opt.label} />}
                </GlazingBtnStyle>
                <Typography variant="subtitle1" sx={{ textTransform: 'uppercase' }}>
                  {opt.label}
                </Typography>
              </Box>
            );
          })}
        </Box>
      </>
    );
  }, [select]);
};
