import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import LinearProgress from '@mui/material/LinearProgress';
import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  minHeight: '100%',
  paddingTop: APP_BAR_DESKTOP,
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_DESKTOP
  },
  zIndex: 10
}));
const ProgressBarStyle = styled(LinearProgress)(({ theme }) => ({
  zIndex: 10
}));
export default function ProgressBarUnity({ progress, ...props }) {
  const [buffer, setBuffer] = useState(10);
  useEffect(() => {
    if (!progress) return;
    const diff = Math.random() * 10;
    setBuffer(progress + diff);
  }, [progress]);

  return (
    <RootStyle display="flex" justifyContent="center" alignItems="center" flexDirection="column">
      <Box sx={{ width: '70%' }}>
        <ProgressBarStyle
          variant="buffer"
          value={progress}
          valueBuffer={buffer}
          sx={{ height: '10px' }}
        />
        <Typography sx={{ textAlign: 'center', marginTop: '10px' }}>
          Chargement en cours ... {`${Math.round(progress)}%`}
        </Typography>
      </Box>
    </RootStyle>
  );
}
