import React, { useState, useEffect } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import TextField from '@mui/material/TextField';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import FormControl from '@mui/material/FormControl';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import InputBase from '@mui/material/InputBase';
import OutlinedInput from '@mui/material/OutlinedInput';
import ListItemText from '@mui/material/ListItemText';
import InputLabel from '@mui/material/InputLabel';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import useAuth from 'hooks/useAuth';
import GradientBtn from 'components/custom/GradientBtn';
import useTechEditProject from 'hooks/useTechEditProject';
// ----------------------------------------------------------------------
const RootStyle = styled(Button)(({ theme }) => ({
  minHeight: '64px',
  width: '100%',
  background: 'linear-gradient(93.46deg, #668AF5 37.45%, #52A2F9 93.7%)',
  boxShadow: '0px 8px 25px rgba(99, 142, 244, 0.46)',
  borderRadius: '15px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  paddingRight: '79px',
  paddingLeft: '79px',
  cursor: 'pointer',
  marginBottom: '100px'
}));
export default function CreationCard({ style, onCreate, project, ...props }) {
  const theme = useTheme();
  const [options, setOptions] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = React.useRef(null);
  const [selectingProbs, setselectingProbs] = useState(false);
  useEffect(() => {
    if (!project) return;
    if (!project.problematics) return;
    const arr = project.problematics.map((it) => {
      return { code: it.id, label: it.label, value: { ...it } };
    });
    setOptions(arr);
  }, [project]);

  const handleMenuItemClick = (event, option) => {
    setOpen(false);
    onCreate(option.value);
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (selectingProbs) return;
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };
  if (!options) return <></>;
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', margin: theme.spacing(0, 0, 3, 0) }}
    >
      <RootStyle
        ref={anchorRef}
        aria-controls={open ? 'split-button-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-label="select merge strategy"
        aria-haspopup="menu"
        onClick={handleToggle}
      >
        <Typography component="div" variant="h4" sx={{ color: '#FFFFFF' }}>
          Nouvelle vitre
        </Typography>
      </RootStyle>

      <Popper
        style={{ zIndex: '1', width: '656px' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom'
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  id="split-button-menu"
                  sx={{ borderRadius: '15px', margin: theme.spacing(1, 0, 0, 0) }}
                >
                  {options.map((option, index) => (
                    <MenuItem
                      key={index}
                      onClick={(event) => handleMenuItemClick(event, option)}
                      sx={{
                        fontFamily: 'Proxima',
                        fontStyle: 'normal',
                        fontWeight: '400',
                        fontSize: '18px',
                        lineHeight: '22px',
                        color: '#000000',
                        margin: theme.spacing(1, 0, 1, 0)
                      }}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                  <AddProblematics
                    onSelecting={() => setselectingProbs(true)}
                    onFinish={() => setselectingProbs(false)}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
};

const AddProblematics = ({ onSelecting, onFinish, ...props }) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();
  const { project, problematics, addProblematics, updateGlazing } = useTechEditProject();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [selectedProblematics, setselectedProblematics] = useState([]);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    onSelecting();
  };

  const handleClose = () => {
    setAnchorEl(null);
    onFinish();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  const handleAddProbs = async () => {
    try {
      if (selectedProblematics.length < 1) return;
      console.log(selectedProblematics);
      const prj = {
        project: {
          id: project.code,
          problematics: problematics
            .filter((it) => {
              return selectedProblematics.includes(it.code);
            })
            .map((it) => {
              return {
                code: it.code,
                label: it.label
              };
            })
        }
      };
      await addProblematics(prj);
      setselectedProblematics([]);
      enqueueSnackbar('Les problématiques sont enregistrées avec succès.', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      let msg = '';
      if (error && error.response && error.response.data) {
        msg = error.response.data.message;
      } else {
        msg = 'Une erreur est survenue';
      }
      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setselectedProblematics(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };
  return (
    <>
      <MenuItem
        aria-describedby={id}
        onClick={handleClick}
        sx={{
          margin: theme.spacing(2, 2, 2, 2),

          background: `linear-gradient(91.63deg, #678BF6 18.03%, #54A0F9 79.34%)`,
          borderRadius: '5px',
          color: '#FFFFFF',
          fontFamily: 'Proxima',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '18px',
          lineHeight: '29px'
        }}
      >
        Ajouter d'autres problématiques
      </MenuItem>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        PaperProps={{
          sx: {
            width: 600
          }
        }}
      >
        <Card>
          <Box
            display="flex"
            justifyContent="center"
            alignContent="center"
            flexDirection="column"
            sx={{
              padding: '10px',
              width: '100%',
              height: '200px',
              '& .MuiTextField-root': { width: '100%' }
            }}
            component="form"
            noValidate
            autoComplete="off"
          >
            <InputLabel id="demo-multiple-checkbox-label">Problématiques</InputLabel>
            <Select
              fullWidth
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={selectedProblematics}
              onChange={handleChange}
              input={<OutlinedInput label="Problématiques" />}
              renderValue={(selected) => {
                return problematics
                  .filter((it) => {
                    return selected.includes(Number(it.code));
                  })
                  .map((it) => it.label)
                  .join(', ');
              }}
              MenuProps={MenuProps}
              sx={{ margin: theme.spacing(0, 0, 2, 0) }}
            >
              {problematics
                .filter((it) => {
                  const idx = project.problematics.findIndex((p) => p.code === it.code);
                  return idx < 0;
                })
                .map((option, i) => (
                  <MenuItem key={i} value={option.code}>
                    <Checkbox checked={selectedProblematics.indexOf(option.code) > -1} />
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))}
            </Select>
            <GradientBtn size="large" color="info" onClick={handleAddProbs}>
              Valider
            </GradientBtn>
          </Box>
        </Card>
      </Popover>
    </>
  );
};
