import React, { useContext, useCallback, useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CardActions from '@mui/material/CardActions';
import HomeIcon from '@mui/icons-material/Home';
import WindowIcon from '@mui/icons-material/Window';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import GradientBtn from 'components/custom/GradientBtn';
import useClientEditProject from 'hooks/useClientEditProject';
import useMediaQuery from '@mui/material/useMediaQuery';
import { NAV_URL_COM } from 'config/paths';
import {
  MENU_CLIP_WIDTH,
  MENU_CLIP_HEIGHT,
  MENU_CLIP_SHOT_WIDTH,
  MENU_CLIP_SHOT_HEIGHT
} from 'config/appConfig';
// ----------------------------------------------------------------------

const WrapperMenuStyle = styled('div')(({ theme }) => ({
  width: `${MENU_CLIP_WIDTH}px`,
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 10,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));
const BoxMenuStyle = styled(Box)(({ height, theme }) => ({
  position: 'relative',
  height: `${height}px`,
  backgroundColor: 'transparent',
  width: '100%',
  /*  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg)`, */
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
}));
const BgMenuStyle = styled('img')(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0
}));
const VerticalMenuStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  backgroundColor: 'transparent',
  width: '100%',
  /*  backgroundRepeat: 'no-repeat',
  backgroundSize: 'contain',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg)`, */
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center'
  // padding: theme.spacing(14, 0, 14, 0)
}));
const ContentMenuStyle = styled(Box)(({ longHeightMatches, theme }) => {
  const typeAign = longHeightMatches ? 'space-between' : 'space-evenly';
  return {
    height: '600px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: typeAign,
    zIndex: 1
  };
});
const MenuItemStyle = styled(IconButton)(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center'
  };
});
const StyledBadge = styled(Badge)(({ theme }) => ({
  '& .MuiBadge-badge': {
    background: '#3C60B5'
  }
}));
const menu = [
  {
    label: 'Home',
    icon: <img src="/static/icons/ic_home.svg" alt="Accueil" />
  },
  {
    label: 'Problématique',
    icon: <img src="/static/icons/ic_problematic.svg" alt="Problématique" />
  },
  {
    label: 'Typologie',
    icon: <img src="/static/icons/ic_typo.svg" alt="Typologie" />
  },
  {
    label: 'Custom',
    icon: <img src="/static/icons/ic_custom.svg" alt="Custom" />
  },
  {
    label: 'Contact',
    icon: <img src="/static/icons/ic_contact.svg" alt="Contact" />
  }
];
export default function MenuClip({ ...props }) {
  const longHeightMatches = useMediaQuery('(min-height:1080px)');

  const theme = useTheme();
  const [prods, setProds] = useState([]);
  const { editProject } = useClientEditProject();
  useEffect(() => {
    if (!editProject) return;
    if (!editProject.problematics) return;
    try {
      let arr = [];
      editProject.problematics.forEach((p) => {
        if (p.products) {
          const newProducts = p.products.map((it) => {
            return { problematicId: p.id, ...it };
          });
          arr = [...arr, ...newProducts];
        }
      });
      setProds(arr);
    } catch (error) {
      console.error(error);
    }
  }, [editProject]);

  return useMemo(() => {
    try {
      if (!editProject) return <></>;
      const height = longHeightMatches ? MENU_CLIP_HEIGHT : MENU_CLIP_SHOT_HEIGHT;
      const width = longHeightMatches ? MENU_CLIP_WIDTH : MENU_CLIP_SHOT_WIDTH;
      return (
        <WrapperMenuStyle>
          <BoxMenuStyle height={height}>
            <BgMenuStyle
              src={`${process.env.PUBLIC_URL}/static/bg/bg_vert_menu.svg#svgView(preserveAspectRatio(none))`}
              width={width}
              height={height}
            />
            <ContentMenuStyle longHeightMatches={longHeightMatches}>
              {menu.map((item, i) => {
                if (item.label === 'Custom' && prods.length > 0) {
                  return <CustomMenuItem key={i} prods={prods} item={item} />;
                }

                return (
                  <MenuItemStyle key={Math.random()}>
                    {item.icon}
                    <Typography
                      sx={{
                        textAlign: 'center',
                        fontSize: '9px',
                        margin: theme.spacing(1, 0, 0, 0)
                      }}
                    >
                      {item.label}
                    </Typography>
                  </MenuItemStyle>
                );
              })}
            </ContentMenuStyle>
          </BoxMenuStyle>
        </WrapperMenuStyle>
      );
    } catch (error) {
      return <></>;
    }
  }, [longHeightMatches, editProject, prods]);
}
const CustomMenuItem = ({ item, prods, ...props }) => {
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const validateProducts = () => {
    navigate(NAV_URL_COM.contact);
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <React.Fragment key="custom">
      <MenuItemStyle onClick={handleClick} aria-describedby={id}>
        <StyledBadge badgeContent={prods.length} color="primary">
          {item.icon}
        </StyledBadge>

        <Typography variant="subtitle2" sx={{ textAlign: 'center' }}>
          {item.label}
        </Typography>
      </MenuItemStyle>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <CartFlows prods={prods} validateProducts={validateProducts} />
      </Popover>
    </React.Fragment>
  );
};
const CartStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  /* display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center', */
  overflowY: 'scroll',
  overflowX: 'hidden',
  margin: theme.spacing(2, 0.5, 2, 0.5),
  padding: theme.spacing(1.5)
}));

const CartFlows = ({ prods, validateProducts, ...props }) => {
  const theme = useTheme();
  if (!Array.isArray(prods)) return <></>;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ height: '300px', overflow: 'hidden' }}
    >
      <CartStyle>
        {prods.map((it, i) => {
          return (
            <CartItem
              key={Math.random()}
              item={it}
              style={{ marginTop: i > 0 ? theme.spacing(2) : 0 }}
            />
          );
        })}
      </CartStyle>
      <GradientBtn
        size="medium"
        color="info"
        variant="contained"
        sx={{ width: '211px', margin: theme.spacing(1, 0, 1, 0) }}
        onClick={() => validateProducts()}
      >
        Valider
      </GradientBtn>
    </Box>
  );
};

const CartItem = ({ item, style, ...props }) => {
  const { editProject, onUpdateEditProject } = useClientEditProject();
  const theme = useTheme();

  const handleRemoveProduct = () => {
    try {
      const newPrj = { ...editProject };
      const newProblematics = [...newPrj.problematics];
      const newPbIdx = newProblematics.findIndex(
        (it) => String(it.code) === String(item.problematicId)
      );
      const newPb = { ...newProblematics[newPbIdx] };
      const newProducts = newPb.products.filter((it) => String(it.code) !== String(item.code));
      newPb.products = [...newProducts];
      newProblematics[newPbIdx] = newPb;
      newPrj.problematics = newProblematics;
      onUpdateEditProject(newPrj);
    } catch (error) {
      console.error(error);
    }
  };
  const getLabel = () => {
    try {
      if (item.technical_name) return item.technical_name;
      return `${item.label} - ${item.film_color_appearance}`;
    } catch (error) {
      return '';
    }
  };
  return (
    <Card
      sx={{
        display: 'flex',
        height: '120px',
        width: '300px',
        ...style
      }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: theme.spacing(1, 0, 3, 0),
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Typography component="div" variant="h6">
            {getLabel()}
          </Typography>
          <Typography variant="overline" color="text.secondary" component="span">
            {/*       {item.category.label} */}
          </Typography>
          <Box>
            <IconButton onClick={handleRemoveProduct} color="error">
              <DeleteOutlineIcon />
            </IconButton>
          </Box>
        </CardContent>
      </Box>
      <Box
        sx={{
          width: '100px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center',
          backgroundImage: `url(${item.image})`,
          backgroundSize: 'cover'
        }}
      />
    </Card>
  );
};
