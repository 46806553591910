import React, { Fragement, useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import Fade from '@mui/material/Fade';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import Stack from '@mui/material/Stack';
import { useTheme, styled } from '@mui/material/styles';
import Collapse from '@mui/material/Collapse';
import Slide from '@mui/material/Slide';
import { useSelector } from 'react-redux';
import FullScalableVideo from 'components/player/FullScalableVideo';
import Drawer from '@mui/material/Drawer';
import { TransitionGroup } from 'react-transition-group';
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import { NAV_URL_COM } from 'config/paths';
import ReactPlayer from 'react-player';
import BackIcon from 'components/custom/icons/BackIcon';
import CircularProgress from '@mui/material/CircularProgress';
import BusinessIcon from '@mui/icons-material/Business';
import SchoolIcon from '@mui/icons-material/School';
import AddBusinessIcon from '@mui/icons-material/AddBusiness';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import LocationCityIcon from '@mui/icons-material/LocationCity';
import SportsSoccerIcon from '@mui/icons-material/SportsSoccer';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MuseumIcon from '@mui/icons-material/Museum';
import CameraIndoorIcon from '@mui/icons-material/CameraIndoor';
import DirectionsCarFilledIcon from '@mui/icons-material/DirectionsCarFilled';
import useMediaQuery from '@mui/material/useMediaQuery';
import useWindowDimensions from 'hooks/useWindowDimensions';
import DrawerSpecDetail from './flow/DrawerSpecDetail';
import BuildingTypeBtn from './building/BuildingTypeBtn';
import SpecButton from './building/SpecButton';
// ----------------------------------------------------------------------

export default function BuildingForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();
  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            {editProject.building ? (
              <ValidatedBuilding selectedBuildingType={editProject.building.type} />
            ) : (
              <SelectBuilding editProject={editProject} />
            )}
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, [editProject]);
}
const ValidatedBuilding = ({ selectedBuildingType, ...props }) => {
  if (!selectedBuildingType) return <></>;
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      sx={{ mt: '50px' }}
    >
      <Box sx={{ mb: '50px' }}>
        <h3>Vous avez choisi le type: {selectedBuildingType.label}</h3>
      </Box>

      <GradientBtn
        size="large"
        color="info"
        variant="contained"
        component={RouterLink}
        to={NAV_URL_COM.specifications}
        style={{ textTransform: 'normal' }}
      >
        Veuillez sélectionner les problématiques
      </GradientBtn>
    </Box>
  );
};
const BuildingTypesStackStyle = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center'
}));
const BackButtonStyle = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: '30px',
  left: '30px',
  zIndex: 10,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const DrawerStyle = styled(Box)(({ theme }) => ({
  background: 'transparent!important',
  flexDirection: 'row!important',
  boxShadow: 'none!important'
}));
const VIDEO_LOOPS = `${process.env.PUBLIC_URL}/static/building/videos/Loop.mp4`;
const SelectBuilding = ({ editProject, ...props }) => {
  const navigate = useNavigate();

  const { specifications, onUpdateEditProject } = useClientEditProject();
  const [displaySpecs, setDisplaySpecs] = useState({
    url: VIDEO_LOOPS,
    open: false,
    buildingTypeSelected: null
  });

  const onSelectBuildingType = useCallback(
    (pathToVideo, bType) => {
      setDisplaySpecs({ url: pathToVideo, open: true, buildingTypeSelected: bType });
    },
    [displaySpecs]
  );
  const onSelectSpec = useCallback(() => {
    const newProject = { ...editProject };
    const typeBuilding = displaySpecs.buildingTypeSelected;
    newProject.building = { type: { ...typeBuilding } };
    /* use case vehicule */
    if (typeBuilding.code === 10) {
      const pbVehicule = specifications.filter((it) => it.code >= 10);
      newProject.problematics = [...pbVehicule];
      onUpdateEditProject(newProject);
      navigate(NAV_URL_COM.custom);
    } else {
      onUpdateEditProject(newProject);
      navigate(NAV_URL_COM.specifications);
    }
  }, [displaySpecs]);

  const returnToSelectBuilding = useCallback(() => {
    const dSpecs = { ...displaySpecs };
    dSpecs.open = false;
    dSpecs.url = VIDEO_LOOPS;
    setDisplaySpecs(dSpecs);
  }, [displaySpecs]);

  const handleBufferEnd = useCallback((e) => {}, []);
  return useMemo(() => {
    if (!editProject) return <></>;
    return (
      <>
        <FullScalableVideo
          url={VIDEO_LOOPS}
          handleBufferEnd={handleBufferEnd}
          isLooped={Boolean(true)}
        />

        {displaySpecs.open ? (
          <>
            <BuildingSpecs
              displaySpecs={displaySpecs}
              onSelectSpec={onSelectSpec}
              onSelectBuildingType={onSelectBuildingType}
              returnToSelectBuilding={returnToSelectBuilding}
            />
          </>
        ) : (
          <BuildingTypes onSelectBuildingType={onSelectBuildingType} />
        )}
      </>
    );
  }, [displaySpecs, editProject]);
};
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
const BuildingTypes = ({ onSelectBuildingType, ...props }) => {
  const { height, width } = useWindowDimensions();
  const { buildingTypes } = useSelector((state) => state.referential);
  const theme = useTheme();
  const [showLoopVid, setShowLoopVid] = useState(false);
  const viewSpecsByBuildingType = (bType) => {
    onSelectBuildingType(bType.video, bType);
  };
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoopVid(true);
    }, 20);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const matchesIpadPortrait = useMediaQuery(
    '(width: 1536px) and (height: 2048px) and (orientation:portrait)'
  );
  const matchesIpadLandscape = useMediaQuery(
    '(width: 2048px) and (height: 1536px) and (orientation:landscape)'
  );
  const matchesSurfacePortrait = useMediaQuery(
    '(width: 1824px) and (height: 2736px) and (orientation:portrait)'
  );
  const matchesSurfaceLandscape = useMediaQuery(
    '(width: 2736px) and (height: 1824px) and (orientation:landscape)'
  );
  const getDim = (pos, dim) => {
    try {
      const dimReturned =
        pos === 'left' ? `${Math.floor(width * dim)}px` : `${Math.floor(height * dim)}px`;
      return dimReturned;
    } catch (error) {
      console.error(error);
      return dim;
    }
  };
  function generateLightColorHex() {
    let color = '#';
    for (let i = 0; i < 3; i++)
      color += ('0' + Math.floor(((1 + Math.random()) * Math.pow(16, 2)) / 2).toString(16)).slice(
        -2
      );
    return color;
  }
  return useMemo(() => {
    if (!buildingTypes || buildingTypes.length === 0) return <></>;
    const buidingDisplay = buildingTypes.filter((it) => it.code <= 10);
    return (
      <>
        {!showLoopVid && (
          <BoxProgrestStyle>
            <CircularProgress size={60} sx={{ color: '#668AF5' }} />
          </BoxProgrestStyle>
        )}
        <Fade in={showLoopVid}>
          <BuildingTypesStackStyle>
            <TransitionGroup>
              {buidingDisplay.map((it, i) => {
                const { code, picto, label } = it;
                let style = {};
                let colorBg = '#668AF5';
                let IconCompo = <img src={picto} alt={label} />;
                if (code === 1) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.08),
                    top: getDim('top', 0.5)
                  };
                  IconCompo = <img src={picto} alt={label} />;
                  colorBg = generateLightColorHex();
                } else if (code === 2) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.28),
                    top: getDim('top', 0.3)
                  };
                  IconCompo = AddBusinessIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 3) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.6),
                    top: getDim('top', 0.6)
                  };
                  IconCompo = SchoolIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 4) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.65),
                    top: getDim('top', 0.3)
                  };
                  IconCompo = LocalHospitalIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 5) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.15),
                    top: getDim('top', 0.3)
                  };
                  IconCompo = LocationCityIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 6) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.9),
                    top: getDim('top', 0.55)
                  };
                  IconCompo = SportsSoccerIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 7) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.58),
                    top: getDim('top', 0.2)
                  };
                  IconCompo = AccountBalanceIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 8) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.4),
                    top: getDim('top', 0.58)
                  };
                  IconCompo = CameraIndoorIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 9) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.8),
                    top: getDim('top', 0.3)
                  };
                  IconCompo = MuseumIcon;
                  colorBg = generateLightColorHex();
                } else if (code === 10) {
                  style = {
                    position: 'absolute',
                    left: getDim('left', 0.45),
                    top: getDim('top', 0.3)
                  };

                  IconCompo = DirectionsCarFilledIcon;
                  colorBg = generateLightColorHex();
                }
                return (
                  <BuildingTypeBtn
                    key={i}
                    colorBg={colorBg}
                    item={it}
                    style={style}
                    icon={picto}
                    viewSpecsByBuildingType={() => viewSpecsByBuildingType(it)}
                  />
                );
              })}
            </TransitionGroup>
          </BuildingTypesStackStyle>
        </Fade>
      </>
    );
  }, [showLoopVid, buildingTypes]);
};
const CircleBox = styled(Box)(({ theme }) => ({
  position: 'relative',
  margin: theme.spacing(4, 4, 0, 0),
  width: '700px',
  height: '700px',

  zIndex: 1,
  '& .tick ': {
    position: 'absolute',
    left: '50%',
    top: '50%',
    zIndex: 20
  }
}));

const BuildingSpecs = ({
  displaySpecs,
  onSelectBuildingType,
  returnToSelectBuilding,
  onSelectSpec,
  ...props
}) => {
  const theme = useTheme();
  const [fadeVidInvertor, setFadeVidInvertor] = useState(true);
  const [currentUrl, setCurrentUrl] = useState(null);
  const [showSpecs, setShowSpecs] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [displayDrawerSpec, setDisplayDrawerSpec] = useState(null);
  // const { specifications } = useSelector((state) => state.referential);
  const specifications = displaySpecs.buildingTypeSelected.problematics.slice(0, 3);
  const viewSpecsDetail = (s) => {
    if (!openDrawer) {
      setOpenDrawer(true);
    }
    setDisplayDrawerSpec(s);
  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setDisplayDrawerSpec(null);
  };
  /*   useEffect(() => {
    if (!displaySpecs) return;
    const timeout = setTimeout(() => {
      if (!openDrawer) {
        setOpenDrawer(true);
      }
      setDisplayDrawerSpec(specifications[0]);
    }, 4000);
    return () => {
      clearTimeout(timeout);
    };
  }, []); */

  const onChangeSpec = useCallback(
    (pos) => {
      if (!specifications || !displayDrawerSpec) return;
      const currentIdx = specifications.findIndex((it) => {
        return displayDrawerSpec.code === it.code;
      });
      if (currentIdx < 0) return;
      if (currentIdx === specifications.length - 1 && pos > 0) {
        setDisplayDrawerSpec(specifications[0]);
      } else if (currentIdx === 0 && pos < 0) {
        setDisplayDrawerSpec(specifications[specifications.length - 1]);
      } else {
        setDisplayDrawerSpec(specifications[currentIdx + pos]);
      }
    },
    [displayDrawerSpec]
  );
  const handleEndedVideo = useCallback(() => {
    setShowSpecs(true);
  }, []);
  const handleReturn = () => {
    setCurrentUrl(displaySpecs.buildingTypeSelected.videoInvert);
    setTimeout(() => {
      setFadeVidInvertor(false);
    }, 3000);
  };
  return useMemo(() => {
    const degreNumber =
      specifications && specifications ? Math.floor(360 / specifications.length) : 0;
    const degreArr = [];
    for (let i = 0; i < specifications.length; i++) {
      degreArr[i] = degreNumber * i;
    }
    const fixPos = [200, 128, 300];
    return (
      <Fade in={Boolean(fadeVidInvertor)} timeout={{ enter: 10, exit: 1600 }}>
        <div>
          <FullScalableVideo
            url={displaySpecs.url}
            isLooped={false}
            handleEndedVideo={handleEndedVideo}
          />

          {currentUrl && (
            <div>
              <FullScalableVideo
                url={currentUrl}
                isLooped={Boolean(false)}
                handleEndedVideo={returnToSelectBuilding}
              />
            </div>
          )}
          <Fade in={showSpecs}>
            <BuildingTypesStackStyle>
              {specifications && (
                <CircleBox>
                  {specifications.map((it, i) => {
                    return (
                      <Box
                        className="tick"
                        key={Math.random()}
                        style={{
                          transform: `rotate(${
                            i <= 2 ? fixPos[i] : degreArr[i]
                          }deg) translate(250px)`
                        }}
                      >
                        <SpecButton
                          activ={Boolean(displayDrawerSpec && displayDrawerSpec.code === it.code)}
                          item={it}
                          handleOnClickSpec={() => {
                            viewSpecsDetail(it);
                          }}
                        />
                      </Box>
                    );
                  })}
                </CircleBox>
              )}
            </BuildingTypesStackStyle>
          </Fade>

          <BackButtonStyle onClick={handleReturn} size="small">
            <BackIcon />
          </BackButtonStyle>
          <Drawer
            anchor="right"
            open={openDrawer}
            onClose={onCloseDrawer}
            ModalProps={{
              keepMounted: true
            }}
            PaperProps={{ component: DrawerStyle }}
            hideBackdrop
          >
            <DrawerSpecDetail
              bgImg={displaySpecs.buildingTypeSelected.photo}
              spec={displayDrawerSpec}
              onCloseDrawer={onCloseDrawer}
              onChangeSpec={onChangeSpec}
              onSelectSpec={onSelectSpec}
            />
          </Drawer>
        </div>
      </Fade>
    );
  }, [fadeVidInvertor, currentUrl, showSpecs, displayDrawerSpec, specifications]);
};
