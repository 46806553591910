import React from 'react';
import { Outlet, useOutletContext, useLocation, Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import TopNavbar from 'layouts/vertical/TopNavbar';
import TechTopBar from './TechTopBar';
import TechEditMeasureRight from './TechEditMeasureRight';
import DoneMeasureButton from './glazing/DoneMeasureButton';
import SaveMeasureButton from './glazing/SaveMeasureButton';

// ----------------------------------------------------------------------

export default function TechEditMeasure({ ...props }) {
  const theme = useTheme();
  const { project } = useOutletContext();
  const location = useLocation();
  const isActive = (path) => {
    try {
      if (!location) return;
      const pathArr = location.pathname.split('/');
      const lastIdx = pathArr[pathArr.length - 1];
      return path === PATH_TECH.measureBuilding && lastIdx === PATH_TECH.measure;
    } catch (error) {
      return false;
    }
  };
  const MENU = [
    {
      label: 'Bâtiment',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.measure}/${PATH_TECH.measureBuilding}`,
      defActiv: isActive(PATH_TECH.measureBuilding)
    },
    {
      label: 'Vitrage',
      path: `${PATH_TECH.editProject}/${project.code}/${PATH_TECH.measure}/${PATH_TECH.measureGlazing}`
    }
  ];
  return (
    <>
      {project && (
        <>
          <TopNavbar>
            <TechTopBar menuCenter={MENU}>
              <SaveMeasureButton
                component={RouterLink}
                to={`${PATH_TECH.editProject}/${project.code}/${PATH_TECH.order}`}
              />
              <DoneMeasureButton
                component={RouterLink}
                to={`${PATH_TECH.editProject}/${project.code}/${PATH_TECH.order}`}
              />
            </TechTopBar>
          </TopNavbar>

          <Outlet context={{ project }} />
          <TechEditMeasureRight />
        </>
      )}
    </>
  );
}
