import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import InputAdornment from '@mui/material/InputAdornment';

import { useTheme, styled, alpha } from '@mui/material/styles';
import useTechEditProject from 'hooks/useTechEditProject';
// ----------------------------------------------------------------------
const InfoStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));
const InfoLeftStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column'
}));
const InfoRightStyle = styled(Box)(({ theme }) => ({
  width: '389px',
  height: '199px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  background: '#FFFFFF',
  border: '1px solid #95A2AD',
  boxSizing: 'border-box',
  borderRadius: '10px',
  padding: theme.spacing(3)
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0)
}));
const NumberInputField = styled(TextField)(({ width, theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: width ? width : '120px',
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: '16px',
    padding: '5px 12px 5px 12px',
    margin: theme.spacing(0, 2, 0, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

export default function EstimateInfos({ project, ...props }) {
  const theme = useTheme();
  const { orderBill } = useTechEditProject();
  const [estimateDate, setEstimateDate] = React.useState(new Date());
  const [validDate, setValidDate] = useState(new Date());
  const [beginDate, setBeginDate] = useState(new Date());
  const [estimateDays, setEstimateDays] = useState(0);
  const handleChange = (newValue) => {
    setEstimateDate(newValue);
  };
  useEffect(() => {
    if (!orderBill) return;
    if (!orderBill.expires_at) return;
    setValidDate(orderBill.expires_at);
  }, [orderBill]);

  if (!orderBill) return <></>;
  const client = orderBill && orderBill.customer ? orderBill.customer : null;
  return (
    <>
      <InfoStyle>
        <img
          src={`${process.env.PUBLIC_URL}/static/illustrations/Logo_JPS.svg`}
          alt="JPS France"
          width="230"
        />
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          sx={{ margin: theme.spacing(3, 0, 3, 0) }}
        >
          <InfoLeftStyle>
            <Typography variant="h5">DEVIS N°: {orderBill.reference}</Typography>
            <Box display="flex" alignItems="center" sx={{ margin: theme.spacing(1, 0, 1, 0) }}>
              <LabelStyle>En date du:</LabelStyle>
              <MobileDatePicker
                inputFormat="dd/MM/yyyy"
                value={estimateDate}
                onChange={(val) => setEstimateDate(val)}
                renderInput={(params) => <NumberInputField {...params} />}
              />
            </Box>
            <Box display="flex" alignItems="center" sx={{ margin: theme.spacing(1, 0, 1, 0) }}>
              <LabelStyle>Valabe jusqu'au:</LabelStyle>
              <MobileDatePicker
                inputFormat="dd/MM/yyyy"
                value={validDate}
                onChange={(val) => setValidDate(val)}
                renderInput={(params) => <NumberInputField {...params} />}
              />
            </Box>
            <Box display="flex" alignItems="center" sx={{ margin: theme.spacing(1, 0, 1, 0) }}>
              <LabelStyle>Début des travaux le:</LabelStyle>
              <MobileDatePicker
                inputFormat="dd/MM/yyyy"
                value={beginDate}
                onChange={(val) => setBeginDate(val)}
                renderInput={(params) => <NumberInputField {...params} />}
              />
            </Box>
            <Box display="flex" alignItems="center" sx={{ margin: theme.spacing(1, 0, 1, 0) }}>
              <LabelStyle>Durée estimée:</LabelStyle>
              <NumberInputField
                width="30px"
                type="number"
                InputProps={{
                  inputMode: 'numeric',
                  pattern: '[0-9]*',
                  endAdornment: <InputAdornment position="end">jour(s)</InputAdornment>
                }}
                onChange={(val) => setEstimateDays(val)}
              />
            </Box>
          </InfoLeftStyle>
          {client && (
            <InfoRightStyle>
              <Typography sx={{ textAlign: 'left', width: '100%' }} variant="h5">
                {client.name}
              </Typography>
              <LabelStyle style={{ textAlign: 'left', width: '100%' }}>
                {client.address1}
              </LabelStyle>
              {client.address2 && (
                <LabelStyle style={{ textAlign: 'left', width: '100%' }}>
                  {client.address2}
                </LabelStyle>
              )}
              <LabelStyle style={{ textAlign: 'left', width: '100%' }}>
                {client.postcode}
              </LabelStyle>
              <LabelStyle style={{ textAlign: 'left', width: '100%' }}>{client.city}</LabelStyle>
              {client.Country && (
                <LabelStyle style={{ textAlign: 'left', width: '100%' }}>
                  {client.Country}
                </LabelStyle>
              )}
            </InfoRightStyle>
          )}
        </Box>
      </InfoStyle>
    </>
  );
}
