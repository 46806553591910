import React, { useContext, useState, useEffect, useRef, useMemo } from 'react';
import { NavLink as RouterLink, useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import GradientBtn from 'components/custom/GradientBtn';
import CellPhoneSound from 'assets/sound/sound_cell_phone.mp3';
import { NAV_URL_COM } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';
import useSound from 'use-sound';

// ----------------------------------------------------------------------
const BotTextFieldStyle = styled(Box)(({ theme }) => ({
  maxHeight: '258px',
  background: '#668AF5',
  borderRadius: '50px 50px 0px 50px',
  display: 'flex',
  justifyContent: 'flex-start',
  padding: theme.spacing(4)
}));
const UserTextFieldStyle = styled(Box)(({ theme }) => ({
  width: '333px',
  height: 'auto',
  background: '#FFFFFF',
  borderRadius: ' 50px 50px 50px 0px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: theme.spacing(4),
  margin: theme.spacing(3, 0, 0, 0)
}));
export default function StepsForm({ steps, handleValidSteps, urlToBuilding, ...props }) {
  const navigate = useNavigate();
  const [play] = useSound(CellPhoneSound);

  const theme = useTheme();
  const { handleSendingConfig } = useProjectUnity();
  useEffect(() => {
    try {
      handleSendingConfig({
        objectName: 'Game Controller',
        methodName: 'SetAnim',
        parameters: 'Parle'
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  const ValidationLine = (s) => {
    return (
      <>
        <BotTextFieldStyle sx={{ margin: theme.spacing(3, 0, 3, 0) }}>
          <Typography
            sx={{
              fontFamily: 'Proxima',
              fontSize: '24px',
              color: 'white',
              fontWeight: 400,
              lineHeight: '38px',
              textAlign: 'justify'
            }}
          >
            <AnimatedText text={s.text} latence="20" anim="Felicite" />
          </Typography>
        </BotTextFieldStyle>
        {s.textValidBtn ? (
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            component={RouterLink}
            onClick={() => play()}
            to={urlToBuilding}
          >
            <Typography
              variant="body1"
              sx={{
                color: 'white',
                fontWeight: 400,
                lineHeight: '38px',
                textAlign: 'justify'
              }}
            >
              {s.textValidBtn}
            </Typography>
          </GradientBtn>
        ) : (
          <GradientBtn size="large" color="info" variant="contained" onClick={handleValidSteps}>
            Valider
          </GradientBtn>
        )}
      </>
    );
  };

  return useMemo(() => {
    if (!steps) return <></>;
    return (
      <>
        {steps.map((s, i) => {
          //if (i > 0 && s.state !== 'validate') return <React.Fragment key={Math.random()} />;
          return (
            <React.Fragment key={Math.random()}>
              {s.id === 'last' ? (
                <>{ValidationLine(s)}</>
              ) : (
                <>
                  {((s.isCurrent === false && s.isValidate === true) || s.isCurrent === true) && (
                    <BotTextFieldStyle sx={{ margin: i > 0 ? theme.spacing(3, 0, 0, 0) : '' }}>
                      <Typography
                        sx={{
                          fontFamily: 'Proxima',
                          fontSize: '24px',
                          color: 'white',
                          fontWeight: 400,
                          lineHeight: '38px',
                          textAlign: 'justify'
                        }}
                      >
                        {s.isCurrent === false && s.isValidate === true ? (
                          <div dangerouslySetInnerHTML={{ __html: s.bot.text }} />
                        ) : (
                          <AnimatedText
                            text={s.bot.text}
                            latence="20"
                            anim="Parle"
                            nbActionRepeat={s.id === 'contactName' ? 2 : 1}
                          />
                        )}
                      </Typography>
                    </BotTextFieldStyle>
                  )}
                  {s.isValidate === true && (
                    <UserTextFieldStyle>
                      <Typography
                        sx={{
                          fontFamily: 'Proxima',
                          fontSize: '24px',
                          fontWeight: 400,
                          lineHeight: '38px',
                          textAlign: 'justify'
                        }}
                      >
                        {s.user.text}
                      </Typography>
                    </UserTextFieldStyle>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </>
    );
  }, [steps]);
}
const AnimatedText = ({ text, latence, anim, nbActionRepeat, ...props }) => {
  const [placeholder, setPlaceholder] = useState('');
  const index = useRef(0);
  const repeat = useRef(0);
  const { sceneLoaded, handleSendingConfig } = useProjectUnity();
  const [timeToRepeat, setTimeToRepeat] = useState(1);

  useEffect(() => {
    if (!sceneLoaded) return;
    function tick() {
      setTimeToRepeat((prev) => prev + repeat.current);
      repeat.current += 1;
    }
    const stopAfter = nbActionRepeat || 1;
    if (repeat.current <= stopAfter) {
      handleSendingConfig({
        objectName: 'Game Controller',
        methodName: 'SetAnim',
        parameters: anim
      });
      const repeatInterval = setInterval(tick, 1000);
      return () => clearInterval(repeatInterval);
    }
  }, [timeToRepeat, sceneLoaded]);
  useEffect(() => {
    function tick() {
      setPlaceholder((prev) => prev + text[index.current]);
      index.current += 1;
    }
    if (index.current < text.length) {
      const addChar = setInterval(tick, latence);
      return () => clearInterval(addChar);
    }
  }, [placeholder]);
  return <div dangerouslySetInnerHTML={{ __html: placeholder }} />;
};
