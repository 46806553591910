import React from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
import useProjectUnity from 'hooks/useProjectUnity';
// components
import Page from 'components/Page';
import ProjectPage from 'pages/project/ProjectPage';
import MenuClip from 'layouts/MenuClip';
import BotProjectForm from './BotProjectForm';
// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',
  height: `calc(100vh - ${APP_BAR_MOBILE * 2.8}px)`,
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP * 2.2}px)`
  },
  zIndex: 1,
  '@media (max-height: 960px)': {
    overflow: 'auto'
  }
}));

const BgCloudStyle = styled('div')(({ bgcolor, theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 0,
  background: bgcolor ? `${bgcolor}` : '',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: bgcolor ? 'none' : `url(${process.env.PUBLIC_URL}/static/bg/bg_cloud.svg)`,
  backgroundSize: 'contain'
}));

export default function AddProject({ ...props }) {
  const { bgStyle } = useProjectUnity();
  return (
    <RootStyle title="Projets | JPS France">
      <BgCloudStyle bgcolor={bgStyle ? bgStyle.background : null} />
      {/* <BotProjectForm /> */}
      <MenuClip />
      <Outlet />
    </RootStyle>
  );
}
