import React, { useContext, useMemo, useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';

import { EditProjectContext } from 'provider/EditProjectProvider';
import { BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import StepsForm from './StepsForm';
import StepEntryForm from './StepEntryForm';
import ProjectFlowForm from './flow/ProjectFlowForm';
import ProjectValidated from './flow/ProjectValidated';
// ----------------------------------------------------------------------

const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot.svg)`,
  backgroundSize: 'contain'
}));
const FormBoxStyle = styled(Box)(({ theme }) => ({
  width: '650px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 200}px`,
  right: `${BOT_WIDTH - 150}px`
}));
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function BotProjectForm({ ...props }) {
  const { projectValidated } = useContext(EditProjectContext);
  return useMemo(() => {
    // The rest of your rendering logic
    return (
      <>
        {projectValidated ? <ProjectValidated project={projectValidated} /> : <EditProjectForm />}
      </>
    );
  }, [projectValidated]);
}

const EditProjectForm = ({ ...props }) => {
  const { editProject } = useContext(EditProjectContext);

  return useMemo(() => {
    // The rest of your rendering logic
    return (
      <>
        {editProject && editProject.name ? (
          <ProjectFlowForm project={editProject} />
        ) : (
          <ProjectSteps />
        )}
      </>
    );
  }, [editProject]);
};

const ProjectSteps = ({ ...props }) => {
  const { steps, getCurrentStep } = useContext(EditProjectContext);
  const currentStep = getCurrentStep();
  const [showBotForm, setShowBotForm] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowBotForm(true);
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {!showBotForm && (
        <BoxProgrestStyle>
          <CircularProgress size={60} sx={{ color: '#668AF5' }} />
        </BoxProgrestStyle>
      )}
      <Fade in={showBotForm}>
        <div>
          <BgBotStyle />
          <FormBoxStyle>
            <StepsForm />
            {currentStep && <StepEntryForm step={currentStep} />}
          </FormBoxStyle>
        </div>
      </Fade>
    </>
  );
};
