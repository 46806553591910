import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import Fade from '@mui/material/Fade';
import { useSelector } from 'react-redux';
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {
  SCOPE_INOUT,
  SCOPE_FLOORS,
  SCOPE_ROOMS,
  ROOFTYPES,
  GlAZINGTYPE,
  SURFACES,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP,
  BOT_WIDTH,
  BOT_HEIGHT
} from 'config/appConfig';
import { NAV_URL_COM } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';
import FullScalableVideo from 'components/player/FullScalableVideo';

export default function ScopeForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();
  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            {editProject.problematics ? (
              <SelectScope
                selectSpecifications={editProject.problematics}
                scope={editProject.scope}
                building={editProject.building}
              />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
                <GradientBtn
                  size="large"
                  color="info"
                  variant="contained"
                  component={RouterLink}
                  to={NAV_URL_COM.specifications}
                >
                  Veuillez sélectionner les problématiques du projet
                </GradientBtn>
              </Box>
            )}
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, [editProject]);
}

const RootStyle = styled(Box)(({ image, theme }) => {
  const img = image ? image : `${process.env.PUBLIC_URL}/static/bg/bg_scope.svg`;
  return {
    position: 'absolute',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    zIndex: 1,
    overflow: 'hidden',
    backgroundPosition: 'bottom',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img})`,
    backgroundSize: 'cover'
  };
});

const RightSideStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '366px',
  height: `100vh`,
  background: ' #F9F9F9',
  boxShadow: `0px 8px 25px rgba(0, 0, 0, 0.15)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingRight: '16px',
  paddingLeft: '16px'
}));
const TitleBoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '166px',
  background: `linear-gradient(68deg, #85A9E6 0.04%, #B0D9E2 99.19%, rgba(255, 255, 255, 0) 101.68%)`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 0, 0)
}));
const ContentBoxStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(360deg, #FFFFFF -2.96%, rgba(255, 255, 255, 0) 136.82%)`,
  backdropFilter: `blur(25px)`,
  borderRadius: '20px',
  marginTop: '63px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: theme.spacing(3, 0, 0, 0),
  overflow: 'auto'
}));
const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: '#FFFFFF'
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',

    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '& .MuiSvgIcon': {
      color: '#FFFFFF'
    }
  }
}));
const SelectScope = ({ building, selectSpecifications, scope, ...props }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const [selectedSpecId, setSelectedSpecId] = useState(selectSpecifications[0].id);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const [displayConfirm, setDisplayConfirm] = useState({ open: false, buildingType: null });
  const [currentUrl, setCurrentUrl] = useState(null);
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    setCurrentUrl(building.type.videoZoom);
  }, [building]);

  useEffect(() => {
    if (!selectedSpecId || !selectSpecifications) return;
    const specFounded = selectSpecifications.find((it) => {
      return it.id === selectedSpecId;
    });
    if (specFounded) {
      setSelectedSpec(specFounded);
    }
  }, [selectedSpecId]);
  const handleChange = (event) => {
    setSelectedSpecId(event.target.value);
  };
  const onDisplayConfirm = useCallback((configSpec, scopeTypo) => {
    /* setDisplayConfirm({ open: true, buildingType: 'pinkin', data: { configSpec, scopeTypo } }); */
    navigate(NAV_URL_COM.custom);
  }, []);
  const onConfirmScope = useCallback(() => {
    navigate(NAV_URL_COM.custom);
  }, [displayConfirm]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 10);
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <>
      {displayConfirm.open && <ConfirmScope onConfirmScope={onConfirmScope} />}

      <RootStyle image={building.type.background}>
        <Fade in={showPage} timeout={{ enter: 100, exit: 160 }}>
          <div>
            <FullScalableVideo
              url={currentUrl}
              isLooped={Boolean(false)}
              handleEndedVideo={() => {
                console.log();
              }}
            />
          </div>
        </Fade>
        <RightSideStyle>
          <TitleBoxStyle>
            {/* <Typography variant="h4" sx={{ color: '#FFFFFF' }}>
              Typologie
            </Typography> */}
          </TitleBoxStyle>
          <ContentBoxStyle>
            <h3 style={{ color: '#FFFFFF' }}>{building.type.label}</h3>
            {selectedSpec && (
              <ConfigScope
                onDisplayConfirm={onDisplayConfirm}
                spec={selectedSpec}
                selectSpecifications={selectSpecifications}
                scope={scope}
              />
            )}
          </ContentBoxStyle>
        </RightSideStyle>
      </RootStyle>
    </>
  );
};
const LabelStyle = styled(Typography)(({ selected, theme }) => ({
  textAlign: 'left',
  color: '#121E6E',
  width: '100%',
  margin: theme.spacing(3, 0, 2, 0)
}));
const BootstrapScopeInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '300px',
    borderRadius: '5px',
    position: 'relative',
    backgroundColor: 'transparent',
    boxSizing: 'border-box',
    border: '1px solid #B0B6C2',

    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));

const RootScopeStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0),
  padding: theme.spacing(0, 2, 0, 2),
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));
const TypoImgStyle = styled(Box)(({ image, theme }) => {
  const img =
    image && typeof image === 'string'
      ? image
      : `${process.env.PUBLIC_URL}/static/bg/bg_school.svg`;
  return {
    width: '320px',
    height: '180px',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundImage: `url(${img})`,
    backgroundSize: 'contain'
  };
});
const ConfigScope = ({ spec, onDisplayConfirm, selectSpecifications, scope, ...props }) => {
  const { editProject, onUpdateEditProject } = useClientEditProject();
  const [scopeTypo, setScopeTypo] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    if (!scope) return;
    setScopeTypo(scope);
  }, [scope]);
  useEffect(() => {
    if (!scopeTypo) return;
    if (selectSpecifications.length === Object.keys(scopeTypo).length) {
      onDisplayConfirm(scopeTypo);
    }
  }, [scopeTypo]);
  const onValidScopeByTypo = (val) => {
    if (!editProject) return;
    if (!editProject.problematics) return;
    if (!selectSpecifications) return;
    let newScope = { ...scopeTypo };
    if (val.id === 'all') {
      newScope = {}; //reinitialize
      selectSpecifications.forEach((it) => {
        if (it.id !== 'all') {
          newScope[it.id] = {
            spec: it,
            values: { ...val.data }
          };
        }
      });
    } else {
      const specFound = selectSpecifications.find((it) => it.id === val.id);
      newScope[val.id] = {
        spec: specFound,
        values: { ...val.data }
      };
    }
    const newProject = { ...editProject };
    const newPb = [];
    newProject.problematics.forEach((p) => {
      const newP = { ...p };
      Object.entries(newScope).forEach(([k, v]) => {
        if (p.id === k) {
          newP.scope = { ...val.data };
        }
      });
      newPb.push(newP);
    });
    newProject.problematics = newPb;
    onUpdateEditProject(newProject);
    setScopeTypo(newScope);
  };
  const onBackConfig = (c) => {
    navigate(NAV_URL_COM.typo);
  };
  return (
    <>
      <RootScopeStyle>
        {editProject && editProject.building && editProject.building.type && (
          <TypoImgStyle image={editProject.building.type.photo} />
        )}
        <ScopeTypo
          scopeTypo={scopeTypo}
          selectSpecifications={selectSpecifications}
          onBackConfig={onBackConfig}
          onValidScopeByTypo={onValidScopeByTypo}
        >
          <>
            {scopeTypo && Object.keys(scopeTypo).length > 0 && (
              <>
                <LabelStyle variant="body1">Problématique(s)</LabelStyle>
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="flex-start"
                  sx={{
                    width: '100%',
                    height: '200px',
                    overflowX: 'hidden',
                    overflowY: 'auto'
                  }}
                >
                  {Object.entries(scopeTypo).map(([k, v]) => {
                    const { spec, values } = v;
                    return (
                      <React.Fragment key={Math.random()}>
                        <Typography variant="subtitle1">{spec.label}</Typography>
                        {Object.entries(values).map(([key, value]) => {
                          try {
                            const { label } = value;

                            return (
                              <React.Fragment key={Math.random()}>
                                <Typography variant="caption">{label}</Typography>
                              </React.Fragment>
                            );
                          } catch (error) {
                            return <></>;
                          }
                        })}
                      </React.Fragment>
                    );
                  })}
                </Box>
              </>
            )}
          </>
        </ScopeTypo>
      </RootScopeStyle>
    </>
  );
};

const ScopeTypo = ({
  scopeTypo,
  selectSpecifications,
  onBackConfig,
  onValidScopeByTypo,
  ...props
}) => {
  const theme = useTheme();
  const [idSpecSelected, setIdSpecSelected] = useState({
    id: 'all',
    values: { inOut: 'ext', floors: 'all', rooms: 1 },
    data: {
      inOut: SCOPE_INOUT[0],
      floors: [
        {
          code: '4',
          label: '4ème étage'
        },
        {
          code: 5,
          label: '5ème étage'
        }
      ],
      rooms: SCOPE_ROOMS[0]
    }
  });

  const handleChangeOptSpec = (event) => {
    const newSpec = { ...idSpecSelected };
    newSpec.id = event.target.value;
    setIdSpecSelected(newSpec);
  };

  const updateScope = (key, value, opts) => {
    const newSpec = { ...idSpecSelected };
    newSpec.values[key] = value;
    if (opts) {
      newSpec.data[key] = opts.find((it) => {
        return it.code === value;
      });
    } else {
      newSpec.data[key] = value;
    }

    setIdSpecSelected(newSpec);
  };
  return useMemo(() => {
    return (
      <Box display="flex" flexDirection="column" sx={{ height: '70%' }}>
        <Box sx={{ flexGrow: 1 }}>
          <LabelStyle variant="body1">Périmètre d'intervention</LabelStyle>
          {/* specification */}
          <FormControl variant="standard">
            <Select
              fullWidth
              value={idSpecSelected.id}
              onChange={handleChangeOptSpec}
              input={<BootstrapScopeInput />}
            >
              <MenuItem value="all">Tous</MenuItem>
              {selectSpecifications.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.id}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {idSpecSelected && <ScopeTypoBySpec spec={idSpecSelected} updateScope={updateScope} />}
          {props.children}
        </Box>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          sx={{ margin: theme.spacing(3, 0, 0, 0), width: '100%' }}
        >
          {/* <Button size="large" color="warning" onClick={() => onBackConfig()}>
            <ArrowBackIcon />
          </Button> */}
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            sx={{ width: '161px' }}
            onClick={() => onValidScopeByTypo(idSpecSelected)}
          >
            Valider
          </GradientBtn>
        </Box>
      </Box>
    );
  }, [idSpecSelected, selectSpecifications, scopeTypo]);
};
const ScopeTypoBySpec = ({ spec, updateScope, ...props }) => {
  const theme = useTheme();
  const [floorsMulti, setfloorsMulti] = React.useState([]);
  const handleChange = (event) => {
    const {
      target: { value }
    } = event;
    setfloorsMulti(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  useEffect(() => {
    if (!floorsMulti) return;
    if (floorsMulti.length === 0) return;
    const newFloorsMulti = SCOPE_FLOORS.filter((it) => floorsMulti.includes(it.code));
    updateScope('floors', newFloorsMulti, null);
  }, [floorsMulti]);

  /*   useEffect(() => {
    if (!spec.values.floors) return;
    if (Array.isArray(spec.values.floors)) {
      setfloorsMulti(spec.values.floors);
    } else {
      setfloorsMulti([spec.values.floors]);
    }
  }, [spec]); */
  return (
    <>
      {/* inout */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          fullWidth
          value={spec.values.inOut}
          onChange={(e) => updateScope('inOut', e.target.value, SCOPE_INOUT)}
          input={<BootstrapScopeInput />}
        >
          {SCOPE_INOUT.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* floors */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          multiple
          fullWidth
          value={floorsMulti}
          onChange={(e) => handleChange(e)}
          input={<BootstrapScopeInput />}
          MenuProps={{
            PaperProps: {
              sx: {
                '& .MuiMenuItem-root.Mui-selected': {
                  backgroundColor: '#B0D9E2  '
                }
              }
            }
          }}
        >
          {SCOPE_FLOORS.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      {/* Rooms */}
      <FormControl variant="standard" sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
        <Select
          fullWidth
          value={spec.values.rooms}
          onChange={(e) => updateScope('rooms', e.target.value, SCOPE_ROOMS)}
          input={<BootstrapScopeInput />}
        >
          {SCOPE_ROOMS.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.code}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </>
  );
};

const ConfirmRootStyle = styled(Box)(({ theme }) => ({
  background: `linear-gradient(68deg, rgba(133, 169, 230, 0.8) -8.1%, rgba(176, 217, 226, 0.8) 47.48%, rgba(255, 255, 255, 0) 93.54%)`,
  backdropFilter: 'blur(12px)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  zIndex: 10
}));
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  margin: theme.spacing(0, -2, 0, 0),
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot_confirm.svg)`,
  backgroundSize: 'contain'
}));

const WrapperBoxStyle = styled('div')(({ theme }) => ({
  width: '630px',
  height: '480px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 250}px`,
  right: `${BOT_WIDTH + 50}px`
}));
const MsgBoxStyle = styled('div')(({ theme }) => ({
  width: '619px',
  height: 'auto',
  background: ' #668AF5',
  borderRadius: '50px 50px 0px 50px',
  padding: theme.spacing(3)
}));
const TextBoxStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '54px',
  color: '#FFFFFF'
}));

function ConfirmScope({ onConfirmScope, ...props }) {
  const { sceneLoaded, scene, handleUpdScene, handleSendingConfig } = useProjectUnity();
  const theme = useTheme();
  useEffect(() => {
    handleUpdScene({
      name: 'Mascotte',
      display: 'visible',
      style: { visibility: 'visible', zIndex: 20 },
      updTime: new Date().getTime()
    });
    const timeout = setTimeout(() => {
      handleSendingConfig({
        objectName: 'Game Controller',
        methodName: 'SetAnim',
        parameters: 'Pointe'
      });
    }, 1000);
    return () => {
      clearTimeout(timeout);
    };
  }, [sceneLoaded]);
  return (
    <ConfirmRootStyle>
      <WrapperBoxStyle>
        <MsgBoxStyle>
          <TextBoxStyle variant="h6">En tant que </TextBoxStyle>
          <TextBoxStyle variant="body1" sx={{ margin: theme.spacing(3, 0, 3, 0) }}>
            pinkinn
          </TextBoxStyle>

          <TextBoxStyle variant="body1">Puis je vous le proposer.</TextBoxStyle>
        </MsgBoxStyle>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-end"
          sx={{ width: '100%', margin: theme.spacing(4, 0, 0, 0) }}
        >
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            onClick={onConfirmScope}
            sx={{
              minWidth: '260px',
              margin: theme.spacing(2, 0, 0, 0),
              background: `linear-gradient(93.46deg, #95A2AD 37.45%, #C4C4C4 93.7%)!important`
            }}
          >
            Non, merci
          </GradientBtn>
        </Box>
      </WrapperBoxStyle>
    </ConfirmRootStyle>
  );
}
