import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';

import { useSelector } from 'react-redux';
import useClientEditProject from 'hooks/useClientEditProject';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import ViewInArIcon from '@mui/icons-material/ViewInAr';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP, BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import GradientBtn from 'components/custom/GradientBtn';
import ProductUnity from 'components/unity/ProductUnity';
import { NAV_URL_COM } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';

// ----------------------------------------------------------------------

export default function CustomForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();

  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            {editProject.problematics ? (
              <SelectProducts selectSpecifications={editProject.problematics} />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
                <GradientBtn
                  size="large"
                  color="info"
                  variant="contained"
                  component={RouterLink}
                  to={NAV_URL_COM.specifications}
                >
                  Veuillez sélectionner les problématiques du projet
                </GradientBtn>
              </Box>
            )}
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, []);
}
const FIX_WIDTH = 300;
const CenterSideStyle = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  left: '50%',
  marginLeft: `-${Math.floor(FIX_WIDTH / 2)}px`,
  width: `${FIX_WIDTH}px`,
  height: `${APP_BAR_DESKTOP}px`,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1200
}));
const ViewEDStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100px',
  height: '50px',
  display: 'flex',
  justifyContent: 'flex-start',
  alignItems: 'center',
  zIndex: 999999
}));
const VirButtonStyle = styled(IconButton)(({ activ, theme }) => {
  const color = activ ? 'error' : 'primary';
  return {
    boxShadow: '0px 8px 25px rgba(99, 142, 244, 0.46)',
    color: theme.palette[color].contrastText,
    background: `linear-gradient(93.46deg, ${theme.palette[color].main} 37.45%, ${theme.palette[color].light} 93.7%)`,
    '&:hover': {
      background: `linear-gradient(93.46deg, ${theme.palette[color].darker} 37.45%, ${theme.palette[color].dark} 93.7%)`
    }
  };
});
const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: '#000000'
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#000000',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',

    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '& .MuiSvgIcon': {
      color: '#FFFFFF'
    }
  }
}));
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 999999999999,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_cloud.svg)`,
  backgroundSize: 'contain'
}));
function SelectProducts({ selectSpecifications, ...props }) {
  const [showPage, setShowPage] = useState(false);
  const {
    enableZindex,
    setSceneVisibility,
    setBgColor,
    handleSendingConfig,
    sceneLoaded,
    scene,
    handleUpdScene,
    setEnableZindex
  } = useProjectUnity();

  const { editProject, onUpdateEditProject } = useClientEditProject();
  const theme = useTheme();
  const [sceneSelected, setSceneSelected] = useState(null);
  const VehicleScene = React.lazy(() => import('pages/client/scene/VehicleScene'));
  const DecorationScene = React.lazy(() => import('pages/client/scene/DecorationScene'));
  const PrivacyScene = React.lazy(() => import('pages/client/scene/PrivacyScene'));
  const SecurityScene = React.lazy(() => import('pages/client/scene/SecurityScene'));
  const SolarScene = React.lazy(() => import('pages/client/scene/SolarScene'));

  useEffect(() => {
    setBgColor({
      background: `linear-gradient(68deg, rgba(133, 169, 230, 0.8) 18.94%, rgba(176, 217, 226, 0.8) 74.51%, rgba(255, 255, 255, 0) 120.58%);`
    });
    return () => {
      setBgColor(null);
    };
  }, []);
  const handleChange = (event) => {
    try {
      const idSel = event.target.value;
      const spec = selectSpecifications.find((it) => {
        return it.id === idSel;
      });
      if (!spec) return;

      configProductScene(spec);
    } catch (error) {
      console.error(error);
    }
  };
  const getProductsBySpec = (sp) => {
    try {
      const arr = [];
      sp.category.forEach((cat) => {
        cat.products.forEach((p) => {
          const pExist = arr.findIndex((it) => it.code === p.code);
          if (pExist < 0) {
            arr.push(p);
          }
        });
      });
      return arr;
    } catch (error) {
      return [];
    }
  };
  const getSubCat = (sp) => {
    try {
      return sp.category[0].subcategories;
    } catch (error) {
      return [];
    }
  };
  const configProductScene = (speci) => {
    let spec = { ...speci };
    const specProducts = getProductsBySpec(spec);
    const subCat = getSubCat(spec);
    switch (spec.category[0].code) {
      case 1: //film solaire
        spec = { ...spec, scene: 'Interior_02' };

        setSceneSelected({
          spec,
          component: (
            <SolarScene spec={spec.id} onEventSending={onEventSending} products={specProducts} />
          )
        });
        break;
      case 2: //film secirity
        spec = { ...spec, scene: 'Interior_03' };
        setSceneSelected({
          spec,
          component: (
            <SecurityScene spec={spec.id} onEventSending={onEventSending} products={specProducts} />
          )
        });
        break;
      case 3: //film privacy
        spec = { ...spec, scene: 'Interior_01' };
        setSceneSelected({
          spec,
          component: (
            <PrivacyScene spec={spec.id} onEventSending={onEventSending} products={specProducts} />
          )
        });
        break;
      case 4: //film vehicule
        spec = { ...spec, scene: 'Interior_05' };
        setSceneSelected({
          spec,
          component: (
            <VehicleScene
              spec={spec.id}
              onEventSending={onEventSending}
              products={specProducts}
              subcategories={subCat}
            />
          )
        });

        break;
      case 5: //film deco
        spec = { ...spec, scene: 'Interior_04' };
        setSceneSelected({
          spec,
          component: (
            <DecorationScene
              spec={spec.id}
              onEventSending={onEventSending}
              products={specProducts}
            />
          )
        });
        break;
      default:
        break;
    }
  };
  useEffect(() => {
    if (!selectSpecifications) return;
    configProductScene(selectSpecifications[0]);
  }, [selectSpecifications]);
  useEffect(() => {
    if (!sceneSelected) return;
    if (!sceneSelected.spec.scene) return;

    handleUpdScene({
      name: sceneSelected.spec.scene,
      display: 'visible',
      style: {
        visibility: 'visible',
        zIndex: 0,
        width: '100%',
        height: `100vh`,
        borderRadius: 0,
        margin: 0
      },
      updTime: new Date().getTime()
    });

    return () => {
      handleUpdScene({
        ...scene,
        display: 'hidden',
        style: { visibility: 'hidden' },
        updTime: new Date().getTime()
      });
    };
  }, [sceneSelected]);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const onEventSending = useCallback((event) => {
    try {
      handleSendingConfig({ ...event });
    } catch (error) {
      console.error(error);
    }
  }, []);
  const ZINDEX_SCENE = 999;
  const handleChangeZindexScene = () => {
    if (enableZindex === ZINDEX_SCENE) {
      setEnableZindex(0);
    } else {
      setEnableZindex(ZINDEX_SCENE);
    }
  };
  return useMemo(() => {
    if (!selectSpecifications) return <></>;
    const idSel = sceneSelected ? sceneSelected.spec.id : null;
    return (
      <>
        {!showPage ? (
          <BoxProgrestStyle>
            <CircularProgress size={60} sx={{ color: '#668AF5' }} />
          </BoxProgrestStyle>
        ) : (
          <>
            {/* <TopGradientStyle /> */}
            {sceneLoaded && sceneSelected && (
              <>
                <FormControl
                  variant="standard"
                  sx={{ width: '100%', padding: theme.spacing(0, 2, 0, 2) }}
                >
                  {/* <ViewEDStyle>
                    <VirButtonStyle
                      onClick={() => handleChangeZindexScene()}
                      size="large"
                      activ={enableZindex > 0 ? true : false}
                    >
                      <ViewInArIcon />
                    </VirButtonStyle>
                  </ViewEDStyle> */}
                  <CenterSideStyle>
                    <Select
                      sx={{
                        /*  background: 'rgba(255,255,255,0.5)',
                        borderRadius: '8px', */
                        padding: theme.spacing(0, 3, 0, 3)
                      }}
                      labelId="demo-customized-select-label"
                      id="demo-customized-select"
                      value={idSel}
                      onChange={handleChange}
                      input={<BootstrapInput />}
                      IconComponent={KeyboardArrowDownIconStyle}
                    >
                      {selectSpecifications.map((opt) => {
                        return (
                          <MenuItem key={Math.random()} value={opt.id}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </CenterSideStyle>
                </FormControl>

                <React.Suspense fallback={<span>Loading...</span>}>
                  <>{sceneSelected ? sceneSelected.component : ''}</>
                </React.Suspense>
              </>
            )}
          </>
        )}
      </>
    );
  }, [enableZindex, showPage, selectSpecifications, sceneSelected, sceneLoaded]);
}
