import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useNavigate, NavLink as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import IconButton from '@mui/material/IconButton';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { useTheme, styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import { useSelector } from 'react-redux';
import useClientEditProject from 'hooks/useClientEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import { NAV_URL_COM } from 'config/paths';
import useProjectUnity from 'hooks/useProjectUnity';
import ContactAutoComplet from 'components/project/ContactAutoComplet';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
// ----------------------------------------------------------------------

export default function ContactForm({ ...props }) {
  const { editProject, steps } = useClientEditProject();
  const { setSceneVisibility, handleUpdScene } = useProjectUnity();
  useEffect(() => {
    setSceneVisibility(false);
    return () => {
      setSceneVisibility(true);
    };
  }, []);
  return useMemo(() => {
    return (
      <>
        {editProject && editProject.steps ? (
          <>
            {editProject.problematics && editProject.problematics ? (
              <ContactProject />
            ) : (
              <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
                <GradientBtn
                  size="large"
                  color="info"
                  variant="contained"
                  component={RouterLink}
                  to={NAV_URL_COM.botDialog}
                >
                  Veuillez initier le projet avec le druide
                </GradientBtn>
              </Box>
            )}
          </>
        ) : (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              component={RouterLink}
              to={NAV_URL_COM.botDialog}
            >
              Veuillez initier le projet avec le druide
            </GradientBtn>
          </Box>
        )}
      </>
    );
  }, [editProject]);
}

const RootStyle = styled(Box)(({ theme }) => ({
  zIndex: 1,
  width: '100%',
  height: 'auto',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

const CenterBoxStyle = styled(Box)(({ theme }) => ({
  width: '551px',
  height: '100%',
  background: `linear-gradient(360deg, #FFFFFF -2.96%, rgba(255, 255, 255, 0) 136.82%)`,
  backdropFilter: 'blur(25px)',
  borderRadius: '20px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  '& .title': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '44px',
    textTransform: 'uppercase',
    color: '#121E6E',
    textAlign: 'center',
    margin: theme.spacing(2, 0, 0, 0)
  },
  '& .inner-box': {
    margin: theme.spacing(1.5, 0, 1.5, 0),
    padding: theme.spacing(2, 3, 2, 3),
    width: '475px',
    background: '#FFFFFF',
    border: '1px solid #D6D6D6',
    boxSizing: 'border-box',
    borderRadius: '10px'
  },
  '@media (max-height: 960px)': {
    marginTop: `${APP_BAR_MOBILE + 250}px`
  }
}));

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#121E6E'
  }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'uppercase',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '48px',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  marginRight: theme.spacing(1),

  '&:hover': {
    color: '#3C60B5',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#3C60B5',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));
const Contact = ({ ...props }) => {
  return <>qsdg</>;
};

function ContactProject({ ...props }) {
  const { editProject, onUpdateEditProject, validateProject } = useClientEditProject();
  const { scene, setSceneVisibility, handleUpdScene } = useProjectUnity();

  const theme = useTheme();
  const [contact, setContact] = useState(null);
  const [currentTab, setCurrentTab] = useState(0);
  const [disableTechResp, setDisableTechResp] = useState(true);

  useEffect(() => {
    if (!contact || !contact.client) setDisableTechResp(true);
  }, [contact]);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };

  const onSubmitContact = useCallback(
    (values) => {
      if (values) {
        setCurrentTab(1);
        setContact({ client: { ...values, role: 'administrator' } });
      } else {
        setContact(null);
      }
    },
    [currentTab, contact]
  );
  const onSubmitTechResp = useCallback(
    (values) => {
      setCurrentTab(1);
      setContact({ client: { ...values } });
    },
    [currentTab, contact]
  );
  const onChangeTechResp = useCallback(
    (val) => {
      const newContact = { ...contact };
      newContact.isTechResp = val;
      if (val) {
        const { clientLastName, clientName, clientRank, email, phoneNumber, code } = contact.client;
        newContact.techResp = {
          clientLastName,
          clientName,
          clientRank,
          email,
          phoneNumber,
          code
        };
      } else {
        delete newContact.techResp;
      }
      setContact(newContact);
    },
    [currentTab, contact]
  );
  const onComplete = useCallback(
    async (values) => {
      try {
        const newContact = { ...contact };
        newContact.techResp = { ...values, role: 'technical' };
        setContact(newContact);
        setSceneVisibility(true);
        handleUpdScene({
          ...scene,
          name: 'Mascotte',
          updTime: new Date().getTime()
        });
        await new Promise((r) => setTimeout(r, 4000));
        const newProject = { ...editProject };
        newProject.contacts = [newContact.client, newContact.techResp];
        onUpdateEditProject(newProject);
        await validateProject(newProject);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    [currentTab, contact]
  );
  const onSelectRespTech = (values) => {
    const newContact = { ...contact };
    newContact.techResp = null;
    setContact(newContact);
  };
  return (
    <Box
      display="flex"
      flexDirection="cener"
      alignItems="center"
      sx={{ height: '100%', width: '100%' }}
    >
      <RootStyle>
        <CenterBoxStyle>
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{ position: 'relative', width: '100%' }}
          >
            <Typography className="title">Fiche Contact</Typography>
            {/*  <Box
              sx={{
                position: 'absolute',
                right: '30px',
                top: '5px',
                margin: theme.spacing(1, 0, 0, 0)
              }}
            >

            </Box> */}
          </Box>

          <Box className="inner-box">
            <AntTabs value={currentTab} onChange={handleChange} aria-label="Contact" centered>
              <AntTab label="Contact client" />
              <AntTab label="Responsable technique" disabled={disableTechResp} />
            </AntTabs>
            {currentTab === 0 && (
              <FormContact
                client={contact ? contact.client : null}
                onSubmitContact={onSubmitContact}
                handleChangeTab={() => setCurrentTab(1)}
              />
            )}
            {currentTab === 1 && (
              <FormTechResp
                techResp={contact ? contact.techResp : null}
                isTechResp={contact ? contact.isTechResp : false}
                onSubmitTechResp={onSubmitTechResp}
                onChangeTechResp={onChangeTechResp}
                onSelectRespTech={onSelectRespTech}
                onComplete={onComplete}
                contactClient={contact ? contact.client : null}
              />
            )}
          </Box>
        </CenterBoxStyle>
      </RootStyle>
    </Box>
  );
}
const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const FormContact = ({ onSubmitContact, client, handleChangeTab, ...props }) => {
  const theme = useTheme();
  const [disableEdit, setdisableEdit] = useState(false);
  const ContactSchema = Yup.object().shape({
    nameCompany: Yup.string().required('Veuillez renseigner le nom de la société'),
    clientLastName: Yup.string().required(
      `Veuillez renseigner le prénom de la personne à contacter`
    ),
    clientName: Yup.string().required(`Veuillez renseigner le nom de la personne à contacter`),
    email: Yup.string()
      .email(`L'adresse mail n'est pas valide`)
      .required(`Veuillez renseigner un email`)
  });
  useEffect(() => {
    if (client && client.code) {
      setdisableEdit(true);
    } else {
      setdisableEdit(false);
    }
  }, [client]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      nameCompany: client ? client.nameCompany : '',
      addrQuote: client ? client.addrQuote : '',
      addrProject: client ? client.addrProject : '',
      clientLastName: client ? client.clientLastName : '',
      clientName: client ? client.clientName : '',
      clientRank: client ? client.clientRank : '',
      email: client ? client.email : '',
      phoneNumber: client ? client.phoneNumber : ''
    },
    validationSchema: ContactSchema,
    onSubmit: async (values) => {
      try {
        onSubmitContact(values);
      } catch (error) {
        console.error(error);
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleSelectClient = (val) => {
    try {
      onSubmitContact({
        codeCompany: val.company.code,
        nameCompany: val.company.name,
        addrQuote: '',
        addrProject: '',
        clientLastName: val.lastname,
        clientName: val.firstname,
        clientRank: val.poste,
        email: val.email,
        phoneNumber: val.phone,
        code: val.code
      });
      setdisableEdit(true);
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveSelect = () => {
    onSubmitContact(null);
    setdisableEdit(false);
  };

  return (
    <FormikProvider value={formik}>
      <Form
        autoComplete="off"
        noValidate
        onSubmit={handleSubmit}
        style={{
          margin: theme.spacing(3, 0, 3, 0),
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
              disabled={disableEdit}
              sx={{
                input: { fontFamily: 'ProximaLight', color: '#95A2AD' },
                marginRight: theme.spacing(0.5)
              }}
              fullWidth
              type="text"
              label="Nom de la société"
              {...getFieldProps('nameCompany')}
              error={Boolean(touched.nameCompany && errors.nameCompany)}
              helperText={touched.nameCompany && errors.nameCompany}
            />
            {disableEdit ? (
              <IconButton onClick={handleRemoveSelect}>
                <HighlightOffIcon />
              </IconButton>
            ) : (
              <ContactAutoComplet
                label="Recherche le client dans l'annuaire"
                onSelect={handleSelectClient}
              />
            )}
          </Box>

          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Adresse du devis"
            {...getFieldProps('addrQuote')}
            error={Boolean(touched.addrQuote && errors.addrQuote)}
            helperText={touched.addrQuote && errors.addrQuote}
          />
          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Adresse du projet si différente"
            {...getFieldProps('addrProject')}
            error={Boolean(touched.addrProject && errors.addrProject)}
            helperText={touched.addrProject && errors.addrProject}
          />

          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Nom"
            {...getFieldProps('clientLastName')}
            error={Boolean(touched.clientLastName && errors.clientLastName)}
            helperText={touched.clientLastName && errors.clientLastName}
          />
          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Prénom"
            {...getFieldProps('clientName')}
            error={Boolean(touched.clientName && errors.clientName)}
            helperText={touched.clientName && errors.clientName}
          />
          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Poste"
            {...getFieldProps('clientRank')}
            error={Boolean(touched.clientRank && errors.clientRank)}
            helperText={touched.clientRank && errors.clientRank}
          />
          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="email"
            label="Adresse email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            disabled={disableEdit}
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            type="text"
            label="Numéro de téléphone"
            {...getFieldProps('phoneNumber')}
            error={Boolean(touched.phoneNumber && errors.phoneNumber)}
            helperText={touched.phoneNumber && errors.phoneNumber}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="center">
          {disableEdit ? (
            <GradientBtn
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
              onClick={handleChangeTab}
            >
              Valider
            </GradientBtn>
          ) : (
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
            >
              Valider
            </GradientBtn>
          )}
        </Stack>
      </Form>
    </FormikProvider>
  );
};

const FormTechResp = ({
  onSubmitTechResp,
  techResp,
  isTechResp,
  onChangeTechResp,
  onSelectRespTech,
  onComplete,
  contactClient,
  ...props
}) => {
  const [disableEdit, setdisableEdit] = useState(false);
  useEffect(() => {
    if (techResp && techResp.code) {
      setdisableEdit(true);
    } else {
      setdisableEdit(false);
    }
  }, [techResp]);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [isRunning, setisRunning] = useState(false);
  const theme = useTheme();
  const TechRespSchema = Yup.object().shape({
    clientLastName: Yup.string().required('Veuillez renseigner le prénom'),
    clientName: Yup.string().required(`Veuillez renseigner le nom famille`),
    email: Yup.string()
      .email(`L'adresse mail n'est pas valide`)
      .required(`Veuillez renseigner un email`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      clientLastName: techResp ? techResp.clientLastName : '',
      clientName: techResp ? techResp.clientName : '',
      clientRank: techResp ? techResp.clientRank : '',
      email: techResp ? techResp.email : '',
      phoneNumber: techResp ? techResp.phoneNumber : ''
      //code: techResp ? techResp.code : null
    },
    validationSchema: TechRespSchema,
    onSubmit: async (values) => {
      try {
        setisRunning(true);
        await onComplete(values);
        enqueueSnackbar('Projet est créé avec succès', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        navigate(NAV_URL_COM.projectCreated);
        setisRunning(false);
      } catch (error) {
        setisRunning(false);
        console.error(error);
        enqueueSnackbar('Une erreur est survenue. Veuillez réessayer', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const handleSelectRespTech = (val) => {
    try {
      setFieldValue('clientLastName', val.lastname);
      setFieldValue('clientName', val.firstname);
      setFieldValue('clientRank', val.poste);
      setFieldValue('email', val.email);
      setFieldValue('phoneNumber', val.phone);
      setFieldValue('code', val.code);
      setdisableEdit(true);
      onSelectRespTech({
        clientLastName: val.lastname,
        clientName: val.firstname,
        clientRank: val.post,
        email: val.email,
        phoneNumber: val.phone,
        code: val.code
      });
    } catch (error) {
      console.error(error);
    }
  };
  const handleRemoveSelect = () => {
    setFieldValue('clientLastName', '');
    setFieldValue('clientName', '');
    setFieldValue('clientRank', '');
    setFieldValue('email', '');
    setFieldValue('phoneNumber', '');
    setFieldValue('code', '');
    onSelectRespTech(null);
    setdisableEdit(false);
  };
  const handleCheck = (event) => {
    onChangeTechResp(event.target.checked);
  };
  const { errors, touched, values, setFieldValue, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="flex-start" sx={{ my: 2 }}>
        <FormControlLabel
          disabled={disableEdit}
          control={<Checkbox checked={isTechResp} onChange={handleCheck} />}
          label={
            <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
              Je suis responsable technique
            </Typography>
          }
        />
      </Stack>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            margin: theme.spacing(3, 0, 3, 0),
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Stack spacing={2}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <TextField
                disabled={disableEdit}
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                fullWidth
                type="text"
                label="Nom"
                {...getFieldProps('clientLastName')}
                error={Boolean(touched.clientLastName && errors.clientLastName)}
                helperText={touched.clientLastName && errors.clientLastName}
              />
              {disableEdit ? (
                <IconButton onClick={handleRemoveSelect}>
                  <HighlightOffIcon />
                </IconButton>
              ) : (
                <ContactAutoComplet
                  label="Recherche le responsable technique dans l'annuaire"
                  onSelect={handleSelectRespTech}
                  fixCompany={
                    contactClient && contactClient.codeCompany ? contactClient.codeCompany : null
                  }
                />
              )}
            </Box>

            <TextField
              disabled={disableEdit}
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="text"
              label="Prénom"
              {...getFieldProps('clientName')}
              error={Boolean(touched.clientName && errors.clientName)}
              helperText={touched.clientName && errors.clientName}
            />
            <TextField
              disabled={disableEdit}
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="text"
              label="Poste"
              {...getFieldProps('clientRank')}
              error={Boolean(touched.clientRank && errors.clientRank)}
              helperText={touched.clientRank && errors.clientRank}
            />
            <TextField
              disabled={disableEdit}
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="email"
              label="Adresse email"
              {...getFieldProps('email')}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
            />
            <TextField
              disabled={disableEdit}
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="text"
              label="Numéro de téléphone"
              {...getFieldProps('phoneNumber')}
              error={Boolean(touched.phoneNumber && errors.phoneNumber)}
              helperText={touched.phoneNumber && errors.phoneNumber}
            />
          </Stack>

          <Stack direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              disabled={isRunning}
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ position: 'relative', width: '211px', margin: theme.spacing(2, 0, 2, 0) }}
            >
              {isRunning && (
                <Box
                  sx={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    padding: theme.spacing(0, 0, 0, 2)
                  }}
                  display="flex"
                  alignItems="center"
                  justifyContent="flex-start"
                >
                  <CircularProgress sx={{ color: '#668AF5' }} />
                </Box>
              )}
              Finaliser
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
};
