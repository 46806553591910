import React, { useEffect } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled, useTheme } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import useTechEditProject from 'hooks/useTechEditProject';
import { TOP_BAR_TECHPROJECT_DESKTOP, APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
import Page from 'components/Page';
import ProjectProvider from 'provider/ProjectProvider';
import TechEditMenu from './TechEditMenu';

// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',
  height: `calc(100vh - ${APP_BAR_DESKTOP}px)`,
  overflowX: 'hidden',
  overflowY: 'auto',
  [theme.breakpoints.down('lg')]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP}px)`
  },
  zIndex: 1
}));

export default function TechEdit({ ...props }) {
  const theme = useTheme();
  const { id } = useParams();
  const { project, initProjectById } = useTechEditProject();
  useEffect(() => {
    if (!id) return;
    initProjectById(id);
  }, [id]);
  return (
    <ProjectProvider>
      <RootStyle title="Projets | JPS France">
        {project && (
          <>
            <TechEditMenu item={project} />{' '}
            <Box sx={{ margin: theme.spacing(3, 0, 0, 0) }}>
              <Outlet context={{ project }} />
            </Box>
          </>
        )}
      </RootStyle>
    </ProjectProvider>
  );
}
