import React, { useCallback, useContext, useMemo, useState, useEffect } from 'react';
import { darken, useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { keyframes } from '@mui/system';
import BackIcon from 'components/custom/icons/BackIcon';
import ClickAwayListener from '@mui/material/ClickAwayListener';
// ----------------------------------------------------------------------
const IconSvgtStyle = styled('img')(({ theme }) => ({
  width: '50px',
  color: '#FFFFFF',
  [theme.breakpoints.down('xxl')]: { width: '25px' }
}));

const MAX_FADE_TIME = 5;
const MIN_FADE_TIME = 1.5;
const fade = keyframes`
  from { opacity: 0.3; } 
  20% { opacity: 0.4; } 
  50% { opacity: 0.5; } 
  70% { opacity: 0.7; } 
  90% { opacity: 0.9; } 
`;
const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: '#000000',
    fontFamily: 'Proxima',
    fontSstyle: 'normal',
    fontWeight: '600',
    fontSize: '24px',
    lineHeight: '54px',
    boxShadow: theme.shadows[1]
  }
}));
const ColorButton = styled(IconButton)(({ colorBg, open, theme }) => {
  const rdTimeout = Math.random() * (MAX_FADE_TIME - MIN_FADE_TIME) + MIN_FADE_TIME;
  return {
    color: theme.palette.getContrastText(colorBg),
    backgroundColor: open ? darken(colorBg, 0.3) : colorBg,
    '&:hover': {
      backgroundColor: open ? darken(colorBg, 0.3) : colorBg
    },
    width: '100px',
    height: '100px',
    zIndex: 20,
    animation: open ? 'none' : `${fade} ${rdTimeout}s ease-out infinite alternate`,
    [theme.breakpoints.down('xxl')]: { width: '50px', height: '50px' }
  };
});

const BtnStyle = styled(Box)(({ theme }) => ({
  zIndex: 1,
  display: 'flex',
  justifyContent: 'center'
}));
export default function BuildingTypeBtn({
  colorBg,
  item,
  viewSpecsByBuildingType,
  icon,
  style,
  ...props
}) {
  const [open, setOpen] = React.useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    if (open) {
      viewSpecsByBuildingType();
    }
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <div>
        <LightTooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          title={item.label}
        >
          <ColorButton
            colorBg={colorBg}
            sx={{ ...style }}
            size="large"
            open={open}
            onClick={handleTooltipOpen}
          >
            <IconSvgtStyle src={icon} alt={item.label} />
          </ColorButton>
        </LightTooltip>
      </div>
    </ClickAwayListener>
  );
}
