import * as React from 'react';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { useSelector } from 'react-redux';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import CloseIcon from 'components/custom/icons/CloseIcon';
import GradientBtn from 'components/custom/GradientBtn';
import PrevIcon from 'components/custom/icons/PrevIcon';
import NextIcon from 'components/custom/icons/NextIcon';
// ----------------------------------------------------------------------

const DrawerContentStyle = styled(Box)(({ theme }) => ({
  width: '366px',
  height: '100%',
  background: '#FFFFFF',
  boxShadow: ' 0px 8px 25px rgba(0, 0, 0, 0.15)',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between'
}));
const BoxNextBtnStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  padding: theme.spacing(4, 5, 4, 5)
}));
const BoxImgStyle = styled(Box)(({ bgImg, theme }) => ({
  width: '320px',
  height: '200px',
  background: '#C4C4C4',
  borderRadius: '15px',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${bgImg})`,
  backgroundSize: 'cover',
  margin: theme.spacing(4, 0, 4, 0)
}));
const BoxContentStyle = styled(Box)(({ bgImg, theme }) => ({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

const BoxSliderStyle = styled(Box)(({ bgImg, theme }) => ({
  minHeight: '77px',
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  background: '#F9F9F9',
  boxShadow: '0px -10px 25px rgba(0, 0, 0, 0.15)'
}));

export default function DrawerSpecDetail({
  spec,
  bgImg,
  onCloseDrawer,
  onChangeSpec,
  onSelectSpec,
  ...props
}) {
  const theme = useTheme();
  const { specifications } = useSelector((state) => state.referential);

  if (!spec) return <></>;
  const getText = () => {
    try {
      if (!specifications) return;
      const founded = specifications.find((it) => String(it.code) === String(spec.code));

      return founded ? founded.text1 : '';
    } catch (error) {
      return '';
    }
  };
  const getTitle = () => {
    try {
      if (!specifications) return;
      const founded = specifications.find((it) => String(it.code) === String(spec.code));
      return founded ? founded.category[0].label : '';
    } catch (error) {
      return '';
    }
  };
  const getSlug = () => {
    try {
      if (!specifications) return;
      const founded = specifications.find((it) => String(it.code) === String(spec.code));
      return founded ? founded.titre : '';
    } catch (error) {
      return '';
    }
  };
  return (
    <>
      <Box display="flex" alignItems="center" sx={{ width: 'auto' }} justifyContent="center">
        <IconButton onClick={onCloseDrawer}>
          <CloseIcon />
        </IconButton>
      </Box>

      <DrawerContentStyle>
        <BoxNextBtnStyle>
          <GradientBtn
            size="large"
            fullWidth
            color="info"
            variant="contained"
            onClick={onSelectSpec}
          >
            Suivant
          </GradientBtn>
        </BoxNextBtnStyle>
        {spec && (
          <BoxContentStyle>
            <BoxImgStyle bgImg={spec.photo} />
            <Typography
              sx={{
                width: '100%',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
                fontFamily: 'Proxima',
                color: '#3C60B5',
                letterSpacing: '0.1em',
                padding: theme.spacing(0, 3, 0, 3),
                textTransform: 'uppercase'
              }}
            >
              {getTitle()}
            </Typography>
            <Typography
              sx={{
                width: '100%',
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '36px',
                lineHeight: '44px',
                padding: theme.spacing(0, 3, 0, 3),
                fontFamily: 'Proxima',
                margin: theme.spacing(6, 0, 6, 0)
              }}
            >
              {getSlug()}
            </Typography>
            <Typography
              sx={{
                fontStyle: 'normal',
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '24px',
                padding: theme.spacing(0, 3, 0, 3),
                fontFamily: 'Proxima'
              }}
            >
              {getText()}
            </Typography>
          </BoxContentStyle>
        )}
        <BoxSliderStyle>
          <IconButton onClick={() => onChangeSpec(-1)}>
            <PrevIcon />
          </IconButton>

          <Typography
            align="center"
            sx={{
              fontStyle: 'normal',
              fontWeight: '600',
              fontSize: '18px',
              lineHeight: '22px',
              fontFamily: 'Proxima'
            }}
          >
            {spec.label}
          </Typography>
          <IconButton onClick={() => onChangeSpec(1)}>
            <NextIcon />
          </IconButton>
        </BoxSliderStyle>
      </DrawerContentStyle>
    </>
  );
}
