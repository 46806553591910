import { useState } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';

//


// ----------------------------------------------------------------------
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background:
    'linear-gradient(68deg, #85A9E6 0.04%, #B0D9E2 55.61%, rgba(255, 255, 255, 0) 101.68%)'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_DESKTOP,
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

export default function VerticalLayout({ bg, ...props }) {
  return (
    <RootStyle>
       <MainStyle>
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
}
