import React, { useEffect, useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Popover from '@mui/material/Popover';

import IconButton from '@mui/material/IconButton';
import EditIcon from '@mui/icons-material/Edit';
import DoneIcon from '@mui/icons-material/Done';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
// ----------------------------------------------------------------------
const CellBoxStyle = styled(Box)(({ theme }) => ({
  minWidth: '80px',
  height: '25px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export default function EditCellNumber({ item, fiedToEdit, fieldLabel, handeSave, ...props }) {
  const theme = useTheme();
  const [value, setvalue] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    if (!item) return;
    setvalue(item[fiedToEdit]);
  }, [item]);
  const CellNumberSchema = Yup.object().shape({
    cellNumber: Yup.string()
      //.matches(/^[-+]?[0-9]?\.[0-9]+$/, 'Veuillez renseigner un nombre')
      .max(10, 'Maximum 10 digits')
      .required(`${fieldLabel} est obligatoire.`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cellNumber: value ? value : ''
    },
    validationSchema: CellNumberSchema,
    onSubmit: async (values) => {
      try {
        const { cellNumber } = values;
        await handeSave(item, fiedToEdit, cellNumber);
        handleClose();
      } catch (error) {
        console.error();
        handleClose();
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;
  return (
    <>
      {value}
      <IconButton aria-describedby={id} color="primary" onClick={handleClick}>
        <EditIcon sx={{ fontSize: '16px' }} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Box sx={{ p: theme.spacing(3) }}>
          <FormikProvider value={formik}>
            <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
              <TextField
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                fullWidth
                type="number"
                label={fieldLabel}
                {...getFieldProps('cellNumber')}
                error={Boolean(touched.cellNumber && errors.cellNumber)}
                helperText={touched.cellNumber && errors.cellNumber}
              />
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ m: theme.spacing(2) }}
              >
                <IconButton color="primary" type="submit">
                  <DoneIcon />
                </IconButton>
                <IconButton onClick={handleClose}>
                  <HighlightOffIcon />
                </IconButton>
              </Box>
            </Form>
          </FormikProvider>
        </Box>
      </Popover>
    </>
  );
}
