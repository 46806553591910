import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useTheme, styled, alpha } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { DEPOSIT_OPTIONS } from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import { fCurrency } from 'utils/formatNumber';
// ----------------------------------------------------------------------
const InfoRightStyle = styled(Box)(({ theme }) => ({
  width: '389px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FFFFFF',
  border: '1px solid #95A2AD',
  boxSizing: 'border-box',
  borderRadius: '10px',
  '& .divider': {
    width: '90%',
    height: '0px',
    border: '0.5px solid #95A2AD',
    transform: 'rotate(-180deg)'
  }
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0)
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const RemiseInputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '80px',
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: '16px',
    padding: '5px 12px 5px 12px',
    margin: theme.spacing(0, 2, 0, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const NotePaymentStyle = styled(Box)(({ theme }) => ({
  background: '#FFFFFF',
  border: '1px solid #95A2AD',
  boxSizing: 'border-box',
  borderRadius: '5px',
  margin: theme.spacing(4, 4, 4, 4),
  padding: theme.spacing(3, 3, 3, 3)
}));

const optionsUnit = ['%', '€'];

export default function InvoicePayment({ project, ...props }) {
  const { orderBill } = useTechEditProject();
  const theme = useTheme();

  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <InfoRightStyle>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle>Total HT</LabelStyle>
            <LabelStyle>{fCurrency(orderBill.total - orderBill.tax)} €</LabelStyle>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle>TVA 20%</LabelStyle>
            <LabelStyle>{fCurrency(orderBill.tax)} €</LabelStyle>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle variant="h6">Total TTC</LabelStyle>
            <LabelStyle>{fCurrency(orderBill.total)} €</LabelStyle>
          </Box>
        </InfoRightStyle>
      </Box>
    </>
  );
}
