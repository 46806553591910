import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: true,
  sceneLoaded: null,
  progression: null,
  scene: null,
  sendingConfig: null,
  display: true,
  bgStyle: null,
  sceneInitialized: false,
  enableZindex: null
};
const projectUnitySlice = createSlice({
  name: 'projectUnity',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.sceneLoaded = null;
    },
    setSceneLoaded(state, action) {
      state.display = true;
      state.isLoading = false;
      state.sceneLoaded = new Date().getTime();
    },
    setProgression(state, action) {
      state.progression = action.payload.progression;
      if (Math.floor(action.payload.progression === 1)) {
        state.sceneInitialized = true;
      }
    },
    setScene(state, action) {
      state.display = true;
      state.scene = action.payload.scene;
    },
    setSendingConfig(state, action) {
      state.sendingConfig = action.payload.sendingConfig;
    },
    resetUnity(state, action) {
      state.sceneLoaded = null;
      state.progression = 0;
      state.scene = null;
      state.sendingConfig = null;
      state.bgStyle = null;
      state.sceneInitialized = false;
      state.enableZindex = null;
    },
    setSceneVisibility(state, action) {
      state.display = action.payload.visibility;
    },
    setBgStyle(state, action) {
      state.bgStyle = action.payload.bgStyle;
    },
    setEnableZindex(state, action) {
      state.enableZindex = action.payload.enableZindex;
    }
  }
});
export const {
  setEnableZindex,
  setSendingConfig,
  resetUnity,
  startLoading,
  setSceneLoaded,
  setScene,
  setProgression,
  setSceneVisibility,
  setBgStyle
} = projectUnitySlice.actions;
const projectUnityReducer = projectUnitySlice.reducer;

export default projectUnityReducer;
