import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import { APP_BAR_DESKTOP, BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import GradientBtn from 'components/custom/GradientBtn';
import useProjectUnity from 'hooks/useProjectUnity';
// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  background: `linear-gradient(68deg, rgba(133, 169, 230, 0.8) 18.94%, rgba(176, 217, 226, 0.8) 74.51%, rgba(255, 255, 255, 0) 120.58%)`,
  backdropFilter: 'blur(12px)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  zIndex: 2,
  overflow: 'hidden'
}));
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  margin: theme.spacing(0, -2, 0, 0),
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot_confirm.svg)`,
  backgroundSize: 'contain'
}));

const WrapperBoxStyle = styled('div')(({ theme }) => ({
  width: '630px',
  height: '480px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 250}px`,
  right: `${BOT_WIDTH + 50}px`,
  '@media (max-height: 960px)': {
    top: `${APP_BAR_DESKTOP + 60}px`,
    bottom: ''
  }
}));
const MsgBoxStyle = styled('div')(({ theme }) => ({
  width: '619px',
  height: 'auto',
  background: ' #668AF5',
  borderRadius: '50px 50px 0px 50px',
  padding: theme.spacing(3)
}));
const TextBoxStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima',
  fontStyle: 'normal',
  fontWeight: '400',
  fontSize: '24px',
  lineHeight: '38px',

  color: '#FFFFFF'
}));

export default function ConfirmSpecsSelected({ spec, onConfirmSpecs, ...props }) {
  const { sceneLoaded, scene, handleUpdScene, handleSendingConfig } = useProjectUnity();
  const theme = useTheme();
  useEffect(() => {
    if (!spec) return;
    if (!sceneLoaded) return;
    handleUpdScene({
      name: 'Mascotte',
      display: 'visible',
      style: { visibility: 'visible', zIndex: 20 },
      updTime: new Date().getTime()
    });
    const timeout = setTimeout(() => {
      handleSendingConfig({
        objectName: 'Game Controller',
        methodName: 'SetAnim',
        parameters: 'Pointe'
      });
    }, 1000);
    return () => {
      handleUpdScene({
        ...scene,
        display: 'hidden',
        style: { visibility: 'hidden' },
        updTime: new Date().getTime()
      });
      clearTimeout(timeout);
    };
  }, [spec]);

  if (!spec) return;
  return (
    <RootStyle>
      <WrapperBoxStyle>
        <MsgBoxStyle>
          <TextBoxStyle>Nos solutions {spec.label}</TextBoxStyle>
          <TextBoxStyle>{spec.dialogue}</TextBoxStyle>

          <TextBoxStyle>Trouvons ensemble le meilleur film pour vous.</TextBoxStyle>
        </MsgBoxStyle>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ width: '100%', margin: theme.spacing(2, 0, 0, 0) }}
        >
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            onClick={onConfirmSpecs}
            sx={{ minWidth: '200px' }}
          >
            Continuer
          </GradientBtn>
        </Box>
      </WrapperBoxStyle>
    </RootStyle>
  );
}
