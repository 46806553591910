import { Fragment, useEffect, useState } from 'react';
import Unity, { UnityContext } from 'react-unity-webgl';
import './smartcity.css';
// ----------------------------------------------------------------------

const unityContext = new UnityContext({
  productName: 'JPS-Films',
  companyName: 'JPS France',
  loaderUrl: '/static/unity/product/public.loader.js',
  dataUrl: '/static/unity/product/public.data',
  frameworkUrl: '/static/unity/product/public.framework.js',
  codeUrl: '/static/unity/product/public.wasm',
  streamingAssetsUrl: '/static/unity/product/StreamingAssets',
  webglContextAttributes: {
    preserveDrawingBuffer: true
  }
});

export default function ProductUnity({ onLoadedUnity, scene, sendingConfig, ...props }) {
  // The app's state.
  const [isUnityMounted, setIsUnityMounted] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [progression, setProgression] = useState(0);
  // When the component is mounted, we'll register some event listener.
  useEffect(() => {
    unityContext.on('canvas', handleOnUnityCanvas);
    unityContext.on('progress', handleOnUnityProgress);
    unityContext.on('loaded', handleOnUnityLoaded);
    // When the component is unmounted, we'll unregister the event listener.
    return function () {
      unityContext.removeAllEventListeners();
    };
  }, []);
  // Built-in event invoked when the Unity canvas is ready to be interacted with.
  function handleOnUnityCanvas(canvas) {
    canvas.setAttribute('role', 'unityCanvas');
  }
  // Built-in event invoked when the Unity app's progress has changed.
  function handleOnUnityProgress(progression) {
    setProgression(progression);
  }
  // Built-in event invoked when the Unity app is loaded.
  function handleOnUnityLoaded() {
    setIsLoaded(true);
  }
  // Event invoked when the user clicks the button, the unity container will be
  // mounted or unmounted depending on the current mounting state.
  function handleOnClickUnMountUnity() {
    if (isLoaded === true) {
      setIsLoaded(false);
    }
    setIsUnityMounted(isUnityMounted === false);
  }

  useEffect(() => {
    if (!isLoaded) return;
    onLoadedUnity(isLoaded);
    /* unityContext.send('Scene Manager', 'LoadScene', 'Assets/Scenes/Interior_01.unity'); */
  }, [isLoaded]);
  useEffect(() => {
    if (!isLoaded) return;
    if (!scene) return;
    console.log('====================================');
    console.log(scene);
    console.log('====================================');
    unityContext.send('Scene Manager', 'LoadScene', scene);
  }, [scene]);
  useEffect(() => {
    if (!sendingConfig) return;
    if (!unityContext) return;
    if (!isLoaded) return;
    if (!scene) return;

    unityContext.send(sendingConfig.objectName, sendingConfig.methodName, sendingConfig.parameters);
  }, [sendingConfig]);
  /*   useEffect(() => {
    if (!isLoaded) return;
    const interval = setInterval(() => {
      const rand = Number.parseFloat(Math.random()).toFixed(2);
      console.log('opacity to ', rand);
      unityContext.send('Game Controller', 'SetFilmOpacity', rand);
    }, 3000);
    return () => {
      clearInterval(interval);
    };
  }, [isLoaded]); */
  return (
    <>
      <div className="wrapper">
        {isUnityMounted === true && (
          <>
            <div className="unity-container">
              {/* The loading screen will be displayed here. */}
              {isLoaded === false && (
                <div className="loading-overlay">
                  <div className="progress-bar">
                    <div className="progress-bar-fill" style={{ width: `${progression * 100}%` }} />
                  </div>
                </div>
              )}
              {/* The Unity app will be rendered here. */}
              <Unity className="unity-canvas" unityContext={unityContext} />
            </div>
          </>
        )}
      </div>
    </>
  );
}
