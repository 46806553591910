import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { styled, useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import GradientBtn from 'components/custom/GradientBtn';
import { EditProjectContext } from 'provider/EditProjectProvider';
import { BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import { NAV_URL_COM } from 'config/paths';
import useClientEditProject from 'hooks/useClientEditProject';
import useProjectUnity from 'hooks/useProjectUnity';

// ----------------------------------------------------------------------
const BillStyle = styled('div')(({ theme }) => ({
  width: `720px`,
  height: `100vh`,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  background: '#F9F9F9',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(1, 4, 4, 4),
  '& .title': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '44px',
    textTransform: 'uppercase',
    color: '#121E6E',
    textAlign: 'center',
    margin: theme.spacing(12, 0, 4, 0),
    '@media (max-height: 960px)': {
      fontSize: '26px',
      lineHeight: '24px',
      overflow: 'auto'
    }
  },
  '& .pro-title': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#000000',
    margin: theme.spacing(2, 0, 8, 0),
    '@media (max-height: 960px)': {
      fontSize: '14px',
      lineHeight: '12px',
      margin: theme.spacing(1, 0, 4, 0)
    }
  },
  '& .sub-title': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    color: '#121E6E',
    '@media (max-height: 960px)': {
      fontSize: '14px',
      lineHeight: '20px'
    }
  },
  '& .paragraph': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '40px',
    color: '#000000',
    margin: theme.spacing(1, 0, 2, 0),
    '@media (max-height: 960px)': {
      fontSize: '12px',
      lineHeight: '20px'
    }
  },
  '& .label': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#121E6E',
    '@media (max-height: 960px)': {
      fontSize: '12px',
      lineHeight: '15px'
    }
  }
}));
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot_confirm.svg)`,
  backgroundSize: 'contain'
}));
const WrapperBoxStyle = styled('div')(({ theme }) => ({
  width: '430px',
  height: '280px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 250}px`,
  right: `${BOT_WIDTH + 50}px`
}));
const MsgBoxStyle = styled('div')(({ theme }) => ({
  width: '619px',
  height: 'auto',
  background: '#668AF5',
  borderRadius: '50px 50px 0px 50px',
  padding: theme.spacing(3)
}));
const TextBoxStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '38px',
  color: '#FFFFFF'
}));
const BoxProgrestStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function ProjectCreated({ ...props }) {
  const theme = useTheme();
  const { projectCreated } = useClientEditProject();
  const { sceneLoaded, scene, handleUpdScene, handleSendingConfig } = useProjectUnity();
  useEffect(() => {
    if (!sceneLoaded) return;
    handleUpdScene({
      ...scene,
      display: 'visible',
      style: { visibility: 'visible', zIndex: 20 },
      updTime: new Date().getTime()
    });
    handleSendingConfig({
      objectName: 'Game Controller',
      methodName: 'SetAnim',
      parameters: 'Pointe'
    });
    const timeout = setTimeout(() => {
      handleSendingConfig({
        objectName: 'Game Controller',
        methodName: 'SetAnim',
        parameters: 'Felicite'
      });
    }, 5000);
    return () => {
      handleUpdScene({
        ...scene,
        display: 'hidden',
        style: { visibility: 'hidden' },
        updTime: new Date().getTime()
      });
      clearTimeout(timeout);
    };
  }, []);
  const [showPage, setShowPage] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowPage(true);
    }, 3000);
    return () => {
      clearTimeout(timeout);
    };
  }, []);
  console.log(projectCreated);
  const displayProducts = () => {
    try {
      let res = false;
      projectCreated.problematics.forEach((it) => {
        if (it.product.length > 0) {
          res = true;
        }
      });
      return res;
    } catch (error) {
      return false;
    }
  };
  return useMemo(() => {
    if (!projectCreated || Object.keys(projectCreated).length === 0) {
      return (
        <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            component={RouterLink}
            to={NAV_URL_COM.botDialog}
          >
            Veuillez initier le projet avec le druide
          </GradientBtn>
        </Box>
      );
    }
    try {
      const building =
        projectCreated && projectCreated.buildings ? projectCreated.buildings[0] : null;

      return (
        <>
          {projectCreated && building ? (
            <>
              {!showPage && (
                <BoxProgrestStyle>
                  <CircularProgress size={60} sx={{ color: '#668AF5' }} />
                </BoxProgrestStyle>
              )}
              <Fade in={showPage}>
                <div>
                  <BillStyle>
                    <Box className="title">Récapitulatif</Box>
                    <Box className="pro-title">{projectCreated.name}</Box>
                    <Box className="sub-title">Typologie</Box>
                    <Box className="paragraph">{building.type.label}</Box>
                    {building.type.code !== 10 && (
                      <>
                        <Box className="label">Superficie du bâtiment</Box>
                        <Box className="paragraph">{building.surface.label}</Box>
                        <Box className="label">Type de vitrage</Box>
                        <Box className="paragraph">{building.glazing_type.label}</Box>
                        <Box className="label">Nombre d'étage</Box>
                        <Box className="paragraph">{building.floors}</Box>
                        <Box className="label">Type de toit</Box>
                        <Box className="paragraph">{building.floors.label}</Box>
                      </>
                    )}
                    <Grid container>
                      <Grid item md={6} />
                      <Grid md={6}>
                        <Box
                          display="flex"
                          flexDirection="column"
                          justifyContent="flex-start"
                          alignItems="flex-start"
                        >
                          {displayProducts() && (
                            <Box sx={{ margin: theme.spacing(0, 0, 2, 0) }} className="sub-title">
                              Les films adhésifs
                            </Box>
                          )}
                          {projectCreated.problematics.map((p) => {
                            return (
                              <Box key={Math.random()} sx={{ margin: theme.spacing(0, 0, 2, 0) }}>
                                <Box className="label">{p.label}</Box>
                                {p.product && (
                                  <>
                                    {p.product.map((prod) => {
                                      return (
                                        <Box
                                          sx={{
                                            textAlign: 'left'
                                          }}
                                          key={Math.random()}
                                        >
                                          {prod.category && (
                                            <Box className="sub-title">{prod.category.label}</Box>
                                          )}
                                          <Box className="paragraph" sx={{ margin: '0!important' }}>
                                            {prod.label}
                                          </Box>
                                        </Box>
                                      );
                                    })}
                                  </>
                                )}
                              </Box>
                            );
                          })}
                        </Box>
                      </Grid>
                    </Grid>
                  </BillStyle>

                  <WrapperBoxStyle>
                    <MsgBoxStyle>
                      <TextBoxStyle
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '24px',
                          lineHeight: '38px',
                          textAlign: 'justify'
                        }}
                      >
                        Votre projet a été créé avec succès
                      </TextBoxStyle>
                      <TextBoxStyle
                        sx={{
                          fontStyle: 'normal',
                          fontWeight: '400',
                          fontSize: '24px',
                          lineHeight: '38px',
                          textAlign: 'justify'
                        }}
                      >
                        Prochain step ! Un technicien va prendre rendez-vous afin de vous établir un
                        devis
                      </TextBoxStyle>
                    </MsgBoxStyle>
                    <Box
                      display="flex"
                      justifyContent="flex-end"
                      sx={{ width: '100%', margin: theme.spacing(2, 0, 0, 0) }}
                    >
                      <GradientBtn
                        size="large"
                        color="info"
                        component={RouterLink}
                        to={`${NAV_URL_COM.projects}`}
                        variant="contained"
                        sx={{ minWidth: '200px' }}
                      >
                        Fermer
                      </GradientBtn>
                    </Box>
                  </WrapperBoxStyle>
                </div>
              </Fade>
            </>
          ) : (
            <Box display="flex" alignItems="center" justifyContent="center" sx={{ mt: '50px' }}>
              <GradientBtn
                size="large"
                color="info"
                variant="contained"
                component={RouterLink}
                to={NAV_URL_COM.botDialog}
              >
                Veuillez initier le projet avec le druide
              </GradientBtn>
            </Box>
          )}
        </>
      );
    } catch (error) {
      console.error(error);
      return <></>;
    }
  }, [showPage, projectCreated]);
}
