import React, { useMemo, useContext, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP, BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import { EditProjectContext } from 'provider/EditProjectProvider';
import GradientBtn from 'components/custom/GradientBtn';
import ProductUnity from 'components/unity/ProductUnity';
// ----------------------------------------------------------------------

const TopGradientStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${APP_BAR_DESKTOP}px`,
  left: 0,
  width: '100%',
  height: '274px',
  background: `linear-gradient(180deg, #FFFFFF 18.84%, rgba(255, 255, 255, 0) 77.75%)`,
  zIndex: 1
}));
const UnitySceneStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  height: `100vh`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  zIndex: 0,
  paddingTop: '34px'
}));
const CenterSideStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  width: '100%',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: 1
}));
const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: '#000000'
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#000000',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',

    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '& .MuiSvgIcon': {
      color: '#FFFFFF'
    }
  }
}));

export default function FlowSelectProduct({ flow, ...props }) {
  const [isLoadedUnity, setLoadedUnity] = useState(false);
  const { flows, onChangeFlow } = useContext(EditProjectContext);
  const theme = useTheme();
  const selectSpecifications = flows ? flows[1] : null;
  const [sceneSelected, setSceneSelected] = useState(null);
  const [sendingConfig, setSendingConfig] = useState(null);
  const VehicleScene = React.lazy(() => import('pages/client/scene/VehicleScene'));
  const DecorationScene = React.lazy(() => import('pages/client/scene/DecorationScene'));
  const PrivacyScene = React.lazy(() => import('pages/client/scene/PrivacyScene'));
  const SecurityScene = React.lazy(() => import('pages/client/scene/SecurityScene'));
  const SolarScene = React.lazy(() => import('pages/client/scene/SolarScene'));

  const handleChange = (event) => {
    try {
      const idSel = event.target.value;
      let spec = selectSpecifications.value.find((it) => {
        return it.id === idSel;
      });
      if (!spec) return;

      switch (spec.category.id) {
        case 1: //film solaire
          spec = { ...spec, scene: 'Assets/Scenes/Interior_01.unity' };
          setSceneSelected({ spec, component: <SolarScene onEventSending={onEventSending} /> });
          break;
        case 2: //film secirity
          spec = { ...spec, scene: 'Assets/Scenes/Interior_02.unity' };
          setSceneSelected({ spec, component: <SecurityScene onEventSending={onEventSending} /> });
          break;
        case 3: //film privacy
          spec = { ...spec, scene: 'Assets/Scenes/Interior_03.unity' };
          setSceneSelected({ spec, component: <PrivacyScene onEventSending={onEventSending} /> });
          break;
        case 4: //film deco
          spec = { ...spec, scene: 'Assets/Scenes/Interior_04.unity' };
          setSceneSelected({
            spec,
            component: <DecorationScene onEventSending={onEventSending} />
          });
          break;
        case 5: //film vehicule
          spec = { ...spec, scene: 'Assets/Scenes/Interior_05.unity' };
          setSceneSelected({ spec, component: <VehicleScene onEventSending={onEventSending} /> });
          break;
        default:
          break;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onLoadedUnity = useCallback(
    (loaded) => {
      setLoadedUnity(loaded);
      setSceneSelected({
        spec: { ...selectSpecifications.value[0], scene: 'Assets/Scenes/Interior_01.unity' },
        component: <SolarScene onEventSending={onEventSending} />
      });
    },
    [isLoadedUnity]
  );
  const onEventSending = useCallback(
    (event) => {
      try {
        setSendingConfig({ ...event });
      } catch (error) {
        setSendingConfig(null);
        console.error(error);
      }
    },
    [sendingConfig]
  );
  return useMemo(() => {
    if (!selectSpecifications) return <></>;
    const idSel = sceneSelected ? sceneSelected.spec.id : null;
    const sceneToLoad = sceneSelected ? sceneSelected.spec.scene : null;
    return (
      <>
        <TopGradientStyle />
        <UnitySceneStyle>
          <ProductUnity
            onLoadedUnity={onLoadedUnity}
            scene={sceneToLoad}
            sendingConfig={sendingConfig}
          />
        </UnitySceneStyle>
        {isLoadedUnity && sceneSelected && (
          <>
            <FormControl
              variant="standard"
              sx={{ width: '100%', padding: theme.spacing(0, 2, 0, 2) }}
            >
              <CenterSideStyle>
                <Select
                  labelId="demo-customized-select-label"
                  id="demo-customized-select"
                  value={idSel}
                  onChange={handleChange}
                  input={<BootstrapInput />}
                  IconComponent={KeyboardArrowDownIconStyle}
                >
                  {selectSpecifications.value.map((opt) => {
                    return (
                      <MenuItem key={Math.random()} value={opt.id}>
                        {opt.label}
                      </MenuItem>
                    );
                  })}
                </Select>
              </CenterSideStyle>
            </FormControl>

            <React.Suspense fallback={<span>Loading...</span>}>
              <>{sceneSelected ? sceneSelected.component : ''}</>
            </React.Suspense>
          </>
        )}
      </>
    );
  }, [sendingConfig, sceneSelected, isLoadedUnity]);
}
