import React, { useContext, useState, useEffect, useCallback } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTheme, styled } from '@mui/material/styles';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP, BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import { EditProjectContext } from 'provider/EditProjectProvider';
import GradientBtn from 'components/custom/GradientBtn';

import ConfigTypoAndScope from './ConfigTypoAndScope';
// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  width: '100%',
  height: '100%',
  zIndex: 1,
  overflow: 'hidden',
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_typology.svg)`,
  backgroundSize: 'cover'
}));
const TopGradientStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${APP_BAR_DESKTOP}px`,
  left: 0,
  width: '100%',
  height: '274px',
  background: `linear-gradient(180deg, #FFFFFF 18.84%, rgba(255, 255, 255, 0) 77.75%)`
}));
const RightSideStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: `${APP_BAR_DESKTOP}px`,
  right: 0,
  width: '366px',
  height: '100%',
  background: ' #F9F9F9',
  boxShadow: `0px 8px 25px rgba(0, 0, 0, 0.15)`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  paddingRight: '16px',
  paddingLeft: '16px'
}));
const TitleBoxStyle = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '100%',
  height: '166px',
  background: `linear-gradient(68deg, #85A9E6 0.04%, #B0D9E2 99.19%, rgba(255, 255, 255, 0) 101.68%)`,
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(3, 0, 0, 0)
}));
const ContentBoxStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  height: '100%',
  background: `linear-gradient(360deg, #FFFFFF -2.96%, rgba(255, 255, 255, 0) 136.82%)`,
  backdropFilter: `blur(25px)`,
  borderRadius: '20px',
  marginTop: '63px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: theme.spacing(3, 0, 0, 0)
}));

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: '#FFFFFF'
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#FFFFFF',
    width: '100%',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: 'none',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textAlign: 'center',
    letterSpacing: '0.1em',
    textTransform: 'uppercase',

    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    },
    '& .MuiSvgIcon': {
      color: '#FFFFFF'
    }
  }
}));
const ConfirmRootStyle = styled(Box)(({ theme }) => ({
  background: `linear-gradient(68deg, rgba(133, 169, 230, 0.8) -8.1%, rgba(176, 217, 226, 0.8) 47.48%, rgba(255, 255, 255, 0) 93.54%)`,
  backdropFilter: 'blur(12px)',
  position: 'absolute',
  bottom: 0,
  right: 0,
  width: '100%',
  height: '100%',
  zIndex: 10
}));
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  margin: theme.spacing(0, -2, 0, 0),
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot_confirm.svg)`,
  backgroundSize: 'contain'
}));

const WrapperBoxStyle = styled('div')(({ theme }) => ({
  width: '630px',
  height: '480px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 350}px`,
  right: `${BOT_WIDTH - 150}px`
}));
const MsgBoxStyle = styled('div')(({ theme }) => ({
  width: '619px',
  height: 'auto',
  background: ' #668AF5',
  borderRadius: '50px 50px 0px 50px',
  padding: theme.spacing(3)
}));
const TextBoxStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '600',
  lineHeight: '54px',
  color: '#FFFFFF'
}));
export default function FlowSelectTypo({ flow, ...props }) {
  const { flows, onChangeFlow } = useContext(EditProjectContext);
  const theme = useTheme();
  const selectSpecifications = flows ? flows[1] : null;
  const [selectedSpecId, setSelectedSpecId] = useState(selectSpecifications.value[0].id);
  const [selectedSpec, setSelectedSpec] = useState(null);
  const [displayConfirm, setDisplayConfirm] = useState({ open: false, buildingType: null });

  useEffect(() => {
    if (!selectedSpecId || !selectSpecifications) return;
    const specFounded = selectSpecifications.value.find((it) => {
      return it.id === selectedSpecId;
    });
    if (specFounded) {
      setSelectedSpec(specFounded);
    }
  }, [selectedSpecId]);

  const handleChange = (event) => {
    setSelectedSpecId(event.target.value);
  };
  const onDisplayConfirm = useCallback((configSpec, scopeTypo) => {
    setDisplayConfirm({ open: true, buildingType: 'pinkin', data: { configSpec, scopeTypo } });
  }, []);

  const onConfirmScope = useCallback(() => {
    onChangeFlow(flow, displayConfirm.data);
  }, [displayConfirm]);

  /*  */
  if (!selectSpecifications) return <></>;
  return (
    <>
      {displayConfirm.open && <ConfirmScope onConfirmScope={onConfirmScope} />}
      <RootStyle>
        <TopGradientStyle />
        <RightSideStyle>
          <TitleBoxStyle>
            <Typography variant="h4" sx={{ color: '#FFFFFF' }}>
              Typologie
            </Typography>
          </TitleBoxStyle>
          <ContentBoxStyle>
            <FormControl
              variant="standard"
              sx={{ width: '100%', padding: theme.spacing(0, 2, 0, 2) }}
            >
              <Select
                fullWidth
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={selectedSpecId}
                onChange={handleChange}
                input={<BootstrapInput />}
                IconComponent={KeyboardArrowDownIconStyle}
              >
                {selectSpecifications.value.map((opt) => {
                  return (
                    <MenuItem key={Math.random()} value={opt.id}>
                      {opt.label}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {selectedSpec && (
              <ConfigTypoAndScope
                flow={flow}
                onDisplayConfirm={onDisplayConfirm}
                spec={selectedSpec}
                selectSpecifications={selectSpecifications}
              />
            )}
          </ContentBoxStyle>
        </RightSideStyle>
      </RootStyle>
    </>
  );
}
function ConfirmScope({ onConfirmScope, ...props }) {
  const theme = useTheme();
  return (
    <ConfirmRootStyle>
      <BgBotStyle />
      <WrapperBoxStyle>
        <MsgBoxStyle>
          <TextBoxStyle variant="h6">En tant que </TextBoxStyle>
          <TextBoxStyle variant="body1" sx={{ margin: theme.spacing(3, 0, 3, 0) }}>
            pinkinn
          </TextBoxStyle>

          <TextBoxStyle variant="body1">Puis je vous le proposer.</TextBoxStyle>
        </MsgBoxStyle>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="flex-end"
          sx={{ width: '100%', margin: theme.spacing(4, 0, 0, 0) }}
        >
          <GradientBtn size="large" color="info" variant="contained" sx={{ minWidth: '260px' }}>
            Ajouter la problématique
          </GradientBtn>
          <GradientBtn
            size="large"
            color="info"
            variant="contained"
            onClick={onConfirmScope}
            sx={{
              minWidth: '260px',
              margin: theme.spacing(2, 0, 0, 0),
              background: `linear-gradient(93.46deg, #95A2AD 37.45%, #C4C4C4 93.7%)!important`
            }}
          >
            Non, merci
          </GradientBtn>
        </Box>
      </WrapperBoxStyle>
    </ConfirmRootStyle>
  );
}
