import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import LoadingScreen from 'components/LoadingScreen';

import useAuth from 'hooks/useAuth';
import { PATH_PAGE } from 'config/paths';
// ----------------------------------------------------------------------
export default function AuthWaitingToRedirect({ props }) {
  const { user } = useAuth();
  const navigate = useNavigate();
  useEffect(() => {
    if (!user) return;
    if (!user.profile) return;
    navigate(`/${user.profile}`);
  }, [user]);

  return <></>;
}
