import * as React from 'react';
import { styled } from '@mui/material/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
// ----------------------------------------------------------------------

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '90px',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: 16,
    padding: '10px 26px 10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
export default function ProjectListFilterState({
  handleChangeFilters,
  optionsState,
  stateSelected,
  ...props
}) {
  const handleChange = (event) => {
    handleChangeFilters({ state: event.target.value });
  };
  return (
    <div>
      <FormControl variant="standard">
        <Select
          fullWidth
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={stateSelected}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          {optionsState.map((opt) => {
            return (
              <MenuItem key={Math.random()} value={opt.id}>
                {opt.label}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
}
