import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ src, sx, ...props }) {
  return (
    <NavLink to="/">
      <Box
        component="img"
        src={src ? src : '/static/logo.png'}
        sx={{ width: 50, height: 'auto', ...sx }}
      />
    </NavLink>
  );
}
