import React, { useMemo, useEffect, useState } from 'react';
import Popover from '@mui/material/Popover';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { styled, useTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import useTechEditProject from 'hooks/useTechEditProject';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'theme/@material-extend/Buttons';
import closeFill from '@iconify-icons/eva/close-fill';
import DoneIcon from '@mui/icons-material/Done';
import InputBase from '@mui/material/InputBase';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Stack from '@mui/material/Stack';
import GradientBtn from 'components/custom/GradientBtn';

// ----------------------------------------------------------------------
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const RemiseInputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '80px',
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: '16px',
    padding: '5px 12px 5px 12px',
    margin: theme.spacing(0, 2, 0, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0)
}));
const optionsUnit = [
  { code: 'percent', label: '%' },
  { code: 'euro', label: '€' }
];
export default function PaymentCondition({ ...props }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const theme = useTheme();

  const { orderBill, saveOrderBill, project } = useTechEditProject();

  useEffect(() => {
    if (!orderBill) return;
    console.log();
  }, [orderBill]);

  const handleValidDeposit = async (depositValues) => {
    try {
      if (!project) return;
      if (!orderBill) return;
      if (!depositValues) return;
      const newOrd = {
        quote: {
          code: orderBill.code,
          deposit: {
            ...depositValues
          }
        }
      };

      await saveOrderBill(newOrd);
      enqueueSnackbar('Sauvegarde réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="flex-start"
        flexDirection="column"
        sx={{ margin: theme.spacing(3, 0, 0, 0) }}
      >
        <FormSelectPayment />
        <FormSelectMethodsPayment />
        {/*  <FormConditionPayment handleValidDeposit={handleValidDeposit} /> */}
      </Box>
    </>
  );
}

const FormSelectMethodsPayment = ({ ...props }) => {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [conditionPayment, setconditionPayment] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { payment_methods, orderBill, saveOrderBill, project } = useTechEditProject();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectPaymentMethod = async (e) => {
    try {
      if (!project) return;
      if (!orderBill) return;
      const val = e.target.value;
      setconditionPayment(val);
      const conditionFounded = payment_methods.find((it) => String(it.code) === String(val));
      if (!conditionFounded) return;
      const newOrd = {
        quote: {
          code: orderBill.code,
          payment_methods: {
            ...conditionFounded
          }
        }
      };
      await saveOrderBill(newOrd);
      enqueueSnackbar('Sauvegarde réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box
      display="flex"
      justifyContent="flex-start"
      alignItems="center"
      sx={{ margin: theme.spacing(3, 3, 0, 0) }}
    >
      <Typography variant="h5" sx={{ textTransform: 'upper', margin: theme.spacing(0, 3, 0, 0) }}>
        Méthode de paiement
      </Typography>
      {orderBill && orderBill.payment_method && (
        <Typography variant="body2" sx={{ margin: theme.spacing(0, 3, 0, 0) }}>
          {orderBill.payment_method.label}
        </Typography>
      )}

      <div>
        <Button
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            fontFamily: 'Proxima',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#3C60B5',
            textTransform: 'none'
          }}
        >
          <img
            src="/static/icons/ic_add_circle_blue.svg"
            alt="Nouveau commentaire"
            style={{ margin: theme.spacing(0, 2, 0, 0) }}
          />
          {`${
            orderBill && orderBill.payment_method ? 'Modifier la' : 'Ajouter une'
          } méthode de paiement`}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              padding: theme.spacing(3)
            }}
            noValidate
            autoComplete="off"
            display="flex"
            alignItems="center"
          >
            {payment_methods && payment_methods.length > 0 && (
              <TextField
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                select
                label="Méthode de paiement"
                value={conditionPayment}
                onChange={(e) => handleSelectPaymentMethod(e)}
              >
                {payment_methods.map((option, i) => (
                  <MenuItem key={i} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </Popover>
      </div>
    </Box>
  );
};
const FormSelectPayment = ({ ...props }) => {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [conditionPayment, setconditionPayment] = useState('');

  const [anchorEl, setAnchorEl] = React.useState(null);
  const { payment_methods, payment_conditions, orderBill, saveOrderBill, project } =
    useTechEditProject();
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectPaymentMethod = async (e) => {
    try {
      if (!project) return;
      if (!orderBill) return;
      const val = e.target.value;
      setconditionPayment(val);
      const conditionFounded = payment_conditions.find((it) => String(it.code) === String(val));
      if (!conditionFounded) return;
      const newOrd = {
        quote: {
          code: orderBill.code,
          payment_conditions: {
            ...conditionFounded
          }
        }
      };
      await saveOrderBill(newOrd);
      enqueueSnackbar('Sauvegarde réussie', {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      handleClose();
    } catch (error) {
      console.error(error);
      let msg = '';
      if (error.response) {
        msg = error.response.data.message;
      } else {
        msg = 'Network error';
      }

      enqueueSnackbar(msg, {
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'left'
        },
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <Box display="flex" justifyContent="flex-start" alignItems="center">
      <Typography variant="h5" sx={{ textTransform: 'upper', margin: theme.spacing(0, 3, 0, 0) }}>
        Conditions de paiement
      </Typography>
      {orderBill && orderBill.payment_condition && (
        <Typography variant="body2" sx={{ margin: theme.spacing(0, 3, 0, 0) }}>
          {orderBill.payment_condition.label}
        </Typography>
      )}

      <div>
        <Button
          aria-describedby={id}
          onClick={handleClick}
          sx={{
            fontFamily: 'Proxima',
            fontStyle: 'normal',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19px',
            color: '#3C60B5',
            textTransform: 'none'
          }}
        >
          <img
            src="/static/icons/ic_add_circle_blue.svg"
            alt="Nouveau commentaire"
            style={{ margin: theme.spacing(0, 2, 0, 0) }}
          />
          {`${orderBill && orderBill.payment_condition ? 'Modifier la' : 'Ajouter une'} condition`}
        </Button>
        <Popover
          id={id}
          open={open}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right'
          }}
        >
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1, width: '25ch' },
              padding: theme.spacing(3)
            }}
            noValidate
            autoComplete="off"
            display="flex"
            alignItems="center"
          >
            {payment_conditions && payment_conditions.length > 0 && (
              <TextField
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                select
                label="Condition"
                value={conditionPayment}
                onChange={(e) => handleSelectPaymentMethod(e)}
              >
                {payment_conditions.map((option, i) => (
                  <MenuItem key={i} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            )}
          </Box>
        </Popover>
      </div>
    </Box>
  );
};
const FormConditionPayment = ({ handleValidDeposit, ...props }) => {
  const theme = useTheme();
  const { orderBill } = useTechEditProject();
  const PaymentSchema = Yup.object().shape({
    amountDeposit: Yup.string()
      .matches(/^[0-9]+$/, 'Veuillez renseigner un nombre')
      .max(10, 'Maximum 10 digits')
      .required(`Veuillez renseigner le montant de l'acompte `),
    unitDeposit: Yup.string().required(`Veuillez sélectionner l'unité.`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      amountDeposit: orderBill && orderBill.deposit_value ? orderBill.deposit_value : '',
      unitDeposit:
        orderBill && orderBill.deposit_unity ? orderBill.deposit_unity : optionsUnit[0].code
    },
    validationSchema: PaymentSchema,
    onSubmit: (values) => {
      try {
        const obj = { value: values.amountDeposit, unity: values.unitDeposit };
        handleValidDeposit(obj);
      } catch (error) {
        console.error();
      }
    }
  });

  const { errors, touched, values, handleChange, isSubmitting, handleSubmit, getFieldProps } =
    formik;
  return (
    <Box sx={{ margin: theme.spacing(4, 0, 4, 0) }}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Stack spacing={2} sx={{ width: '700px' }} direction="row" alignItems="center">
            <LabelStyle>Acompte de</LabelStyle>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              type="number"
              {...getFieldProps('amountDeposit')}
              error={Boolean(touched.amountDeposit && errors.amountDeposit)}
              helperText={touched.amountDeposit && errors.amountDeposit}
            />
            <FormControl variant="standard" sx={{ margin: theme.spacing(0, 0, 0, 2) }}>
              <TextField
                sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
                select
                fullWidth
                label="Unité"
                value={values.unitDeposit}
                onChange={handleChange('unitDeposit')}
                {...getFieldProps('unitDeposit')}
                error={Boolean(touched.unitDeposit && errors.unitDeposit)}
                helperText={touched.unitDeposit && errors.unitDeposit}
              >
                {optionsUnit.map((option, i) => (
                  <MenuItem key={i} value={option.code}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl>
            <GradientBtn
              type="submit"
              size="small"
              color="info"
              variant="contained"
              sx={{ margin: theme.spacing(1, 0, 1, 0), width: '113px', height: '36px' }}
            >
              Enregistrer
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </Box>
  );
};
