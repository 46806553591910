import * as Yup from 'yup';
import { useState, useEffect } from 'react';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useFormik, Form, FormikProvider } from 'formik';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
// material
import {
  Link,
  Stack,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Typography
} from '@mui/material';
import Box from '@mui/material/Box';
import { LoadingButton } from '@mui/lab';
import { MIconButton } from 'theme/@material-extend/Buttons';
import GradientLoadingBtn from 'components/custom/GradientLoadingBtn';
import useAuth from '../../../hooks/useAuth';
import { PATH_APP } from '../../../config/paths';
// ----------------------------------------------------------------------

export default function LoginForm() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { user, login } = useAuth();
  const { state } = useLocation();
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);

  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('Email must be a valid email address').required('Email is required'),
    password: Yup.string().required('Password is required')
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      password: '',
      remember: true
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      try {
        const { email, password, remember } = values;
        const res = await login(email, password, remember);
        enqueueSnackbar('Authentification réussie', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (error) {
        let msg = '';
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = 'Network error';
        }

        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }
    }
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            autoComplete="username"
            type="email"
            label="Adresse email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
            fullWidth
            autoComplete="current-password"
            type={showPassword ? 'text' : 'password'}
            label="Mot de passe"
            {...getFieldProps('password')}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={handleShowPassword} edge="end">
                    <Icon icon={showPassword ? eyeFill : eyeOffFill} />
                  </IconButton>
                </InputAdornment>
              )
            }}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
        </Stack>

        <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
          <FormControlLabel
            control={<Checkbox {...getFieldProps('remember')} checked={values.remember} />}
            label={
              <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                Se souvenir de moi
              </Typography>
            }
          />
        </Stack>

        {/*         <LoadingButton
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={isSubmitting}
        >
          Login
        </LoadingButton> */}
        <Box display="flex" justifyContent="center">
          <GradientLoadingBtn
            size="large"
            type="submit"
            color="info"
            variant="contained"
            loading={isSubmitting}
          >
            Valider
          </GradientLoadingBtn>
        </Box>
      </Form>
    </FormikProvider>
  );
}
