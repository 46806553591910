import { Icon } from '@iconify/react';
import { useRef, useState, useEffect } from 'react';
import homeFill from '@iconify/icons-eva/home-fill';
import personFill from '@iconify/icons-eva/person-fill';
import settings2Fill from '@iconify/icons-eva/settings-2-fill';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { alpha } from '@mui/material/styles';
import { Button, Box, Divider, MenuItem, Typography, Avatar, IconButton } from '@mui/material';
import useAuth from 'hooks/useAuth';
import { PATH_CLIENT, PATH_TECH } from 'config/paths';
import { isSuperadminProfile } from 'config/appConfig';
// components
import MenuPopover from '../../components/MenuPopover';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: homeFill,
    linkTo: '/'
  },
  {
    label: 'Profile',
    icon: personFill,
    linkTo: '#'
  },
  {
    label: 'Settings',
    icon: settings2Fill,
    linkTo: '#'
  }
];

// ----------------------------------------------------------------------

export default function TopAccountPopover() {
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const { user, logout } = useAuth();
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handleLogout = async () => {
    await logout();
  };
  if (!user) return <></>;
  const avatar = user.avatarUrl ? user.avatarUrl : '/static/icons/user.svg';
  
  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72)
            }
          })
        }}
      >
        <Avatar src={avatar} alt="photoURL" sx={{ width: 32, height: 32 }} />
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{ width: 220 }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle1" noWrap>
            {user.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>
        {isSuperadminProfile(user) && <MenuItemSuperadmin />}
        <Divider sx={{ my: 1 }} />

        <Box sx={{ p: 2, pt: 1.5 }}>
          <Button fullWidth color="inherit" variant="outlined" onClick={handleLogout}>
            Logout
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}
const MENU = [
  {
    label: 'Profile commercial',
    description: 'Créer le projet avec les modèles 3D',
    url: PATH_CLIENT.projects
  },
  {
    label: 'Profile technicien',
    description: 'Créer et configurer les données techniques du projet',
    url: PATH_TECH.root
  }
];
const MenuItemSuperadmin = () => {
  return (
    <>
      {MENU.map((it, i) => {
        return (
          <Box sx={{ p: 2, pt: 1.5 }} key={i}>
            <Button
              fullWidth
              color="inherit"
              variant="contained"
              component={RouterLink}
              to={it.url}
            >
              {it.label}
            </Button>
          </Box>
        );
      })}
    </>
  );
};
