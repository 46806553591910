import React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Scrollbar from 'components/Scrollbar';
import ProjectListItem from './ProjectListItem';

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  overflowY: 'scroll',
  overflowX: 'hidden',
  margin: theme.spacing(2, 0, 0, 0),
  padding: theme.spacing(1, 1, 1, 0)
}));
export default function ProjectList({ items, ...props }) {
  const theme = useTheme();
  if (!Array.isArray(items)) return <></>;
  return (
    <RootStyle>
      {items.map((it, i) => {
        return (
          <ProjectListItem
            key={Math.random()}
            item={it}
            style={{ marginTop: i > 0 ? theme.spacing(2) : 0 }}
          />
        );
      })}
    </RootStyle>
  );
}
