import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import DateTimePicker from '@mui/lab/DateTimePicker';
import DesktopDatePicker from '@mui/lab/DesktopDatePicker';
import MobileDatePicker from '@mui/lab/MobileDatePicker';
import { useTheme, styled, alpha } from '@mui/material/styles';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputBase from '@mui/material/InputBase';
import InputLabel from '@mui/material/InputLabel';
import { DEPOSIT_OPTIONS } from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import GradientBtn from 'components/custom/GradientBtn';
import { fCurrency } from 'utils/formatNumber';
// ----------------------------------------------------------------------
const InfoRightStyle = styled(Box)(({ theme }) => ({
  width: '389px',
  height: 'auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  background: '#FFFFFF',
  border: '1px solid #95A2AD',
  boxSizing: 'border-box',
  borderRadius: '10px',
  '& .divider': {
    width: '90%',
    height: '0px',
    border: '0.5px solid #95A2AD',
    transform: 'rotate(-180deg)'
  }
}));
const LabelStyle = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(0, 2, 0, 0)
}));
const BootstrapInput = styled(InputBase)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: 'auto',
    borderRadius: 4,
    position: 'relative',
    backgroundColor: 'transparent',
    border: '1px solid #95A2AD',
    fontSize: '12px',
    padding: '5px 12px 5px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const RemiseInputField = styled(TextField)(({ theme }) => ({
  '& .MuiInputBase-input': {
    color: '#676B6F',
    width: '80px',
    position: 'relative',
    backgroundColor: 'transparent',
    fontSize: '16px',
    padding: '5px 12px 5px 12px',
    margin: theme.spacing(0, 2, 0, 2),
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.

    '&:focus': {
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)'
    }
  }
}));
const optionsUnit = [
  { code: 'percent', label: '%' },
  { code: 'euro', label: '€' }
];
export default function EstimatePayment({ project, ...props }) {
  const { orderBill } = useTechEditProject();
  const theme = useTheme();
  const [remise, setRemise] = useState();
  useEffect(() => {
    if (!orderBill) return;
    setRemise(orderBill && orderBill.discount_value ? orderBill.discount_value : 0);
    setRemiseUnit(orderBill && orderBill.discount_unity ? orderBill.discount_unity : 'percent');
  }, [orderBill]);

  const [remiseUnit, setRemiseUnit] = useState('percent');

  if (!orderBill) return <></>;
  return (
    <>
      <Box display="flex" justifyContent="flex-end">
        <InfoRightStyle>
          {remise > 0 && (
            <>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
              >
                <LabelStyle>Total HT</LabelStyle>
                <LabelStyle>{fCurrency(orderBill.subtotal)} €</LabelStyle>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
              >
                <LabelStyle>Remise globale</LabelStyle>
                <Box sx={{ flexGrow: 1 }} display="flex" alignItems="center">
                  <RemiseInputField
                    disabled
                    type="number"
                    value={fCurrency(remise)}
                    inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                  />
                  <FormControl variant="standard" sx={{ margin: theme.spacing(0, 0, 0, 1) }}>
                    <Select
                      disabled
                      fullWidth
                      value={remiseUnit}
                      onChange={(val) => setRemiseUnit(val)}
                      input={<BootstrapInput />}
                    >
                      {optionsUnit.map((opt) => {
                        return (
                          <MenuItem key={Math.random()} value={opt.code}>
                            {opt.label}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Box>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
              >
                <LabelStyle>Remise HT</LabelStyle>
                <LabelStyle>
                  {Number(orderBill.discount_total) > 0
                    ? '-' + fCurrency(orderBill.discount_total)
                    : 0}{' '}
                  €
                </LabelStyle>
              </Box>
              <Box className="divider" />
            </>
          )}

          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle>Total HT {remise > 0 ? '(après remise)' : ''}</LabelStyle>
            <LabelStyle>
              {fCurrency(orderBill.subtotal - Number(orderBill.discount_total))} €
            </LabelStyle>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle>TVA</LabelStyle>
            <LabelStyle>{fCurrency(orderBill.tax)} €</LabelStyle>
          </Box>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            sx={{ width: '100%', padding: theme.spacing(1, 3, 1, 3) }}
          >
            <LabelStyle variant="h6">Total TTC</LabelStyle>
            <LabelStyle>{fCurrency(orderBill.total)} €</LabelStyle>
          </Box>
        </InfoRightStyle>
      </Box>

      {/*       <Box display="flex" justifyContent="center" alignItems="center" sx={{ minHeight: '200px' }}>
        <GradientBtn
          size="medium"
          color="info"
          variant="contained"
          sx={{ width: '211px', margin: theme.spacing(1, 0, 1, 0) }}
          onClick={() => console.log()}
        >
          Valider
        </GradientBtn>
      </Box> */}
    </>
  );
}
