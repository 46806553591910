import { useEffect } from 'react';
import { motion } from 'framer-motion';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { Box, Button, Typography, Container } from '@mui/material';
import { PATH_PAGE } from 'config/paths';
// components
import { MotionContainer, varBounceIn } from '../components/animate';
import Page from '../components/Page';

// ----------------------------------------------------------------------

const RootStyle = styled(Page)(({ theme }) => ({
  display: 'flex',
  width: '100%',
  minHeight: '100%',
  alignItems: 'center',
  paddingTop: theme.spacing(15),
  paddingBottom: theme.spacing(10)
}));

// ----------------------------------------------------------------------

export default function Page401() {
  const navigate = useNavigate();
  useEffect(() => {
    const timeout = setTimeout(() => {
     const button= document.getElementById('btn-path-login');
     if(button){
      button.click();
     }

    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <RootStyle title="401 Page unauthorized | JPS France">
      <Container>
        <MotionContainer initial="initial" open>
          <Box sx={{ maxWidth: 480, margin: 'auto', textAlign: 'center' }}>
            <motion.div variants={varBounceIn}>
              <Typography variant="h3" paragraph>
                Sorry, page unauthorized!
              </Typography>
            </motion.div>
            <Typography sx={{ color: 'text.secondary' }}>
              Sorry, we couldn’t find the page you’re looking for. Perhaps you don't have the rights
              to access?
            </Typography>

            <motion.div variants={varBounceIn}>
              <Box
                component="img"
                src="/static/illustrations/illustration_401.svg"
                sx={{ height: 260, mx: 'auto', my: { xs: 5, sm: 10 } }}
              />
            </motion.div>

            <Button
              id="btn-path-login"
              to={PATH_PAGE.auth.login}
              size="large"
              variant="contained"
              component={RouterLink}
            >
              Retour à la page Login
            </Button>
          </Box>
        </MotionContainer>
      </Container>
    </RootStyle>
  );
}
