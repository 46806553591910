import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { API_URL } from 'config/appConfig';
// ----------------------------------------------------------------------
const initialFlows = [
  {
    id: 'selectBuilding',
    isCurrent: true,
    isValidate: false
  },
  {
    id: 'selectSpecifications',
    isCurrent: false,
    isValidate: false
  },
  {
    id: 'selectBuildingTypo',
    isCurrent: false,
    isValidate: false
  },
  {
    id: 'selectProduct',
    isCurrent: false,
    isValidate: false
  },
  {
    id: 'selectContact',
    isCurrent: false,
    isValidate: false
  }
];
const initialSteps = [
  {
    id: 'contactName',
    placeholder: 'Entrez votre prénom',
    bot: {
      text: `Bonjour,<br/> Je m’appelle Jean-Pierre mais tout le monde me surnomme le druide. et vous comment dois-je vous appeler ?`
    },
    isCurrent: true,
    isValidate: false,
    user: { text: '' }
  },
  {
    id: 'name',
    placeholder: 'Entrez le nom du projet',
    bot: {
      text: `Enchanté @name.<br/> Et si l’on nommait ce beau projet que l’on va mener ensemble ?`
    },
    isCurrent: false,
    isValidate: false,
    user: { text: '' }
  },
  {
    id: 'location',
    placeholder: 'Entrez votre ville',
    bot: {
      text: `Puis-je vous demander la localisation de votre projet ?`
    },
    isCurrent: false,
    isValidate: false,
    user: { text: '' }
  }
];
const initialState = {
  isLoading: false,
  steps: [...initialSteps],
  flows: [...initialFlows],
  editProject: null,
  products: [],
  prodInitialized: false,
  projectCreated: null
};

const clientEditProjectSlice = createSlice({
  name: 'clientEditProject',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
    }, // INITIALISE
    initProducts(state, action) {
      state.isLoading = false;
      state.products = action.payload;
    },
    updateEditProject(state, action) {
      state.editProject = action.payload;
    },
    initProjectCreated(state, action) {
      state.isLoading = false;
      state.editProject = null;
      state.projectCreated = action.payload;
    },
    resetProject(state, action) {
      state.steps = [...initialSteps];
      state.flows = [...initialFlows];
      state.editProject = null;
      state.prodInitialized = false;
      state.projectCreated = null;
    }
  }
});

export const { startLoading, initProducts, updateEditProject, initProjectCreated, resetProject } =
  clientEditProjectSlice.actions;
const clientEditProjectReducer = clientEditProjectSlice.reducer;

export default clientEditProjectReducer;

export function validateSteps(values) {
  return (dispatch) => {
    const newEditPrj = {};
    newEditPrj.steps = [...values];
    values.forEach((it) => {
      if (it.id !== 'last') {
        newEditPrj[it.id] = it.user.text;
      }
    });
    dispatch(updateEditProject(newEditPrj));
  };
}
