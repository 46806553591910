import React, { useContext } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
const BoxIconStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function CloseIcon({ sx, ...props }) {
  return (
    <BoxIconStyle sx={{ ...sx }}>
      <svg
        width="100"
        height="100"
        viewBox="0 0 100 100"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_0_1)">
          <circle cx="50" cy="46" r="25" fill="white" />
        </g>
        <g clipPath="url(#clip0_0_1)">
          <path
            d="M51.1047 46L59.7709 37.3337C60.0761 37.0286 60.0761 36.5339 59.7709 36.2288C59.4658 35.9238 58.9711 35.9237 58.6661 36.2288L49.9997 44.8952L41.3335 36.2288C41.0284 35.9237 40.5337 35.9237 40.2286 36.2288C39.9235 36.534 39.9235 37.0286 40.2286 37.3337L48.8949 46L40.2286 54.6663C39.9235 54.9714 39.9235 55.4661 40.2286 55.7712C40.3811 55.9237 40.5811 56 40.7811 56C40.981 56 41.1809 55.9237 41.3335 55.7712L49.9997 47.1049L58.666 55.7712C58.8186 55.9237 59.0185 56 59.2185 56C59.4184 56 59.6184 55.9237 59.7709 55.7712C60.0761 55.4661 60.0761 54.9714 59.7709 54.6663L51.1047 46Z"
            fill="black"
          />
        </g>
        <defs>
          <filter
            id="filter0_d_0_1"
            x="0"
            y="0"
            width="100"
            height="100"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB"
          >
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="12.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
          </filter>
          <clipPath id="clip0_0_1">
            <rect width="20" height="20" fill="white" transform="translate(40 36)" />
          </clipPath>
        </defs>
      </svg>
    </BoxIconStyle>
  );
}
