import React, { useContext } from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import Box from '@mui/material/Box';
import { styled, useTheme } from '@mui/material/styles';
// ----------------------------------------------------------------------
const BoxIconStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
export default function NextIcon({ sx, ...props }) {
  return (
    <svg width="91" height="84" viewBox="0 0 91 84" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g filter="url(#filter0_d_0_1)">
        <circle
          cx="50"
          cy="46"
          r="24.5"
          stroke="url(#paint0_linear_0_1)"
          shapeRendering="crispEdges"
        />
      </g>
      <g filter="url(#filter1_d_0_1)">
        <circle cx="50" cy="46" r="20" fill="white" />
        <circle cx="50" cy="46" r="19.75" stroke="url(#paint1_linear_0_1)" strokeWidth="0.5" />
      </g>
      <g clipPath="url(#clip0_0_1)">
        <path
          d="M54.5863 45.7256L49.961 41.1131C49.8095 40.962 49.5641 40.9623 49.4128 41.1139C49.2617 41.2654 49.2621 41.5109 49.4136 41.662L53.7636 46L49.4135 50.338C49.2619 50.4891 49.2615 50.7345 49.4127 50.886C49.4885 50.962 49.5879 51 49.6872 51C49.7863 51 49.8853 50.9623 49.961 50.8868L54.5863 46.2744C54.6593 46.2018 54.7003 46.103 54.7003 46C54.7003 45.897 54.6592 45.7983 54.5863 45.7256Z"
          fill="black"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_0_1"
          x="0"
          y="0"
          width="100"
          height="100"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <filter
          id="filter1_d_0_1"
          x="5"
          y="5"
          width="90"
          height="90"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="12.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0" />
          <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1" />
          <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape" />
        </filter>
        <linearGradient
          id="paint0_linear_0_1"
          x1="92.5"
          y1="59"
          x2="25"
          y2="21"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAD3E3" />
          <stop offset="1" stopColor="#92B7E5" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_0_1"
          x1="84"
          y1="56.4"
          x2="30"
          y2="26"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#AAD3E3" />
          <stop offset="1" stopColor="#92B7E5" />
        </linearGradient>
        <clipPath id="clip0_0_1">
          <rect width="10" height="10" fill="white" transform="translate(47 41)" />
        </clipPath>
      </defs>
    </svg>
  );
}
