import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { Card, Stack, Link, Container, Typography } from '@mui/material';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
// components
import Page from 'components/Page';
import ProjectProvider from 'provider/ProjectProvider';
import ProjectPage from 'pages/project/ProjectPage';
import TopNavbar from 'layouts/vertical/TopNavbar';
// ----------------------------------------------------------------------
const RootStyle = styled(Page)(({ theme }) => ({
  width: '100%',
  height: `calc(100vh - ${APP_BAR_MOBILE * 2.8}px)`,
  overflow: 'hidden',
  [theme.breakpoints.up('lg')]: {
    height: `calc(100vh - ${APP_BAR_DESKTOP * 2.2}px)`
  },
  zIndex: 1
}));
const SectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100vh',
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_cloud.svg)`,
  backgroundSize: 'auto 100%'
}));
const FloatRightSectionStyle = styled('div')(({ theme }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: 0,
  padding: theme.spacing(0, 3, 0, 3)
}));
const ContentStyle = styled('div')(({ theme }) => ({
  width: '600px',
  zIndex: 1
}));

export default function TechProjects({ ...props }) {
  return (
    <ProjectProvider>
      <TopNavbar   />
      <RootStyle title="Projets | JPS France">
        <SectionStyle />
        <FloatRightSectionStyle>
          <ContentStyle>
            <ProjectPage />
          </ContentStyle>
        </FloatRightSectionStyle>
      </RootStyle>
    </ProjectProvider>
  );
}
