// routes
import { Provider as ReduxProvider } from 'react-redux';
import { createBrowserHistory } from 'history';
import { PersistGate } from 'redux-persist/lib/integration/react';
import LoadingScreen from 'components/LoadingScreen';
import { BaseOptionChartStyle } from 'components/charts/BaseOptionChart';
import DateAdapter from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import Router from './routes';
// theme
import { ThemeConfig } from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { store, persistor } from './redux/store';
import AuthProvider from './provider/AuthProvider';
import NotistackProvider from './provider/NotistackProvider';
// ----------------------------------------------------------------------
const history = createBrowserHistory();

export default function App() {
  return (
    <ReduxProvider store={store}>
      <PersistGate loading={<LoadingScreen />} persistor={persistor}>
        <ThemeConfig>
          <NotistackProvider>
            <ScrollToTop />
            <BaseOptionChartStyle />
            <AuthProvider>
              <LocalizationProvider dateAdapter={DateAdapter}>
                <Router history={history} />
              </LocalizationProvider>
            </AuthProvider>
          </NotistackProvider>
        </ThemeConfig>
      </PersistGate>
    </ReduxProvider>
  );
}
