import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../hooks/useAuth';
import { PATH_PAGE, PATH_APP } from '../config/paths';
// ----------------------------------------------------------------------
export default function RequireGuest({ children }) {
  const { user, isAuthenticated } = useAuth();
  const location = useLocation();
  const toDashboard = user && user.profile ? `/${user.profile}` : PATH_APP.waiting;
  return isAuthenticated === false ? (
    children
  ) : (
    <Navigate to={toDashboard} replace state={{ path: location.pathname }} />
  );
}
