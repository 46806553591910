import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import GlazingMenuAction from './GlazingMenuAction';
// ----------------------------------------------------------------------

const BoxImgRightStyle = styled(Box)(({ theme }) => ({
  width: '200px',
  background: `linear-gradient(180deg, #89DDF1 0%, #65B0E9 100%);`
}));
export default function GlazingListItem({
  buildingCode,
  projectCode,
  problematic,
  item,
  style,
  handleEditGlazing,
  handleDupliGlazing,
  ...props
}) {
  const theme = useTheme();

  return (
    <Card
      sx={{
        display: 'flex',
        minHeight: '170px',
        width: '100%',
        ...style
      }}
    >
      <Box
        sx={{
          position: 'relative',
          padding: theme.spacing(3, 0, 3, 0),
          display: 'flex',
          flexDirection: 'column',
          width: '100%'
        }}
      >
        <Box sx={{ position: 'absolute', top: 5, right: 10 }}>
          <GlazingMenuAction
            buildingCode={buildingCode}
            projectCode={projectCode}
            item={item}
            handleEditGlazing={handleEditGlazing}
            handleDupliGlazing={handleDupliGlazing}
          />
        </Box>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            height: '100%'
          }}
        >
          <Typography component="div" variant="h4">
            {item.label}
          </Typography>
          <Typography variant="overline" color="text.secondary" component="span">
            {item.room}
          </Typography>
        </CardContent>
      </Box>
      <BoxImgRightStyle />
    </Card>
  );
}
