import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsReducer from './slices/settings';
import authReducer from './slices/authSlice';
import referentialReducer from './slices/referentialSlice';
import techEditProjectReducer from './slices/techEditProjectSlice';
import clientEditProjectReducer from './slices/clientEditProjectSlice';
import projectUnityReducer from './slices/projectUnitySlice';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  blackList: 'projectUnity',
  whitelist: ['settings', 'clientEditProject']
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  auth: authReducer,
  referential: referentialReducer,
  techEditProject: techEditProjectReducer,
  clientEditProject: clientEditProjectReducer,
  projectUnity: projectUnityReducer
});

export { rootPersistConfig, rootReducer };
