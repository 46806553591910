import React, { useMemo } from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Scrollbar from 'components/Scrollbar';
import CreationCard from './CreationCard';
import GlazingListItem from './GlazingListItem';

// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-end',
  overflowY: 'auto',
  overflowX: 'hidden',
  margin: theme.spacing(2, 0, 0, 0),
  padding: theme.spacing(1, 1, 1, 0)
}));
export default function GlazingList({
  onCreate,
  project,
  handleEditGlazing,
  handleDupliGlazing,
  ...props
}) {
  const theme = useTheme();

  return useMemo(() => {
    try {
      return (
        <RootStyle>
          <CreationCard onCreate={onCreate} project={project} />
          {project && project.buildings && project.buildings.length > 0 && (
            <>
              {project.buildings[0].glazings.map((p, i) => {
                return (
                  <GlazingListItem
                    projectCode={project.code}
                    buildingCode={project.buildings[0].code}
                    key={Math.random()}
                    problematic={p}
                    item={p}
                    handleEditGlazing={() => handleEditGlazing(p.problematic, p)}
                    handleDupliGlazing={() => handleDupliGlazing(p.problematic, p)}
                    style={{ marginTop: i > 0 ? theme.spacing(2) : 0 }}
                  />
                );
              })}
            </>
          )}
        </RootStyle>
      );
    } catch (error) {
      return <></>;
    }
  }, [project]);
}
