import React, { useState, useEffect, useMemo } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import RadioBtnStyled from 'components/custom/RadioBtnStyled';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { styled, useTheme } from '@mui/material/styles';
import { PATH_TECH } from 'config/paths';
import { GLAZING_HEIGHT, GLAZING_WIDTH } from 'config/appConfig';
import * as Yup from 'yup';
import { useSnackbar } from 'notistack';
import { MIconButton } from 'theme/@material-extend/Buttons';
import { Icon } from '@iconify/react';
import eyeFill from '@iconify/icons-eva/eye-fill';
import closeFill from '@iconify-icons/eva/close-fill';
import eyeOffFill from '@iconify/icons-eva/eye-off-fill';
import GradientBtn from 'components/custom/GradientBtn';
import { useFormik, Form, FormikProvider } from 'formik';
import useTechEditProject from 'hooks/useTechEditProject';

// ----------------------------------------------------------------------

const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#121E6E'
  }
});

const AntTab = styled((props) => <Tab disableRipple {...props} />)(({ theme }) => ({
  textTransform: 'uppercase',
  fontStyle: 'normal',
  fontWeight: 'normal',
  fontSize: '18px',
  lineHeight: '48px',
  minWidth: 0,
  [theme.breakpoints.up('sm')]: {
    minWidth: 0
  },
  marginRight: theme.spacing(1),

  '&:hover': {
    color: '#3C60B5',
    opacity: 1
  },
  '&.Mui-selected': {
    color: '#3C60B5',
    fontWeight: theme.typography.fontWeightMedium
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#d1eaff'
  }
}));

export default function GlazingTabs({
  onCancelTabs,
  onBackToForm,
  errorOnValid,
  editGlazingTabs,
  onComplete,
  ...props
}) {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [currentTab, setCurrentTab] = useState(0);
  const [formatGlazing, setFormatGlazing] = useState(null);
  const [typoGlazing, setTypoGlazing] = useState(null);
  const [expoGlazing, setexpoGlazing] = useState(null);
  useEffect(() => {
    if (!editGlazingTabs) return;
    const {
      glazing_format,
      height,
      width,
      removable_frame,
      glazing_type,
      teinted_glazing,
      frame_type,
      joint_type,
      joint_status,
      shutter_type,
      wind_exposure,
      sun_exposure,
      shaded_area,
      advertising
    } = editGlazingTabs;
    setFormatGlazing({ glazing_format, height, width });
    setTypoGlazing({
      removable_frame,
      glazing_type,
      teinted_glazing,
      frame_type,
      joint_type,
      joint_status,
      shutter_type
    });
    setexpoGlazing({ wind_exposure, sun_exposure, shaded_area, advertising });
  }, [editGlazingTabs]);
  const handleChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const onUpdateFormat = (values, isNotSubmited) => {
    if (isNotSubmited) {
      setFormatGlazing({ ...values });
    } else {
      setFormatGlazing({ ...values });
      setCurrentTab(1);
    }
  };
  const onUpdateTypo = (values, isNotSubmited) => {
    if (isNotSubmited) {
      setTypoGlazing({ ...values });
    } else {
      setTypoGlazing({ ...values });
      setCurrentTab(2);
    }
  };
  const onUpdateExpo = (values, isNotSubmited) => {
    if (isNotSubmited) {
      setexpoGlazing({ ...values });
    } else {
      setexpoGlazing({ ...values });
      const obj = { ...formatGlazing, ...typoGlazing, ...values };
      onComplete(obj);
    }
  };
  if (!editGlazingTabs) return;

  const isDisableTypo = () => {
    try {
      if (!formatGlazing) return true;
      if (!formatGlazing.width) return true;
      if (!formatGlazing.height) return true;
      if (!formatGlazing.glazing_format) return true;
      return false;
    } catch (error) {
      return true;
    }
  };
  const isDisableExpo = () => {
    try {
      if (!typoGlazing) return true;
      const {
        frame_type,
        glazing_type,
        joint_status,
        joint_type,
        removable_frame,
        shutter_type,
        teinted_glazing
      } = typoGlazing;
      if (!frame_type) return true;
      if (!glazing_type) return true;

      if (!shutter_type) return true;
      if (!joint_status) return true;
      if (!removable_frame) return true;
      if (!teinted_glazing) return true;

      return false;
    } catch (error) {
      return true;
    }
  };

  return (
    <>
      <Box
        display="flex"
        justifyContent="fex-start"
        alignItems="center"
        sx={{ mt: theme.spacing(4) }}
      >
        {errorOnValid ? (
          <IconButton size="large" onClick={onBackToForm}>
            <SettingsBackupRestoreIcon />
          </IconButton>
        ) : (
          <IconButton size="large" onClick={onCancelTabs}>
            <ArrowBackIcon />
          </IconButton>
        )}

        <Typography variant="h3" sx={{ textTransform: 'uppercase', ml: theme.spacing(4) }}>
          {editGlazingTabs.name}
        </Typography>
      </Box>
      <AntTabs value={currentTab} onChange={handleChange} aria-label="Contact" centered>
        <AntTab label="Format" />
        <AntTab label="Typologie" disabled={isDisableTypo()} />
        <AntTab label="Exposition" disabled={isDisableExpo()} />
      </AntTabs>
      {currentTab === 0 && (
        <FormatTab formatGlazing={formatGlazing} onUpdateFormat={onUpdateFormat} />
      )}
      {currentTab === 1 && <TypoTab typoGlazing={typoGlazing} onUpdateTypo={onUpdateTypo} />}
      {currentTab === 2 && <ExpoTab expoGlazing={expoGlazing} onUpdateExpo={onUpdateExpo} />}
    </>
  );
}

function FormatTab({ formatGlazing, onUpdateFormat, ...props }) {
  const { glazing_format } = useTechEditProject();
  const theme = useTheme();
  const FormatSchema = Yup.object().shape({
    glazing_format: Yup.string().required('Veuillez renseigner le format de la vitre'),
    height: Yup.string()
      .required(`Veuillez sélectionner la hauteur de la vitre`)
      .max(10, 'Maximum 10 digits'),
    width: Yup.string()
      .required(`Veuillez sélectionner le largeur de la vitre`)
      .max(10, 'Maximum 10 digits')
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      glazing_format:
        formatGlazing && formatGlazing.glazing_format ? formatGlazing.glazing_format.code : '',
      height: formatGlazing && formatGlazing.height ? formatGlazing.height : '',
      width: formatGlazing && formatGlazing.width ? formatGlazing.width : ''
    },

    validationSchema: FormatSchema,
    onSubmit: async (values) => {
      try {
        const formatFounded = glazing_format.find((it) => it.code === values.glazing_format);
        if (formatFounded) {
          const newValues = {
            ...values,
            glazing_format: formatFounded
          };
          onUpdateFormat(newValues);
        }
      } catch (error) {
        console.error(error);
      }
    }
  });

  const {
    errors,
    touched,
    values,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleSubmit,
    getFieldProps
  } = formik;
  const disabledHeight =
    String(values.glazing_format) === '0' || String(values.glazing_format) === '2';

  useEffect(() => {
    if (disabledHeight) {
      const valWidth = values.width;
      setFieldValue('height', valWidth);
    }
  }, [disabledHeight, values.width]);

  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{
            margin: theme.spacing(3, 0, 3, 0),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Format de la vitre"
              value={values.glazing_format}
              onChange={handleChange('glazing_format')}
              {...getFieldProps('glazing_format')}
              error={Boolean(touched.glazing_format && errors.glazing_format)}
              helperText={touched.glazing_format && errors.glazing_format}
            >
              {glazing_format.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="number"
              label="Largeur"
              {...getFieldProps('width')}
              error={Boolean(touched.width && errors.width)}
              helperText={touched.width && errors.width}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>
              }}
            />
            <TextField
              disabled={disabledHeight}
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              fullWidth
              type="number"
              label="Hauteur"
              {...getFieldProps('height')}
              error={Boolean(touched.height && errors.height)}
              helperText={touched.height && errors.height}
              InputProps={{
                endAdornment: <InputAdornment position="end">cm</InputAdornment>
              }}
            />
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(4, 0, 2, 0) }}
            >
              Suivant
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}

function TypoTab({ typoGlazing, onUpdateTypo, ...props }) {
  const { glazing_format, glazing_types, frame_type, joint_types, joint_status, shutter_type } =
    useTechEditProject();
  const theme = useTheme();

  const TypoSchema = Yup.object().shape({
    glazing_type: Yup.string().required(`Veuillez renseigner le type de la vitre`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      glazing_type: typoGlazing && typoGlazing.glazing_type ? typoGlazing.glazing_type.code : '',
      removable_frame: typoGlazing ? Boolean(typoGlazing.removable_frame) : false,
      teinted_glazing: typoGlazing ? Boolean(typoGlazing.teinted_glazing) : false,
      frame_type: typoGlazing && typoGlazing.frame_type ? typoGlazing.frame_type.code : '',
      joint_type: typoGlazing && typoGlazing.joint_type ? typoGlazing.joint_type.code : '',
      joint_status: typoGlazing && typoGlazing.joint_status ? typoGlazing.joint_status.code : '',
      shutter_type: typoGlazing && typoGlazing.shutter_type ? typoGlazing.shutter_type.code : ''
    },
    validationSchema: TypoSchema,
    onSubmit: async (values) => {
      try {
        onUpdateTypo({ ...getFormValue(values) });
      } catch (error) {
        console.error(error);
      }
    }
  });
  const getFormValue = (values) => {
    try {
      const frameType = frame_type.find((it) => it.code === values.frame_type);
      const glazing_type = glazing_types.find((it) => it.code === values.glazing_type);
      const jointStatus = joint_status.find(
        (it) => String(it.code) === String(values.joint_status)
      );
      const joint_type = joint_types.find((it) => it.code === values.joint_type);
      const shutterType = shutter_type.find((it) => it.code === values.shutter_type);
      const newValues = {
        ...values,
        removable_frame: Boolean(values.removable_frame),
        teinted_glazing: Boolean(values.teinted_glazing),
        frame_type: frameType,
        glazing_type,
        joint_status: jointStatus,
        joint_type,
        shutter_type: shutterType
      };
      return newValues;
    } catch (error) {
      return values;
    }
  };
  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } =
    formik;
  useEffect(() => {
    if (!values) return;
    onUpdateTypo({ ...getFormValue(values) }, true);
  }, [values]);
  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{
            margin: theme.spacing(3, 0, 3, 0),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Encadrement démontable</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.removable_frame}
                onChange={handleChange('removable_frame')}
              >
                <FormControlLabel
                  value={Boolean(true)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Oui
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={Boolean(false)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Non
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Type de la vitre"
              value={values.type}
              onChange={handleChange('glazing_type')}
              {...getFieldProps('glazing_type')}
              error={Boolean(touched.glazing_type && errors.glazing_type)}
              helperText={touched.glazing_type && errors.glazing_type}
            >
              {glazing_types.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Vitre teintée</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.teinted_glazing}
                onChange={handleChange('teinted_glazing')}
              >
                <FormControlLabel
                  value={Boolean(true)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Oui
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={Boolean(false)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Non
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Type d'encadrement"
              value={values.frame_type}
              onChange={handleChange('frame_type')}
              {...getFieldProps('frame_type')}
              error={Boolean(touched.frame_type && errors.frame_type)}
              helperText={touched.frame_type && errors.frame_type}
            >
              {frame_type.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Type de joint"
              value={values.joint_type}
              onChange={handleChange('joint_type')}
              {...getFieldProps('joint_type')}
              error={Boolean(touched.joint_type && errors.joint_type)}
              helperText={touched.joint_type && errors.joint_type}
            >
              {joint_types.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Etat du joint</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.joint_status}
                onChange={handleChange('joint_status')}
              >
                {joint_status.map((it) => {
                  return (
                    <FormControlLabel
                      key={Math.random()}
                      value={it.code}
                      control={<RadioBtnStyled />}
                      label={it.label}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Type de store"
              value={values.shutter_type}
              onChange={handleChange('shutter_type')}
              {...getFieldProps('shutter_type')}
              error={Boolean(touched.shutter_type && errors.shutter_type)}
              helperText={touched.shutter_type && errors.shutter_type}
            >
              {shutter_type.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(4, 0, 2, 0) }}
            >
              Suivant
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
function ExpoTab({ expoGlazing, onUpdateExpo, ...props }) {
  const { wind_exposure, sun_exposure, shaded_area } = useTechEditProject();
  const theme = useTheme();

  const ExpoSchema = Yup.object().shape({
    sun_exposure: Yup.string().required(`Veuillez renseigner l'exposition au soleil'`)
  });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      wind_exposure: expoGlazing && expoGlazing.wind_exposure ? expoGlazing.wind_exposure.code : '',
      sun_exposure: expoGlazing && expoGlazing.sun_exposure ? expoGlazing.sun_exposure.code : '',
      shaded_area: expoGlazing && expoGlazing.shaded_area ? expoGlazing.shaded_area.code : '',
      advertising: expoGlazing ? Boolean(expoGlazing.advertising) : false
    },
    validationSchema: ExpoSchema,
    onSubmit: async (values) => {
      try {
        onUpdateExpo({ ...getFormValue(values) });
      } catch (error) {
        console.error(error);
      }
    }
  });
  const getFormValue = (values) => {
    try {
      const windExposure = wind_exposure.find(
        (it) => String(it.code) === String(values.wind_exposure)
      );
      const sunExposure = sun_exposure.find(
        (it) => String(it.code) === String(values.sun_exposure)
      );
      const shadedArea = shaded_area.find((it) => String(it.code) === String(values.shaded_area));
      const newValues = {
        advertising: Boolean(values.advertising),
        wind_exposure: windExposure,
        sun_exposure: sunExposure,
        shaded_area: shadedArea
      };
      return newValues;
    } catch (error) {
      return values;
    }
  };
  const { errors, touched, values, isSubmitting, handleChange, handleSubmit, getFieldProps } =
    formik;
  useEffect(() => {
    if (!values) return;
    onUpdateExpo({ ...getFormValue(values) }, true);
  }, [values]);
  return (
    <>
      <FormikProvider value={formik}>
        <Form
          autoComplete="off"
          noValidate
          onSubmit={handleSubmit}
          style={{
            margin: theme.spacing(3, 0, 3, 0),
            width: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Stack spacing={2} sx={{ width: '100%' }}>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Exposition au vent</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.wind_exposure}
                onChange={handleChange('wind_exposure')}
              >
                {wind_exposure.map((it) => {
                  return (
                    <FormControlLabel
                      key={Math.random()}
                      value={it.code}
                      control={<RadioBtnStyled />}
                      label={it.label}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
            <TextField
              sx={{ input: { fontFamily: 'ProximaLight', color: '#95A2AD' } }}
              select
              label="Exposition au soleil"
              value={values.sun_exposure}
              onChange={handleChange('sun_exposure')}
              {...getFieldProps('sun_exposure')}
              error={Boolean(touched.sun_exposure && errors.sun_exposure)}
              helperText={touched.sun_exposure && errors.sun_exposure}
            >
              {sun_exposure.map((opt) => {
                return (
                  <MenuItem key={Math.random()} value={opt.code}>
                    {opt.label}
                  </MenuItem>
                );
              })}
            </TextField>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Zone ombragée</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.shaded_area}
                onChange={handleChange('shaded_area')}
              >
                {shaded_area.map((it) => {
                  return (
                    <FormControlLabel
                      key={Math.random()}
                      value={it.code}
                      control={<RadioBtnStyled />}
                      label={it.label}
                    />
                  );
                })}
              </RadioGroup>
            </Box>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              <FormLabel component="legend">Présence de publicité</FormLabel>
              <RadioGroup
                row
                aria-labelledby="demo-controlled-radio-buttons-group"
                name="controlled-radio-buttons-group"
                value={values.advertising}
                onChange={handleChange('advertising')}
              >
                <FormControlLabel
                  value={Boolean(true)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Oui
                    </Typography>
                  }
                />
                <FormControlLabel
                  value={Boolean(false)}
                  control={<RadioBtnStyled />}
                  label={
                    <Typography sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
                      Non
                    </Typography>
                  }
                />
              </RadioGroup>
            </Box>
          </Stack>
          <Stack direction="row" alignItems="center" justifyContent="center">
            <GradientBtn
              type="submit"
              size="large"
              color="info"
              variant="contained"
              sx={{ width: '211px', margin: theme.spacing(4, 0, 2, 0) }}
            >
              Enregistrer
            </GradientBtn>
          </Stack>
        </Form>
      </FormikProvider>
    </>
  );
}
const LabelStyle = styled(Typography)(({ selected, theme }) => ({
  textAlign: 'left',
  color: '#121E6E',
  width: '100%',
  margin: theme.spacing(3, 0, 2, 0)
}));
const SurfaceBtnStyle = styled(Box)(({ selected, theme }) => ({
  width: '85px',
  height: '31px',
  background: selected ? '#93D5FE' : '#E1E4E6',
  borderRadius: '10px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer'
}));
const DimSelect = ({ select, onSelect, label, options, ...props }) => {
  const theme = useTheme();
  return useMemo(() => {
    return (
      <>
        <LabelStyle variant="body1">{label}</LabelStyle>
        <Box
          display="flex"
          alignItems="center"
          justifyContent={options.length <= 3 ? 'flex-start' : 'space-between'}
          sx={{ width: '100%' }}
        >
          {options.map((opt) => {
            return (
              <SurfaceBtnStyle
                sx={{ mr: theme.spacing(2) }}
                key={Math.random()}
                selected={select ? opt.code === select : false}
                onClick={() => onSelect(opt.code)}
              >
                <Typography variant="body2">{opt.label}</Typography>
              </SurfaceBtnStyle>
            );
          })}
        </Box>
      </>
    );
  }, [select]);
};
