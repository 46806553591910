import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { PATH_TECH } from 'config/paths';
import useTechEditProject from 'hooks/useTechEditProject';

// ----------------------------------------------------------------------

export default function GlazingMenuAction({
  buildingCode,
  projectCode,
  item,
  handleEditGlazing,
  handleDupliGlazing,
  ...props
}) {
  const { initProjectById, removeGlazing } = useTechEditProject();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleOpenMenuAction = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const onEdit = () => {
    handleEditGlazing();
    handleClose();
  };
  const onDupli = () => {
    handleDupliGlazing();
    handleClose();
  };

  const onDelete = async () => {
    try {
      const p = {
        project: {
          id: projectCode,
          buildings: [
            {
              code: buildingCode,
              glazings: [
                {
                  code: item.code
                }
              ]
            }
          ]
        }
      };
      await removeGlazing(p);
      initProjectById(projectCode);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <>
      <IconButton
        sx={{ p: '10px' }}
        aria-label="Menu"
        id="demo-positioned-button"
        aria-controls={open ? 'demo-positioned-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleOpenMenuAction}
      >
        <MoreVertIcon />
      </IconButton>
      <Menu
        id="demo-positioned-menu"
        aria-labelledby="demo-positioned-button"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
      >
        <MenuItem onClick={onEdit}>
          <EditIcon fontSize="small" sx={{ mr: theme.spacing(1) }} /> Editer
        </MenuItem>
        <MenuItem onClick={onDupli}>
          <ContentCopyIcon fontSize="small" sx={{ mr: theme.spacing(1) }} /> Dupliquer
        </MenuItem>
        <MenuItem onClick={onDelete}>
          <DeleteForeverIcon color="error" fontSize="small" sx={{ mr: theme.spacing(1) }} />{' '}
          Supprimer
        </MenuItem>
      </Menu>
    </>
  );
}
