import React, { useMemo } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import useAuth from 'hooks/useAuth';
import { intervalToDuration } from 'date-fns';
import { isTechProfile } from 'config/appConfig';
import ProjectMenuAction from './ProjectMenuAction';
// ----------------------------------------------------------------------
const StatusStyle = styled(Box)(({ bg, theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: '20px',
  width: '100px',
  height: '24px',
  padding: theme.spacing(2),
  display: 'flex',
  justifyItems: 'flex-start',
  alignItems: 'center',
  color: '#FFFFFF',
  background: bg
}));
export default function ProjectListItem({ item, style, ...props }) {
  const theme = useTheme();
  const { user, logout } = useAuth();
  const duration = () => {
    try {
      const start = new Date(item.created);

      const end = new Date();
      const dur = intervalToDuration({
        start,
        end
      }); //  years,  days,  hours,  minutes,  seconds
      return dur.days;
    } catch (error) {
      console.error(error);
      return '';
    }
  };
  const getImage = () => {
    try {
      const url = item.photo ? item.photo : item.problematics[0].photo;
      return url;
    } catch (error) {
      return '';
    }
  };
  const getContactName = () => {
    try {
      const client = item.contacts.find(
        (it) => it.administrator === 1 && it.company_type === 'customer'
      );
      return client.firstname;
    } catch (error) {
      return '';
    }
  };
  return useMemo(() => {
    if (!item) return <></>;
    try {
      return (
        <Card
          sx={{
            display: 'flex',
            minHeight: '170px',
            width: '100%',
            ...style
          }}
        >
          <Box
            sx={{
              position: 'relative',
              display: 'flex',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            {isTechProfile(user) && (
              <Box sx={{ position: 'absolute', top: 5, right: 10 }}>
                <ProjectMenuAction item={item} />
              </Box>
            )}
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
              }}
            >
              <Typography component="div" variant="h4">
                {item.name}
              </Typography>
              <Typography variant="overline" color="text.secondary" component="span">
                {getContactName()}
              </Typography>
              <Typography
                variant="caption"
                color="text.secondary"
                component="span"
                sx={{ marginTop: theme.spacing(1), fontFamily: 'ProximaLight' }}
              >
                {item.created_at}
              </Typography>
            </CardContent>
            <StatusStyle bg={item.status.color}>
              <Typography variant="subtitle2">{item.status.label} </Typography>
            </StatusStyle>
          </Box>
          <Box
            sx={{
              width: '200px',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
              backgroundImage: `url(${getImage()})`,
              backgroundSize: 'cover'
            }}
          />
        </Card>
      );
    } catch (error) {
      return <></>;
    }
  }, [item]);
}
