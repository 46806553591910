import React, { useContext, useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import { useTheme, styled } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import * as Yup from 'yup';
import { useFormik, Form, FormikProvider } from 'formik';
import Button from '@mui/material/Button';
import SendIcon from 'components/custom/icons/SendIcon';
// ----------------------------------------------------------------------
const StepFormStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(3, 0, 0, 0)
}));
const useStyles = makeStyles(() => ({
  input: {
    color: 'white',
    '& ::placeholder': {
      color: '#000000'
    }
  }
}));
const regex = new RegExp('^[a-zA-Z0-9.,/ $@()]+$');
export default function StepEntryForm({ step, handleSubmitStep, ...props }) {
  const theme = useTheme();
  const classes = useStyles();
  const [textEntered, setTextEntered] = useState([]);

  const EntrySchema = Yup.object().shape({
    entryText: Yup.string().required(`Veuillez renseigner le ${step ? step.id : ''} !`)
  });
  const formik = useFormik({
    initialValues: {
      entryText: step && step.user.text ? step.user.text : ''
    },
    validationSchema: EntrySchema,
    onSubmit: (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        handleSubmitStep(step, values);
        resetForm();
      } catch (error) {
        console.error(error);
      }
    }
  });
  const {
    errors,
    touched,
    values,
    isSubmitting,
    handleSubmit,
    setFieldValue,
    submitForm,
    getFieldProps
  } = formik;
  useEffect(() => {
    setFieldValue('entryText', textEntered.join(''));
  }, [textEntered]);
  if (!step) return <></>;
  return (
    <StepFormStyle>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <TextField
              InputProps={{
                className: classes.input
              }}
              sx={{
                flexGrow: 1,
                input: {
                  fontFamily: 'ProximaLight',
                  color: '#000000',
                  label: { color: 'blue' }
                }
              }}
              fullWidth
              type="text"
              placeholder={step.placeholder}
              {...getFieldProps('entryText')}
              error={Boolean(touched.entryText && errors.entryText)}
            />
            <Button type="submit">
              <SendIcon sx={{ margin: theme.spacing(0, 0, 0, 1) }} />
            </Button>
          </Box>
        </Form>
      </FormikProvider>
    </StepFormStyle>
  );
}
