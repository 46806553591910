import * as React from 'react';
import { useTheme, styled } from '@mui/material/styles';
import Button from '@mui/material/Button';

// ----------------------------------------------------------------------
const ButtonStyle = styled(Button)(({ theme }) => ({
  marginRight: theme.spacing(2),
  border: '0.5px solid #FFFFFF',
  background: 'transparent',
  color: '#FFFFFF',
  borderRadius: '5px',
  '&:hover': { opacity: 0.6 }
}));
export default function SaveOrderButton({ handleClick, ...props }) {
  const theme = useTheme();
  return (
    <ButtonStyle
      sx={{}}
      variant="outlined"
      aria-label="select merge strategy"
      aria-haspopup="menu"
      onClick={handleClick}
    >
      Enregistrer
    </ButtonStyle>
  );
}
