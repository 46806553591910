import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { styled, useTheme } from '@mui/material/styles';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import GradientBtn from 'components/custom/GradientBtn';
import { EditProjectContext } from 'provider/EditProjectProvider';
import { BOT_WIDTH, BOT_HEIGHT } from 'config/appConfig';
import { PATH_CLIENT } from 'config/paths';
// ----------------------------------------------------------------------
const BillStyle = styled('div')(({ theme }) => ({
  width: `720px`,
  height: `100vh`,
  position: 'absolute',
  top: 0,
  left: 0,
  zIndex: 0,
  background: '#F9F9F9',
  boxShadow: '0px 8px 25px rgba(0, 0, 0, 0.15)',
  borderRadius: '5px',
  padding: theme.spacing(1, 4, 4, 4),
  '& .title': {
    fontStyle: 'normal',
    fontWeight: 'bold',
    fontSize: '36px',
    lineHeight: '44px',
    textTransform: 'uppercase',
    color: '#121E6E',
    textAlign: 'center',
    margin: theme.spacing(12, 0, 4, 0)
  },
  '& .pro-title': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    textTransform: 'uppercase',
    letterSpacing: '0.1em',
    color: '#000000'
  },
  '& .sub-title': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '40px',
    textTransform: 'uppercase',
    color: '#121E6E'
  },
  '& .paragraph': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '40px',
    color: '#000000',
    margin: theme.spacing(1, 0, 2, 0)
  },
  '& .label': {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: '18px',
    lineHeight: '22px',
    color: '#121E6E'
  }
}));
const BgBotStyle = styled('div')(({ theme }) => ({
  width: `${BOT_WIDTH}px`,
  height: `${BOT_HEIGHT}px`,
  position: 'absolute',
  bottom: 0,
  right: 0,
  zIndex: 0,
  backgroundPosition: 'bottom',
  backgroundRepeat: 'no-repeat',
  backgroundImage: `url(${process.env.PUBLIC_URL}/static/bg/bg_bot_confirm.svg)`,
  backgroundSize: 'contain'
}));
const WrapperBoxStyle = styled('div')(({ theme }) => ({
  width: '630px',
  height: '480px',
  position: 'absolute',
  bottom: `${BOT_HEIGHT - 450}px`,
  right: `${BOT_WIDTH - 250}px`
}));
const MsgBoxStyle = styled('div')(({ theme }) => ({
  width: '619px',
  height: 'auto',
  background: '#668AF5',
  borderRadius: '50px 50px 0px 50px',
  padding: theme.spacing(3)
}));
const TextBoxStyle = styled(Typography)(({ theme }) => ({
  fontFamily: 'Proxima Nova',
  fontStyle: 'normal',
  fontWeight: '600',
  fontSize: '24px',
  lineHeight: '54px',
  color: '#FFFFFF'
}));
export default function ProjectValidated({ ...props }) {
  const theme = useTheme();
  const { projectValidated } = useContext(EditProjectContext);

  return useMemo(() => {
    if (!projectValidated) return <></>;
    return (
      <>
        <BillStyle>
          <Box className="title">Récapitulatif</Box>
          <Box className="pro-title" sx={{ margin: theme.spacing(2, 0, 8, 0) }}>
            {projectValidated.name}
          </Box>

          <Box className="sub-title">Typologie</Box>
          <Box className="paragraph">{projectValidated.selectBuilding.label}</Box>
          <Box className="label">Superficie du bâtiment</Box>
          <Box className="paragraph">
            {projectValidated.selectBuildingTypo.configSpec.surfaceSelected.label}
          </Box>
          <Box className="label">Type de vitrage</Box>
          <Box className="paragraph">
            {projectValidated.selectBuildingTypo.configSpec.glazingType.label}
          </Box>
          <Box className="label">Nombre d'étage</Box>
          <Box className="paragraph">{projectValidated.selectBuildingTypo.configSpec.floorsNb}</Box>
          <Box className="label">Type de toit</Box>
          <Box className="paragraph">
            {projectValidated.selectBuildingTypo.configSpec.roofType.label}
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="flex-end"
            alignItems="flex-end"
          >
            <Box className="sub-title">Les films adhésifs</Box>
            {projectValidated.selectProduct.products.map((p) => {
              return (
                <Box
                  sx={{ margin: theme.spacing(2, 4, 0, 0), textAlign: 'right' }}
                  key={Math.random()}
                >
                  <Box className="sub-title">{p.category.label}</Box>
                  <Box className="paragraph" sx={{ margin: '0!important' }}>
                    {p.name}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </BillStyle>
        <BgBotStyle />
        <WrapperBoxStyle>
          <MsgBoxStyle>
            <TextBoxStyle variant="body1"> Votre projet a été créé avec succès</TextBoxStyle>
            <TextBoxStyle variant="body1" sx={{ margin: theme.spacing(3, 0, 3, 0) }}>
              Prochain step ! Un technicien va prendre rendez-vous afin de vous établir un devis
            </TextBoxStyle>
          </MsgBoxStyle>
          <Box
            display="flex"
            justifyContent="flex-end"
            sx={{ width: '100%', margin: theme.spacing(2, 0, 0, 0) }}
          >
            <GradientBtn
              size="large"
              color="info"
              component={RouterLink}
              to={`${PATH_CLIENT.projects}`}
              variant="contained"
              sx={{ minWidth: '200px' }}
            >
              Fermer
            </GradientBtn>
          </Box>
        </WrapperBoxStyle>
      </>
    );
  }, [projectValidated]);
}
