import { useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
// material
import { styled } from '@mui/material/styles';
import { APP_BAR_MOBILE, APP_BAR_DESKTOP } from 'config/appConfig';
import useProjectUnity from 'hooks/useProjectUnity';
import UnitySection from 'pages/client/UnitySection';
import ProgressBarUnity from 'components/unity/ProgressBarUnity';
import useClientEditProject from 'hooks/useClientEditProject';

//
import TopNavbar from './TopNavbar';
// ----------------------------------------------------------------------
const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
  background:
    'linear-gradient(68deg, #85A9E6 0.04%, #B0D9E2 55.61%, rgba(255, 255, 255, 0) 101.68%)'
});

const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflowX: 'hidden',
  overflowY: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_DESKTOP,
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_DESKTOP
  }
}));

// ----------------------------------------------------------------------

export default function ClientLayout() {
  const { sceneInitialized, progression, sceneLoaded } = useProjectUnity();
  const { isMediaCaching } = useClientEditProject();
  return (
    <RootStyle>
      <TopNavbar />
      <MainStyle>
        <UnitySection />

        {sceneLoaded && sceneInitialized ? (
          <Outlet />
        ) : (
          <ProgressBarUnity progress={Math.floor(progression * 100)} />
        )}
      </MainStyle>
    </RootStyle>
  );
}
