import React, { useState, useEffect, useCallback } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import { visuallyHidden } from '@mui/utils';
import { useTheme, styled, alpha } from '@mui/material/styles';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import { MIconButton } from 'theme/@material-extend/Buttons';
import closeFill from '@iconify-icons/eva/close-fill';
import SelectCellProd from './SelectCellProd';
import EditCellNumber from './EditCellNumber';
import EditCellDim from './EditCellDim';
import { fCurrency, fNumberWithDigit } from '../../../../utils/formatNumber';
import EditCellQuty from './EditCellQuty';
// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  {
    id: 'ref',
    numeric: true,
    disablePadding: true,
    label: 'Réf'
  },
  {
    id: 'floors',
    numeric: false,
    disablePadding: false,
    label: 'Etage'
  },
  {
    id: 'designation',
    numeric: false,
    disablePadding: false,
    label: 'Désignation'
  },
  {
    id: 'measure',
    numeric: false,
    disablePadding: false,
    label: 'Largeur/Hauteur (cm)'
  },
  {
    id: 'quantity',
    numeric: true,
    disablePadding: false,
    label: 'Qté'
  },
  {
    id: 'surface',
    numeric: true,
    disablePadding: false,
    label: 'Surface vitrée (m²)'
  },
  {
    id: 'priceUnit',
    numeric: false,
    disablePadding: false,
    label: 'Prix U. HT'
  },
  {
    id: 'priceTt',
    numeric: false,
    disablePadding: false,
    label: 'Total HT'
  }
];
const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#3C60B5',
    color: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 0,
    textAlign: 'right'
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    boxShadow: 'none',
    borderRadius: 0,
    position: 'unset'
  }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.common.white,
    boxShadow: 'none',
    borderRadius: 0
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
    boxShadow: 'none',
    borderRadius: 0
  }
}));
export default function TechEditOrderBillTable({ avProducts, ...props }) {
  const theme = useTheme();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { orderBill, saveOrderBill } = useTechEditProject();
  const [items, setitems] = useState([]);
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  useEffect(() => {
    if (!orderBill) return;
    if (!orderBill.items) return;
    const arr = orderBill.items.map((it) => {
      const p = it.product[0];
      return {
        ref: it.code,
        floors: it.floors,
        designation: it.name,
        height: it.height,
        width: it.width,
        measure: `${it.width * it.height}`,
        quantity: it.quantity ? it.quantity : 1,
        surface: it.surface ? fNumberWithDigit(it.surface, 2) : '',
        priceUnit: it.selling_price_exc_tax,
        priceTt: fCurrency(it.price),
        product: p
      };
    });
    setitems(arr);
  }, [orderBill]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((n) => n.code);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event) => {
    setDense(event.target.checked);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const handeSave = useCallback(
    async (item, field, value) => {
      try {
        if (!orderBill) return;
        const itemToSaved = {};
        itemToSaved.code = item.ref;
        itemToSaved.height = item.height;
        itemToSaved.width = item.width;
        itemToSaved.quantity = item.quantity;
        itemToSaved.selling_price_exc_tax = item.priceUnit;
        if (field === 'measure') {
          const { height, width } = value;
          itemToSaved.height = height;
          itemToSaved.width = width;
        } else if (field === 'priceUnit') {
          itemToSaved.selling_price_exc_tax = value;
        } else if (field === 'quantity') {
          itemToSaved.quantity = value.quantity;
        } else {
          itemToSaved[field] = value;
        }
        const newOrd = {
          quote: {
            code: orderBill.code,
            items: [{ ...itemToSaved }]
          }
        };
        await saveOrderBill(newOrd);
        enqueueSnackbar('Sauvegarde réussie', {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'success',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      } catch (error) {
        let msg = '';
        if (error.response) {
          msg = error.response.data.message;
        } else {
          msg = 'Network error';
        }

        enqueueSnackbar(msg, {
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left'
          },
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
        throw error;
      }
    },
    [orderBill]
  );

  // Avoid a layout jump when reaching the last page with empty items.
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - items.length) : 0;
  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 items.slice().sort(getComparator(order, orderBy)) */}
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.ref);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <StyledTableRow
                      hover
                      onClick={(event) => handleClick(event, row.ref)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.ref}
                      selected={isItemSelected}
                    >
                      <StyledTableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId
                          }}
                        />
                      </StyledTableCell>
                      <StyledTableCell id={labelId} scope="row" padding="none">
                        {row.ref}
                        {/*  <SelectCellProd item={row} avProducts={avProducts} orderBill={orderBill} /> */}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.floors}</StyledTableCell>
                      <StyledTableCell align="right">{row.designation}</StyledTableCell>
                      <StyledTableCell align="right">
                        {/*   <EditCellDim
                          item={row}
                          fiedToEdit="measure"
                          fieldLabel="Dimensions"
                          handeSave={handeSave}
                        /> */}
                        {row.measure}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        {/*  <EditCellQuty
                          item={row}
                          fiedToEdit="quantity"
                          fieldLabel="Quantité"
                          handeSave={handeSave}
                        /> */}
                        {row.quantity}
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.surface}</StyledTableCell>
                      <StyledTableCell align="right">
                        <EditCellNumber
                          item={row}
                          fiedToEdit="priceUnit"
                          fieldLabel="Prix U. HT"
                          handeSave={handeSave}
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">{row.priceTt}</StyledTableCell>
                    </StyledTableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: (dense ? 33 : 53) * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          labelRowsPerPage="Articles par page"
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={items.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <StyledTableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts'
            }}
          />
        </StyledTableCell>
        {headCells.map((headCell) => (
          <StyledTableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
