import React from 'react';
import { useTheme, styled } from '@mui/material/styles';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ProjectListSearch from './ProjectListSearch';
import ProjectListFilterState from './ProjectListFilterState';
// ----------------------------------------------------------------------
const RootStyle = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  margin: theme.spacing(2, 0, 0, 0)
  // padding: theme.spacing(1, 1, 1, 0)
}));

export default function ProjectListFilter({
  handleChangeFilters,
  filters,
  handleChange,
  optionsState,
  ...props
}) {
  const theme = useTheme();
  return (
    <RootStyle>
      <ProjectListFilterState
        stateSelected={filters.state}
        optionsState={optionsState}
        handleChangeFilters={handleChangeFilters}
      />

      <Box sx={{ flexGrow: 1, margin: theme.spacing(0, 0, 0, 4) }}>
        <ProjectListSearch handleChangeFilters={handleChangeFilters} />
      </Box>
    </RootStyle>
  );
}
