import clsx from 'clsx';
import PropTypes from 'prop-types';
import React, { forwardRef } from 'react';
import { makeStyles } from '@mui/styles';
import { alpha } from '@mui/material/styles';
import { capitalize } from '@mui/material/utils';
import { Button } from '@mui/material';

// ----------------------------------------------------------------------

const useStyles = makeStyles((theme) => {
  const styleContained = (color) => ({
    textTransform: 'none',
    fontFamily: 'ProximaMedium',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '48px',
    minWidth: '220px',
    minHeight: '60px',
    boxShadow: '0px 8px 25px rgba(99, 142, 244, 0.46)',
    borderRadius: '15px',
    color: theme.palette[color].contrastText,
    background: `linear-gradient(93.46deg, #668AF5 37.45%, #52A2F9 93.7%)`,
    '&:hover': {
      background: `linear-gradient(93.46deg, #2C4DB0 37.45%, #3379C5 93.7%);`
    }
  });

  const styleOutlined = (color) => ({
    color: theme.palette[color].main,
    border: `1px solid ${alpha(theme.palette[color].main, 0.48)}`,
    '&:hover': {
      border: `1px solid ${theme.palette[color].main}`,
      backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity)
    }
  });

  const styleText = (color) => ({
    color: theme.palette[color].main,
    '&:hover': {
      backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity)
    }
  });
  return {
    containedInfo: styleContained('info'),
    containedSuccess: styleContained('success'),
    containedWarning: styleContained('warning'),
    containedError: styleContained('error'),
    containedWhite: {
      boxShadow: theme.shadows[25].z8,
      color: theme.palette.getContrastText(theme.palette.common.white),
      backgroundColor: theme.palette.common.white,
      '&:hover': {
        backgroundColor: theme.palette.grey[300]
      }
    },

    outlinedInfo: styleOutlined('info'),
    outlinedSuccess: styleOutlined('success'),
    outlinedWarning: styleOutlined('warning'),
    outlinedError: styleOutlined('error'),

    textInfo: styleText('info'),
    textSuccess: styleText('success'),
    textWarning: styleText('warning'),
    textError: styleText('error')
  };
});

// ----------------------------------------------------------------------

const GradientBtn = forwardRef(
  ({ color = 'primary', variant = 'text', children, className, ...other }, ref) => {
    const classes = useStyles();

    if (color === 'inherit' || color === 'primary' || color === 'secondary') {
      return (
        <Button ref={ref} color={color} variant={variant} className={className} {...other}>
          {children}
        </Button>
      );
    }

    return (
      <Button
        ref={ref}
        variant={variant}
        className={clsx(
          classes[variant],
          {
            [classes[`${variant}${capitalize(color)}`]]: color
          },
          className
        )}
        {...other}
      >
        {children}
      </Button>
    );
  }
);

GradientBtn.propTypes = {
  children: PropTypes.node,
  classes: PropTypes.object,
  className: PropTypes.string,
  color: PropTypes.oneOf([
    'inherit',
    'primary',
    'secondary',
    'info',
    'success',
    'warning',
    'error',
    'white'
  ]),
  variant: PropTypes.oneOfType([
    PropTypes.oneOf(['contained', 'outlined', 'text']),
    PropTypes.string
  ])
};

export default GradientBtn;
