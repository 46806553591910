import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import faker from 'faker';
import { API_URL } from 'config/appConfig';

import {
  resetProject,
  updateEditProject,
  validateSteps,
  initProjectCreated
} from 'redux/slices/clientEditProjectSlice';

import { getAllProjects, setProjectChanged, getReferential } from 'redux/slices/referentialSlice';

export default function useClientEditProject() {
  const dispatch = useDispatch();
  const {
    specifications,
    projectChanged,
    avProjects,
    buildingTypes,
    isMediaCaching,
    refInitialized
  } = useSelector((state) => state.referential);
  const { projectCreated, products, prodInitialized, steps, flows, editProject } = useSelector(
    (state) => state.clientEditProject
  );
  useEffect(() => {
    if (!refInitialized) {
      console.log('**************** useClientEditProject', refInitialized);
      dispatch(getReferential());
      dispatch(getAllProjects());
    }
  }, [refInitialized]);
  return {
    specifications,
    isMediaCaching,
    avProjects,
    projectCreated,
    buildingTypes,
    steps,
    flows,
    editProject,
    resetEditProject: () => {
      try {
        dispatch(resetProject());
      } catch (error) {
        console.error(error);
      }
    },
    onValidatedStep: (values) => {
      dispatch(validateSteps(values));
    },
    onUpdateEditProject: (values) => {
      dispatch(updateEditProject(values));
    },
    validateProject: async (pj) => {
      try {
        const projectCreated = { ...PROJECT_MODEL };
        projectCreated.name = pj.name;
        projectCreated.city = pj.location;

        const company = {
          name: pj.contacts[0].nameCompany,
          type: 'societe',
          description: '',
          address1: pj.contacts[0].addrQuote,
          address2: pj.contacts[0].addrProject,
          email: pj.contacts[0].email,
          postcode: '',
          city: '',
          country: '',
          phone: '',
          code: pj.contacts[0].codeCompany
        };

        projectCreated.company = { ...company };
        const contacts = [
          {
            lastname: pj.contacts[0].clientLastName,
            firstname: pj.contacts[0].clientName,
            poste: pj.contacts[0].clientRank,
            role: 'administrator',
            email: pj.contacts[0].email,
            phone: pj.contacts[0].phoneNumber,
            code: pj.contacts[0].code
          },
          {
            lastname: pj.contacts[1].clientLastName,
            firstname: pj.contacts[1].clientName,
            poste: pj.contacts[1].clientRank,
            role: 'technician',
            email: pj.contacts[1].email,
            phone: pj.contacts[1].phoneNumber,
            code: pj.contacts[1].code
          }
        ];
        projectCreated.contacts = [...contacts];
        const problematics = pj.problematics.map((it) => {
          const probProduct = Array.isArray(it.products)
            ? it.products.map((p) => {
                return {
                  code: p.code,
                  label: p.label
                };
              })
            : [];
          if (probProduct.length === 0) {
            try {
              const { code, label } = it.category[0].products[0];
              probProduct.push({
                code,
                label
              });
            } catch (error) {
              console.error(error);
            }
          }
          const myScope = it.scope ? { ...it.scope, installation: { ...it.scope.inOut } } : {};
          delete myScope.inOut;
          return {
            code: it.code,
            label: it.label,
            products: [...probProduct],
            scope: { ...myScope }
          };
        });

        projectCreated.problematics = problematics;
        const building =
          pj.building.type && pj.building.type.code !== 10
            ? {
                type: {
                  code: pj.building.type.code,
                  label: pj.building.type.label
                },
                floors: pj.building.floorsNb,
                ignoredFloorsNb: pj.building.ignoredFloorsNb
              }
            : {
                type: {
                  code: pj.building.type.code,
                  label: pj.building.type.label
                }
              };
        try {
          if (pj.building.glazingType) {
            building.glazing_type = {
              code: pj.building.glazingType.code,
              label: pj.building.glazingType.label
            };
          }
        } catch (error) {
          console.error(error);
        }
        try {
          if (pj.building.roofType) {
            building.roof_type = {
              code: pj.building.roofType.code,
              label: pj.building.roofType.label
            };
          }
        } catch (error) {
          console.error(error);
        }
        try {
          if (pj.building.surfaceSelected) {
            building.surface = {
              code: pj.building.surfaceSelected.code
            };
          }
        } catch (error) {
          console.error(error);
        }
        projectCreated.building = { ...building };
        const projectPayload = { project: { ...projectCreated } };
        console.log(projectPayload);
        const res = await axios.post(`${API_URL.projectCreate}`, projectPayload);
        if (res && res.data) {
          dispatch(initProjectCreated(res.data.data.project));
          dispatch(setProjectChanged());
          dispatch(getAllProjects());
        } else {
          throw new Error('Création de projet est échouée');
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    }
  };
}
const PROJECT_MODEL = {
  name: 'Lorem Ipsum',
  description: 'Project description if available.',
  city: 'Project location a city for exemple',

  company: {
    name: 'SARL Duchemin',
    type: 'societe',
    description: '',
    address1: '6 Rue Balzac',
    address2: '',
    postcode: '78000',
    city: 'Paris',
    country: 'France',
    phone: '0662723252',
    email: 'infos@duchemin.fr',
    url: ''
  },
  contacts: [
    {
      lastname: 'Leguin',
      firstname: 'Jean',
      poste: 'Directeur',
      role: 'administrator',
      email: 'leguin@duchemin.fr',
      phone: '0662723252'
    },
    {
      lastname: 'Rodriguez',
      firstname: 'Jean',
      poste: '',
      role: 'technician',
      email: 'rodriguez@duchemin.fr',
      phone: '0662723252'
    }
  ],
  problematics: [
    {
      code: '1',
      label: 'Chaleur excessives',
      products: [
        {
          code: '1',
          label: 'FDFD656565'
        },
        {
          code: '2',
          label: 'FDFDdfsdf656565'
        }
      ],
      scope: {
        installation: {
          code: 'ext',
          label: 'Extérieur'
        },
        floors: {
          code: 'all',
          label: 'Tous les étages'
        },
        rooms: {
          code: 1,
          label: '1 pièce'
        }
      }
    },
    {
      code: '5',
      label: 'Effraction',
      products: [
        {
          code: '1',
          label: 'FDFD656565'
        }
      ],
      scope: {
        installation: {
          code: 'ext',
          label: 'Extérieur'
        },
        floors: {
          code: 'all',
          label: 'Tous les étages'
        },
        rooms: {
          code: 1,
          label: '1 pièce'
        }
      }
    }
  ],
  building: {
    type: {
      code: '3',
      label: 'Établissement scolaire'
    },
    surface: {
      code: 1
    },
    glazing_type: {
      code: 2,
      label: 'Double'
    },
    roof_type: {
      code: 2,
      label: 'Plat'
    },
    occupation_type: {
      code: 1,
      label: 'Jour'
    },
    access: {
      code: 1,
      label: 'Libre'
    },
    floors: 3,
    height: 1800,
    year: 1980,
    ignoredFloorsNb: false
  }
};
