import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isTechProfile } from 'config/appConfig';
import LoadingScreen from 'components/LoadingScreen';
import useAuth from '../hooks/useAuth';
import { PATH_PAGE, PATH_COM } from '../config/paths';

// ----------------------------------------------------------------------
export default function RequireTech({ children }) {
  const { isLoading, user, isAuthenticated } = useAuth();
  const location = useLocation();
  if (isLoading) {
    return <LoadingScreen />;
  }
  return isAuthenticated === true && isTechProfile(user) ? (
    children
  ) : (
    <Navigate to="/401" replace state={{ path: location.pathname }} />
  );
}
