import React, { useState, useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { useTheme, styled, alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {
  MARGE_BAR_DESKTOP,
  TOP_BAR_TECHPROJECT_DESKTOP,
  APP_BAR_MOBILE,
  APP_BAR_DESKTOP
} from 'config/appConfig';
import useTechEditProject from 'hooks/useTechEditProject';
import InvoiceInfos from './invoice/InvoiceInfos';
import TechEditOrderBillTable from './bill/TechEditOrderBillTable';
import InvoicePayment from './invoice/InvoicePayment';
import EstimateDiscount from './bill/EstimateDiscount';
import PaymentCondition from './bill/PaymentCondition';
import InvoiceModalities from './invoice/InvoiceModalities';

// ----------------------------------------------------------------------
const MBuildingStyle = styled(Box)(({ theme }) => ({
  width: '80%',
  minHeight: `110vh`,
  background: '#F9F9F9',
  boxShadow: '0px 8px 2,5px rgba(0, 0, 0, 0.15)',
  borderTopRightRadius: '5px',
  borderTopLeftRadius: '5px',
  padding: theme.spacing(6, 4, 0, 4)
}));

export default function TechEditBillingInvoice({ ...props }) {
  const { project } = useOutletContext();
  const { orders } = useTechEditProject();
  const { orderBill } = useTechEditProject();
  const theme = useTheme();
  if (!orderBill) return <></>;
  console.log(orderBill.invoice);
  return (
    <Box display="flex" justifyContent="center" alignItems="center" sx={{ height: 'auto' }}>
      <MBuildingStyle>
        {orderBill.invoice && orderBill.invoice.code ? (
          <>
            <InvoiceInfos project={project} />
            <TechEditOrderBillTable />
            <Box
              display="flex"
              justifyContent="flex-end"
              alignItems="center"
              sx={{ margin: theme.spacing(3, 0, 3, 0) }}
            >
              <EstimateDiscount project={project} />
            </Box>
            <InvoicePayment />
            <PaymentCondition />
            <InvoiceModalities />
          </>
        ) : (
          <Box display="flex" justifyContent="center" alignItems="center">
            <Typography variant="h3" sx={{ color: '#52A2F9', fontFamily: 'ProximaMedium' }}>
              La facture n'est pas encore générée pour ce devis
            </Typography>
          </Box>
        )}
      </MBuildingStyle>
    </Box>
  );
}
